import React from "react";
import { Stack, Typography } from "@mui/material";
import BaseModal from "../BaseModal/BaseModal";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";

interface CustomErrorModalProps {
  errorMessageModal?: boolean;
  setErrorMessageModal?: (boolean) => void;
  closeErrorMessageModal?: () => void;
}

const CustomErrorModal: React.FC<CustomErrorModalProps> = ({
  errorMessageModal,
  setErrorMessageModal,
  closeErrorMessageModal,
}: CustomErrorModalProps) => {
  return (
    <BaseModal
      open={errorMessageModal}
      setVisibility={setErrorMessageModal}
      headerText="Error"
    >
      <Stack spacing={4} sx={{ maxWidth: "275px" }}>
        <Typography variant="body1">There was a problem.</Typography>
        <Typography variant="body1">
          Please try again. If this issue continues, please contact YAPI.
        </Typography>
        <Stack
          sx={{ width: "100%", height: "36px" }}
          alignItems="center"
          justifyContent="center"
        >
          <CtaButton
            width={204}
            ctaText="OK"
            clickHandler={() => closeErrorMessageModal()}
            variant="contained"
          />
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default CustomErrorModal;
