import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";

import { useAppSelector } from "../../../../../global/hooks/useTypedRedux.hook";
import StretchedModal from "../../../../../global/components/Modals/StretchedModal/StretchedModal";
import { selectSelectedPatientId } from "./patientSelectorSlice";
import { PatientResults } from "./patientResults/PatientResults";
import PatientSearch from "./patientSearch/PatientSearch";
import { TPatient } from "../../../../../global/domains/patients/types/TPatient.type";

type TPatientSelectorModal = {
  changeVisibility: (open: boolean) => void;
  isOpen: boolean;
  ctaButtonHandler: () => void;
  headerText: string;
  ctaText: string;
  bodyText: string;
  clearState: () => void;
  onPatientCardClick: (patient: TPatient) => Promise<void>;
};

export const PatientSelectorModal = ({
  changeVisibility,
  isOpen,
  ctaButtonHandler,
  headerText,
  ctaText,
  bodyText,
  clearState,
  onPatientCardClick,
}: TPatientSelectorModal) => {
  const selectedPatientId: string = useAppSelector(selectSelectedPatientId);

  useEffect((): void => {
    if (isOpen) {
      clearState();
    }
  }, [isOpen]);

  return (
    <StretchedModal
      changeVisibility={changeVisibility}
      headerText={headerText}
      isOpen={isOpen}
    >
      <Typography variant="body1" mb={2}>
        {bodyText}
      </Typography>
      <PatientSearch />
      <PatientResults onPatientCardClick={onPatientCardClick} />
      <Box display="flex" justifyContent="center">
        <Box width={230} height={48}>
          <CtaButton
            clickHandler={ctaButtonHandler}
            disabled={!selectedPatientId}
            ctaText={ctaText}
          />
        </Box>
      </Box>
    </StretchedModal>
  );
};
