import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useGetPatientSummaryQuery } from "../../../../global/services/patientsApi";

import { updateAvailableOptionsSendTo } from "../common/patientSelectorModal/patientSelectorSlice";

import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { ISendToWrapper, IPatientInformation } from "./SendTo.interfaces";
import { useAppDispatch } from "../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../global/store";
import { ENecessaryPatientFields } from "./ENecessaryPatientFields";
import { InOfficePatientsSendTo } from "./InOfficePatientsSendTo";
import { PendingFormsSendTo } from "./PendingFormsSendTo";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { MOBILE } from "./constants";

const SendToWrapper = ({
  patientId,
  isRequestForm,
  updateSendTo,
  isRequiredDoB,
}: ISendToWrapper) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { watch, setValue } = useFormContext();

  const { data: patient } = useGetPatientSummaryQuery(patientId);

  const email: string = patient?.email;
  const dob: string = patient?.dob;
  const mobile: string = patient?.phones?.find(
    (phone) => phone?.phoneType === MOBILE
  )?.phone;
  const isDobValid = !isRequiredDoB || Boolean(dob);

  useEffect(() => {
    return () => {
      dispatch(updateAvailableOptionsSendTo([]));
    };
  }, []);

  const addMobileToMissedFields = (missedFields: string[]): void => {
    !mobile && missedFields.push(ENecessaryPatientFields.MOBILE_NUMBER);
  };

  const addDobToMissedFields = (missedFields: string[]): void => {
    isRequiredDoB && !dob && missedFields.push(ENecessaryPatientFields.DOB);
  };

  const addEmailToMissedFields = (missedFields: string[]): void => {
    !email && missedFields.push(ENecessaryPatientFields.EMAIL);
  };

  const patientTextInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(mobile)),
        missedFields,
      };
    }, [mobile, dob, isDobValid, isRequiredDoB]);

  const patientEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(email)),
        missedFields,
      };
    }, [email, dob, isDobValid, isRequiredDoB]);

  const patientTextAndEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled:
          patientTextInformation.disabled || patientEmailInformation.disabled,
        missedFields,
      };
    }, [
      patientTextInformation,
      patientEmailInformation,
      isRequiredDoB,
      email,
      mobile,
      dob,
    ]);

  const onChangeSendTo = (value: EFormAssignmentNotificationType): void => {
    if (updateSendTo) {
      dispatch(updateSendTo(value));
    }
  };

  return isRequestForm ? (
    <PendingFormsSendTo
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      patientTextAndEmailInformation={patientTextAndEmailInformation}
      dispatch={dispatch}
      onChange={onChangeSendTo}
      setValue={setValue}
    />
  ) : (
    <InOfficePatientsSendTo
      patientId={patientId}
      dispatch={dispatch}
      setValue={setValue}
      watch={watch}
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      onChange={onChangeSendTo}
    />
  );
};

export const SendTo = React.memo(SendToWrapper, areEqualProps);
