import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { IPatientDetails } from "../../types/documentManager/IPatientDetails";
import {
  dispatchFormAssignmentUpdates,
  fetchData,
  filterEnabledDevices,
  findPreviousDevice,
  handleFormAssignments,
} from "../../helperFunctions/api/formAssignmentHelpers";
import { TDevice } from "../../types/settings/DeviceSettings/TDevice.type";
import { IFormAssignmentViaAppointment } from "./pending-forms/IFormAssignmentViaAppointment";
import { EFormAssignmentNotificationType } from "../../enums/EFormAssignmentNotificationType";
import { TEN_MINUTES_IN_S } from "../../constants";

export const formAssignmentModalDataApi = createApi({
  reducerPath: "formAssignmentModalDataApi",
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/`
  ),
  endpoints: (builder) => ({
    getFormAssignmentModalData: builder.query<
      IPatientDetails,
      { patientId: string }
    >({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const patientResponseData = await fetchData<IPatientDetails>(
          `patients/inOffice/${args.patientId}`,
          "GET",
          fetchWithBQ
        );
        const devicesData = await fetchData<TDevice[]>(
          `devices`,
          "GET",
          fetchWithBQ
        );

        const enabledDevices = filterEnabledDevices(devicesData);

        const formAssignment: IFormAssignmentViaAppointment =
          patientResponseData?.data?.find(
            (obj) => obj.formAssignment
          )?.formAssignment;

        const deviceNotifications = formAssignment?.notifications.filter(
          ({ type }) => type === EFormAssignmentNotificationType.Device
        );
        const previousDevice = findPreviousDevice(
          enabledDevices,
          deviceNotifications
        );

        dispatchFormAssignmentUpdates(api, formAssignment);
        handleFormAssignments(api, formAssignment?.forms, formAssignment);

        return {
          data: {
            ...patientResponseData,
            previousDevice,
            enabledDevices,
          },
        };
      },
      keepUnusedDataFor: TEN_MINUTES_IN_S,
    }),
  }),
});

export const { useGetFormAssignmentModalDataQuery } =
  formAssignmentModalDataApi;
