import React from "react";
import { SingleForm } from "./SingleForm";
import { Package } from "./Package";
import { TGetFormsAndPackages } from "./types/TGetFormsAndPackages";

export const getFormsAndPackages = ({
  forms,
  packagesById,
  onRemoveForm,
  onRemovePackage,
}: TGetFormsAndPackages) => {
  const items: JSX.Element[] = [];
  let lastRenderedPackageId = "";
  for (const form of forms) {
    const { packageId, order } = form;
    const isSingleForm = !packageId;
    if (isSingleForm) {
      items.push(
        <SingleForm
          key={`single_${form.formId}_${order}`}
          form={form}
          onRemoveForm={onRemoveForm}
        />
      );
      continue;
    }

    const isNewPackage = packageId !== lastRenderedPackageId;
    if (isNewPackage) {
      const formPackage = packagesById[packageId];
      items.push(
        <Package
          key={packageId}
          formPackage={formPackage}
          onRemovePackage={onRemovePackage}
        />
      );
      lastRenderedPackageId = packageId;
    }
    items.push(
      <SingleForm
        key={`${packageId}-${form.formId}`}
        form={form}
        onRemoveForm={onRemoveForm}
      />
    );
  }
  return items;
};
