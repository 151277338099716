import {
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";

export const SAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(({ isSelected }) => ({
  backgroundColor: "#FFC1881A",
  paddingLeft: 16,
  paddingRight: 8,
  borderBottomLeftRadius: isSelected ? 0 : "inherit",
  borderBottomRightRadius: isSelected ? 0 : "inherit",
}));

export const SAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: "1px solid " + theme.palette.accent.main,
  borderBottomLeftRadius: "inherit",
  borderBottomRightRadius: "inherit",
}));

export const STitle = styled(Typography)({
  fontSize: "0.875rem !important",
  fontWeight: "bold",
});

export const SSubtitle = styled(Typography)({
  fontSize: "0.875rem !important",
  paddingTop: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
});
