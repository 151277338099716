import React, { FC, useEffect } from "react";

import { setInOfficeStateToDefault } from "./inOfficeModalSlice";

import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import StretchedModal from "../../../../../global/components/Modals/StretchedModal/StretchedModal";
import { InOfficeModalContent } from "./InOfficeModalContent";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IFormsAssignerModalProps } from "./types/IFormsAssignerModalProps";
import { FORM_ASSIGNER_MODAL_PADDING } from "./constants";
import { formAssignmentModalDataApi } from "../../../../../global/services/document-manager/formAssignmentModalDataApi";

const FormsAssignerModal: FC<IFormsAssignerModalProps> = ({
  isOpen,
  setVisibility,
  patientsListRefetch,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  // clear modal state during closing dialog
  useEffect(() => {
    dispatch(formAssignmentModalDataApi.util.resetApiState());
    return () => {
      if (isOpen) {
        dispatch(setInOfficeStateToDefault());
      }
    };
  }, [isOpen]);

  return (
    <StretchedModal
      headerText=""
      changeVisibility={setVisibility}
      isOpen={isOpen}
      padding={FORM_ASSIGNER_MODAL_PADDING}
    >
      <InOfficeModalContent
        isOpen={isOpen}
        setVisibility={setVisibility}
        patientsListRefetch={patientsListRefetch}
      />
    </StretchedModal>
  );
};

export const InOfficeFormsAssignerModal = React.memo(
  FormsAssignerModal,
  areEqualProps
);
