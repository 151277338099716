import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../../../../global/helperFunctions/api/baseQueryWithReauth";
import { IReturnPracticeInformation } from "../../../../../global/types/practice/IPracticeInformation";
import {
  IRecallReminderConfiguration,
  IRecallType,
} from "./IRecallRemindersApiContract";

const RECALL_REMINDERS_SETTINGS = "RECALL_REMINDERS_SETTINGS";
export const recallRemindersApi = createApi({
  reducerPath: "recallRemindersApi",
  tagTypes: [RECALL_REMINDERS_SETTINGS],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getRecallTypes: builder.query<IRecallType[], void>({
      query: () => "RecallTypes",
    }),

    setRecallReminderConfigurationDetails: builder.mutation<
      IReturnPracticeInformation,
      IRecallReminderConfiguration
    >({
      query(body) {
        return {
          url: "RecallReminderConfigurationDetails/current",
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: [RECALL_REMINDERS_SETTINGS],
    }),

    getRecallReminderConfigurationDetails: builder.query<
      IRecallReminderConfiguration,
      void
    >({
      query: () => "RecallReminderConfigurationDetails/current",
      providesTags: [RECALL_REMINDERS_SETTINGS],
    }),
  }),
});

export const {
  useGetRecallTypesQuery,
  useGetRecallReminderConfigurationDetailsQuery,
  useSetRecallReminderConfigurationDetailsMutation,
} = recallRemindersApi;
