import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../../global/hooks/useTypedRedux.hook";
import { selectIsLarge } from "../../../../../layout/layoutSlice";
import { InOfficeCardWrapper } from "../InOfficeCardWrapper";
import LargeAppointmentInfo from "./AppointmentInfo/LargeAppointmentInfo";
import SmallAppointmentInfo from "./AppointmentInfo/SmallAppointmentInfo";
import { FormsSection } from "../formsSection/FormsSection";
import { TInOfficePatientCard } from "./TInOfficePatientCard";

export const InOfficePatientCard = ({
  patient,
  isSelected,
}: TInOfficePatientCard) => {
  const isLarge: boolean = useAppSelector(selectIsLarge);

  const appointmentInfo = useMemo(
    () =>
      isLarge ? (
        <LargeAppointmentInfo inOfficePatient={patient} />
      ) : (
        <SmallAppointmentInfo inOfficePatient={patient} />
      ),
    [isLarge, patient]
  );

  const {
    formAssignment,
    patient: { id: patientId },
    officeTasks,
  } = patient;

  return (
    <InOfficeCardWrapper
      formAssignmentId={formAssignment?.id}
      isSelected={isSelected}
      patientId={patientId}
    >
      {appointmentInfo}
      <FormsSection
        officeTasks={officeTasks}
        patientId={patientId}
        formAssignment={formAssignment}
        isSelected={isSelected}
      />
    </InOfficeCardWrapper>
  );
};
