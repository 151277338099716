import React, { FC, ReactNode } from "react";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";

const SModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "16px 16px 32px",
  gap: "10px",
  width: "376px",
  minHeight: "276px",
  background: theme.palette.background.default,
  boxShadow: "0 4px 4px rgba(229, 229, 229, 0.25)",
  borderRadius: "8px",
}));

const SCancelButton = styled("button")(({ theme }) => ({
  border: 0,
  cursor: "pointer",
  background: theme.palette.background.default,
  height: "36px",
  width: "204px",
  textAlign: "center",
}));

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  onSecondaryButtonClick?: () => void;
  title: string;
  subtitle?: string;
  primaryText?: string;
  secondaryText?: string;
  children?: ReactNode;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  className?: string;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onPrimaryButtonClick,
  title,
  subtitle,
  children,
  primaryText,
  secondaryText,
  disablePrimary = false,
  disableSecondary = false,
  onSecondaryButtonClick = onClose,
  className,
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onClose={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClose();
      }}
      data-testid="modal"
    >
      <SModalBox
        onClick={(e) => e.stopPropagation()}
        m="auto"
        data-testid="modalContainer"
        className={className}
      >
        <Stack justifyContent={"center"}>
          <Box width={"100%"} display="flex" justifyContent="flex-end">
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography align="center" variant="h2">
            {title}
          </Typography>
          {subtitle && (
            <Typography align="center" variant="body2" paddingTop={2}>
              {subtitle}
            </Typography>
          )}

          <Box>{children}</Box>
          <Box m="auto" paddingTop={3}>
            <CtaButton
              data-testid="acceptButton"
              disabled={disablePrimary}
              ctaText={primaryText ? primaryText : "Accept"}
              clickHandler={onPrimaryButtonClick}
              width={206}
            />
          </Box>

          <Box m="auto" paddingTop={1}>
            <SCancelButton
              disabled={disableSecondary}
              data-testid="cancelButton"
              onClick={onSecondaryButtonClick}
            >
              <Typography align="center" variant="subtitle2" color={"#595959"}>
                {secondaryText ? secondaryText : "Cancel"}
              </Typography>
            </SCancelButton>
          </Box>
        </Stack>
      </SModalBox>
    </Modal>
  );
};
