export const INITIAL_FORM_POST_BODY = {
  id: null,
  name: null,
  author: "Unnamed User",
  pages: [
    {
      id: 0,
      isActive: true,
      pageNumber: 0,
      skip: false,
      skipLogic: null,
      components: [],
      type: "page",
      parent: null,
      pageName: { en: "Page 1", es: "Página 1", de: "Seite 1" },
    },
  ],
  settings: {
    surveyStatus: 2,
    renumber: 1,
    isPrinting: true,
    showNumbers: false,
    showOnePageView: false,
    global: {
      undoRedo: false,
      saveAnswersByEachPage: false,
      previewAnswersBeforeSubmit: true,
      isCombineAllowed: false,
      builderEditSurvey: {},
    },
    section: {
      content: {
        isCompleted: null,
        isActive: true,
        isLocked: false,
        desktop: "",
        menubar: "",
        navbar: "",
        toolbar: "",
      },
      welcome: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      header: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      footer: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      thankYou: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
    },
    builderSectionStatus: {
      content: {
        isCompleted: null,
        isActive: true,
        isLocked: false,
        desktop: "",
        menubar: "",
        navbar: "",
        toolbar: "",
      },
      welcome: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      header: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      footer: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
      thankYou: {
        isCompleted: null,
        isActive: false,
        isLocked: false,
        title: { en: "", es: "", de: "" },
        subtitle: { en: "", es: "", de: "" },
        logo: "",
        logoSettings: {},
        bgColor: "#fff",
        alignment: "bottom",
        fitType: "fill",
        bgImage: "",
        fullWidth: false,
      },
    },
    multiLanguages: {
      supported: ["en", "es", "de"],
      languages: ["en"],
      isMulti: false,
      selected: "en",
      defaultLang: "en",
    },
    dateTimeFormat: { time: "h:mm A", date: "MM/DD/YYYY" },
  },
  type: "Form",
  activeStatus: 2,
  tags: [],
  postSubmitLogic: null,
};
