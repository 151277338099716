import React, { Suspense } from "react";
import { Provider } from "react-redux";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { store } from "./global/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./global/helperFunctions/persistState/persistor";
import { getAuth0Config } from "./global/helperFunctions/auth0/auth0Config";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import pubnubConfig from "./global/mockData/pubnubconfig.json";
import Loading from "./global/components/ImageContainer/Loading";
import { createRoot } from "react-dom/client";

const container =
  document.getElementById("root") || document.createElement("div");
const root = createRoot(container);

const AppComponent = React.lazy(() => import("./App"));

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getAuth0Config();

const providerConfig: Auth0ProviderOptions = {
  domain: config.domain,
  clientId: config.clientId,
  audience: config.audience,
  redirectUri: `${window.location.origin}/loading`,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

const { publishKey, subscribeKey, uuid } = pubnubConfig;
const pubnub = new PubNub({
  //   yapi creds
  publishKey,
  subscribeKey,
  logVerbosity: true,
  uuid,
});

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<Loading />}>
        <Auth0Provider {...providerConfig}>
          <PubNubProvider client={pubnub}>
            <AppComponent />
          </PubNubProvider>
        </Auth0Provider>
      </Suspense>
    </PersistGate>
  </Provider>
);

serviceWorkerRegistration.register();
