import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../global/store";
import { TPatient } from "../../../../../global/domains/patients/types/TPatient.type";
import { TNamedEntity } from "../../../../../global/types/TNamedEntity.type";

interface PatientSelectorState {
  selectedDevice: { [patientId: string]: TNamedEntity<string> };
  hasSearched: boolean;
  isLoading: boolean;
  lastSearchText: string;
  pageIndex: number;
  patients: TPatient[];
  selectedPatientId: string;
  totalPatients: number;
  availableOptionsSendTo: number[];
}

const initialState: PatientSelectorState = {
  hasSearched: false,
  isLoading: false,
  lastSearchText: "",
  pageIndex: 0,
  patients: [],
  selectedDevice: {},
  selectedPatientId: "",
  totalPatients: 0,
  availableOptionsSendTo: [],
};

const patientSelectorSlice = createSlice({
  name: "assignFormPatientSelector",
  initialState,
  reducers: {
    clearState: (state) => {
      state.hasSearched = false;
      state.isLoading = false;
      state.lastSearchText = "";
      state.pageIndex = 0;
      state.patients = [];
      state.selectedDevice = {};
      state.selectedPatientId = "";
      state.totalPatients = 0;
      state.availableOptionsSendTo = [];
    },
    clearPatientResults: (state) => {
      state.patients = [];
      state.totalPatients = 0;
    },
    updateHasSearched: (state, action: PayloadAction<boolean>) => {
      state.hasSearched = action.payload;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updatePageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    updatePatients: (state, action: PayloadAction<TPatient[]>) => {
      state.patients = action.payload;
    },
    updateTotalPatients: (state, action: PayloadAction<number>) => {
      state.totalPatients = action.payload;
    },
    updateSelectedPatientId: (state, action: PayloadAction<string>) => {
      state.selectedPatientId = action.payload;
    },
    updateAvailableOptionsSendTo: (state, action: PayloadAction<number[]>) => {
      state.availableOptionsSendTo = action.payload;
    },
    updateSelectedDevice: (
      state,
      action: PayloadAction<{
        selectedDevice: TNamedEntity<string>;
        patientId: string;
      }>
    ) => {
      state.selectedDevice[action.payload.patientId] =
        action.payload.selectedDevice;
    },
    updateLastTextSearch: (state, action: PayloadAction<string>) => {
      state.lastSearchText = action.payload;
    },
  },
});

export const {
  clearState,
  clearPatientResults,
  updateHasSearched,
  updateIsLoading,
  updateLastTextSearch,
  updatePageIndex,
  updateSelectedDevice,
  updateSelectedPatientId,
  updatePatients,
  updateTotalPatients,
  updateAvailableOptionsSendTo,
} = patientSelectorSlice.actions;

export const selectSelectedDevice = (state: RootState) =>
  state.patientSelectorState.selectedDevice;

export const selectHasSearched = (state: RootState) =>
  state.patientSelectorState.hasSearched;

export const selectIsLoading = (state: RootState): boolean =>
  state.patientSelectorState.isLoading;

export const selectLastSearchText = (state: RootState) =>
  state.patientSelectorState.lastSearchText;

export const selectAvailableOptionsSendTo = (state: RootState) =>
  state.patientSelectorState.availableOptionsSendTo;

export const selectPageIndex = (state: RootState): number =>
  state.patientSelectorState.pageIndex;
export const selectPatients = (state: RootState): TPatient[] =>
  state.patientSelectorState.patients;

export const selectSelectedPatientId = (state: RootState): string =>
  state.patientSelectorState.selectedPatientId;

export const selectTotalPatients = (state: RootState): number =>
  state.patientSelectorState.totalPatients;

export default patientSelectorSlice.reducer;
