import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Control,
  Controller,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";

export interface FormTextInputPropTypes {
  /**
   * This name prop should come from the react-hook-form implementation in the parent component and should reflect it's usage. Without this the input will not connect to the form object and could cause a lot of errors.
   */
  name: string;
  /**
   * This text will display in the text field as a label until the field is clicked
   */
  parentLabel?: string | JSX.Element;
  /**
   * If true, the field will be disabled
   */
  // disabled={!email}
  disabled?: boolean;
  /**
   * If true, this component will render as required. This will need to be paired with the validation schema via yup or react-hook-form to ensure stability.
   */
  required?: boolean;
  /**
   * This text will display in the text field after the field is clicked
   */
  placeholder?: string;
  /**
   * Sets the character limit for the text field
   */
  characterLimit?: number;
  /**
   * The title attribute specifies extra information about an element. The information is most often shown as a tooltip text when the mouse moves over the element.
   */
  title?: string;
  /**
   * Adds regex to the text field
   */
  regexType?: string;
  controlProps?: Control<FieldValues, any>;
  endAdornment?: JSX.Element | string;
  keyPressEnter?: boolean;
  onSubmit?: () => void;
  readOnly?: boolean;
  maxValue?: number;
  /**
   * If true, remove the padding on the the formTextInput
   */
  noFormLabel?: boolean;
  textAlignment?: string;
  /**
   * need to trigger validation immediately, without waiting for onBlur event
   */
  forceValidation?: boolean;
  dataTestid?: string;
  className?: string;
}

// continue to add enum when different regex types expand.
enum RegexTypeEnum {
  name = "name",
  number = "number",
}

const RootDiv = styled("div")(() => ({
  flexGrow: 1,
}));

export const StyledTextInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderBottomColor: theme.palette.text.secondary,
  "& label.Mui-focused": {
    color: theme.palette.text.secondary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.text.secondary,
  },
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.text.secondary,
  },
  "& .Mui-disabled": {
    color: theme.palette.text.disabled,
    borderBottomColor: theme.palette.text.disabled,
  },
  ".MuiInputLabel-asterisk": {
    color: theme.palette.error.main,
  },
  ".MuiInput-root": {
    paddingLeft: "10px",
    paddingTop: "20px",
  },
}));

export const FormTextInput: React.FC<FormTextInputPropTypes> = ({
  name,
  parentLabel = "",
  disabled = false,
  required = false,
  placeholder = "",
  regexType = null,
  characterLimit,
  controlProps,
  endAdornment,
  readOnly = false,
  onSubmit,
  maxValue = null,
  noFormLabel = false,
  textAlignment = "left",
  forceValidation = false,
  dataTestid = "inputPropsTestId",
  className = "",
  title = "",
}: FormTextInputPropTypes) => {
  const { control } = useFormContext();
  const controller = useController({
    name,
    control,
  });
  const NAME_REGEX = /^([^0-9]*)$/;
  const NUMBER_REGEX = /^([0-9]*)$/;
  return (
    <RootDiv>
      <Controller
        control={controlProps || control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <StyledTextInput
              title={title || value}
              className={className}
              focused={false}
              fullWidth
              data-testid="formTextInput"
              onKeyPress={(event) => {
                if (Boolean(onSubmit) && event.key === "Enter") {
                  onSubmit();
                }
              }}
              onBlur={controller.field.onBlur}
              onKeyDown={(event) => {
                switch (regexType) {
                  case RegexTypeEnum.name:
                    if (!NAME_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                    break;
                  case RegexTypeEnum.number:
                    if (
                      !NUMBER_REGEX.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight" &&
                      event.key !== "Tab"
                    ) {
                      event.preventDefault();
                    }
                    break;
                }
              }}
              onChange={(e) => {
                if (regexType === RegexTypeEnum.number && maxValue) {
                  if (
                    parseInt(e.target.value, 10) <= maxValue ||
                    e.target.value === ""
                  ) {
                    onChange(e.target.value);
                  }
                } else {
                  onChange(
                    // https://stackoverflow.com/questions/10992921/how-to-remove-emoji-code-using-javascript/63464318#63464318 article to remove emoji.
                    e.target.value.replace(
                      /[^\p{L}\p{N}\p{P}\p{Z}^$=+`~<>\n]/gu,
                      ""
                    )
                  );
                }
              }}
              value={value || ""}
              label={parentLabel}
              InputLabelProps={{ shrink: true }}
              helperText={(forceValidation || error) && error?.message}
              disabled={disabled}
              required={required}
              error={Boolean(forceValidation || error)}
              placeholder={placeholder}
              inputProps={{
                "data-testid": dataTestid,
                "aria-label": name,
                maxLength: characterLimit,
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textAlign: textAlignment as any,
                },
              }}
              InputProps={{
                endAdornment,
                readOnly: readOnly,
                style: {
                  paddingTop: noFormLabel ? "5px" : "20px",
                },
              }}
              variant={"standard" as any}
            />
          );
        }}
      />
    </RootDiv>
  );
};
