import React, { useState } from "react";
import { Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useAppSelector } from "../../../global/hooks/useTypedRedux.hook";
import {
  selectCurrentBreakpoint,
  selectIsLarge,
} from "../../layout/layoutSlice";

import { getDateStringShortDate } from "../../../global/helperFunctions/text/getDateStringShortDate";

import PatientImageEditor from "../../../global/domains/patients/components/PatientImageEditor";
import { SettingsNavIcon } from "@yapi/yapi2.icons.settings-nav-icon";
import { ifTextNull } from "../../../global/helperFunctions/text/ifTextNull";
import PhotoEditorModal from "../../../global/components/Modals/PhotoEditorModal/PhotoEditorModal";
import Routes from "../../../global/routes/routes";
import { useAppHistory } from "../../../global/hooks/useAppHistory";
import { PillButton } from "../../../global/components/PillButton/PillButton";
import { IPatientSummary } from "../../../global/domains/patients/types/IPatientSummary.interface";
import defaultAvatarImg from "../../../assets/PatientProfile/avatar.svg";
import { formatPatientName } from "../../../global/helperFunctions/patients/formatPatientName";

interface ProfileHeaderPropTypes {
  hidePreferences?: boolean;
  patient: IPatientSummary;
  paddingBottom?: number;
}

const PreferencesButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "120px",
    height: "32px",
  },
  [theme.breakpoints.up("md")]: {
    width: "161px",
    height: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "134px",
    height: "35px",
  },
}));

const ProfileHeader: React.FC<ProfileHeaderPropTypes> = ({
  paddingBottom = 5,
  patient,
  hidePreferences = false,
}: ProfileHeaderPropTypes) => {
  const {
    firstName,
    middleInitial,
    lastName,
    preferredName,
    age,
    dob,
    chartId,
    id,
  } = patient;
  // React Hooks
  const [photoEditorModal, setPhotoEditorModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  // RTK
  const isLarge = useAppSelector(selectIsLarge);
  const currentBreakpoint = useAppSelector(selectCurrentBreakpoint);
  const history = useAppHistory();
  // Utility Functions
  const formattedDob = getDateStringShortDate(dob);
  const formattedPatientName = formatPatientName(
    firstName,
    middleInitial,
    lastName,
    preferredName
  );

  // Render Functions
  const headerSmall = (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      pb={paddingBottom}
    >
      <PatientImageEditor
        editEnabled={false}
        size={currentBreakpoint}
        image={defaultAvatarImg}
        setPhotoEditorModal={setPhotoEditorModal}
        setPreviewImage={setPreviewImage}
      />
      <Stack direction="column" sx={{ paddingLeft: "16px" }}>
        <Typography variant="h5" data-testid="patient-name">
          {ifTextNull(formattedPatientName)}
        </Typography>
        <Stack direction="row">
          <Typography variant="body2" color={!dob && "red"}>
            DOB
          </Typography>
          <Typography variant="body2">{`: ${ifTextNull(
            formattedDob
          )} (Age ${ifTextNull(age)})`}</Typography>
        </Stack>
        <Typography variant="body2">{`Chart #: ${ifTextNull(
          chartId
        )}`}</Typography>
        {!hidePreferences && (
          <PreferencesButton>
            <PillButton
              buttonText={"Preferences"}
              pillButtonClickHandler={() =>
                history.push(Routes.preferences(id))
              }
              startIcon={
                <SettingsNavIcon
                  size={currentBreakpoint}
                  disabled={false}
                  selected={false}
                />
              }
            />
          </PreferencesButton>
        )}
      </Stack>
    </Stack>
  );
  const headerLarge = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pb={paddingBottom}
    >
      <Stack direction="row">
        <PatientImageEditor
          editEnabled={false}
          size={currentBreakpoint}
          image={defaultAvatarImg}
          setPhotoEditorModal={setPhotoEditorModal}
          setPreviewImage={setPreviewImage}
        />
        <Stack direction="column" sx={{ paddingLeft: "16px" }}>
          <Typography variant="h5" data-testid="patient-name">
            {ifTextNull(formattedPatientName)}
          </Typography>
          <Stack direction="row">
            <Typography variant="body2" color={!dob && "red"}>
              DOB
            </Typography>
            <Typography variant="body2">{`: ${ifTextNull(
              formattedDob
            )} (Age ${ifTextNull(age)})`}</Typography>
          </Stack>
          <Typography variant="body2">{`Chart ID: ${ifTextNull(
            chartId
          )}`}</Typography>
        </Stack>
      </Stack>
      {!hidePreferences && (
        <PreferencesButton>
          <PillButton
            buttonText={"Preferences"}
            pillButtonClickHandler={() => history.push(Routes.preferences(id))}
            startIcon={
              <SettingsNavIcon size={"md"} disabled={false} selected={false} />
            }
          />
        </PreferencesButton>
      )}
    </Stack>
  );

  return (
    <div data-testid="profileHeader">
      {isLarge ? headerLarge : headerSmall}
      <PhotoEditorModal
        type="patient"
        borderType="round"
        headerText="Profile Photo"
        open={photoEditorModal}
        image={defaultAvatarImg}
        setPhotoEditorModal={setPhotoEditorModal}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
      />
    </div>
  );
};

export default ProfileHeader;
