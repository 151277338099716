import React from "react";
import { Grid, Stack, styled } from "@mui/material";
import { CameraIcon } from "@yapi/yapi2.icons.camera-icon";
import avatar_img from "../../../../assets/no_avatar.svg";
import user_img from "../../../../assets/no_user_photo.svg";
interface PhotoEditorProps {
  editEnabled: boolean;
  size?: string;
  image?: string;
  setPhotoEditorModal: (boolean) => void;
  setPreviewImage: (string) => void;
  type?: "user" | "patient";
}

enum PhotoEditorTypeEnum {
  user = "user",
  patient = "patient",
}

const PhotoEditorBackground = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "enabled",
})<{ enabled: boolean }>(({ theme, enabled }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "77px",
    height: "77px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90px",
    height: "90px",
  },
  [theme.breakpoints.up("md")]: {
    width: "115px",
    height: "115px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "77px",
    height: "77px",
  },
  backgroundColor: theme.palette.background.default,
  position: "relative",
  borderRadius: "25px",
  cursor: enabled ? "pointer" : null,
}));

export const PhotoBackground = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "77px",
    height: "77px",
    borderRadius: "40px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90px",
    height: "90px",
    borderRadius: "45px",
  },
  [theme.breakpoints.up("md")]: {
    width: "115px",
    height: "115px",
    borderRadius: "60px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "77px",
    height: "77px",
    borderRadius: "40px",
  },
  position: "relative",
}));

const EditPhotoLayover = styled(Grid)(({ theme }) => ({
  position: "absolute",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  bottom: "0px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "23px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "33px",
  },
  [theme.breakpoints.up("md")]: {
    height: "33px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "23px",
  },
  cursor: "pointer",
}));

const PatientImageEditor: React.FC<PhotoEditorProps> = ({
  editEnabled,
  size,
  image,
  setPhotoEditorModal,
  setPreviewImage,
  type,
}: PhotoEditorProps) => {
  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const url = URL.createObjectURL(e.target.files[0]);
      setPreviewImage(url);
      setPhotoEditorModal(true);
    }
  };

  const handlePhotoEditorClick = (e) => {
    if (editEnabled) {
      if (image) {
        setPhotoEditorModal(true);
      }
    } else {
      e.stopPropagation();
    }
  };

  return (
    <>
      {image ? (
        <PhotoEditorBackground
          alignItems="center"
          justifyContent="center"
          onClick={handlePhotoEditorClick}
          enabled={editEnabled}
        >
          <PhotoBackground src={image} alt="test" id="select_logo" />
          {editEnabled && (
            <EditPhotoLayover
              container
              item
              justifyContent="center"
              alignItems="center"
            >
              <CameraIcon size={size === "md" || size === "sm" ? "md" : "xs"} />
            </EditPhotoLayover>
          )}
        </PhotoEditorBackground>
      ) : (
        <label htmlFor="select_logo">
          <input
            type="file"
            name="select_logo"
            id="select_logo"
            onChange={onFileChange}
            accept=".jpg, .jpeg, .png"
            style={{ display: "none" }}
          />
          <PhotoEditorBackground
            enabled={editEnabled}
            alignItems="center"
            justifyContent="center"
            onClick={handlePhotoEditorClick}
          >
            <PhotoBackground
              src={type === PhotoEditorTypeEnum.user ? user_img : avatar_img}
              alt="test"
              id="select_logo"
            />
            {editEnabled && (
              <EditPhotoLayover
                container
                item
                justifyContent="center"
                alignItems="center"
                id="logo"
              >
                <CameraIcon
                  size={size === "md" || size === "sm" ? "md" : "xs"}
                />
              </EditPhotoLayover>
            )}
          </PhotoEditorBackground>
        </label>
      )}
    </>
  );
};

export default PatientImageEditor;
