import React, { ReactNode } from "react";
import BaseModal from "../BaseModal/BaseModal";

type TStretchedModal = {
  isOpen: boolean;
  changeVisibility: (value: boolean) => void;
  children: ReactNode;
  gap?: number;
  padding?: string;
  headerText: string;
};

const StretchedModal = ({
  isOpen,
  changeVisibility,
  children,
  headerText,
  padding = "16px",
}: TStretchedModal) => {
  return (
    <BaseModal
      setVisibility={changeVisibility}
      open={isOpen}
      headerText={headerText}
      sxDiag={{ minHeight: "500px" }}
      sxBody={{
        minHeight: 0,
        height: "100%",
        display: "flex",
        flex: 2,
        gap: 0,
        flexDirection: "column",
        width: "100%",
        padding,
      }}
    >
      {children}
    </BaseModal>
  );
};

export default StretchedModal;
