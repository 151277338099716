import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

import { ContextMenuButton } from "../../../../../../../global/components/ContextMenu/ContextMenuButton";
import { TOption } from "../../../../../../../global/components/ContextMenu/Option.type";
import { DeleteFormModal } from "../../deleteFormModal/DeleteFormModal";
import { SRowWrapper } from "./styles";
import { TFormRow } from "./types/TFormRow";

export const FormRow = ({
  children,
  name,
  formAssignmentId,
  formId,
  patientId,
  responseId,
  customOptions = [],
  hideOptions = false,
  hideDelete = false,
  formNameClickHandler,
}: TFormRow) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModalHandler = (): void => {
    setIsDeleteModalOpen(true);
  };

  const baseOptions: TOption[] = hideDelete
    ? []
    : [
        {
          label: "Delete",
          callBack: openDeleteModalHandler,
        },
      ];

  const options = [...customOptions, ...baseOptions];

  const nameClickHandler = formNameClickHandler || undefined;

  return (
    <SRowWrapper>
      <DeleteFormModal
        responseId={responseId}
        formAssignmentId={formAssignmentId}
        formId={formId}
        isOpen={isDeleteModalOpen}
        name={name}
        patientId={patientId}
        setIsOpen={setIsDeleteModalOpen}
      />
      <Box display="flex" flexDirection="column">
        <Typography
          onClick={nameClickHandler}
          fontWeight="bold"
          color="secondary"
        >
          {name}
        </Typography>
        {children || null}
      </Box>
      {hideOptions ? null : <ContextMenuButton options={options} />}
    </SRowWrapper>
  );
};
