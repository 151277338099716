import { checkIsAllFormsFromOnePackage } from "../../../../global/helperFunctions/documentManager/checkIsAllFormsFromOnePackage";
import { ISelectedPackageForm } from "../../../../global/types/documentManager/ISelectedPackageForm.interface";
import { ISelectedForm } from "../../../../global/types/documentManager/ISelectedForm.interface";
import { numberOfElementsInArrays } from "../../../../global/helperFunctions/arrays/numberOfElementsInArrays";

export const getShowCustomPackageName = (
  selectedForms: ISelectedForm[],
  submittedForms: ISelectedForm[]
): boolean => {
  const formsThatNeedToCheck = [];
  selectedForms && formsThatNeedToCheck.push(...selectedForms);
  submittedForms && formsThatNeedToCheck.push(...submittedForms);
  const isAllFormsFromOnePackage: boolean = checkIsAllFormsFromOnePackage(
    formsThatNeedToCheck as ISelectedPackageForm[]
  );

  if (isAllFormsFromOnePackage) {
    return false;
  }
  // in packageName visibility also includes submittedForms
  return numberOfElementsInArrays([selectedForms, submittedForms]) > 1;
};
