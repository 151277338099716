const rule = /[`~!@#$%^&*(_)\-+=:|}\]{[';,."<>\\/?]/g;

export const escapeSpecialCharacters = (
  word: string,
  regex: RegExp = rule
): string => {
  if (!word || typeof word !== "string") {
    return word;
  }

  return word.replace(regex, (symbol: string): string => {
    return `\\${symbol}`;
  });
};
