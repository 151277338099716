import dayjs from "dayjs";

export const getAge = (dob: string) => {
  const date = dayjs(dob);
  const year = date.year();
  return `Age ${new Date().getFullYear() - year}`;
};

export const getDateStringShortDate = (date: string) => {
  return date ? dayjs(date).format("MMM D, YYYY") : "-";
};
