import React from "react";
import { Virtualizer } from "@tanstack/react-virtual";
import { InfiniteScrollRow } from "./InfiniteScrollRow";
import { styled } from "@mui/material";

const SVirtualizedTable = styled("div", {
  shouldForwardProp: (prop) => prop !== "height",
})(() => ({
  height: "100%",
  width: "calc(100% - 8px)",
  position: "relative",
}));

export type TVirtualizedTable = {
  renderItem: (index: number) => JSX.Element;
  rowVirtualizer: Virtualizer<undefined, Element>;
  totalCount: number;
  hideEndOfResultsText?: boolean;
  className?: string;
};

export function VirtualizedTable({
  rowVirtualizer,
  renderItem,
  totalCount,
  hideEndOfResultsText,
  className,
}: TVirtualizedTable) {
  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <SVirtualizedTable className={className}>
      {virtualItems.map((virtualRow) => (
        <InfiniteScrollRow
          key={virtualRow.index}
          rowVirtualizer={rowVirtualizer}
          virtualRow={virtualRow}
          renderItem={renderItem}
          isLastRow={
            hideEndOfResultsText ? false : virtualRow.index === totalCount - 1
          }
        />
      ))}
    </SVirtualizedTable>
  );
}
