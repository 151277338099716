import React, { FC, useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";

import {
  updateInOfficeAppointmentId,
  setDefaultStateForAllFamilyMembers,
  selectInOfficePatientsStateById,
  IPatientStateById,
} from "./inOfficeModalSlice";

import { AppDispatch } from "../../../../../global/store";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";
import { FamilyMembersErrorModal } from "./FamilyMembersErrorModal";
import { InOfficeModalBody } from "./InOfficeModalBody";
import { useGetInOfficeModalState } from "./useGetInOfficeModalState";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { getPatientTabList } from "./getPatientTabList";
import { IModalContentProps } from "./types/IModalContentProps";

const ModalContent: FC<IModalContentProps> = ({
  isOpen,
  setVisibility,
  patientsListRefetch,
}): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const {
    isLoading,
    isFamilyMemberErrorModalVisible,
    patient,
    setFamilyMemberErrorModalVisibility,
    refetchFamilyMembers,
    familyMembers,
    patientAppointments,
  } = useGetInOfficeModalState(isOpen);
  const isErrorModalShow: boolean = patient && isFamilyMemberErrorModalVisible;
  const patientState: { [key: string]: IPatientStateById } = useAppSelector(
    selectInOfficePatientsStateById
  );

  useEffect((): void => {
    const currentAppointmentId: string = patientAppointments?.[0]?.id;

    if (currentAppointmentId) {
      dispatch(
        updateInOfficeAppointmentId({
          appointmentId: currentAppointmentId,
          patientId: patient?.id,
        })
      );
    }
  }, [patientAppointments]);

  const patientTabList: IPatientFamilyMember[] = useMemo(() => {
    if (patient && familyMembers) {
      return getPatientTabList(patient, familyMembers);
    }
    return [];
  }, [patient, familyMembers]);

  useEffect((): void => {
    if (patientTabList.length) {
      const patientIds: string[] = patientTabList.map(
        (tab: IPatientFamilyMember) => tab.id
      );
      dispatch(setDefaultStateForAllFamilyMembers(patientIds));
    }
  }, [patientTabList]);
  // do not render dialog until patientState default values were set to redux
  if (isLoading || isEmpty(patientState)) return <ImageContainer />;

  if (isErrorModalShow) {
    return (
      <FamilyMembersErrorModal
        isOpen
        patientName={patient.preferredName}
        setVisibility={setFamilyMemberErrorModalVisibility}
        refetch={refetchFamilyMembers}
      />
    );
  }

  return (
    <InOfficeModalBody
      patientTabList={patientTabList}
      patient={patient}
      setVisibility={setVisibility}
      patientsListRefetch={patientsListRefetch}
    />
  );
};

export const InOfficeModalContent = React.memo(ModalContent, areEqualProps);
