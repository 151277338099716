import React from "react";
import { Box, useTheme } from "@mui/material";

import { IFormAssignmentForm } from "../../../../../../../../global/types/documentManager/InOfficePatient";
import { CategoryHeader } from "../CategoryHeader";
import { FormRow } from "../FormRow";
import { useDownloadOption } from "../options/useDownloadOption";
import { filedToPmsTask } from "../../../../../../../../global/constants/officeTasks";
import { OfficeTaskItem } from "../../../../inOfficePatientList/OfficeTaskItem";
import { getPmsName } from "../../../../../../../../global/helperFunctions/text/getPmsName";
import { useAppSelector } from "../../../../../../../../global/hooks/useTypedRedux.hook";
import { selectPmsType } from "../../../../../../../authentication/appSlice";
import {
  TAppHistory,
  useAppHistory,
} from "../../../../../../../../global/hooks/useAppHistory";
import Routes from "../../../../../../../../global/routes/routes";
import { ISubmittedFormRow } from "./ISubmittedFormRow";

const SubmittedFormRow = ({
  formAssignmentId,
  form,
  patientId,
}: ISubmittedFormRow) => {
  const download = useDownloadOption({ patientId, form });
  const pmsName: string = getPmsName(useAppSelector(selectPmsType));
  const history: TAppHistory = useAppHistory();

  const formNameClickHandler = (): void => {
    if (form?.name) {
      history.push(
        Routes.DocumentManager.inOfficeFormPreview(form.id, form?.responseId),
        form.name
      );
    }
  };

  return (
    <FormRow
      responseId={form.responseId}
      formAssignmentId={formAssignmentId}
      formId={form.id}
      name={form.name}
      patientId={patientId}
      customOptions={[download]}
      hideDelete
      formNameClickHandler={formNameClickHandler}
    >
      <OfficeTaskItem task={filedToPmsTask(pmsName)} />
    </FormRow>
  );
};

interface ISubmittedForms {
  formAssignmentId: string;
  submittedForms: IFormAssignmentForm[];
  patientId: string;
}
const SubmittedForms = ({
  formAssignmentId,
  submittedForms,
  patientId,
}: ISubmittedForms) => {
  const theme = useTheme();

  if (!submittedForms.length) return null;

  return (
    <Box display="flex" flexDirection="column">
      <CategoryHeader
        background={theme.palette.success.light}
        title="Successfully Imported"
      />
      {submittedForms.map((form) => (
        <SubmittedFormRow
          key={`submitted_${form.id}`}
          formAssignmentId={formAssignmentId}
          form={form}
          patientId={patientId}
        />
      ))}
    </Box>
  );
};

export default SubmittedForms;
