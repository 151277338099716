import React, { FC, useMemo } from "react";

import { AddCircle, ExpandMore } from "@mui/icons-material";

import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";
import {
  selectFormOptions,
  selectPackageOptions,
} from "../../documentManagerSlice";
import { TMenuOption } from "../../../../global/components/ContextMenu/ContextMenuWithChildren/ContextMenuWithChildren";
import { IFormData } from "../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { IFormPackage } from "../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { generateFormOptions } from "./actionButtons/helpers/generateFormOptions";
import { generatePackageOptions } from "./actionButtons/helpers/generatePackageOptions";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import AddFormControl from "../../../../global/components/AddFormControl";
import { IAddFormButtonProps } from "./types/IAddFormButtonProps";

const FormBtn: FC<IAddFormButtonProps> = ({
  handleFormSelection,
  handlePackageSelection,
  selectedPackagedIds,
  startIcon,
  startIconColor = "success",
  endIcon,
  buttonText = "Form",
  width,
  height,
  disabled,
  shouldCloseOnSelect = false,
}): JSX.Element => {
  const activePackages: IFormPackage[] = useAppSelector(selectPackageOptions);
  const forms: IFormData[] = useAppSelector(selectFormOptions);

  const StartIcon: JSX.Element = useMemo((): JSX.Element => {
    if (startIcon) {
      return startIcon;
    }

    if (startIcon === null) {
      return null;
    }

    return <AddCircle color={disabled ? "disabled" : startIconColor} />;
  }, [startIcon, startIconColor, disabled]);

  const EndIcon: JSX.Element = useMemo((): JSX.Element => {
    if (endIcon) {
      return endIcon;
    }

    if (endIcon === null) {
      return null;
    }

    return <ExpandMore fontSize="large" />;
  }, [endIcon]);

  const packageOptions: TMenuOption[] = generatePackageOptions(
    selectedPackagedIds,
    activePackages,
    handlePackageSelection
  );
  const formOptions: TMenuOption[] = generateFormOptions(
    forms,
    handleFormSelection
  );
  const options = [
    {
      label: "Packages",
      disabled: !packageOptions.length,
      children: packageOptions,
    },
    {
      label: "Forms",
      disabled: false,
      children: formOptions,
    },
  ];

  return (
    <AddFormControl
      options={options}
      buttonText={buttonText}
      startIcon={StartIcon}
      endIcon={EndIcon}
      width={width}
      height={height}
      disabled={disabled}
      shouldCloseOnSelect={shouldCloseOnSelect}
    />
  );
};

export const AddFormButton = React.memo(FormBtn, areEqualProps);
