import React, { useCallback } from "react";
import { Grid, Avatar, Box, Typography } from "@mui/material";

import { SecondaryText } from "../../../../../../../global/components/SecondaryText/SecondaryText";
import { get12HrTimeEpoch } from "../../../../../../../global/helperFunctions/epoch/get12HrTimeEpoch";
import NewPatientTag from "../../../../../../appointments/NewPatientTag";
import { PatientName } from "../../../../../../appointments/schedule/appointmentCard/small/PatientName";

import OptionsMenu from "../../optionsMenu/OptionsMenu";
import PatientTaskStatus from "../PatientTaskStatus/PatientTaskStatus";
import noAvatar from "../../../../../../../assets/no_avatar.svg";

import { TAppointmentInfo } from "../../types/TAppointmentInfo";
import { SCenteredGrid, SOfficeTaskList } from "../../../styles";

const SmallAppointmentInfo = ({
  inOfficePatient: {
    appointment,
    patient: {
      age,
      avatar,
      chartId,
      dateOfBirth,
      firstName,
      isNewPatient,
      id: patientId,
      lastName,
    },
    patient,
    checkInTime,
    officeTasks,
    formAssignment,
  },
}: TAppointmentInfo) => {
  const formAssignmentId = formAssignment?.id;
  const forms = formAssignment?.forms || [];

  const Left = useCallback(() => {
    return (
      <SCenteredGrid item xs={1}>
        <Avatar src={avatar || noAvatar} />
      </SCenteredGrid>
    );
  }, [avatar]);

  const Center = useCallback(
    () => (
      <Grid display="block" flexDirection="column" item xs={10}>
        <PatientName
          fontSize="1.2rem"
          firstName={firstName}
          lastName={lastName}
        />
        <SecondaryText>
          Age {age} | DOB: {dateOfBirth} | Chart #: {chartId}
        </SecondaryText>
        <Box display="flex" gap={1} py={1}>
          <Typography variant="body2" fontWeight="bold">
            Appt Time:{" "}
            {get12HrTimeEpoch(
              appointment?.startsAt ?? formAssignment?.dateCreated
            )}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            |
          </Typography>
          <SecondaryText>
            Time Checked-In: {checkInTime ? get12HrTimeEpoch(checkInTime) : "-"}
          </SecondaryText>
        </Box>
        <Typography variant="body2">
          Office To Do: {!officeTasks.length && "-"}{" "}
        </Typography>
        {officeTasks.length ? (
          <SOfficeTaskList officeTasks={officeTasks} />
        ) : null}
        <PatientTaskStatus forms={forms} />
      </Grid>
    ),
    [
      patient,
      officeTasks,
      forms,
      checkInTime,
      appointment?.startsAt,
      formAssignment?.dateCreated,
    ]
  );

  const Right = useCallback(
    () => (
      <SCenteredGrid xs={1} justifyContent="end">
        <Box
          display="flex"
          flexDirection="column"
          position="absolute"
          alignItems="center"
          top={0}
        >
          {isNewPatient && <NewPatientTag />}
        </Box>
        <OptionsMenu
          appointmentId={appointment?.id}
          formAssignmentId={formAssignmentId}
          forms={forms}
          patientId={patientId}
        />
      </SCenteredGrid>
    ),
    [isNewPatient]
  );

  return (
    <Box display="flex" width="100%" pl={2} pt={2} pr={1}>
      <Left />
      <Center />
      <Right />
    </Box>
  );
};

export default SmallAppointmentInfo;
