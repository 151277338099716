import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IGetResponse } from "../../types/apiResponse/IGetResponse.interface";
import { IPaging } from "../../types/apiResponse/Paging";
import { IInOfficePatient } from "../../types/documentManager/InOfficePatient";
import { queryStringBuilder } from "../../helperFunctions/api/queryStringBuilder";
import { IPatientFamilyMember } from "../../domains/patients/types/IPatientFamilyMember.interface";
import { IPatientCheckoutRequest } from "../../types/documentManager/IPatientCheckoutRequest";
import { IOutstandingTask } from "../../types/documentManager/InOfficeOutstandingTasks";
import { IIdentifiable } from "../../types/apiResponse/IIdentifiable.interface";
import { IPatientDetails } from "../../types/documentManager/IPatientDetails";
import { EOfficeTaskStatus } from "../../types/documentManager/EOfficeTaskStatus.enum";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { FORMS_TAG } from "../formsTag";
import { FIVE_MINUTES_IN_S } from "../../constants";
import { IGetOutstandingTaskParams } from "./outstanding-tasks/IGetOutstandingTaskParams";

export enum EOfficeTaskUserAction {
  Signed = 1,
  Reviewed,
  RefileToPmsRequested,
}

export type IOutstandingTasksParameters = {
  assignedProviderId?: string;
  status?: EOfficeTaskStatus;
  unassigned?: boolean;
  pageIndex: number;
  pageSize: number;
};

interface BodyCheckout {
  appointmentId?: string;
  formAssignmentId?: string;
}

export const inOfficePatientsApi = createApi({
  reducerPath: "inOfficePatientsApi",
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/`
  ),
  tagTypes: ["InOfficePatients", "OutStandingTasks", "PendingForms", FORMS_TAG],
  endpoints: (builder) => ({
    getInOfficePatients: builder.query<
      IGetResponse<IInOfficePatient[]>,
      Pick<IPaging, "pageIndex" | "pageSize">
    >({
      query: ({ pageIndex, pageSize }) => {
        const queryParams = queryStringBuilder({ pageIndex, pageSize });
        return `patients/inOffice?${queryParams}`;
      },
      providesTags: ["InOfficePatients"],
    }),

    getInOfficeFamilyMembers: builder.query<IPatientFamilyMember[], string>({
      query: (id) => `patients/${id}/familyMembersInOffice`,
    }),

    getCheckedInPatient: builder.query<IPatientDetails, string>({
      query: (patientId: string): string => `patients/inOffice/${patientId}`,
    }),

    checkout: builder.mutation<IIdentifiable, IPatientCheckoutRequest>({
      query: ({ patientId, appointmentId, formAssignmentId }) => {
        const body: Partial<BodyCheckout> = {};
        if (appointmentId) {
          body.appointmentId = appointmentId;
        } else {
          body.formAssignmentId = formAssignmentId;
        }

        return {
          url: `patients/${patientId}/checkOut`,
          method: "POST",
          body,
        };
      },
    }),

    getOutstandingTasks: builder.query<
      IGetResponse<IOutstandingTask[]>,
      IOutstandingTasksParameters
    >({
      query: (query) => {
        const filteredParams = Object.entries(query)
          .filter(([_key, value]) => !!value)
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        const queryParams = queryStringBuilder(filteredParams);
        return `officeTasks/outstanding?${queryParams}`;
      },
      providesTags: ["OutStandingTasks"],
    }),

    getOutstandingTask: builder.query<
      IGetResponse<IOutstandingTask[]>,
      IGetOutstandingTaskParams
    >({
      query: ({ responseId, formId }) =>
        `officeTasks/outstanding?responseId=${responseId}&formId=${formId}&pageIndex=1&pageSize=10`,
      providesTags: ["OutStandingTasks"],
    }),

    deleteOutstandingTask: builder.mutation({
      query({ outstandingTaskId }) {
        return {
          url: `officeTasks/${outstandingTaskId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OutStandingTasks"],
    }),

    updateOutstandingTaskStatus: builder.mutation({
      query({ outstandingTaskId, newStatus }) {
        return {
          url: `officeTasks/${outstandingTaskId}/userAction`,
          method: "POST",
          body: {
            userAction: newStatus,
          },
        };
      },
      invalidatesTags: ["OutStandingTasks"],
    }),

    assignProvider: builder.mutation({
      query({ outstandingTaskId, assignedProviderId }) {
        return {
          url: `officeTasks/${outstandingTaskId}/assign`,
          method: "POST",
          body: {
            assignedProviderId: assignedProviderId,
          },
        };
      },
      invalidatesTags: ["OutStandingTasks"],
    }),

    signForm: builder.mutation<string, { formId: string; payload: unknown }>({
      query({ formId, payload }) {
        return {
          url: `${process.env.REACT_APP_YAPI_DATAFORMS}/form/response?fid=${formId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
  keepUnusedDataFor: FIVE_MINUTES_IN_S,
});

export const {
  useGetInOfficePatientsQuery,
  useGetInOfficeFamilyMembersQuery,
  useCheckoutMutation,
  useGetOutstandingTasksQuery,
  useLazyGetOutstandingTaskQuery,
  useDeleteOutstandingTaskMutation,
  useUpdateOutstandingTaskStatusMutation,
  useAssignProviderMutation,
  useSignFormMutation,
  useLazyGetCheckedInPatientQuery,
} = inOfficePatientsApi;
