import { IFormPackage } from "../../../../../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { formEditorApi, PACKAGES_TAG } from "../formEditor/formEditorApi";
import { EStatusDefinition } from "../../../../../enums/EStatusDefinition";
import { updateSnackbar } from "../../../../../../features/authentication/globalMessagesSlice";
import { EPackageStatus } from "../../../../../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/EPackageStatus";
import { EStatusActions } from "../../../../../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Forms/types/EStatusActions";
import { IPackageOrderData } from "../../../../../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/Package.interfaces";

export const packagesApi = formEditorApi.injectEndpoints({
  endpoints: (build) => ({
    getPackage: build.query<IFormPackage, string>({
      query: (id: string) => `packages/${id}`,
    }),
    getPackages: build.query<IFormPackage[], string | undefined>({
      query: (name?) => `packages${name ? `?name=${name}` : ""}`,
      providesTags: [PACKAGES_TAG],
    }),
    deletePackage: build.mutation<null, string | number>({
      query(id) {
        return {
          url: `packages/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),
    changePackageStatus: build.mutation<
      IFormPackage,
      { id: string; status: EStatusDefinition }
    >({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const { id, status } = args;
        const { Active, Inactive } = EPackageStatus;
        const newStatus =
          status === EStatusDefinition.Active ? Inactive : Active;

        const response = await fetchWithBQ({
          url: `packages/${id}`,
          method: "PATCH",
          body: { status: newStatus },
        });

        const responseData: IFormPackage = response?.data as IFormPackage;

        if (responseData) {
          const { Active, Inactive } = EStatusDefinition;
          const message = `${responseData?.name} package made ${
            status === Active ? Inactive : Active
          }`;

          api.dispatch(updateSnackbar({ status: true, message }));
        } else {
          const statusLabel: string =
            status === EStatusDefinition.Active
              ? EStatusActions.Inactivate
              : EStatusActions.Activate;

          api.dispatch(
            updateSnackbar({
              status: true,
              message: `An error has occurred during ${statusLabel} process`,
            })
          );
        }

        return { data: responseData };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),

    renamePackage: build.mutation<IFormPackage, { id: string; name: string }>({
      query({ id, name }) {
        return {
          url: `packages/${id}`,
          method: "PATCH",
          body: { name },
        };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),

    addAndRemoveForms: build.mutation<
      IFormPackage,
      { id: string; payload: unknown[] }
    >({
      query({ id, payload }) {
        return {
          url: `packages/${id}/forms`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),
    createPackage: build.mutation<IFormPackage, string>({
      query(name) {
        return {
          url: "packages",
          method: "POST",
          body: {
            name,
          },
        };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),
    changeFormsOrder: build.mutation<
      IFormPackage,
      { packageId: string; payload: IPackageOrderData[] }
    >({
      query({ packageId, payload }) {
        return {
          url: `packages/${packageId}/forms`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: [PACKAGES_TAG],
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useLazyGetPackagesQuery,
  useDeletePackageMutation,
  useAddAndRemoveFormsMutation,
  useChangePackageStatusMutation,
  useCreatePackageMutation,
  useRenamePackageMutation,
  useLazyGetPackageQuery,
  useChangeFormsOrderMutation,
} = packagesApi;
