import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";

interface INewPatientWelcomeForm extends INewPatientWelcome {
  id: number;
}

interface INewPatientWelcomePackage extends INewPatientWelcome {
  id: string;
}

export type TNewPatientWelcomeMergeTag =
  | INewPatientWelcomeForm
  | INewPatientWelcomePackage;

export interface INewPatientWelcome {
  mergeTagDescription: string;
  mergeTag: string;
  name: string;
  id: string | number;
}

interface ITextTemplateSlice {
  newPatientWelcomeData: INewPatientWelcome;
  isSelectedAutomationFormsMergeTag: boolean;
}

const initialState: ITextTemplateSlice = {
  newPatientWelcomeData: null,
  isSelectedAutomationFormsMergeTag: null,
};

export const textTemplateSlice = createSlice({
  name: "textTemplate",
  initialState,
  reducers: {
    updateNewPatientWelcomeData: (
      state: ITextTemplateSlice,
      action: PayloadAction<INewPatientWelcome>
    ): void => {
      state.newPatientWelcomeData = action.payload;
    },
    updateAutomationFormsMergeTagSelection: (
      state: ITextTemplateSlice,
      action: PayloadAction<boolean>
    ): void => {
      state.isSelectedAutomationFormsMergeTag = action.payload;
    },
  },
});

export const selectNewPatientWelcomeData = (
  state: RootState
): INewPatientWelcome => {
  return state.textTemplateState.newPatientWelcomeData;
};

export const selectAutomationFormsMergeTag = (state: RootState): boolean => {
  return state.textTemplateState.isSelectedAutomationFormsMergeTag;
};

export const {
  updateNewPatientWelcomeData,
  updateAutomationFormsMergeTagSelection,
} = textTemplateSlice.actions;

export default textTemplateSlice.reducer;
