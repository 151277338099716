import { createApi } from "@reduxjs/toolkit/query/react";
import { TNamedEntity } from "../../types/TNamedEntity.type";
import { TDevice } from "../../types/settings/DeviceSettings/TDevice.type";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";

const GET_DEVICES_TAG = "DEVICES";
export const devicesApi = createApi({
  reducerPath: "devicesApi",
  tagTypes: [GET_DEVICES_TAG],
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/`
  ),
  endpoints: (builder) => ({
    getDevices: builder.query<TDevice[], void>({
      query: () => `devices`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "DEVICES" as const, id })),
              GET_DEVICES_TAG,
            ]
          : [GET_DEVICES_TAG],
    }),
    addDevice: builder.mutation<TNamedEntity, { name: string }>({
      query({ name }) {
        return {
          url: `devices`,
          method: "POST",
          body: {
            name: name,
          },
        };
      },
      invalidatesTags: [GET_DEVICES_TAG],
    }),
    updateDevice: builder.mutation<
      TNamedEntity,
      { body: { name?: string; enabled?: boolean }; id: string }
    >({
      query({ body, id }) {
        return {
          url: `devices/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: GET_DEVICES_TAG, id: arg.id },
      ],
    }),
    deleteDevice: builder.mutation<TNamedEntity, { id: string }>({
      query({ id }) {
        return {
          url: `devices/${id}`,
          method: "DELETE",
          body: {
            id: id,
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: GET_DEVICES_TAG, id: arg.id },
      ],
    }),
    generateDeviceSecret: builder.mutation<any, { id: string }>({
      query({ id }) {
        return {
          url: `devices/${id}/link`,
          method: "POST",
          body: {
            id: id,
          },
        };
      },
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useAddDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useGenerateDeviceSecretMutation,
} = devicesApi;
