import React from "react";
import { Menu, MenuListProps, PaperProps } from "@mui/material";

const ITEM_HEIGHT = 48;
const SMenuPaper: Partial<PaperProps<"div">> = {
  style: {
    maxHeight: ITEM_HEIGHT * 5.5,
    width: "23ch",
    backgroundColor: "#F7F7F7",
    boxShadow: "0px 4px 4px rgba(229, 229, 229, 0.25)",
    border: "0.5px solid #E5E5E5",
    borderRadius: "6px",
  },
};

const SMenuList: Partial<MenuListProps<"ul">> = {
  style: {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

interface ICustomContextMenu {
  anchorEl: HTMLElement;
  children: JSX.Element | JSX.Element[];
  onClose: <T>(param?: T) => void;
  dataTestid?: string;
  parentRef?: HTMLElement;
  className?: string;
}

export const CustomContextMenu = ({
  anchorEl,
  parentRef,
  children,
  dataTestid = "contextMenu/menu",
  onClose,
  className = "",
}: ICustomContextMenu) => {
  return (
    <Menu
      anchorOrigin={{
        vertical: parentRef ? "bottom" : "top",
        horizontal: parentRef ? "left" : "right",
      }}
      className={className}
      anchorEl={anchorEl}
      data-testid={dataTestid}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={SMenuPaper}
      MenuListProps={SMenuList}
    >
      {children}
    </Menu>
  );
};
