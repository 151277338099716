import { INewPackageModal } from "./INewPackageModal";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../global/store";
import { SavePackageRequest } from "../../../../../global/services/settings/practiceSettings/formLibrary/Packages/SavePackageRequest.interface";

const initialState: INewPackageModal = {
  isAddPackageModalOpened: false,
  isAddFormsFromPackagesTable: false,
  renameModal: false,
  packageData: {
    name: "",
    id: "",
  },
  setOnCloseModal: null,
  step: 1,
  selectedForms: [],
  nestedFormsInPackage: [],
};

const newPackageModalSlice = createSlice({
  name: "newPackageModalSlice",
  initialState,
  reducers: {
    setIsAddPackageModalOpened: (state, action: PayloadAction<boolean>) => {
      state.isAddPackageModalOpened = action.payload;
    },

    setIsAddFormsFromPackagesTable: (state, action: PayloadAction<boolean>) => {
      state.isAddFormsFromPackagesTable = action.payload;
    },

    setNestedFormsInPackage: (
      state,
      action: PayloadAction<SavePackageRequest[]>
    ) => {
      state.nestedFormsInPackage = action.payload.map((form) => {
        return { formId: form.formId, order: form.order };
      });
    },

    setOnCloseModal: (state, action: PayloadAction<() => void>) => {
      state.setOnCloseModal = action.payload;
    },

    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },

    setRenameModal: (state, action: PayloadAction<boolean>) => {
      state.renameModal = action.payload;
    },

    setPackageData: (
      state,
      action: PayloadAction<INewPackageModal["packageData"]>
    ) => {
      state.packageData = { ...action.payload };
    },

    setSelectedForms: (state, action: PayloadAction<SavePackageRequest>) => {
      const isAlreadySelectedForm = state.selectedForms.some(
        (item) => item.formId === action.payload.formId
      );

      const deleteForm = state.selectedForms.filter(
        (item) => item.formId !== action.payload.formId
      );
      const addFormToExisting = [...state.selectedForms, action.payload];

      state.selectedForms = isAlreadySelectedForm
        ? deleteForm
        : addFormToExisting;
    },

    resetNewPackageModalState: () => initialState,
  },
});

export const {
  setIsAddPackageModalOpened,
  setIsAddFormsFromPackagesTable,
  setOnCloseModal,
  setStep,
  setSelectedForms,
  resetNewPackageModalState,
  setRenameModal,
  setPackageData,
  setNestedFormsInPackage,
} = newPackageModalSlice.actions;

export const selectIsAddPackageModalOpened = (state: RootState) =>
  state.newPackageModalState.isAddPackageModalOpened;

export const selectIsAddFormsFromPackagesTable = (state: RootState) =>
  state.newPackageModalState.isAddFormsFromPackagesTable;

export const selectNestedFormsInPackage = (state: RootState) =>
  state.newPackageModalState.nestedFormsInPackage;

export const selectSelectedForms = (state: RootState) =>
  state.newPackageModalState.selectedForms;

export const selectRenameModal = (state: RootState) =>
  state.newPackageModalState.renameModal;

export const selectPackageData = (state: RootState) =>
  state.newPackageModalState.packageData;

export const selectStep = (state: RootState) => state.newPackageModalState.step;

export default newPackageModalSlice.reducer;
