import React, { FC, useState } from "react";
import { useAppSelector } from "../../../../../global/hooks/useTypedRedux.hook";
import { InOfficePatientSection } from "./InOfficePatientSection";
import {
  updateIsPackageNameVisible,
  selectInOfficePatientsStateById,
  IPatientStateById,
} from "./inOfficeModalSlice";
import { SBaseTabs } from "./styles";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { getShowCustomPackageName } from "../../common/getShowCustomPackageName";
import { ITabsProps } from "./types/ITabsProps";

const Tabs: FC<ITabsProps> = ({ patients, dispatch }): JSX.Element => {
  const [currentTabIndex, setTabIndex] = useState<number>(0);
  const patientsState: { [key: string]: IPatientStateById } = useAppSelector(
    selectInOfficePatientsStateById
  );

  const sections = patients?.map(({ id, firstName }) => {
    const showCustomPackageName: boolean = getShowCustomPackageName(
      patientsState?.[id]?.selectedForms,
      patientsState?.[id]?.submittedForms
    );
    dispatch(
      updateIsPackageNameVisible({
        isPackageNameVisible: showCustomPackageName,
        patientId: id,
      })
    );
    const storedPackageName: string = patientsState?.[id]?.packageName;
    const sendTo: EFormAssignmentNotificationType = patientsState?.[id]?.sendTo;
    const appointmentId: string = patientsState?.[id]?.appointmentId;

    return {
      component: (
        <InOfficePatientSection
          showCustomPackageName={showCustomPackageName}
          storedPackageName={storedPackageName}
          sendTo={sendTo}
          appointmentId={appointmentId}
          patientId={id}
        />
      ),
      name: firstName,
      label: firstName,
      disabled: false,
      noGutter: true,
    };
  });

  return (
    <SBaseTabs
      sections={sections}
      currentTabIndex={currentTabIndex}
      setTabIndex={setTabIndex}
    />
  );
};

export const FamilyMemberTabs = React.memo(Tabs, areEqualProps);
