import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";

interface IPracticeSettings {
  eventsChannel: {
    channel: string;
    channelAuthKey: string;
    timetoken: number;
  };
}

export const fillApptsApi = createApi({
  reducerPath: "fillApptsApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_FILL_APPTS),
  endpoints: (builder) => ({
    getPracticeSettings: builder.query<IPracticeSettings, string>({
      query(id) {
        return {
          url: `practices/${id}/settings`,
        };
      },
    }),
    getFillingStatuses: builder.query<IPracticeSettings, string[]>({
      query(ids) {
        const searchParams = new URLSearchParams();
        ids?.forEach((id) => searchParams.append("apptIds", id));
        return {
          url: `/appointments/fillingStatuses`,
          params: searchParams,
        };
      },
    }),
  }),
});

export const {
  useGetPracticeSettingsQuery,
  useGetFillingStatusesQuery,
  useLazyGetFillingStatusesQuery,
} = fillApptsApi;
