import Routes from "./routes";

export const paths = {
  acceptedInsurance: `${Routes.PracticeSettings.OnlineScheduling.Appointments.ROOT}/acceptedInsurance/:params/`,
  addTreatment:
    Routes.PracticeSettings.OnlineScheduling.Appointments.ADD_TREATMENT,
  appointmentDetails: "/appointment/:appointmentId",
  composePatientEmail: "/composePatientEmail/:id",
  dashboard: "/dashboard",
  documentManager: `${Routes.DocumentManager.ROOT}/:tab`,
  editAdminUser: "/editUser/:id",
  editPmsUser: "/editPmsUser/:id",
  editProvider: "/editProvider/:id",
  editTreatment:
    "/practiceSettings/onlineScheduling/appointments/editTreatment/:treatmentId",
  editUnlinkedPmsUser: "/editPmsUser/:id/:section",
  emailCampaigns: "/emailCampaigns",
  createCampaigns: `${Routes.EmailCampaigns.ROOT}/createCampaign/:activeStep`,
  emailDetails: "/email/:emailId",
  fillApptManualFlow: "/dashboard/fill_appointment",
  fillAppointment: "/dashboard/fill_appointment/:appointmentId",
  formEditor: `${Routes.PracticeSettings.FormsLibrary.ROOT}/:formId/editor`,
  formPreview: `${Routes.PracticeSettings.FormsLibrary.ROOT}/:formId/preview`,
  formsLibrary: `${Routes.PracticeSettings.FormsLibrary.ROOT}/:query`,
  templateLibrary: `${Routes.PracticeSettings.TemplateLibrary.ROOT}/:query`,
  templatePreview: `${Routes.PracticeSettings.TemplateLibrary.ROOT}/:templateId/preview`,
  helpResources: `${Routes.HELP_RESOURCES}`,
  inOfficeDeviceSettings:
    Routes.PracticeSettings.FormsLibrary.inOfficeDeviceSettings,
  inOfficeFormPreview: `${Routes.DocumentManager.ROOT}/:formId/in_office_form/preview/:responseId`,
  insuranceVerification: Routes.PracticeSettings.InsuranceVerification.ROOT,
  inviteNewUser: "/inviteNewUser",
  loadingScreen: Routes.LOADING_SCREEN,
  massTextMessagesCreate: "/createMassText",
  multiLocation: Routes.MULTILOCATION,
  onlineScheduling: "/practiceSettings/onlineScheduling/:section",
  operatories:
    Routes.PracticeSettings.OnlineScheduling.Appointments.OPERATORIES,
  outstandingTaskFormPreview: `${Routes.DocumentManager.ROOT}/:formId/outstanding_task_form/preview/:responseId`,
  pageNotFound: "/pageNotFound",
  practicePatientCommunicationSetting:
    "/practiceSettings/patientCommunication/setting",
  patientFinder: "/patientFinder",
  patientProfile: "/patientProfile/:id/:section",
  patientReminders: `${Routes.PracticeSettings.PatientReminders.ROOT}/:tab`,
  patientRemindersTextTemplatesComposeEdit: `${Routes.PracticeSettings.PatientReminders.ROOT}/textTemplates/compose/:id/:reminderCategory`,
  pendingFormPreview: `${Routes.DocumentManager.ROOT}/:formId/pending_form/preview/:responseId`,
  viewImagePreview: `${Routes.DocumentManager.ROOT}/:formId/image/preview/:responseId`,
  practicePatientCommunication: "/practiceSettings/patientCommunication",
  practiceInformation: "/practiceSettings/practiceInformation/:tab",
  practiceSettings: Routes.PracticeSettings.ROOT,
  preferences: "/preferences/:id",
  profile: "/profile",
  providerSignature: `${Routes.DocumentManager.ROOT}/:formId/signature/:responseId`,
  root: Routes.ROOT,
  schedulingWidget: "/practiceSettings/schedulingWidget/:id",
  switchLocation: Routes.SWITCH_LOCATION,
  templateCreator: "/templateCreator",
  textMessagesPatientComm: "/TextMessages/:textType/:id",
  textTemplates: "/textTemplates",
  textTemplatesCompose: "/textTemplates/compose",
  textTemplatesComposeEdit: "/textTemplates/compose/:id",
  treatmentDetails:
    Routes.PracticeSettings.OnlineScheduling.Appointments.TREATMENT_DETAILS,
  unauthorized: Routes.UNAUTHORIZED,
  users: "/users/:tab/:query/",
  reviews: Routes.PracticeSettings.Reviews.ROOT,
} as const;

export type PathValue = typeof paths[keyof typeof paths];
export type PathsMap<T = string> = Record<PathValue, T>;
