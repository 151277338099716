import { nanoid } from "nanoid";
import { IContentType } from "../../types/settings/formBuilder/IContentType.interface";

interface ApiHeaderProps {
  headers: any;
  practice: {
    practiceGuid: string;
    userSession: string;
    auth0Token: string;
  };
  contentType?: IContentType;
  sessionId: string;
}

export const apiHeaders = (
  headers,
  practice,
  contentType,
  sessionId
): ApiHeaderProps => {
  if (contentType) {
    contentType["Content-Type"] &&
      headers.set("Content-Type", contentType["Content-Type"]);
  } else {
    headers.set("Content-Type", "application/json; charset=utf-8");
  }

  headers.set("X-Yapi-Client-Guid", practice.practiceGuid);
  headers.set("X-Yapi-Client-Profile-Id", "");
  headers.set("X-Yapi-WebApp-Instance-Id", sessionId);
  headers.set("X-Yapi-WebApp-Request-Id", `YDPREQ-${nanoid(32)}`);
  headers.set("Authorization", `Bearer ${practice.auth0Token}`);
  return headers;
};
