import React from "react";
import { Check } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../../../../../../../global/hooks/useTypedRedux.hook";
import { CircleWithLabel } from "../../../../../../../../global/components/CircleWithLabel/CircleWithLabel";
import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { getPmsName } from "../../../../../../../../global/helperFunctions/text/getPmsName";
import { selectPmsType } from "../../../../../../../authentication/appSlice";
import { getTaskStatusColor } from "./getTaskStatusColor";
import { REFILING_IN_PROGRESS } from "../../../../../pendingForms/constants";
import { useGetFeatureFlag } from "../../../../../../../../global/hooks/useGetFeatureFlag";
import { EFeatureFlags } from "../../../../../../../../global/enums/EFeatureFlags";

export const TaskStatus = ({ summary, status }) => {
  const { isEnabled: isAutomaticFilingBeforeAppointmentEnabled } =
    useGetFeatureFlag(EFeatureFlags.AutomaticFilingBeforeAppointmentEnabled);
  const pmsName: string = getPmsName(useAppSelector(selectPmsType));
  const theme = useTheme();
  if (status === EOfficeTaskStatus.FiledToPms) {
    return (
      <Box>
        <Check color="success" />
        <Typography>Filed to file to {pmsName}</Typography>
      </Box>
    );
  }

  if (status === EOfficeTaskStatus.FailedToFileToPms) {
    const message = `Refile Required`;
    return (
      <CircleWithLabel circleColor={theme.palette.error.main} label={message} />
    );
  }

  if (
    status === EOfficeTaskStatus.FilingToPms &&
    summary === REFILING_IN_PROGRESS &&
    isAutomaticFilingBeforeAppointmentEnabled
  ) {
    return (
      <CircleWithLabel circleColor={theme.palette.error.main} label={summary} />
    );
  }

  return (
    <CircleWithLabel
      circleColor={getTaskStatusColor(status, theme)}
      label={summary}
    />
  );
};
