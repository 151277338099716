import { styled, Typography } from "@mui/material";
import ImageContainer from "../../global/components/ImageContainer/ImageContainer";
import { RootDiv } from "../../global/styles/RootDiv";
import PageTitle from "../../global/components/PageTitle/PageTitle";
import { MoreVert } from "@mui/icons-material";

export const SImageContainer = styled(ImageContainer)({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

export const SRootDiv = styled(RootDiv)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingRight: 0,
  position: "relative",
  marginRight: 16,
});

export const SPageTitle = styled(PageTitle)({
  marginBottom: 24,
});

export const STypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: "1.5rem",
}));

export const SMoreVert = styled(MoreVert)(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: -3.8,
  width: 18,
  height: 18,
}));
