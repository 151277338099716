import React from "react";
import { styled } from "@mui/material/styles";
import loadingImg from "../../../assets/loading.gif";
import { CardMedia, Stack } from "@mui/material";
interface ImageContainerPropTypes {
  img?: string;
}

interface CardMediaPropTypes {
  img?: boolean;
  component?: string;
  alt?: string;
  title?: string;
}

const LoadingContainer = styled(CardMedia, {
  shouldForwardProp: (prop) => prop !== "img",
})<CardMediaPropTypes>(({ img: img }) =>
  img
    ? {
        maxWidth: 275,
        minWidth: 250,
      }
    : {
        maxWidth: "350px",
      }
);

const Loading: React.FC<ImageContainerPropTypes> = ({
  img = loadingImg,
}: ImageContainerPropTypes) => {
  return (
    <Stack
      justifyContent="center"
      data-testid="imageContainer"
      alignItems="center"
      sx={{
        height: "100vh",
      }}
    >
      <LoadingContainer
        img={img === loadingImg}
        component="img"
        alt="Image container"
        image={img}
        title="Image container"
      />
    </Stack>
  );
};

export default Loading;
