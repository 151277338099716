import { EPatientSearchCriteria } from "../../domains/patients/enums/EPatientSearchCriteria";

export const patientSearchInputValidator = (
  event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  type: string
) => {
  const PHONE_REGEX = /^[0-9]{1,10}$/;
  // Special characters allow for patient finder `~!@#$%^&*()_-+=|\}]{[;:'"<,>.?/
  const NAME_REGEX = /^[a-zA-Z0-9`~!@#$%^&*()-_=+{};:'",<.>| ]*$/;
  switch (type) {
    case EPatientSearchCriteria.BY_PHONE:
      if (!PHONE_REGEX.test(event.key)) {
        event.preventDefault();
      }
      break;
    case EPatientSearchCriteria.BY_NAME:
      if (!NAME_REGEX.test(event.key)) {
        event.preventDefault();
      }
      break;
    default:
      return null;
  }
};
