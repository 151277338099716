import { GetTokenSilentlyOptions, LogoutOptions } from "@auth0/auth0-react";

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

let logout: (options?: LogoutOptions | undefined) => void;

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
  getLogout: () => logout,
  setLogout: (func: (options?: LogoutOptions | undefined) => void) =>
    (logout = func),
};
