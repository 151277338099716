import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithReAuth } from "../../../../../global/helperFunctions/api/baseQueryWithReauth";
import { TEN_MINUTES_IN_S } from "../../../../../global/constants";

import { downloadFile } from "../../../../../global/helperFunctions/fileConverter/convertBlobToOtherFormats";
import { removeSpecialCharacters } from "../../../../../global/helperFunctions/text/removeSpecialCharacters";
import { getSortingUrlParams } from "../../../../../global/helperFunctions/query/getSortingUrlParams";
import { getSkipUrlParams } from "../../../../../global/helperFunctions/query/getSkipUrlParams";
import { getUrlParamsFromArray } from "../../../../../global/helperFunctions/query/getUrlParamsFromArray";

import { updateSnackbar } from "../../../../authentication/globalMessagesSlice";
import { ITemplateResponse } from "../../../../../global/types/settings/templates/ITemplateResponse";
import { IPmsInformationItem } from "../../../../../global/types/settings/formBuilder/IPmsInformationItem";
import { IFormTemplateQueryParams } from "../../../../../global/types/settings/templates/IFormTemplateQueryParams";
import { IForm } from "../../../../../global/types/settings/formBuilder/IForm.interface";
import { FORM_TEMPLATES_TAG } from "../../constants";
import {
  updateIsShowFormTemplatesBanner,
  updateIsShowExportErrorModal,
} from "../templateLibrarySlice";

export const formTemplateLibraryApi = createApi({
  reducerPath: "formTemplateLibraryApi",
  tagTypes: [FORM_TEMPLATES_TAG],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_YAPI_DATAFORMS),
  endpoints: (build) => ({
    getFormTemplates: build.query<
      { totalCount: number; forms: ITemplateResponse[] },
      IFormTemplateQueryParams & { pmsType: string }
    >({
      query: ({
        page,
        pageSize,
        sorting,
        languages,
        categories,
        search,
        pmsType,
      }) => {
        const skip: number = getSkipUrlParams(page, pageSize);
        const orderBy: string = getSortingUrlParams(sorting);

        const url = `/odata/Templates?$count=true&$orderby=${orderBy}&$take=${pageSize}&$skip=${skip}&$pmsNameId=${pmsType}`;

        const searchQueryParams: string = search ? `&$name=${search}` : "";

        const filtersQueryParams: string =
          getUrlParamsFromArray("language", languages) +
          getUrlParamsFromArray("category", categories);

        return {
          url: url + searchQueryParams + filtersQueryParams,
          method: "GET",
        };
      },

      providesTags: [FORM_TEMPLATES_TAG],
    }),

    duplicateTemplate: build.mutation<IForm, { id: number; pmsIndex: string }>({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        const { id, pmsIndex } = args;

        const response = await fetchWithBQ({
          url: `/template/${id}/clone`,
          method: "POST",
          body: { pmsNameId: pmsIndex },
        });

        const duplicateResponse: IForm = response?.data as IForm;

        if (duplicateResponse?.id) {
          const messagePayload = {
            status: true,
            message: "Template duplicated",
          };

          api.dispatch(updateSnackbar(messagePayload));
          api.dispatch(updateIsShowFormTemplatesBanner(true));
        }

        return { data: duplicateResponse };
      },
    }),

    exportTemplate: build.query<unknown, { id: number; name: string }>({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        const { id, name } = args;
        const resp = await fetchWithBQ({
          url: `/template/${id}/export`,
          method: "GET",
          responseHandler: (response) => response.blob(),
        });

        if (resp?.data) {
          downloadFile(resp.data, removeSpecialCharacters(name), "zip");
          const messagePayload = {
            status: true,
            message: "Template exported",
          };
          api.dispatch(updateSnackbar(messagePayload));
        } else {
          api.dispatch(updateIsShowExportErrorModal(true));
        }

        return resp;
      },
    }),

    getPmsValues: build.query<IPmsInformationItem[], void>({
      query() {
        return {
          url: "/pmsIntegration/pmsNames",
          method: "GET",
        };
      },
    }),
  }),
  keepUnusedDataFor: TEN_MINUTES_IN_S,
});

export const {
  useGetFormTemplatesQuery,
  useDuplicateTemplateMutation,
  useLazyExportTemplateQuery,
  useGetPmsValuesQuery,
} = formTemplateLibraryApi;
