import React, { useMemo } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../../../../global/hooks/useTypedRedux.hook";
import { updateSelectedCardId } from "../../inOfficePatientsSlice";

import { EFormStatus } from "../../../../../../global/types/documentManager/EFormStatus";
import { ImageIcon } from "@yapi/yapi2.icons.image-icon";
import "./forms-section.scss";
import { FormsByCategory } from "./formsByCategory/FormsByCategory";
import { getFormGroups } from "./getFormGroups";
import { getFormSectionTitle } from "./getFormSectionTitle";
import { SAccordion } from "../../../../../../global/styles/SAccordion";
import { areEqualProps } from "../../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import {
  SAccordionSummary,
  SAccordionDetails,
  STitle,
  SSubtitle,
} from "./styles";
import { TFormsSection } from "./types/TFormsSection";
import { EXPANDED_LABEL } from "./constants";

const FormsSectionContent = ({
  isSelected,
  formAssignment,
  officeTasks,
  patientId,
}: TFormsSection) => {
  const dispatch = useAppDispatch();
  const forms = formAssignment?.forms || [];
  const handleChange = () => {
    if (!isSelected) return;
    dispatch(updateSelectedCardId(""));
  };

  const hasUploadedFiles = forms.some((form) => form.uploadedFiles.length > 0);
  const expandedLabel = EXPANDED_LABEL[isSelected.toString()];
  const formGroups = useMemo(() => getFormGroups(forms), [forms]);
  const { byCategory: formsByCategory } = formGroups;

  const patientSubmittedForms = useMemo(
    () => formsByCategory[EFormStatus.Submitted],
    [forms, officeTasks?.length]
  );

  const title = useMemo(
    () => getFormSectionTitle(formAssignment),
    [formAssignment]
  );

  if (!formAssignment?.forms?.length) {
    return <Box mb={2} />;
  }

  return (
    <SAccordion
      data-testid="forms-section"
      expanded={isSelected}
      onChange={handleChange}
    >
      <SAccordionSummary
        isSelected={isSelected}
        expandIcon={<ExpandMore fontSize={"medium"} sx={{ color: "black" }} />}
        sx={{
          "& > .MuiAccordionSummary-contentGutters": {
            margin: "4px 0",
          },
        }}
      >
        <Box display="flex" flexDirection="column">
          <STitle>{title}</STitle>
          <SSubtitle>
            {expandedLabel} Status ({patientSubmittedForms.length}/
            {forms.length} completed)
            {hasUploadedFiles && <ImageIcon />}
          </SSubtitle>
        </Box>
      </SAccordionSummary>
      {Boolean(forms.length) && (
        <SAccordionDetails>
          <FormsByCategory
            formAssignmentId={formAssignment?.id}
            patientId={patientId}
            officeTasks={officeTasks}
            formGroups={formGroups}
          />
        </SAccordionDetails>
      )}
    </SAccordion>
  );
};

export const FormsSection = React.memo(FormsSectionContent, areEqualProps);
