import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../global/store";
import { EOrderBy } from "../../../../global/enums/EOrderBy";
import { EPendingFormAssignmentSortBy } from "../../../../global/types/documentManager/EPendingFormAssignmentSortBy.enum";
import { IAppointment } from "../../../../global/domains/patients/types/IAppointment.interface";
import { PendingFormsData } from "../../../../global/services/document-manager/pending-forms/PendingFormsResponse.interface";

import { EDocumentManagerEventType } from "../../events-channel/types/EDocumentManagerEventType";
import { IOfficeTask } from "../../../../global/types/documentManager/InOfficePatient";

interface ReloadAppointmentEvent {
  type: EDocumentManagerEventType;
  formAssignmentId?: string;
  formAssignmentIds?: string[];
}

export interface FormVersionBumpedEvent {
  formAssignmentId: string;
  formId: number;
}

export interface ChangeAppointmentModalInfo {
  isOpen: boolean;
  name?: string;
  patientId?: string;
  formAssignmentId?: string;
  appointmentId?: string;
}

export interface PendingFormsState {
  isSelectPatientModalVisible: boolean;
  isNoAppointmentErrorModalVisible: boolean;
  sortBy: EOrderBy;
  sortField: EPendingFormAssignmentSortBy;
  pageIndex: number;
  totalCount: number;
  pageSize: number;
  selectedCardId: string;
  changeAppointmentModalInfo: ChangeAppointmentModalInfo;
  patientAppointments: IAppointment[];
  pendingFormsData: PendingFormsData[];
  transitionedFormAssignmentIdToInOffice: string;
  notificationCreatedFormAssignmentId: string;
  reloadAppointmentsKey: string;
  pendingListKey: string;
  deletedAppointmentId: string;
  brokenAppointmentId: string;
  futureAppointmentId: string;
  formVersionBumpedData: FormVersionBumpedEvent[];
}

const initialState: PendingFormsState = {
  isNoAppointmentErrorModalVisible: false,
  sortBy: EOrderBy.asc,
  sortField: EPendingFormAssignmentSortBy.Appointment,
  pageIndex: 1,
  pageSize: 10,
  totalCount: 0,
  selectedCardId: null,
  isSelectPatientModalVisible: false,
  changeAppointmentModalInfo: {
    isOpen: false,
    name: null,
    patientId: null,
    formAssignmentId: null,
    appointmentId: null,
  },
  patientAppointments: [],
  pendingFormsData: [],
  transitionedFormAssignmentIdToInOffice: null,
  notificationCreatedFormAssignmentId: null,
  reloadAppointmentsKey: null,
  deletedAppointmentId: null,
  brokenAppointmentId: null,
  formVersionBumpedData: null,
  pendingListKey: null,
  futureAppointmentId: null,
};

const pendingFormsSlice = createSlice({
  name: "pendingForms",
  initialState,
  reducers: {
    updateFutureAppointmentId: (state, action: PayloadAction<string>) => {
      state.futureAppointmentId = action.payload;
    },

    updateIsNoAppointmentErrorModalVisible: (
      state,
      action: PayloadAction<boolean>
    ): void => {
      state.isNoAppointmentErrorModalVisible = action.payload;
    },

    updateSortField: (
      state,
      action: PayloadAction<EPendingFormAssignmentSortBy>
    ): void => {
      state.sortField = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<EOrderBy>): void => {
      state.sortBy = action.payload;
    },
    updatePageIndex: (state, action: PayloadAction<number>): void => {
      state.pageIndex = action.payload;
    },
    updateTotalCount: (state, action: PayloadAction<number>): void => {
      state.totalCount = action.payload;
    },
    updateSelectedCardId: (state, action: PayloadAction<string>): void => {
      state.selectedCardId = action.payload;
    },
    updateIsSelectPatientModalVisible: (
      state,
      action: PayloadAction<boolean>
    ): void => {
      state.isSelectPatientModalVisible = action.payload;
    },
    updateChangeAppointmentModalInfo: (
      state,
      action: PayloadAction<ChangeAppointmentModalInfo>
    ): void => {
      state.changeAppointmentModalInfo = action.payload;
    },
    updatePendingFormsData: (
      state,
      action: PayloadAction<PendingFormsData[]>
    ): void => {
      state.pendingFormsData = action.payload;
    },
    updateTransitionedFormAssignmentIdToInOffice: (
      state,
      action: PayloadAction<string>
    ): void => {
      state.transitionedFormAssignmentIdToInOffice = action.payload;
    },
    updateNotificationCreatedFormAssignmentId: (
      state,
      action: PayloadAction<string>
    ): void => {
      state.notificationCreatedFormAssignmentId = action.payload;
    },
    resetChangeAppointmentModalInfo: (state) => {
      state.changeAppointmentModalInfo =
        initialState.changeAppointmentModalInfo;
    },
    updateReloadAppointmentsKey: (
      state,
      action: PayloadAction<ReloadAppointmentEvent>
    ) => {
      if (action.payload) {
        state.reloadAppointmentsKey = JSON.stringify(action.payload);
      } else {
        state.reloadAppointmentsKey = null;
      }
    },
    updatePendingListKey: (state, action: PayloadAction<string>) => {
      state.pendingListKey = action.payload;
    },
    updateDeletedAppointmentId: (
      state,
      action: PayloadAction<string>
    ): void => {
      state.deletedAppointmentId = action.payload;
    },
    updateBrokenAppointmentId: (state, action: PayloadAction<string>): void => {
      state.brokenAppointmentId = action.payload;
    },

    updateFormVersionBumpedData: (
      state,
      action: PayloadAction<FormVersionBumpedEvent[]>
    ): void => {
      state.formVersionBumpedData = action.payload;
    },
  },
});

export const selectSortBy = (state: RootState): EOrderBy =>
  state.pendingFormsState.sortBy;

export const selectSortField = (
  state: RootState
): EPendingFormAssignmentSortBy => state.pendingFormsState.sortField;

export const selectPageIndex = (state: RootState): number =>
  state.pendingFormsState.pageIndex;

export const selectPageSize = (state: RootState): number =>
  state.pendingFormsState.pageSize;

export const selectTotalCount = (state: RootState): number =>
  state.pendingFormsState.totalCount;

export const selectSelectedCardId = (state: RootState): string =>
  state.pendingFormsState.selectedCardId;

export const selectIsSelectPatientModalVisible = (state: RootState): boolean =>
  state.pendingFormsState.isSelectPatientModalVisible;

export const selectIsNoAppointmentErrorModalVisible = (
  state: RootState
): boolean => state.pendingFormsState.isNoAppointmentErrorModalVisible;

export const selectChangeAppointmentModalInfo = (
  state: RootState
): ChangeAppointmentModalInfo =>
  state.pendingFormsState.changeAppointmentModalInfo;

export const selectPatientAppointments = (state: RootState): IAppointment[] =>
  state.pendingFormsState.patientAppointments;

export const selectPendingFormsData = (state: RootState): PendingFormsData[] =>
  state.pendingFormsState.pendingFormsData;

export const selectPatientDataFromPendingForms =
  (patientId: string) => (state: RootState) =>
    state.pendingFormsState.pendingFormsData.find(
      (patientData) => patientData?.patient.id === patientId
    );

export const selectOfficeTaskFromPendingForms =
  (formId: string, responseId: string) => (state: RootState) => {
    let currentTask: IOfficeTask = null;
    state.pendingFormsState.pendingFormsData?.forEach((data) => {
      const task: IOfficeTask = data.officeTasks?.find(
        (officeTask: IOfficeTask) =>
          officeTask.formId === Number(formId) &&
          officeTask.responseId === responseId
      );

      if (task) {
        currentTask = task;
      }
    });

    return currentTask;
  };

export const selectTransitionedFormAssignmentIdToInOffice = (
  state: RootState
): string => state.pendingFormsState.transitionedFormAssignmentIdToInOffice;

export const selectNotificationCreatedFormAssignmentId = (
  state: RootState
): string => state.pendingFormsState.notificationCreatedFormAssignmentId;

export const selectReloadAppointmentsKey = (state: RootState): string =>
  state.pendingFormsState.reloadAppointmentsKey;

export const selectPendingListKey = (state: RootState): string =>
  state.pendingFormsState.pendingListKey;

export const selectBrokenAppointmentId = (state: RootState): string =>
  state.pendingFormsState.brokenAppointmentId;

export const selectDeletedAppointmentId = (state: RootState): string =>
  state.pendingFormsState.deletedAppointmentId;

export const selectFutureAppointmentId = (state: RootState): string =>
  state.pendingFormsState.futureAppointmentId;

export const selectFormVersionBumpedData = (
  state: RootState
): FormVersionBumpedEvent[] => state.pendingFormsState.formVersionBumpedData;

export const {
  updateSortField,
  updateSortBy,
  updatePageIndex,
  updateTotalCount,
  updateSelectedCardId,
  updateIsSelectPatientModalVisible,
  updateIsNoAppointmentErrorModalVisible,
  updateChangeAppointmentModalInfo,
  updatePendingFormsData,
  updateTransitionedFormAssignmentIdToInOffice,
  updateNotificationCreatedFormAssignmentId,
  resetChangeAppointmentModalInfo,
  updateReloadAppointmentsKey,
  updateDeletedAppointmentId,
  updateBrokenAppointmentId,
  updateFormVersionBumpedData,
  updatePendingListKey,
  updateFutureAppointmentId,
} = pendingFormsSlice.actions;

export default pendingFormsSlice.reducer;
