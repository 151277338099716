import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithReAuth } from "../helperFunctions/api/baseQueryWithReauth";
import { IPatientInsuranceV2 } from "../domains/patients/types/IPatientInsurance.interface";

export const formatVersionUrl = (version, url) => {
  return `${version}/${url}`;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["CheckedOut", "InOffice", "HomepageMetrics"],
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_HOST.replace("/v1/", "/")
  ),
  endpoints: (builder) => ({
    getPatientInsurancesV2: builder.query<
      IPatientInsuranceV2,
      { id: string; instanceId: number }
    >({
      query: ({ id, instanceId }) => {
        return `v2/PatientInsurances/${id}?instanceId=${instanceId}`;
      },
    }),
  }),
});

export const { useGetPatientInsurancesV2Query } = api;
