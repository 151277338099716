import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { IPatientInsuranceV2 } from "../../domains/patients/types/IPatientInsurance.interface";
import { IPatientEmailHistorySummary } from "../../domains/patients/types/IPatientEmailHistorySummary.interface";
import { IEmailHistorySummaryRequest } from "../../domains/patients/types/IEmailHistorySummaryRequest.interface";
import { queryStringBuilder } from "../../helperFunctions/api/queryStringBuilder";

export const patientV2Api = createApi({
  reducerPath: "patientV2Api",
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_HOST.replace("v1", "v2")
  ),
  endpoints: (builder) => ({
    getPatientInsurancesV2: builder.query<
      IPatientInsuranceV2,
      { id: string; instanceId: number }
    >({
      query: ({ id, instanceId }) => {
        return `PatientInsurances/${id}?instanceId=${instanceId}`;
      },
    }),
    getEmailHistorySummariesV2: builder.query<
      IPatientEmailHistorySummary,
      IEmailHistorySummaryRequest
    >({
      query: (requestOptions) => {
        const requestArray = Object.entries(requestOptions);
        const newRequestOptions =
          requestOptions.value === "" || requestOptions.value === null
            ? requestArray.filter(
                ([key]) => key !== "criteria" && key !== "value"
              )
            : requestOptions;
        const queryParams = queryStringBuilder(newRequestOptions).toString();
        return `/PatientEmailSummaries?${queryParams}`;
      },
    }),
  }),
});

export const {
  useGetPatientInsurancesV2Query,
  useGetEmailHistorySummariesV2Query,
} = patientV2Api;
