import { Typography, styled, useTheme } from "@mui/material";

import React from "react";
import { useAppHistory } from "../../hooks/useAppHistory";

type TTypography = {
  component: string;
};

const STypography = styled(Typography)<TTypography>(() => ({
  fontSize: "1rem !important",
}));

interface IClickHandler {
  handleClick: () => void;
}

interface IRoute {
  route: string;
}

type TLinkTypography = {
  color?: string;
  disabled?: boolean;
  text: string;
} & (IClickHandler | IRoute);

const LinkTypography = (props: TLinkTypography) => {
  const { color, disabled = false, text } = props;
  const theme = useTheme();
  const textColor = disabled ? theme.palette.text.disabled : "secondary";
  const cursor = disabled ? "default" : "pointer";
  const history = useAppHistory();

  const handleClick = () => {
    if ("handleClick" in props) {
      props.handleClick();
    } else if ("route" in props) {
      history.push(props.route);
    }
  };

  return (
    <STypography
      component="span"
      color={color || textColor}
      onClick={disabled ? undefined : handleClick}
      sx={{ cursor }}
    >
      {text}
    </STypography>
  );
};

export default LinkTypography;
