import { createApi } from "@reduxjs/toolkit/query/react";
import { IGetPmsParams } from "../../../types/users/IGetPmsParams.interface";
import {
  IGetPmsUsers,
  IGetPmsUsersRequest,
} from "../../../types/users/IGetPmsUsers.interface";
import { IUserModel } from "../../../types/users/IUserModel";
import { IGetUserSummaryByEmail } from "../../../types/users/IGetUserSummaryByEmail.interface";
import { INotificationSettings } from "../../../types/users/INotificationSettings.interface";
import { IPostUsersParams } from "../../../types/users/IPostUsers.interface";
import { ISetPracticeProvider } from "../../../types/users/ISetPracticeProvider.interface";
import { ISetUserPracticeStatus } from "../../../types/users/ISetUserPracticeStatus.interface";
import {
  IPmsUserPhoto,
  IUserPhoto,
} from "../../../types/users/IUserPhoto.interface";
import { IUserRequest } from "../../../types/users/IUsersRequest.interface";
import { TUsers } from "../../../types/users/TUsers.type";
import { queryStringBuilder } from "../../../helperFunctions/api/queryStringBuilder";
import { baseQueryWithReAuth } from "../../../helperFunctions/api/baseQueryWithReauth";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  tagTypes: [
    "GetUserById",
    "GetUsers",
    "PmsUserPhoto",
    "GetPmsUsers",
    "GetPmsUsersById",
  ],
  endpoints: (builder) => ({
    getUserById: builder.query<IUserModel, string>({
      query: (id) => {
        return `/Users/${id}`;
      },
      providesTags: ["GetUserById"],
    }),
    getUsers: builder.query<IUserRequest, string>({
      query: (params) => {
        return `/Users?${params}`;
      },
      providesTags: ["GetUsers"],
    }),
    getPmsUsers: builder.query<IGetPmsUsersRequest, IGetPmsParams>({
      query: (params) => {
        const formattedParams = queryStringBuilder(params);
        return `/PmsUsers?${formattedParams}`;
      },
      providesTags: ["GetPmsUsers"],
    }),
    getPmsUsersById: builder.query<IGetPmsUsers, string>({
      query: (id) => {
        return `/PmsUsers/${id}`;
      },
      providesTags: ["GetPmsUsersById"],
    }),
    setUserPracticeProvider: builder.mutation<
      ISetPracticeProvider,
      ISetPracticeProvider
    >({
      query(providerData) {
        return {
          url: "userPracticeProviders",
          method: "POST",
          body: providerData,
        };
      },
      invalidatesTags: ["GetUserById"],
    }),
    getUserPhoto: builder.query<IUserPhoto, string>({
      query: (id) => `UserPhotos/${id}`,
    }),
    setUserPhoto: builder.mutation<IUserPhoto, IUserPhoto>({
      query(params) {
        return {
          url: `UserPhotos/${params.userId}`,
          method: "PUT",
          body: {
            userPhoto: params.photo,
          },
        };
      },
    }),
    deleteUserPhoto: builder.mutation<unknown, string>({
      query(userId) {
        return {
          url: `UserPhotos/${userId}`,
          method: "DELETE",
        };
      },
    }),
    getUserSummariesByEmail: builder.query<IGetUserSummaryByEmail, string>({
      query: (email) => `UserSummaries/${encodeURIComponent(email)}`,
    }),
    setUserPracticeStatuses: builder.mutation<
      ISetUserPracticeStatus,
      { id: string; status: string }
    >({
      query(params) {
        return {
          url: `UserPracticeStatuses/${params.id}`,
          method: "PUT",
          body: {
            userStatus: params.status,
          },
        };
      },
    }),
    setUserInvite: builder.mutation<any, string>({
      query(params) {
        return {
          url: "UserInvites",
          method: "POST",
          body: { email: params },
        };
      },
    }),
    setUserInformation: builder.mutation<
      IUserModel,
      { id: string; data: IUserModel }
    >({
      query(params) {
        return {
          url: `Users/${params.id}`,
          method: "PUT",
          body: params.data,
        };
      },
      invalidatesTags: ["GetUserById", "GetUsers"],
    }),
    setUser: builder.mutation<TUsers, IPostUsersParams>({
      query(userData) {
        return {
          url: "Users",
          method: "POST",
          body: userData,
        };
      },
      invalidatesTags: ["GetUsers", "GetUserById"],
    }),
    setUserNotificationSettings: builder.mutation<
      IUserModel,
      { id: string; data: INotificationSettings }
    >({
      query(params) {
        return {
          url: `Users/${params.id}`,
          method: "PUT",
          body: params.data,
        };
      },
    }),
    setUserNotificationSettingsUpdatable: builder.mutation<
      boolean,
      { id: string; data: boolean }
    >({
      query(params) {
        return {
          url: `Users/${params.id}/notificationSettings`,
          method: "PUT",
          body: params.data,
        };
      },
    }),
    getPmsUserPhoto: builder.query<IPmsUserPhoto, string>({
      query: (id) => {
        return `/PmsUserPhotos/${id}`;
      },
      providesTags: ["PmsUserPhoto"],
    }),
    deletePmsUserPhoto: builder.mutation<unknown, string>({
      query(userId) {
        return {
          url: `PmsUserPhotos/${userId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["PmsUserPhoto"],
    }),
    setPmsUserPhotos: builder.mutation<IUserPhoto, IUserPhoto>({
      query(params) {
        return {
          url: `PmsUserPhotos/${params.userId}`,
          method: "PUT",
          body: {
            pmsUserPhoto: params.photo,
          },
        };
      },
      invalidatesTags: ["PmsUserPhoto"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useSetUserInformationMutation,
  useGetPmsUsersQuery,
  useGetPmsUsersByIdQuery,
  useSetUserMutation,
  useGetUserPhotoQuery,
  useSetUserPracticeStatusesMutation,
  useSetUserPracticeProviderMutation,
  useGetUserSummariesByEmailQuery,
  useSetUserInviteMutation,
  useSetUserPhotoMutation,
  useSetUserNotificationSettingsMutation,
  useDeleteUserPhotoMutation,
  useSetUserNotificationSettingsUpdatableMutation,
  useGetPmsUserPhotoQuery,
  useDeletePmsUserPhotoMutation,
  useSetPmsUserPhotosMutation,
} = usersApi;
