import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import {
  IRecallStartFilling,
  IRecallStartFillingResp,
} from "../../types/appointments/IRecallsStartFilling.interface";
import { EPatientsListType } from "../../../features/appointments/schedule/fillAppointment/choosePatients/summaryDetails/EPatientsListType";

export const fillApptsV2Api = createApi({
  reducerPath: "fillApptsV2Api",
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_FILL_APPTS.replace("v1", "v2")
  ),
  endpoints: (builder) => ({
    getRecallStartFilling: builder.mutation<
      IRecallStartFillingResp,
      IRecallStartFilling
    >({
      query(userData) {
        const key =
          userData.patientsListType === EPatientsListType.ASAP
            ? "asapAppointmentId"
            : "recallId";
        const data = {
          appointmentId: userData.appointmentId,
          messageBody: userData.messageBody,
          recipients: userData.recipients.map((item) => ({ [key]: item })),
        };
        return {
          url: "/fillAppointmentQueues/startFilling",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useGetRecallStartFillingMutation } = fillApptsV2Api;
