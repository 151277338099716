import { queryStringBuilder } from "../../../helperFunctions/api/queryStringBuilder";
import { communicationsApi } from "../communicationsApi";
import { IGetTwilioNumbersRequest } from "./IGetTwilioNumbersRequest";

export const twilioApi = communicationsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTwilioNumbers: builder.query<string[], IGetTwilioNumbersRequest>({
      query: (params) =>
        `AvailableTwilioPhoneNumbers?${queryStringBuilder(params)}`,
    }),
  }),
});

export const { useGetTwilioNumbersQuery, useLazyGetTwilioNumbersQuery } =
  twilioApi;
