import { Box } from "@mui/material";
import React from "react";
import { EOfficeTaskStatus } from "../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { OfficeTaskItem } from "../../inOfficePatientList/OfficeTaskItem";
import { getPmsName } from "../../../../../../global/helperFunctions/text/getPmsName";
import { useAppSelector } from "../../../../../../global/hooks/useTypedRedux.hook";
import { selectPmsType } from "../../../../../authentication/appSlice";
import {
  filedToPmsTask,
  providerSignatureRequiredTask,
  providerSignedTask,
  refileRequiredTask,
} from "../../../../../../global/constants/officeTasks";
import { TOfficeTaskList } from "./TOfficeTaskList";

export const OfficeTaskList = ({ className, officeTasks }: TOfficeTaskList) => {
  const pmsName: string = getPmsName(useAppSelector(selectPmsType));
  const someTasksWereNotSign = officeTasks.some(
    (task) => task.status === EOfficeTaskStatus.ProviderSignatureRequired
  );

  const someTasksWereNotFiled = officeTasks.some(
    (task) => task.status === EOfficeTaskStatus.FailedToFileToPms
  );

  const allTasksWereCompleted = officeTasks.every(
    (task) => task.status === EOfficeTaskStatus.FiledToPms
  );

  const renderFiledTask = () => {
    if (someTasksWereNotFiled) {
      return <OfficeTaskItem task={refileRequiredTask(pmsName)} />;
    }

    if (allTasksWereCompleted) {
      return <OfficeTaskItem task={filedToPmsTask(pmsName)} />;
    } else return !someTasksWereNotSign && "-";
  };

  return (
    <Box className={className}>
      {someTasksWereNotSign ? (
        <OfficeTaskItem
          task={
            someTasksWereNotSign
              ? providerSignatureRequiredTask
              : providerSignedTask
          }
        />
      ) : null}
      {renderFiledTask()}
    </Box>
  );
};
