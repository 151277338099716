import React from "react";
import { Grid } from "@mui/material";
import { SecondaryText } from "../../../../../global/components/SecondaryText/SecondaryText";

export const ListHeader = () => (
  <Grid container mb={1}>
    <Grid item lg={3.9}>
      <SecondaryText>Patient</SecondaryText>
    </Grid>
    <Grid item lg={1.9}>
      <SecondaryText>Appt Time</SecondaryText>
    </Grid>
    <Grid item lg={2.15}>
      <SecondaryText>Time Checked-In</SecondaryText>
    </Grid>
    <Grid item lg={2}>
      <SecondaryText>Office To Do</SecondaryText>
    </Grid>
  </Grid>
);
