import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { Theme } from "@mui/material";

export const getTaskStatusColor = (status: EOfficeTaskStatus, theme: Theme) => {
  const { ProviderSignatureRequired, FilingToPms, ReviewRequired } =
    EOfficeTaskStatus;
  switch (status) {
    case ProviderSignatureRequired:
      return theme.palette.info.main;

    case FilingToPms:
      return theme.palette.primary.light;

    case ReviewRequired:
      return theme.palette.error.main;

    default:
      return theme.palette.success.main;
  }
};
