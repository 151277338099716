import { useEffect } from "react";
import { TEN_MINUTES_IN_S } from "../../../../../global/constants";
import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { useGetPackagesQuery } from "../../../../../global/services/settings/practiceSettings/formLibrary/Packages/packagesApi";
import { EPackageStatus } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/EPackageStatus";
import { updatePackageOptions } from "../../../documentManagerSlice";

export const usePackageOptionsLoader = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { data, isFetching } = useGetPackagesQuery(undefined, {
    refetchOnMountOrArgChange: TEN_MINUTES_IN_S,
  });

  useEffect((): void => {
    if (isFetching || !data?.length) return;
    const enabledPackages = data.filter(
      (formPackage) => formPackage.status === EPackageStatus.Active
    );
    dispatch(updatePackageOptions(enabledPackages));
  }, [isFetching]);
};
