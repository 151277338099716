import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Dialog,
  Paper,
  Stack,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AvatarEditor from "react-avatar-editor";
import closeButton from "../../../../assets/close_button.svg";
import { CtaButtonWithSubtext } from "@yapi/yapi2.components.buttons.cta-button-with-subtext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import BaseModal from "../BaseModal/BaseModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedRedux.hook";
import {
  selectPracticeGuid,
  updatePracticeLogo,
} from "../../../../features/authentication/appSlice";
import {
  selectCurrentBreakpoint,
  selectIsLarge,
} from "../../../../features/layout/layoutSlice";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";
import { SubtextButton } from "@yapi/yapi2.components.buttons.subtext-button";
import { SSlider } from "../../../styles/SSlider";
import { SInput } from "../../../styles/SInput";
import {
  useDeletePracticeLogoMutation,
  useSetPracticeLogoMutation,
} from "../../../services/settings/practiceSettings/practiceApi";
import {
  useDeleteCurrentUserPhotoMutation,
  useSetCurrentUserPhotoMutation,
} from "../../../services/login/currentUserApi";
import {
  updateError,
  updateSnackbar,
} from "../../../../features/authentication/globalMessagesSlice";

import CustomErrorModal from "../ErrorModal/CustomErrorModal";
import CustomLeaveWithoutSavingModal from "../LeaveWithoutSaving/CustomLeaveWithoutSavingModal";
import {
  useDeletePmsUserPhotoMutation,
  useDeleteUserPhotoMutation,
  useSetPmsUserPhotosMutation,
  useSetUserPhotoMutation,
} from "../../../services/settings/users/usersApi";

interface PhotoEditorModalPropTypes {
  type: "practice" | "user" | "userById" | "patient" | "unlinkedUserById";
  borderType: "rect" | "round";
  headerText?: string;
  open: boolean;
  toggleCloseButton?: boolean;
  image: string;
  setPhotoEditorModal: (string) => void;
  previewImage?: string;
  setPreviewImage?: (string) => void;
  refetch?: () => void;
  userId?: string;
}

enum PhotoTypeEnum {
  practice = "practice",
  user = "user",
  userById = "userById",
  unlinkedUserById = "unlinkedUserById",
  patient = "patient",
}

const StyledPaper = styled(Paper)(() => ({
  borderRadius: "8px",
  padding: "16px 16px 32px 16px",
  width: "460px",
}));

const IconButtonHover = styled("div")(() => ({
  cursor: "pointer",
}));

const PhotoEditorContainer = styled("div")(() => ({
  width: "100%",
  position: "relative",
  minHeight: "200px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
}));

const CtaContainer = styled("div")(() => ({
  maxWidth: "350px",
  paddingBottom: "20px",
}));

const PhotoEditorModal: React.FC<PhotoEditorModalPropTypes> = ({
  borderType,
  headerText,
  image,
  toggleCloseButton = true,
  setPhotoEditorModal,
  open,
  previewImage,
  refetch,
  setPreviewImage,
  type,
  userId,
}: PhotoEditorModalPropTypes) => {
  const [editorRef, setEditorRef] = useState(null);
  const [closeWithoutSaving, setCloseWithoutSaving] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState(false);
  const [zoom, setZoom] = useState(1);
  const dispatch = useAppDispatch();
  const guid = useAppSelector(selectPracticeGuid);
  const [setPracticeLogo] = useSetPracticeLogoMutation();
  const [deletePracticeLogo] = useDeletePracticeLogoMutation();
  const [setUserLogo] = useSetCurrentUserPhotoMutation();
  const [setUserLogoById] = useSetUserPhotoMutation();
  const [deleteCurrentUserPhoto] = useDeleteCurrentUserPhotoMutation();
  const [deleteUserPhoto] = useDeleteUserPhotoMutation();
  const [setPmsUserPhotos] = useSetPmsUserPhotosMutation();
  const [deletePmsUserPhoto] = useDeletePmsUserPhotoMutation();
  const isLarge = useAppSelector(selectIsLarge);
  const currentBreakpoint = useAppSelector(selectCurrentBreakpoint);
  // Avatar functions
  const handleScaleChange = (e) => {
    setZoom(e.target.value);
  };

  const handleRotateImage = () => {
    if (rotation > 180) {
      setRotation(0);
    } else {
      setRotation(rotation + 90);
    }
  };

  const editRefChange = (editor) => {
    setEditorRef(editor);
  };

  // image change
  const handleDeleteImageModal = () => {
    setDeleteImageModal(true);
  };

  const onFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setPreviewImage(url);
    setZoom(1);
    setRotation(0);
  };

  const resetImageSettings = () => {
    setTimeout(() => {
      setPreviewImage(null);
      setZoom(1);
      setRotation(0);
    }, 500);
  };

  const handleSaveCroppedImg = () => {
    if (editorRef) {
      const canvasScaled = editorRef.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      if (croppedImg) {
        const base64String = croppedImg.split(",");
        try {
          switch (type) {
            case PhotoTypeEnum.practice:
              setPracticeLogo({
                guid: guid,
                practiceLogo: { practiceLogo: base64String[1] },
              })
                .unwrap()
                .then(() => {
                  refetch();
                  dispatch(updatePracticeLogo(base64String[1]));
                  dispatch(
                    updateSnackbar({ status: true, message: "Updates saved" })
                  );
                })
                .catch(() => {
                  dispatch(updateError(true));
                });
              break;
            case PhotoTypeEnum.user:
              void setUserLogo({
                userPhoto: base64String[1],
              })
                .unwrap()
                .then(() => {
                  refetch();
                  dispatch(
                    updateSnackbar({ status: true, message: "Updates saved" })
                  );
                })
                .catch(() => {
                  dispatch(updateError(true));
                  resetImageSettings();
                });
              break;
            case PhotoTypeEnum.userById:
              void setUserLogoById({
                userId: userId,
                photo: base64String[1],
              }).then(() => {
                refetch();
                dispatch(
                  updateSnackbar({ status: true, message: "Updates saved" })
                );
              });
              break;
            case PhotoTypeEnum.unlinkedUserById:
              void setPmsUserPhotos({
                userId: userId,
                photo: base64String[1],
              }).then(() => {
                refetch();
                dispatch(
                  updateSnackbar({ status: true, message: "Updates saved" })
                );
              });
              break;
          }
          setPhotoEditorModal(false);
          resetImageSettings();
        } catch {
          setPhotoEditorModal(false);
          dispatch(
            updateSnackbar({
              status: true,
              message: "Something went wrong uploading the photo",
            })
          );
        }
      }
    } else {
      dispatch(updateError(true));
      setPhotoEditorModal(false);
      setPreviewImage(null);
    }
  };

  const setClearPracticeLogo = () => {
    switch (type) {
      case PhotoTypeEnum.practice:
        deletePracticeLogo(guid)
          .unwrap()
          .then(() => {
            refetch();
            dispatch(
              updateSnackbar({
                status: true,
                message: "Practice Logo removed",
              })
            );
            dispatch(updatePracticeLogo(""));
          })
          .catch(() => {
            setErrorMessageModal(true);
          });
        break;
      case PhotoTypeEnum.user:
        deleteCurrentUserPhoto(null)
          .unwrap()
          .then(() => {
            refetch();
            dispatch(
              updateSnackbar({
                status: true,
                message: "User Logo removed",
              })
            );
          })
          .catch(() => {
            dispatch(updateError(true));
          });
        break;
      case PhotoTypeEnum.userById:
        deleteUserPhoto(userId)
          .unwrap()
          .then(() => {
            refetch();
            dispatch(
              updateSnackbar({
                status: true,
                message: "User Logo removed",
              })
            );
          })
          .catch(() => dispatch(updateError(true)));
        break;
      case PhotoTypeEnum.unlinkedUserById:
        deletePmsUserPhoto(userId)
          .unwrap()
          .then(() => {
            refetch();
            dispatch(
              updateSnackbar({
                status: true,
                message: "User Logo removed",
              })
            );
          })
          .catch(() => dispatch(updateError(true)));
        break;
    }
    setRotation(0);
    setZoom(1);
    setPhotoEditorModal(false);
    setDeleteImageModal(false);
  };

  const closeModal = () => {
    if (previewImage !== null) {
      setCloseWithoutSaving(true);
      setPhotoEditorModal(false);
    } else {
      resetImageSettings();
      setPhotoEditorModal(false);
    }
  };

  const goBackClickHandler = () => {
    setPhotoEditorModal(true);
  };
  const closeWithoutSavingModal = () => {
    setCloseWithoutSaving(false);
    resetImageSettings();
  };

  const closeErrorMessageModal = () => {
    setErrorMessageModal(false);
    resetImageSettings();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        PaperComponent={StyledPaper}
        data-testid="photoEditorModal"
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          style={{ paddingBottom: "19px" }}
          pl={isLarge ? 2 : 3}
          pr={isLarge ? 0 : 1}
        >
          {headerText && <Typography variant="h5">{headerText}</Typography>}
          {toggleCloseButton && (
            <IconButton onClick={closeModal} disableRipple>
              <img src={closeButton} alt="close_button" />
            </IconButton>
          )}
        </Stack>
        <Box>
          <Stack spacing={3} justifyContent="center">
            <PhotoEditorContainer>
              <AvatarEditor
                border={currentBreakpoint === "xs" ? [40, 32] : [110, 32]}
                borderRadius={borderType === "round" ? 150 : 15}
                color={[0, 0, 0, 0.8]}
                height={200}
                image={!previewImage ? image : previewImage}
                ref={editRefChange}
                rotate={rotation}
                scale={zoom}
                style={{ borderRadius: "15px" }}
                width={200}
              />
            </PhotoEditorContainer>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <IconButtonHover>
                <RotateLeftIcon onClick={handleRotateImage} />
              </IconButtonHover>
              <IconButtonHover>
                <DeleteOutlineIcon onClick={handleDeleteImageModal} />
              </IconButtonHover>
            </Stack>
            <Stack justifyContent="center" alignItems="center" spacing={3}>
              <Typography variant="body2" align="center">
                Click and drag the image to change its position.
              </Typography>
              <Typography variant="body2" align="center">
                Adjust image size.
              </Typography>
              <SSlider
                aria-label="image-scale"
                onChange={handleScaleChange}
                max={3}
                min={0.1}
                step={0.015}
                style={{ maxWidth: "250px" }}
                value={zoom}
                valueLabelDisplay="auto"
              />
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ paddingTop: "15px" }}
            >
              <CtaContainer>
                <CtaButton
                  disabled={false}
                  ctaText="Save Changes"
                  clickHandler={handleSaveCroppedImg}
                  variant="contained"
                />
              </CtaContainer>
              <SubtextButton disabled={false}>
                <label htmlFor="sub_file_upload">Choose Another Photo</label>
              </SubtextButton>
            </Stack>
            <SInput
              accept=".jpg, .jpeg, .png"
              id="sub_file_upload"
              name="sub_file_upload"
              onChange={onFileChange}
              type="file"
            />
          </Stack>
        </Box>
      </Dialog>
      <BaseModal
        open={deleteImageModal}
        setVisibility={setDeleteImageModal}
        headerText={
          type !== "practice" ? "Remove Profile Logo?" : "Remove Practice Logo?"
        }
        subHeaderText={
          type !== "practice"
            ? "Are you sure you want to remove the photo?"
            : "Are you sure you want to remove the logo?"
        }
      >
        <CtaButtonWithSubtext
          ctaText={`${
            type !== "practice" ? "Yes, Remove Photo" : "Yes, Remove Logo"
          }`}
          ctaVariant="contained"
          subtext="Cancel"
          ctaClickHandler={setClearPracticeLogo}
          subtextClickHandler={() => setDeleteImageModal(false)}
        />
      </BaseModal>
      <CustomLeaveWithoutSavingModal
        closeWithoutSaving={closeWithoutSaving}
        closeWithoutSavingModal={setPhotoEditorModal}
        leavePageClickHandler={closeWithoutSavingModal}
        goBackClickHandler={goBackClickHandler}
      />
      <CustomErrorModal
        errorMessageModal={errorMessageModal}
        setErrorMessageModal={setErrorMessageModal}
        closeErrorMessageModal={closeErrorMessageModal}
      />
    </>
  );
};

export default PhotoEditorModal;
