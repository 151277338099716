declare module "@mui/material/styles" {
  interface Palette {
    accent: {
      background: string;
      main: string;
    };
  }
  interface PaletteOptions {
    accent: {
      background: string;
      main: string;
    };
  }
}

export const DARK_THEME = {
  palette: {
    background: {
      default: "#121212",
      paper: "#1C1C1C",
    },
    primary: {
      main: "#F7B500",
      dark: "#F7931E",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B8B8B8",
    },
    error: {
      main: "#FF8A8A",
    },
    success: {
      main: "#47CD0A",
    },
    info: {
      main: "#D187FF", //"Suggested Actions"
      light: "#80BDFF", // "links"
    },
    accent: {
      background: "#FFF3E7",
      main: "#F7931E",
    },
    divider: "#3A3A3A",
  },
  typography: {
    fontFamily: "Roboto Condensed",
    htmlFontSize: 16,
    h1: {
      fontSize: "3.3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.1rem",
      fontWeight: 700,
    },
    fontSize: 16,
    h3: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.3rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    caption: {
      fontSize: "0.9rem",
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1.3rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontWeight: 700,
      textTransform: "none",
    },
    overline: {
      fontSize: "1rem",
    },
  },
};
