import React, { FC, MutableRefObject, useRef, useState } from "react";

import { areEqualProps } from "../../helperFunctions/propsChecker/checkIsPropsChanged";
import { TMenuOption } from "../ContextMenu/ContextMenuWithChildren/TMenuOption";
import { PillButton } from "../PillButton/PillButton";
import ContextMenuOptions from "../ContextMenu/ContextMenuOptions";

interface Option {
  label: string;
  disabled: boolean;
  children: TMenuOption[];
}

interface IProps {
  buttonText: string;
  options: Option[];
  startIcon: JSX.Element;
  endIcon: JSX.Element;
  width?: number;
  height?: number;
  disabled?: boolean;
  shouldCloseOnSelect?: boolean;
}

const AddFormCtrl: FC<IProps> = ({
  buttonText,
  options,
  startIcon,
  endIcon,
  width,
  height,
  disabled = false,
  shouldCloseOnSelect = false,
}): JSX.Element => {
  const pillButtonRef: MutableRefObject<HTMLButtonElement> = useRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAddFormClick = (): void => {
    setAnchorEl(pillButtonRef.current);
  };

  const hideParentHandler = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <ContextMenuOptions
        parentRef={anchorEl}
        openFromElement={anchorEl}
        hideParent={hideParentHandler}
        options={options}
        shouldCloseOnSelect={shouldCloseOnSelect}
      />
      <PillButton
        refEl={pillButtonRef}
        buttonText={buttonText}
        height={height ?? 36}
        pillButtonClickHandler={handleAddFormClick}
        startIcon={startIcon}
        endIcon={endIcon}
        width={width ?? 110}
        disabled={disabled}
      />
    </>
  );
};

const AddFormControl = React.memo(AddFormCtrl, areEqualProps);
export default AddFormControl;
