import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../global/store";
import { getMidnightDate } from "../../global/helperFunctions/epoch/getMidnightDate";
import { IProvider } from "../../global/types/providers/IProvider.interface";
import { ScheduleSortFieldEnum } from "../appointments/schedule/scheduleHeaders/ScheduleSortFields.enum";
import { EPatientType } from "../../global/domains/patients/enums/EPatientType";
import { EAppointmentType } from "../appointments/AppointmentType.enum";
import dayjs from "dayjs";
import { IHomepageAppointment } from "../../global/types/appointments/IHomepageAppointments";
import EAppointmentStatus, {
  ESchedulingAppointmentStatus,
} from "../../global/enums/EAppointmentStatus";
import { IInOfficeAppointment } from "./IInOfficeAppointment";
import { ICheckedOutAppointment } from "./ICheckedOutAppointment";
import { todayCards } from "./getScheduledCards";

export interface ISelectableHomepageAppointment extends IHomepageAppointment {
  isSelected?: boolean;
}

export interface IFilterParams {
  appointmentDate: number;
  appointmentType: EAppointmentType;
  patientType: EPatientType;
  providers?: string[] | null;
  sortField: ScheduleSortFieldEnum;
  sortOrder: string;
}

export enum EMassTextBannerType {
  MANUAL = "manual",
  AUTO_FILLED = "autoFilled",
  AUTO_FILLING_FAILED = "autoFillingFailed",
}

interface IMassTextBannerData {
  message: string;
  type?: EMassTextBannerType;
  appointmentId?: string;
}

interface HomepageSlice {
  displayMassTextBanner: boolean;
  displayMassTextBannerData: IMassTextBannerData;
  filterParams: IFilterParams;
  hideMetrics: boolean;
  providerList: IProvider[];
  recipientList: ISelectableHomepageAppointment[];
  scheduledAppointments: ISelectableHomepageAppointment[];
  selectedAppointments: ISelectableHomepageAppointment[];
  inOfficeAppointments: IInOfficeAppointment[];
  checkedOutAppointments: ICheckedOutAppointment[];
  checkedInAppointments: IHomepageAppointment[];
  visibleStatuses: ESchedulingAppointmentStatus[];
  isInsuranceStatusVisible: boolean;
  isLoadingMassText: boolean;
}

interface IUpdateAppointment {
  checked: boolean;
  selectedAppointment: ISelectableHomepageAppointment;
}

interface IOverrideExclusion {
  shouldAdd: boolean;
  appointment: ISelectableHomepageAppointment;
}

export const defaultFilterParams = {
  appointmentDate: dayjs(getMidnightDate(new Date())).unix(),
  patientType: EPatientType.Any,
  appointmentType: EAppointmentType.Any,
  providers: [],
  sortField: ScheduleSortFieldEnum.Date,
  sortOrder: "asc",
};

const scheduledAppointmentParser = (
  payload: ISelectableHomepageAppointment
) => {
  return {
    ...payload,
    isBroken: null,
    appointmentStatus: EAppointmentStatus[payload.appointmentStatus],
    amount: null,
    reason: null,
    patientInfo: {
      ...payload.patientInfo,
      patientId: payload.patientInfo.id,
      chartId: null,
      phones: [
        {
          phoneType: "Mobile",
          phone: payload.patientInfo.mobilePhoneNumber,
          isPhoneNumberShard: false,
        },
      ],
      email: null,
    },
  };
};

export const initialState: HomepageSlice = {
  filterParams: defaultFilterParams,
  hideMetrics: false,
  providerList: null,
  inOfficeAppointments: [],
  checkedOutAppointments: [],
  scheduledAppointments: [],
  selectedAppointments: [],
  displayMassTextBanner: false,
  displayMassTextBannerData: {
    message: "Mass Text has been sent.",
  },
  recipientList: [],
  checkedInAppointments: [],
  visibleStatuses: todayCards,
  isInsuranceStatusVisible: false,
  isLoadingMassText: false,
};

const removeSelectedAppointment = (
  state: HomepageSlice,
  appointment: ISelectableHomepageAppointment
) => {
  state.selectedAppointments = state.selectedAppointments.filter(
    (selectedAppointment) => selectedAppointment.id !== appointment.id
  );
};

const addSelectedAppointment = (
  state: HomepageSlice,
  appointment: ISelectableHomepageAppointment
) => {
  state.selectedAppointments.push(appointment);
};

export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    selectAppointment: (state, action: PayloadAction<IUpdateAppointment>) => {
      if (action.payload.checked) {
        addSelectedAppointment(state, {
          ...scheduledAppointmentParser(action.payload.selectedAppointment),
          isSelected: true,
        });
      } else {
        removeSelectedAppointment(
          state,
          scheduledAppointmentParser(action.payload.selectedAppointment)
        );
      }
    },
    removeScheduledAppointment: (state, action: PayloadAction<string>) => {
      state.scheduledAppointments = state.scheduledAppointments.filter(
        (appointment) => appointment.id !== action.payload
      );
    },
    removeInOfficeAppointment: (state, action: PayloadAction<string>) => {
      state.inOfficeAppointments = state.inOfficeAppointments.filter(
        (appointment) => appointment.appointmentId !== action.payload
      );
    },
    updateAppointments: (
      state,
      action: PayloadAction<IHomepageAppointment[]>
    ) => {
      state.scheduledAppointments = action.payload;
    },
    updateAppointmentById: (
      state,
      action: PayloadAction<{ id: string; appointment: IHomepageAppointment }>
    ) => {
      const { id, appointment } = action.payload;
      state.scheduledAppointments = state.scheduledAppointments.map(
        (persistedAppointment) => {
          if (persistedAppointment.id !== id) return persistedAppointment;
          return appointment;
        }
      );
    },
    updateSelectAllAppointments: (
      state,
      action: PayloadAction<{
        checked: boolean;
        data?: ISelectableHomepageAppointment[];
      }>
    ) => {
      const { checked, data } = action.payload;
      const addIsSelectedToList = data.map((appointment) => {
        return { ...scheduledAppointmentParser(appointment), isSelected: true };
      });
      state.selectedAppointments = checked ? addIsSelectedToList : [];
    },
    updateSelectedAppointmentDate: (state, action: PayloadAction<Date>) => {
      const { payload: newDate } = action;
      state.filterParams.appointmentDate = dayjs(
        getMidnightDate(newDate)
      ).unix();
      state.selectedAppointments = [];
    },
    updateIsHidingMetrics: (state, action: PayloadAction<boolean>) => {
      state.hideMetrics = action.payload;
    },
    updateSelectedAppointments: (
      state,
      action: PayloadAction<ISelectableHomepageAppointment[]>
    ) => {
      state.selectedAppointments = action.payload;
    },
    updateRecipientList: (
      state,
      action: PayloadAction<ISelectableHomepageAppointment[]>
    ) => {
      state.recipientList = action.payload;
    },
    updateProviderList: (state, action: PayloadAction<IProvider[]>) => {
      state.providerList = action.payload;
    },
    updateAppointmentFilters: (state, action: PayloadAction<IFilterParams>) => {
      state.filterParams = action.payload;
    },
    updateMassTextBanner: (state, action: PayloadAction<boolean>) => {
      state.displayMassTextBanner = action.payload;
    },
    resetMassTextBanner: (state) => {
      state.displayMassTextBanner = initialState.displayMassTextBanner;
    },
    initMassTextBannerMessage: (state) => {
      state.displayMassTextBannerData = initialState.displayMassTextBannerData;
    },
    updateMassTextBannerMessage: (
      state,
      action: PayloadAction<IMassTextBannerData>
    ) => {
      state.displayMassTextBannerData = action.payload;
    },
    updateOverrideAppointment: (
      state,
      action: PayloadAction<IOverrideExclusion>
    ) => {
      const { appointment: selectedAppointment, shouldAdd } = action.payload;
      if (shouldAdd) {
        state.recipientList.push(selectedAppointment);
        return;
      }
      state.recipientList = state.recipientList.filter(
        (appointment) => appointment.id !== selectedAppointment.id
      );
    },
    updateInOfficeAppointments: (
      state,
      action: PayloadAction<IInOfficeAppointment[]>
    ) => {
      state.inOfficeAppointments = action.payload;
    },
    updateCheckedOutAppointments: (
      state,
      action: PayloadAction<ICheckedOutAppointment[]>
    ) => {
      state.checkedOutAppointments = action.payload;
    },
    updateVisibleStatuses: (
      state,
      action: PayloadAction<ESchedulingAppointmentStatus[]>
    ) => {
      state.visibleStatuses = action.payload;
    },
    updateInsuranceStatusVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isInsuranceStatusVisible = action.payload;
    },
    updateIsLoadingMassText: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMassText = action.payload;
    },
  },
});

export const {
  updateInsuranceStatusVisibility,
  updateAppointmentById,
  updateAppointments,
  selectAppointment,
  removeScheduledAppointment,
  removeInOfficeAppointment,
  updateSelectAllAppointments,
  updateSelectedAppointmentDate,
  updateIsHidingMetrics,
  updateSelectedAppointments,
  updateRecipientList,
  updateProviderList,
  updateAppointmentFilters,
  updateMassTextBanner,
  resetMassTextBanner,
  initMassTextBannerMessage,
  updateOverrideAppointment,
  updateMassTextBannerMessage,
  updateInOfficeAppointments,
  updateCheckedOutAppointments,
  updateVisibleStatuses,
  updateIsLoadingMassText,
} = homepageSlice.actions;

export const selectIsLoadingMassText = (state: RootState) =>
  state.homepageState.isLoadingMassText;

export const selectScheduledAppointments = (state: RootState) =>
  state.homepageState.scheduledAppointments;

export const selectSelectedAppointments = (state: RootState) =>
  state.homepageState.selectedAppointments;

export const selectCheckedOutAppointments = (state: RootState) =>
  state.homepageState.checkedOutAppointments;

export const selectCheckedInAppointments = (state: RootState) =>
  state.homepageState.checkedInAppointments;

export const selectInOfficeAppointments = (state: RootState) =>
  state.homepageState.inOfficeAppointments;

export const selectRecipientList = (state: RootState) =>
  state.homepageState.recipientList;

export const selectSelectedRecipientList = (state: RootState) =>
  state.homepageState.recipientList.filter((recipient) => recipient.isSelected);

export const selectSelectedAppointmentDate = (state: RootState) =>
  new Date(
    dayjs.unix(state.homepageState.filterParams.appointmentDate).format()
  );

export const selectIsHidingMetrics = (state: RootState) =>
  state.homepageState.hideMetrics;
export const selectProviderList = (state: RootState) =>
  state.homepageState.providerList;

export const selectAppointmentFilterParams = (state: RootState) =>
  state.homepageState.filterParams;
export const selectDisplayMassTextBanner = (state: RootState) =>
  state.homepageState.displayMassTextBanner;
export const selectDisplayMassTextBannerData = (state: RootState) =>
  state.homepageState.displayMassTextBannerData;

export const selectVisibleStatuses = (state: RootState) =>
  state.homepageState.visibleStatuses;

export const selectIsInsuranceStatusVisible = (state: RootState) =>
  state.homepageState.isInsuranceStatusVisible;

export default homepageSlice.reducer;
