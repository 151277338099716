import { EPMSName } from "../../types/pms/EPMSName";

export const getPmsName = (val: string) => {
  if (val === "opendental") {
    return EPMSName.OPEN_DENTAL;
  } else if (val === "eaglesoft") {
    return EPMSName.EAGLESOFT;
  } else if (val === "dentrix") {
    return EPMSName.DENTRIX;
  } else {
    return "<PMS name>";
  }
};
