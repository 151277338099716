import { IPatientSearchRequest } from "../../../../../../global/domains/patients/types/IPatientSearchRequest.interface";

export const defaultPatientSearchValues: IPatientSearchRequest = {
  criteria: "byName",
  value: "",
  statuses: [],
  appointmentTimeFrame: "all",
  pageIndex: 0,
  pageSize: 10,
};
