import React, { useMemo, useEffect } from "react";

import {
  selectAvailableOptionsSendTo,
  updateAvailableOptionsSendTo,
} from "../common/patientSelectorModal/patientSelectorSlice";
import {
  selectPatientFormSendTo,
  updatePatientFormSendTo,
} from "../pendingForms/FormAssignerModal/pendingFormsModalsSlice";

import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import {
  getOptions,
  PATIENT_TEXT_OPTION,
  PATIENT_EMAIL_OPTION,
  PATIENT_TEXT_AND_EMAIL_OPTION,
} from "./getSendToOptions";
import { SendToView } from "./SendToView";
import { SendToTooltip } from "./SendToTooltip";
import {
  IPendingFormsOptionsArgs,
  IPendingFormsSendTo,
} from "./SendTo.interfaces";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";

const SendTo = ({
  patientTextInformation,
  patientEmailInformation,
  patientTextAndEmailInformation,
  dispatch,
  onChange,
  setValue,
}: IPendingFormsSendTo) => {
  const sendToValue: EFormAssignmentNotificationType = useAppSelector(
    selectPatientFormSendTo
  );
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);

  const optionsConditions: IPendingFormsOptionsArgs = useMemo(() => {
    return {
      textCondition: { disabled: patientTextInformation.disabled },
      emailCondition: { disabled: patientEmailInformation.disabled },
      textAndEmailCondition: {
        disabled: patientTextAndEmailInformation.disabled,
      },
      tooltips: {
        patientText: (
          <SendToTooltip missedFields={patientTextInformation.missedFields} />
        ),
        email: (
          <SendToTooltip missedFields={patientEmailInformation.missedFields} />
        ),
        textAndEmail: (
          <SendToTooltip
            missedFields={patientTextAndEmailInformation.missedFields}
          />
        ),
      },
    };
  }, [
    patientTextInformation,
    patientEmailInformation,
    patientTextAndEmailInformation,
  ]);

  useEffect(() => {
    const setAvailableOptions = (
      isTextDisabled: boolean,
      isEmailDisabled: boolean,
      isTextAndEmailDisabled: boolean
    ): void => {
      const sendToAvailableOptions: EFormAssignmentNotificationType[] = [];

      if (!isTextDisabled)
        sendToAvailableOptions.push(PATIENT_TEXT_OPTION.value);
      if (!isEmailDisabled)
        sendToAvailableOptions.push(PATIENT_EMAIL_OPTION.value);
      if (!isTextAndEmailDisabled) {
        sendToAvailableOptions.push(PATIENT_TEXT_AND_EMAIL_OPTION.value);
      }

      if (
        sendToAvailableOptions.length &&
        sendToAvailableOptions?.length !== storedAvailableOptions?.length
      ) {
        dispatch(updateAvailableOptionsSendTo(sendToAvailableOptions));
      }
    };

    setAvailableOptions(
      patientTextInformation?.disabled,
      patientEmailInformation?.disabled,
      patientTextAndEmailInformation?.disabled
    );
  }, [
    patientTextInformation?.disabled,
    patientEmailInformation?.disabled,
    patientTextAndEmailInformation?.disabled,
    storedAvailableOptions,
  ]);

  useEffect(() => {
    dispatch(updatePatientFormSendTo(storedAvailableOptions?.[0]));
  }, [storedAvailableOptions]);

  return (
    <SendToView
      options={getOptions(optionsConditions)}
      sendTo={sendToValue}
      isRequestForm
      onChange={onChange}
      setValue={setValue}
    />
  );
};

export const PendingFormsSendTo = React.memo(SendTo, areEqualProps);
