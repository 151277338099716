import {
  BaseQueryApi,
  QueryReturnValue,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import orderBy from "lodash/orderBy";
import {
  setInOfficeAlreadyAssignedForms,
  setInOfficeAlreadySubmittedForms,
  updateHaveAssignedForms,
  updateInOfficeFormAssignmentId,
  updateInOfficePackageName,
} from "../../../features/documentManager/tabs/inOfficePatients/FormAssignerModal/inOfficeModalSlice";
import { TForms } from "../../../features/documentManager/tabs/pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import {
  IFormAssignmentViaAppointment,
  IFormViaAppointment,
} from "../../services/document-manager/pending-forms/IFormAssignmentViaAppointment";
import { EFormStatus } from "../../types/documentManager/EFormStatus";
import { ISelectedForm } from "../../types/documentManager/ISelectedForm.interface";
import { ISelectedPackageForm } from "../../types/documentManager/ISelectedPackageForm.interface";
import { TDevice } from "../../types/settings/DeviceSettings/TDevice.type";
import { INotification } from "../../services/document-manager/pending-forms/PendingFormsResponse.interface";

const getNotSubmittedForms = (
  forms: IFormViaAppointment[]
): IFormViaAppointment[] => {
  return forms.filter(
    (form: IFormViaAppointment): boolean =>
      form.status !== EFormStatus.Submitted
  );
};

const getSubmittedForms = (
  forms: IFormViaAppointment[]
): IFormViaAppointment[] => {
  return forms.filter(
    (form: IFormViaAppointment): boolean =>
      form.status === EFormStatus.Submitted
  );
};

const getAssignedForms = (
  forms: IFormViaAppointment[]
): (ISelectedPackageForm | ISelectedForm)[] => {
  return forms.map(
    (
      form: IFormViaAppointment,
      index: number
    ): ISelectedPackageForm | ISelectedForm => {
      return {
        formId: form.id,
        formName: form.name,
        formStatus: form.status,
        hasOfficeDetailsQuestion: form.hasOfficeDetailsQuestion,
        hasPatientInfoQuestions: form.hasPatientInfoQuestions,
        requiresProviderSignature: form.requiresProviderSignature,
        order: ++index,
        responseId: form.responseId,
        formAssignmentId: form.formAssignmentId,
        packageId: form.packageId,
      };
    }
  );
};

const fetchData = async <T>(
  url: string,
  method: string,
  fetchWithBQ: (
    arg: string | FetchArgs
  ) => MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, NonNullable<unknown>>
  >
) => {
  const response = await fetchWithBQ({ url, method });
  return response?.data as T;
};

const filterEnabledDevices = (devicesData: TDevice[]) =>
  devicesData?.filter((device: TDevice) => device.enabled) ?? [];

const findPreviousDevice = (
  enabledDevices: TDevice[],
  deviceNotifications: INotification[]
) => {
  const previousChosenDeviceId = orderBy(
    deviceNotifications,
    "sentAt",
    "desc"
  )[0]?.deviceId;
  return (
    enabledDevices.find((device) => device.id === previousChosenDeviceId) ??
    enabledDevices[0]
  );
};

const dispatchFormAssignmentUpdates = (
  api: BaseQueryApi,
  formAssignment: IFormAssignmentViaAppointment
) => {
  formAssignment &&
    api.dispatch(
      updateInOfficeFormAssignmentId({
        patientId: formAssignment.patientId,
        formAssignmentId: formAssignment.id,
      })
    );

  formAssignment?.packageName &&
    api.dispatch(
      updateInOfficePackageName({
        patientId: formAssignment.patientId,
        packageName: formAssignment.packageName,
      })
    );
};

const handleFormAssignments = (
  api: BaseQueryApi,
  forms: IFormViaAppointment[],
  formAssignment: IFormAssignmentViaAppointment
) => {
  if (forms?.length) {
    const notSubmittedForms: IFormViaAppointment[] =
      getNotSubmittedForms(forms);
    const parsedForms: TForms = getAssignedForms(
      orderBy(notSubmittedForms, "packageId")
    );

    api.dispatch(
      setInOfficeAlreadyAssignedForms({
        forms: parsedForms,
        patientId: formAssignment.patientId,
      })
    );

    api.dispatch(
      updateHaveAssignedForms({
        patientId: formAssignment.patientId,
        haveAssignedForms: true,
      })
    );

    if (forms.length !== notSubmittedForms.length) {
      const submittedForms: IFormViaAppointment[] = getSubmittedForms(forms);
      const parsedSubmittedForms: TForms = getAssignedForms(submittedForms);
      api.dispatch(
        setInOfficeAlreadySubmittedForms({
          patientId: formAssignment.patientId,
          forms: parsedSubmittedForms,
        })
      );
    }
  }
};

export {
  dispatchFormAssignmentUpdates,
  fetchData,
  filterEnabledDevices,
  findPreviousDevice,
  getAssignedForms,
  getNotSubmittedForms,
  getSubmittedForms,
  handleFormAssignments,
};
