import * as Yup from "yup";

const validationSchemaMap = {
  byName: Yup.string()
    .min(2, "Name must be at least 2 characters.")
    .max(75, ""),
  byPhone: Yup.string().min(10, "Invalid format."),
  byEmail: Yup.string().email("Invalid format.").min(1, "Invalid format."),
  byChartId: Yup.string().min(1, "Invalid format.").max(20, ""),
};

export const patientSearchValidationSchema = Yup.object().shape({
  criteria: Yup.string().required("type selection is required"),
  value: Yup.string().when(
    "criteria",
    (criteria) => validationSchemaMap[criteria]
  ),
});
