import React from "react";
import { Box, useTheme } from "@mui/material";

import { CategoryHeader } from "../CategoryHeader";
import { FormRow } from "../FormRow";
import { TPatientIncompleteForms } from "./TPatientIncompleteForms";

export const PatientIncompleteForms = ({
  formAssignmentId,
  incompleteForms,
  patientId,
}: TPatientIncompleteForms) => {
  const theme = useTheme();

  if (!incompleteForms.length) return null;
  return (
    <Box display="flex" flexDirection="column">
      <CategoryHeader
        background={theme.palette.gray.background}
        title="Patient Incomplete Forms"
      />
      {incompleteForms.map((form) => (
        <FormRow
          responseId={form.responseId}
          key={`incomplete_${form.id}_${form.responseId}`}
          formAssignmentId={formAssignmentId}
          formId={form.id}
          name={form.name}
          patientId={patientId}
        />
      ))}
    </Box>
  );
};
