import { IAddToPackageModal } from "./IAddToPackageModal";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../../global/store";

const initialState: IAddToPackageModal = {
  isAddToPackageModalOpened: false,
  selectedPackage: null,
  formData: {
    name: "",
    formId: null,
  },
};

const addToPackageModalSlice = createSlice({
  name: "addToPackageModalSlice",
  initialState,
  reducers: {
    setAddToPackageModalOpened: (state, action: PayloadAction<boolean>) => {
      state.isAddToPackageModalOpened = action.payload;
    },
    setFormData: (
      state,
      action: PayloadAction<IAddToPackageModal["formData"]>
    ) => {
      state.formData = { ...action.payload };
    },
    setSelectedPackage: (
      state,
      action: PayloadAction<IAddToPackageModal["selectedPackage"]>
    ) => {
      state.selectedPackage = action.payload;
    },

    resetAddToPackageModalState: () => initialState,
  },
});

export const {
  setAddToPackageModalOpened,
  resetAddToPackageModalState,
  setFormData,
  setSelectedPackage,
} = addToPackageModalSlice.actions;

export const selectAddToPackageModalOpened = (state: RootState) =>
  state.addToPackageModalState.isAddToPackageModalOpened;

export const selectSelectedPackage = (state: RootState) =>
  state.addToPackageModalState.selectedPackage;

export const selectFormData = (state: RootState) =>
  state.addToPackageModalState.formData;

export default addToPackageModalSlice.reducer;
