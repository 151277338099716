import { createApi } from "@reduxjs/toolkit/query/react";
import { IOnlineSchedulingGeneralSettingsResponse } from "../../../../../types/onlineScheduling/IOnlineSchedulingGeneralSettingsResponse.interface";
import { IOnlineSchedulingGeneralSettingsRequest } from "../../../../../types/onlineScheduling/IOnlineSchedulingGeneralSettingsRequest.interface";
import EStaffType from "../../../../../types/settings/generalSettings/staffType.enum";
import { baseQueryWithReAuth } from "../../../../../helperFunctions/api/baseQueryWithReauth";
import { TEN_MINUTES_IN_S } from "../../../../../constants";

export const generalSettingsApi = createApi({
  reducerPath: "generalSettingsApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  keepUnusedDataFor: TEN_MINUTES_IN_S,
  endpoints: (builder) => ({
    getSchedulingSettings: builder.query<
      IOnlineSchedulingGeneralSettingsResponse,
      null
    >({
      query: () => {
        return `/SchedulingSettings/Current`;
      },
    }),
    setSchedulingSettings: builder.mutation<
      IOnlineSchedulingGeneralSettingsResponse,
      IOnlineSchedulingGeneralSettingsRequest
    >({
      query(schedulingSettingsData) {
        return {
          url: `/SchedulingSettings/Current`,
          method: "PUT",
          body: schedulingSettingsData,
        };
      },
    }),
    getBookingStaff: builder.query<
      {
        id: string;
        staffType: EStaffType;
        firstName: string;
        lastName: string;
        code: string;
        colorHex: string;
        preferredName: string;
      }[],
      { typeFilter: string }
    >({
      query: (staffType) => {
        return `/Staff?typeFilter=${staffType.typeFilter}`;
      },
    }),
  }),
});

export const {
  useGetSchedulingSettingsQuery,
  useSetSchedulingSettingsMutation,
  useGetBookingStaffQuery,
} = generalSettingsApi;
