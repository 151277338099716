import React from "react";

import { FormsTableRow } from "./FormRow";
import { TPackage } from "./types/TPackage";

export const Package = ({ formPackage, onRemovePackage }: TPackage) => {
  if (!formPackage) return null;
  return (
    <FormsTableRow
      showRemoveIcon={true}
      name={formPackage.name}
      boldName
      onRemove={() => onRemovePackage(formPackage.id)}
    />
  );
};
