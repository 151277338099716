import { EOfficeTaskSummaryText } from "../types/documentManager/EOfficeTaskSummaryText.enum";

export const providerSignatureRequiredTask = {
  completed: false,
  summary: EOfficeTaskSummaryText.ProviderSignatureRequired,
};

export const providerSignedTask = {
  completed: true,
  summary: EOfficeTaskSummaryText.ProviderSigned,
};

export const refileRequiredTask = (pmsName) => {
  return {
    completed: false,
    summary: `Refile to ${pmsName} Required`,
  };
};

export const filedToPmsTask = (pmsName) => {
  return {
    completed: true,
    summary: `Filed to ${pmsName}`,
  };
};
