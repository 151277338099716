import React from "react";
import BaseModal from "../BaseModal/BaseModal";
import { styled, Typography, Stack } from "@mui/material";
import { CtaButtonWithSubtext } from "@yapi/yapi2.components.buttons.cta-button-with-subtext";

interface CustomLeaveWithoutSavingProps {
  closeWithoutSaving: boolean;
  closeWithoutSavingModal: (boolean) => void;
  leavePageClickHandler: () => void;
  goBackClickHandler?: () => void;
}
const LeaveWithoutSavingContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "300px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "375px",
  },
}));

const CustomLeaveWithoutSavingModal: React.FC<CustomLeaveWithoutSavingProps> =
  ({
    closeWithoutSaving,
    closeWithoutSavingModal,
    leavePageClickHandler,
    goBackClickHandler,
  }: CustomLeaveWithoutSavingProps) => {
    return (
      <BaseModal
        open={closeWithoutSaving}
        clickHandler={
          goBackClickHandler
            ? goBackClickHandler
            : () => closeWithoutSavingModal(false)
        }
        headerText="Leave Without Saving"
      >
        <LeaveWithoutSavingContainer direction="column" spacing={3}>
          <Typography variant="body1">
            Are you sure you want to leave this page?
          </Typography>
          <Typography variant="body1">Any progress will be lost.</Typography>
          <CtaButtonWithSubtext
            ctaText="Yes, Leave Page"
            ctaVariant="contained"
            subtext="No, Go Back"
            ctaClickHandler={leavePageClickHandler}
            subtextClickHandler={
              goBackClickHandler
                ? goBackClickHandler
                : () => closeWithoutSavingModal(false)
            }
          />
        </LeaveWithoutSavingContainer>
      </BaseModal>
    );
  };

export default CustomLeaveWithoutSavingModal;
