import { MenuItem, styled } from "@mui/material";

type TSelectedMenuItem = {
  isSelected?: boolean;
};

export const SSelectedMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<TSelectedMenuItem>(({ theme, isSelected }) => ({
  "&:hover": {
    backgroundColor: theme.palette.accent.background,
  },
  backgroundColor: "#F7F7F7",
  "&.Mui-disabled": {
    fontWeight: "400",
    backgroundColor: "#F7F7F7",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.accent.background,
  },
  fontWeight: isSelected && 700,
}));
