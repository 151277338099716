import React, { CSSProperties, ReactNode, useRef, useEffect } from "react";

import { InfiniteLoader, List } from "react-virtualized";
import { Box, styled } from "@mui/material";

import { SecondaryText } from "../SecondaryText/SecondaryText";
import { areEqualProps } from "../../helperFunctions/propsChecker/checkIsPropsChanged";
import "./scroll-loader.scss";

type TIndexProp = {
  index: number;
};

type TScrollLoader = {
  isItemLoaded: (props: TIndexProp) => boolean;
  loadMore: () => void;
  renderItem: (prop: TIndexProp) => ReactNode;
  loadedItemsCount: number;
  totalCount: number;
  pageIndex: number;
  className?: string;
  itemHeight: number;
  listHeight?: number;
  batchSize?: number;
};

const SItemWrapper = styled("div")({
  marginBottom: 16,
  ":last-child": {
    marginBottom: 0,
  },
});

type TRowRendererProp = {
  key: string;
  index: number;
  style: CSSProperties;
};

const END_OF_RESULTS_GAP = 24;

const ScrollLoader = ({
  loadMore,
  isItemLoaded,
  totalCount,
  pageIndex,
  renderItem,
  className = "list",
  loadedItemsCount,
  itemHeight,
  batchSize,
  listHeight,
}: TScrollLoader) => {
  // This hardcoded value is overwritten in the css file
  const fakeWidth = 535;
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights();
    }
  }, [itemHeight]);

  const rowRenderer = ({ key, style, index }: TRowRendererProp) => {
    const isLastChild = index === totalCount - 1;
    const props = { index };
    return (
      <SItemWrapper style={style} key={key}>
        {
          <>
            {renderItem(props)}
            {isLastChild && (
              <Box mt={1} display="flex" justifyContent="center">
                <SecondaryText>End of Results</SecondaryText>
              </Box>
            )}
          </>
        }
      </SItemWrapper>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={isItemLoaded}
      loadMoreRows={loadMore}
      threshold={1}
      rowCount={totalCount}
      minimumBatchSize={batchSize}
    >
      {({ onRowsRendered }) => (
        <List
          ref={listRef}
          className={className}
          height={listHeight}
          rowCount={loadedItemsCount}
          rowHeight={({ index }) =>
            index === totalCount - 1
              ? itemHeight + END_OF_RESULTS_GAP
              : itemHeight
          }
          rowRenderer={rowRenderer}
          width={fakeWidth}
          onRowsRendered={onRowsRendered}
          scrollToIndex={pageIndex ? undefined : 0}
        />
      )}
    </InfiniteLoader>
  );
};

export default React.memo(ScrollLoader, areEqualProps);
