import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";
import { saveState } from "../../global/helperFunctions/persistState/saveState";
import { loadState } from "../../global/helperFunctions/persistState/loadState";
import { LIGHT_THEME } from "../../global/constants/themes/lightTheme";
import { DARK_THEME } from "../../global/constants/themes/darkTheme";
import { IIndexable } from "../../global/types/IIndexable.interface";
import {
  ICustomerPracticeLocation,
  ICustomerPracticeLocations,
  IPracticeLocation,
} from "../../global/types/practice/ICustomerPracticeLocations";
import { ICurrentUserPermissions } from "../../global/types/users/ICurrentUserPermissions.interface";
import { IUserModel } from "../../global/types/users/IUserModel";
import { IReturnPracticeInformation } from "../../global/types/practice/IPracticeInformation";

interface ThemeMapType {
  [key: string]: string | number | IIndexable;
}

interface AppStateType {
  theme: ThemeMapType;
  themeName: string;
  loading: boolean;
  customerPracticeLocations: ICustomerPracticeLocations;
  practiceLocations: ICustomerPracticeLocation;
  practice: IPracticeLocation;
  extendedPracticeInformation: IReturnPracticeInformation;
  api: {
    practiceGuid: string;
  };
  user: IUserModel;
  userPermissions: ICurrentUserPermissions;
  sessionId: string;
}

const themeMap: IIndexable = {
  LIGHT_THEME,
  DARK_THEME,
};

const initialState: AppStateType = {
  theme: themeMap["LIGHT_THEME"],
  themeName: "LIGHT_THEME",
  loading: false,
  customerPracticeLocations: {},
  practiceLocations: {
    id: "",
    name: "",
    practiceLocations: [],
  },
  practice: {
    id: "",
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    logo: "",
    email: "",
    pmsType: "",
    pmsVersion: "",
    licensePastDueDays: 0,
  },
  extendedPracticeInformation: {
    city: "",
    customerId: "",
    dateCreated: 0,
    dateModified: 0,
    email: "",
    id: "",
    ipV4Address: "",
    isActive: false,
    latitude: 0,
    licensePastDueDays: 0,
    longitude: 0,
    name: "",
    appointmentChannelInfo: undefined,
    notificationsChannelInfo: undefined,
    directSmsNotificationsChannelInfo: undefined,
    phone: "",
    postalCode: "",
    state: "",
    streetAddress: "",
    timeZoneName: "",
    web: "",
    features: [],
    licenseFeatures: [],
    yServiceVersion: "",
    pmsType: "",
    pmsVersion: "",
    pluginVersion: "",
    operatingSystem: "",
    subscriptionId: "",
  },
  user: {
    canChangeNotificationSettings: true,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    id: "",
    role: "",
    linkedProviderInfo: {
      firstName: "",
      lastName: "",
      code: "",
    },
    notificationSettings: {
      inbound: false,
      massTexts: false,
      onlineScheduling: false,
    },
    status: "",
  },
  api: {
    practiceGuid: "",
  },
  userPermissions: {},
  sessionId: "",
};

const persistedCurrentTheme = loadState("theme");

if (persistedCurrentTheme !== initialState.themeName) {
  initialState.themeName = persistedCurrentTheme;
  initialState.theme = themeMap[persistedCurrentTheme];
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateAppLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      saveState("theme", action.payload);
      return {
        ...state,
        themeName: action.payload,
        theme: themeMap[action.payload],
      };
    },
    // selected practice location
    updatePracticeGuid: (state, action: PayloadAction<string>) => {
      state.api.practiceGuid = action.payload;
    },
    updatePracticeLocations: (state, action) => {
      state.practiceLocations = action.payload;
    },
    updateCustomerPracticeLocations: (state, action) => {
      state.customerPracticeLocations = action.payload;
    },
    updatePractice: (state, action: PayloadAction<IPracticeLocation>) => {
      state.practice = {
        logo: state.practice.logo,
        pmsType: state.practice.pmsType,
        pmsVersion: state.practice.pmsVersion,
        ...action.payload,
      };
    },
    updateExtendedPracticeInformation: (
      state,
      action: PayloadAction<IReturnPracticeInformation>
    ) => {
      state.extendedPracticeInformation = action.payload;
    },
    updatePracticeLogo: (state, action: PayloadAction<string>) => {
      state.practice.logo = action.payload;
    },
    updateCurrentUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    updateCurrentUserPermissions: (
      state,
      action: PayloadAction<ICurrentUserPermissions>
    ) => {
      state.userPermissions = action.payload;
    },
    updateSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
  },
});

export const selectCurrentTheme = (state: RootState): ThemeMapType =>
  state.appState.theme;
export const selectCurrentThemeName = (state: RootState): string =>
  state.appState.themeName;
export const selectPracticeLocations = (
  state: RootState
): ICustomerPracticeLocation => state.appState.practiceLocations;
export const selectCustomerPracticeLocations = (
  state: RootState
): ICustomerPracticeLocations => state.appState.customerPracticeLocations;
export const selectPracticeGuid = (state: RootState): string =>
  state.appState.api.practiceGuid;
export const selectPractice = (state: RootState): IPracticeLocation =>
  state.appState.practice;
export const selectPmsType = (state: RootState): string =>
  state.appState.practice.pmsType;
export const selectCurrentUser = (state: RootState): IUserModel =>
  state.appState.user;
export const selectCurrentUserPermissions = (state: RootState) =>
  state.appState.userPermissions;
export const selectIsCurrentUserAdmin = (state: RootState) =>
  Boolean(state.appState.userPermissions?.UserAdmin);
export const selectExtendedPracticeInformation = (
  state: RootState
): IReturnPracticeInformation => state.appState.extendedPracticeInformation;
export const selectSessionId = (state: RootState) => state.appState.sessionId;

export const {
  updateTheme,
  updatePracticeLocations,
  updatePracticeGuid,
  updateCustomerPracticeLocations,
  updatePractice,
  updatePracticeLogo,
  updateCurrentUser,
  updateCurrentUserPermissions,
  updateExtendedPracticeInformation,
  updateSessionId,
} = appSlice.actions;

export default appSlice.reducer;
