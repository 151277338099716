import React from "react";
import { CategoryHeader } from "../CategoryHeader";
import { Box, useTheme } from "@mui/material";

import { OfficeToDoRow } from "./OfficeToDoRow";
import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { TOfficeTasks } from "./types/TOfficeTasks";

export const OfficeTasks = ({
  formsByResponseId,
  formAssignmentId,
  officeTasks,
  patientId,
}: TOfficeTasks) => {
  const theme = useTheme();

  const notFinishedOfficeTasks = officeTasks?.filter(
    (task) => task.status !== EOfficeTaskStatus.FiledToPms
  );
  if (!notFinishedOfficeTasks?.length) return null;

  return (
    <Box display="flex" flexDirection="column">
      <CategoryHeader
        background={theme.palette.error.light}
        title="Office To Do"
      />
      {notFinishedOfficeTasks.map((task) => {
        if (formsByResponseId[task.responseId]) {
          return (
            <OfficeToDoRow
              key={task.id}
              formAssignmentId={formAssignmentId}
              form={formsByResponseId[task.responseId]}
              patientId={patientId}
              task={task}
            />
          );
        }
      })}
    </Box>
  );
};
