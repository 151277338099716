import { PendingFormsRequest } from "./PendingFormsRequest.interface";
import { PendingFormsResponse } from "./PendingFormsResponse.interface";
import { IPendingPatientResponse } from "../../../types/documentManager/IPendingPatientResponse.interface";
import { inOfficePatientsApi } from "../inOfficePatientsApi";
import { updateRequiresPatientDob } from "../../../../features/documentManager/tabs/pendingForms/FormAssignerModal/pendingFormsModalsSlice";

export const pendingFormsApi = inOfficePatientsApi.injectEndpoints({
  endpoints: (build) => ({
    getPendingForms: build.query<PendingFormsResponse, PendingFormsRequest>({
      query: ({ pageIndex, pageSize = 10, sortField, sortOrder }) =>
        `/formAssignments/pending?pageIndex=${pageIndex}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortField=${sortField}`,
      providesTags: ["PendingForms"],
      keepUnusedDataFor: 0,
    }),
    getPendingPatient: build.query<any, string>({
      async queryFn(patientId, api, extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `/patients/pending/${patientId}`,
          method: "GET",
        });

        const data = response?.data as IPendingPatientResponse;

        if (data) {
          api.dispatch(updateRequiresPatientDob(data?.requiresPatientDob));
        }

        return response;
      },
    }),
  }),
});

export const {
  useGetPendingFormsQuery,
  useLazyGetPendingFormsQuery,
  useLazyGetPendingPatientQuery,
} = pendingFormsApi;
