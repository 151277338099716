import React from "react";
import { SInfiniteScrollRow } from "./styles";
import { TInfiniteScrollRow } from "./types/TInfiniteScrollRow";
import { EndOfResults } from "./EndOfResults";

export const InfiniteScrollRow = ({
  rowVirtualizer,
  virtualRow,
  renderItem,
  isLastRow,
}: TInfiniteScrollRow) => {
  return (
    <SInfiniteScrollRow
      key={virtualRow.key}
      data-index={virtualRow.index}
      ref={rowVirtualizer.measureElement}
      start={virtualRow.start}
    >
      {renderItem(virtualRow.index)}
      {isLastRow && <EndOfResults />}
    </SInfiniteScrollRow>
  );
};
