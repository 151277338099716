export const monthly = {
  chart: {
    type: "areaspline",
  },
  title: {
    text: "YAPI 2.0 chart POC - monthly",
  },
  xAxis: {
    categories: [],
    crosshair: {},
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  tooltip: {},
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {},
    series: {
      fillColor: {
        linearGradient: [],
        stops: [],
      },
      point: {
        events: {
          mouseOver: null,
          click: null,
        },
      },
    },
  },
  series: [],
};

export const yearly = {
  chart: {
    type: "areaspline",
  },
  title: {
    text: "YAPI2.0 chart POC - Yearly",
  },
  xAxis: {
    categories: [],
    crosshair: {
      color: "#F7931E",
      dashStyle: "LongDash",
      width: 1,
      zIndex: 2,
    },
  },
  tooltip: {},
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {},
    series: {
      fillColor: {
        linearGradient: [],
        stops: [],
      },
      point: {
        events: {
          mouseOver: null,
          click: null,
        },
      },
    },
  },
  series: [],
};

// master chart options object - keeping for reference
// {
//   chart: {
//     type: "areaspline",
//   },
//   title: {
//     text: "YAPI2.0 chart POC - monthly",
//   },
//   xAxis: {
//     categories: [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ],
//     crosshair: {
//       color: "#F7931E",
//       dashStyle: "LongDash",
//       width: 1,
//       zIndex: 2,
//     },
//   },
//   yAxis: {
//     title: {
//       text: "",
//     },
//   },
//   tooltip: {
//     shared: true,
//     valueSuffix: "",
//     borderWidth: 0,
//     borderRadius: 25,
//     backgroundColor: "#FFF",
//     useHTML: true,
//     headerFormat: "<small></small><table>",
//     pointFormat:
//       '<tr><td style="height: 5px; font-size: 20px;">Total Revenue: </td>' +
//       '<td style="text-align: right; font-weight: bolder; font-size: 20px;"><b>${point.y}</b></td></tr>',
//     footerFormat: "</table>",
//   },
//   credits: {
//     enabled: false,
//   },
//   plotOptions: {
//     areaspline: {
//       fillOpacity: 0.5,
//       color: "#F7931E",
//     },
//     series: {
//       fillColor: {
//         linearGradient: [0, 0, 0, 300],
//         stops: [
//           [0, "#F7931E"],
//           [1, Highcharts.color("#F7931E").setOpacity(0).get("rgba")],
//         ],
//       },
//       point: {
//         events: {
//           mouseOver: null,
//           click: null,
//         },
//       },
//     },
//   },
//   series: [],
// };
