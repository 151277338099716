import orderBy from "lodash/orderBy";

import {
  TForms,
  setAlreadyAssignedPatientForms,
  setAlreadySubmittedPatientForms,
  updateHasAssignedForms,
  updatePatientFormAssignmentId,
  updatePatientFormPackageName,
} from "../../../features/documentManager/tabs/pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import { downloadFile } from "../../helperFunctions/fileConverter/convertBlobToOtherFormats";
import { IPatientDetails } from "../../types/documentManager/IPatientDetails";
import { TDeviceFormAssignmentNotificationsRequest } from "../../types/notification/TDeviceFormAssignmentNotificationsRequest.type";
import { TDeviceFormAssignmentNotificationsResponse } from "../../types/notification/TDeviceFormAssignmentNotificationsResponse.type";
import { IAssignFormsApiRequest } from "../settings/practiceSettings/formLibrary/formEditor/AssignFormsApiRequest.interface";
import { IAssignFormsApiResponse } from "../settings/practiceSettings/formLibrary/formEditor/AssignFormsApiResponse.interface";
import { DeleteFormApiRequest } from "../settings/practiceSettings/formLibrary/formEditor/DeleteFormApiRequest.interface";
import { IFormAssignmentNotificationResponse } from "../settings/practiceSettings/formLibrary/formEditor/FormAssignmentNotificationRequest.interface";
import { IFormAssignmentNotificationRequest } from "../settings/practiceSettings/formLibrary/formEditor/FormAssignmentNotificationResponse.interface";
import { IUpdateFormAssignmentRequest } from "../settings/practiceSettings/formLibrary/formEditor/UpdateFormAssignmentRequest.interface";
import { inOfficePatientsApi } from "./inOfficePatientsApi";
import {
  IFormAssignmentViaAppointment,
  IFormViaAppointment,
} from "./pending-forms/IFormAssignmentViaAppointment";
import {
  getAssignedForms,
  getNotSubmittedForms,
  getSubmittedForms,
} from "../../helperFunctions/api/formAssignmentHelpers";

export const formAssignmentsApi = inOfficePatientsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPdfDocument: builder.query<
      unknown,
      {
        responseId: string;
        formName: string;
        patientFirstName: string;
        patientLastName: string;
      }
    >({
      async queryFn(args, _api, _extraOptions, fetchWithBQ) {
        const { responseId, formName, patientFirstName, patientLastName } =
          args;
        const resp = await fetchWithBQ({
          url: `${process.env.REACT_APP_YAPI_DATAFORMS}/pdfdocument/response/${responseId}/${patientFirstName}/${patientLastName}`,
          method: "GET",
          responseHandler: (response) => response.blob(),
        });

        if (resp?.data) {
          downloadFile(resp?.data, formName, "pdf");
        }

        return resp;
      },
    }),
    notification: builder.mutation<
      IFormAssignmentNotificationResponse,
      IFormAssignmentNotificationRequest
    >({
      query({ type, deviceId, patientId, formAssignmentId }) {
        return {
          url: `patients/${patientId}/formAssignments/${formAssignmentId}/notifications`,
          method: "POST",
          body: {
            deviceId,
            type,
          },
        };
      },
      invalidatesTags: ["PendingForms"],
    }),
    deviceNotification: builder.mutation<
      TDeviceFormAssignmentNotificationsResponse,
      TDeviceFormAssignmentNotificationsRequest
    >({
      query({ deviceId, patientId, payload }) {
        return {
          url: `patients/${patientId}/formAssignments/notifications/devices/${deviceId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    deleteForm: builder.mutation<void, DeleteFormApiRequest>({
      query({ patientId, formAssignmentId, formId, responseId }) {
        return {
          url: `patients/${patientId}/formAssignments/${formAssignmentId}/forms/${formId}?responseId=${responseId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["InOfficePatients", "PendingForms"],
    }),
    assignForms: builder.mutation<
      IAssignFormsApiResponse,
      IAssignFormsApiRequest
    >({
      query({
        packageName,
        patientId,
        forms,
        pendingPatientCheckIn = false,
        appointmentId = null,
      }) {
        return {
          url: `patients/${patientId}/formAssignments`,
          method: "POST",
          body: {
            forms,
            packageName,
            pendingPatientCheckIn,
            appointmentId,
          },
        };
      },
    }),
    saveFormAssignment: builder.mutation<
      IAssignFormsApiResponse,
      IUpdateFormAssignmentRequest
    >({
      query({
        packageName,
        patientId,
        forms,
        formAssignmentId,
        appointmentId,
      }) {
        return {
          url: `patients/${patientId}/formAssignments/${formAssignmentId}`,
          method: "PATCH",
          body: {
            forms,
            packageName,
            appointmentId,
          },
        };
      },
      invalidatesTags: ["PendingForms"],
    }),
    getFormAssignmentViaAppointment: builder.query<
      IFormAssignmentViaAppointment,
      { appointmentId: string }
    >({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const { appointmentId } = args;
        const response = await fetchWithBQ({
          url: `appointments/${appointmentId}/formAssignment`,
          method: "GET",
        });

        const data = response?.data as IFormAssignmentViaAppointment;

        api.dispatch(updatePatientFormAssignmentId(data?.id || null));
        api.dispatch(updatePatientFormPackageName(data?.packageName || null));

        if (data?.forms?.length) {
          const notSubmittedForms: IFormViaAppointment[] = getNotSubmittedForms(
            data.forms
          );
          const parsedForms: TForms = getAssignedForms(
            orderBy(notSubmittedForms, "packageId")
          );
          api.dispatch(setAlreadyAssignedPatientForms(parsedForms));
          api.dispatch(updateHasAssignedForms(true));

          if (data.forms.length !== notSubmittedForms.length) {
            const submittedForms: IFormViaAppointment[] = getSubmittedForms(
              data.forms
            );
            const parsedSubmittedForms: TForms =
              getAssignedForms(submittedForms);
            api.dispatch(setAlreadySubmittedPatientForms(parsedSubmittedForms));
          }
        } else if (appointmentId && !data?.forms?.length) {
          api.dispatch(setAlreadyAssignedPatientForms(null));
          api.dispatch(setAlreadySubmittedPatientForms(null));
          api.dispatch(updateHasAssignedForms(false));
        }

        return { data };
      },
    }),

    getPatientData: builder.query<IPatientDetails, { patientId: string }>({
      query: ({ patientId }) => `patients/inOffice/${patientId}`,
    }),
  }),
});

export const {
  useDeleteFormMutation,
  useNotificationMutation,
  useLazyGetPdfDocumentQuery,
  useAssignFormsMutation,
  useSaveFormAssignmentMutation,
  useDeviceNotificationMutation,
  useGetFormAssignmentViaAppointmentQuery,
  useLazyGetFormAssignmentViaAppointmentQuery,
  useGetPatientDataQuery,
} = formAssignmentsApi;
