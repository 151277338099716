import { Accordion, styled } from "@mui/material";

export const SAccordion = styled(Accordion)({
  border: "none",
  boxShadow: "none",
  width: "100%",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  marginTop: 10,
  "&.Mui-expanded": {
    marginTop: 10,
    marginBottom: 0,
    borderTop: "inherit",
  },
  borderBottomLeftRadius: "inherit",
  borderBottomRightRadius: "inherit",
});
