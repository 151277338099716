enum EAppointmentStatus {
  Unconfirmed = 1,
  Confirmed = 2,
  Completed = 3,
  PatientCheckedIn = 4,
}

export enum ESchedulingAppointmentStatus {
  Unconfirmed = "Unconfirmed",
  Confirmed = "Confirmed",
  Completed = "Completed",
  CheckedIn = "CheckedIn",
  Broken = "Broken",
}

export enum EAppointmentStepper {
  ChoosePatients = 0,
  CreateMassText = 1,
}

export enum EAppointmentFiltersPanel {
  filters = "filters",
}

export enum ESummaryDetailsType {
  totalPatients = "ids",
  noSms = "smsOptOutIds",
  noMobile = "noMobilePhoneIds",
}

export enum EAppointmentExtraPanel {
  filters = "filters",
  totalPatients = "ids",
  noSms = "smsOptOutIds",
  noMobile = "noMobilePhoneIds",
}

export enum EAppointmentFillingStatus {
  None = 0,
  Filling = 1,
  FilledSuccessfully = 2,
  FillingFailed = 3,
}

export enum EFillApptButtonName {
  Fill = "Fill Appt",
  Filling = "Filling Appt...",
  Filled = "Filled",
  FillingFailed = "Unable to Fill",
}

export default EAppointmentStatus;
