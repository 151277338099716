import { Box, styled } from "@mui/material";
import { SSelectDropdown } from "../../../../global/styles/SSelectDropdown";
import { SSelectedMenuItem } from "../../../../global/styles/SSelectedItem";

export const SCustomMenuWrapper = styled(SSelectDropdown)({
  width: "calc(100% - 48px)",

  ".MuiSelect-select": {
    paddingTop: 8,
  },
});

export const SCustomMenuItem = styled(SSelectedMenuItem, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ isSelected }: { isSelected: boolean }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "block",
  maxWidth: 500,
  fontWeight: isSelected && 700,
}));

export const SSendToViewWrapper = styled(Box)(({ theme }) => ({
  alignItems: "flex-start",
  border: `1px solid ${theme.palette.text.disabled}`,
  borderRadius: 8,
  boxShadow: "0 4px 4px rgba(229, 229, 229, 0.25)",
  display: "flex",
  gap: 16,
  padding: 16,
  width: "100%",
}));
