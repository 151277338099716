import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { isNil } from "lodash";

import { baseQueryWithReAuth } from "../helperFunctions/api/baseQueryWithReauth";
import { EPatientsListType } from "../../features/appointments/schedule/fillAppointment/choosePatients/summaryDetails/EPatientsListType";
import { ISummaryDetails } from "../../features/appointments/schedule/fillAppointment/fillAppointment.interface";
import {
  IRecallForm,
  IAsapForm,
} from "../../features/appointments/schedule/fillAppointment/fillAppointmentSlice";
import {
  IAsapAppointmentDetails,
  IMappedAppointmentDetails,
  IRecallAppointmentDetails,
} from "../types/appointments/IAppointmentDetails.interface";

interface ISummaryDetailsParams {
  formData: IRecallForm | IAsapForm;
  patientsListType: EPatientsListType;
}

interface IAppointmentsDetailsParams {
  patientIdsChunk: string[];
  patientsListType: EPatientsListType;
}

const getAsapAppointmentDetails = (details: IAsapAppointmentDetails) => {
  return {
    ...details.asapAppointment,
    appointmentDetailsId: details.asapAppointment.id,
  };
};

const getRecallAppointmentDetails = (details: IRecallAppointmentDetails) => {
  return {
    ...details.lastRecallAppointment,
    appointmentDetailsId: details.recall.id,
  };
};

export const baseV2API = createApi({
  reducerPath: "baseV2API",
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_HOST.replace("v1", "v2")
  ),
  endpoints: (builder) => ({
    getSummaryDetails: builder.query<ISummaryDetails, ISummaryDetailsParams>({
      query: (data: ISummaryDetailsParams) => {
        const getQueryParams = (form: IRecallForm | IAsapForm) => {
          const searchParams = new URLSearchParams();
          const appendItem = (key, item) =>
            !(isNil(item) || item === "") && searchParams.append(key, item);
          Object.entries(form).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => appendItem(key, item));
            } else {
              appendItem(key, value);
            }
          });
          return searchParams;
        };

        return {
          url:
            data.patientsListType === EPatientsListType.ASAP
              ? "/AsapAppointments/Summaries"
              : "/Recalls/Summaries",
          params: data.formData ? getQueryParams(data.formData) : undefined,
        };
      },
    }),
    getAppointmentsDetails: builder.query<
      IMappedAppointmentDetails[],
      IAppointmentsDetailsParams
    >({
      query(data: IAppointmentsDetailsParams) {
        const getQueryParams = () => {
          const searchParams = new URLSearchParams();
          data?.patientIdsChunk.forEach((id) => searchParams.append("ids", id));
          return searchParams;
        };
        return {
          url:
            data.patientsListType === EPatientsListType.ASAP
              ? "/AsapAppointments/Details"
              : "/Recalls/Details",
          params: getQueryParams(),
        };
      },
      transformResponse: (
        response: IRecallAppointmentDetails[] | IAsapAppointmentDetails[],
        _meta,
        params
      ) => {
        return response?.map((item) => ({
          ...item,
          appointment:
            params.patientsListType === EPatientsListType.ASAP
              ? getAsapAppointmentDetails(item)
              : getRecallAppointmentDetails(item),
        }));
      },
    }),
  }),
});
