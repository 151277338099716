import { createApi } from "@reduxjs/toolkit/query/react";
import { IUserActionRequest } from "./UserActionRequest.interface";
import { IUserActionResponse } from "./UserActionResponse.interface";
import { baseQueryWithReAuth } from "../../../helperFunctions/api/baseQueryWithReauth";

export const officeTasksApi = createApi({
  reducerPath: "officeTasksApi",
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/officeTasks/`
  ),
  endpoints: (builder) => ({
    userAction: builder.mutation<IUserActionResponse, IUserActionRequest>({
      query({ id, userAction }) {
        return {
          url: `${id}/userAction`,
          method: "POST",
          body: {
            userAction,
          },
        };
      },
    }),
  }),
});

export const { useUserActionMutation } = officeTasksApi;
