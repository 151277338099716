import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

import { RootState } from "../../../../global/store";
import {
  IFormAssignmentForm,
  IInOfficePatient,
} from "../../../../global/types/documentManager/InOfficePatient";

import { EDocumentManagerEventType } from "../../events-channel/types/EDocumentManagerEventType";
import { inOfficePageSize } from "./InOfficePatients";
import { TFormUpdatedByPatientEvent } from "../../events-channel/types/DocumentManagerEvent.interfaces";

export type IUpdateDevice = {
  deviceId: string;
  type: EDocumentManagerEventType.DeviceUpdatedClientEvent;
};

interface ScheduledAppointment {
  type: EDocumentManagerEventType;
  appointmentId: string;
}

interface InOfficePatientsState {
  isPatientSelectorModalVisible: boolean;
  isFormAssignerModalVisible: boolean;
  whereFormAssignerModalOpened: number;
  inOfficePatients: IInOfficePatient[];
  pageIndex: number;
  totalCount: number;
  selectedCardId: string; // can be formAssignmentId or patientId if formAssignment is null
  reFetchOutstandingTasksKey: string;
  updateDeviceObject: IUpdateDevice;
  checkInOutAppointmentId: string;
  scheduledAppointments: ScheduledAppointment[];
  isRefillingCompleted: boolean;
  transitionedFormAssignmentIdToPending: string;
}

const initialState: InOfficePatientsState = {
  isPatientSelectorModalVisible: false,
  isFormAssignerModalVisible: false,
  // need for snackbars
  whereFormAssignerModalOpened: null,
  inOfficePatients: [],
  pageIndex: 1,
  totalCount: 0,
  selectedCardId: "",
  reFetchOutstandingTasksKey: null,
  updateDeviceObject: null,
  checkInOutAppointmentId: null,
  scheduledAppointments: [],
  isRefillingCompleted: false,
  transitionedFormAssignmentIdToPending: null,
};

const inOfficePatientsSlice = createSlice({
  name: "inOfficePatients",
  initialState,
  reducers: {
    resetInOfficePatients: (state) => {
      state.pageIndex = 1;
      state.totalCount = 0;
      state.inOfficePatients = [];
      state.selectedCardId = "";
    },
    removeInOfficePatient: (state, action: PayloadAction<string>) => {
      const newPatients = state.inOfficePatients.filter(
        (inOfficePatient) => inOfficePatient.patient.id !== action.payload
      );
      state.inOfficePatients = newPatients;
      state.selectedCardId = "";
    },
    updateCard: (
      state,
      action: PayloadAction<{
        packageName: string;
        forms: IFormAssignmentForm[];
        formAssignmentId: string;
      }>
    ) => {
      const { forms, formAssignmentId, packageName } = action.payload;
      state.inOfficePatients = state.inOfficePatients.map((card) => {
        const isUpdatedCard = card.formAssignment.id === formAssignmentId;
        if (!isUpdatedCard) return card;

        return {
          ...card,
          formAssignment: {
            ...card.formAssignment,
            forms,
            packageName,
          },
        };
      });
    },
    updateCardForm: (
      state,
      action: PayloadAction<TFormUpdatedByPatientEvent>
    ) => {
      const { formAssignmentId, formId, formStatus } = action.payload;
      state.inOfficePatients = state.inOfficePatients.map((card) => {
        const isSameCard = card.formAssignment?.id !== formAssignmentId;
        if (!isSameCard) return card;

        return {
          ...card,
          formAssignment: {
            ...card.formAssignment,
            forms: card.formAssignment.forms.map((form) => {
              const isSelectedForm = form.id !== formId;
              if (!isSelectedForm) return form;
              return {
                ...form,
                status: formStatus,
              };
            }),
          },
        };
      });
    },
    removeDeletedForm: (
      state,
      action: PayloadAction<{ formAssignmentId: string; formId: number }>
    ) => {
      const { formAssignmentId, formId } = action.payload;
      state.inOfficePatients = state.inOfficePatients.map((card) => {
        const isDeletedFormCard = card.formAssignment?.id === formAssignmentId;
        if (!isDeletedFormCard) return card;

        const newForms = card.formAssignment?.forms?.filter(
          (form) => form.id !== formId
        );
        return {
          ...card,
          formAssignment: {
            ...card.formAssignment,
            forms: newForms,
          },
        };
      });
    },
    updateIsPatientSelectorModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isPatientSelectorModalVisible = action.payload;
    },
    updateIsFormAssignerModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isFormAssignerModalVisible = action.payload;
    },
    updateWhereFormAssignerModalOpened: (
      state,
      action: PayloadAction<number>
    ) => {
      state.whereFormAssignerModalOpened = action.payload;
    },
    updateSelectedCardId: (state, action: PayloadAction<string>) => {
      state.selectedCardId = action.payload;
    },
    updatePageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    updateTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    updateInOfficePatientList: (
      state,
      action: PayloadAction<IInOfficePatient[]>
    ) => {
      state.inOfficePatients = action.payload;
    },
    updateReFetchOutstandingTasksKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.reFetchOutstandingTasksKey = action.payload;
    },
    updateDevicesList: (state, action) => {
      state.updateDeviceObject = action.payload;
    },
    updateCheckInOutAppointmentId: (state, action: PayloadAction<string>) => {
      const index = state.inOfficePatients.findIndex(
        (patient) => patient?.appointment?.id === action.payload
      );
      if (index >= 0) {
        const newPageIndex = Math.trunc(index / inOfficePageSize) + 1;
        if (state.pageIndex !== newPageIndex) {
          state.pageIndex = newPageIndex;
        }
      }
      state.checkInOutAppointmentId = action.payload;
    },
    updateScheduledAppointments: (
      state,
      action: PayloadAction<ScheduledAppointment>
    ) => {
      const existedItem = state.scheduledAppointments?.find((item) =>
        isEqual(item, action.payload)
      );
      if (!existedItem) {
        state.scheduledAppointments = [
          ...state.scheduledAppointments,
          action.payload,
        ];
      }
    },
    updateIsRefillingCompleted: (
      state,
      action: PayloadAction<boolean>
    ): void => {
      state.isRefillingCompleted = action.payload;
    },
    updateTransitionedFormAssignmentIdToPending: (
      state,
      action: PayloadAction<string>
    ) => {
      state.transitionedFormAssignmentIdToPending = action.payload;
    },
  },
});

export const {
  resetInOfficePatients,
  removeInOfficePatient,
  removeDeletedForm,
  updateCard,
  updateCardForm,
  updateIsPatientSelectorModalVisible,
  updateIsFormAssignerModalVisible,
  updateSelectedCardId,
  updatePageIndex,
  updateTotalCount,
  updateInOfficePatientList,
  updateReFetchOutstandingTasksKey,
  updateDevicesList,
  updateWhereFormAssignerModalOpened,
  updateCheckInOutAppointmentId,
  updateScheduledAppointments,
  updateIsRefillingCompleted,
  updateTransitionedFormAssignmentIdToPending,
} = inOfficePatientsSlice.actions;

export const selectIsAssignFormModalVisible = (state: RootState) =>
  state.inOfficePatientState.isPatientSelectorModalVisible;

export const selectWhereFormAssignerModalOpened = (state: RootState) =>
  state.inOfficePatientState.whereFormAssignerModalOpened;

export const selectIsAddFormModalVisible = (state: RootState) =>
  state.inOfficePatientState.isFormAssignerModalVisible;

export const selectSelectedCardId = (state: RootState) =>
  state.inOfficePatientState.selectedCardId;

export const selectPageIndex = (state: RootState) =>
  state.inOfficePatientState.pageIndex;

export const selectTotalCount = (state: RootState) =>
  state.inOfficePatientState.totalCount;

export const selectInOfficePatientList = (state: RootState) =>
  state.inOfficePatientState.inOfficePatients;

export const selectReFetchOutstandingTasksKey = (state: RootState) =>
  state.inOfficePatientState.reFetchOutstandingTasksKey;

export const selectInOfficePatient =
  (patientId: string) => (state: RootState) => {
    return state.inOfficePatientState.inOfficePatients?.find(
      (patient) => patient.formAssignment?.patientId === patientId
    );
  };

export const selectFormResponseId =
  (formAssignmentId: string, formId: number) => (state: RootState) => {
    let responseId;
    state.inOfficePatientState.inOfficePatients?.forEach((obj) => {
      if (obj?.formAssignment?.id === formAssignmentId) {
        const form = obj?.formAssignment?.forms?.find(
          (form) => form.id === formId
        );
        if (form) {
          responseId = form.responseId;
        }
      }
    });

    return responseId;
  };

export const selectOfficeTaskId =
  (formAssignmentId: string, formId: number) => (state: RootState) => {
    const inOfficePatient = state.inOfficePatientState.inOfficePatients?.find(
      (obj) => obj?.formAssignment?.id === formAssignmentId
    );
    const officeTask = inOfficePatient?.officeTasks?.find(
      (task) =>
        task.summary === "Provider signature required" && formId === task.formId
    );
    return officeTask?.id;
  };

export const selectFormAssignmentId =
  (formId: number, responseId: string) =>
  (state: RootState): string => {
    let formAssignmentId;
    state.inOfficePatientState.inOfficePatients?.forEach((obj) => {
      const currentTask = obj?.officeTasks?.find(
        (task) => formId === task.formId && responseId === task.responseId
      );

      if (currentTask?.formAssignmentId) {
        formAssignmentId = currentTask?.formAssignmentId;
      }
    });

    return formAssignmentId;
  };

export const selectUpdateDevicesObject = (state: RootState) =>
  state.inOfficePatientState.updateDeviceObject;

export const selectCheckInOutAppointmentId = (state: RootState): string =>
  state.inOfficePatientState.checkInOutAppointmentId;

export const selectScheduledAppointments = (state: RootState) =>
  state.inOfficePatientState.scheduledAppointments;

export const selectIsRefillingCompleted = (state: RootState): boolean =>
  state.inOfficePatientState.isRefillingCompleted;

export const selectTransitionedFormAssignmentIdToPending = (
  state: RootState
): string => state.inOfficePatientState.transitionedFormAssignmentIdToPending;

export default inOfficePatientsSlice.reducer;
