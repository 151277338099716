import { styled } from "@mui/material/styles";
import { FormHelperText, Radio } from "@mui/material";

export const SRadioButton = styled(Radio)(({ theme }) => ({
  padding: "5px 5px 5px 0",
  "&.Mui-checked": {
    color: theme.palette.text.primary,
  },
  "&.Mui-disabled": {
    color: theme.palette.text.disabled,
  },
}));

export const ErrorHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));
