import { communicationsApi } from "../services/communications/communicationsApi";
import { twilioApi } from "../services/communications/twilio/twilioApi";
import { devicesApi } from "../services/document-manager/devicesApi";
import { documentManagerApi } from "../services/document-manager/documentManagerApi";
import { formAssignmentsApi } from "../services/document-manager/formAssignmentsApi";
import { inOfficePatientsApi } from "../services/document-manager/inOfficePatientsApi";
import { officeTasksApi } from "../services/document-manager/office-tasks/officeTasksApi";
import { pendingFormsApi } from "../services/document-manager/pending-forms/pendingFormsApi";
import { featureFlagsApi } from "../services/feature-flags/featureFlagsApi";
import { chartDataApi } from "../services/mockChartApi";
import { patientApi } from "../services/patientsApi";
import { emailTemplatesApi } from "../services/emailCampaigns/emailTemplatesApi";
import { packagesApi } from "../services/settings/practiceSettings/formLibrary/Packages/packagesApi";
import { formEditorApi } from "../services/settings/practiceSettings/formLibrary/formEditor/formEditorApi";
import { acceptedInsuranceApi } from "../services/settings/practiceSettings/onlineScheduling/acceptedInsurances/acceptedInsurancesApi";
import { generalSettingsApi } from "../services/settings/practiceSettings/onlineScheduling/generalSettings/generalSettingsApi";
import { providersApi } from "../services/settings/practiceSettings/onlineScheduling/providers/providersApi";
import { treatmentsApi } from "../services/settings/practiceSettings/onlineScheduling/treatments/treatmentsApi";
import { practiceApi } from "../services/settings/practiceSettings/practiceApi";
import { usersApi } from "../services/settings/users/usersApi";
import { syncEntitiesApi } from "../services/sync/syncEntitiesApi";
import { importFormApi } from "../../features/settings/practiceSettings/formsLibrary/importFormApi";
import { appointmentRemindersApi } from "../../features/settings/practiceSettings/patientReminders/appointmentReminders/appointmentRemindersApi";
import { emailApi } from "../services/communications/mailchimp/emailApi";
import { recallRemindersApi } from "../../features/settings/practiceSettings/patientReminders/recall/recallRemindersApi";
import { fillApptsApi } from "../services/fill-appointment/fillApptsApi";
import { fillApptsV2Api } from "../services/fill-appointment/fillApptsV2Api";
import { emailCampaignsApi } from "../services/emailCampaigns/emailCampaignsApi";
import { formTemplateLibraryApi } from "../../features/settings/practiceSettings/templateLibrary/Forms/formTemplatesLibraryApi";
import { baseAPI } from "../services/baseApi";
import { baseV2API } from "../services/baseV2Api";
import { patientV2Api } from "../services/patients/patientV2Api";
import { api } from "../services/api";
import { formAssignmentModalDataApi } from "../services/document-manager/formAssignmentModalDataApi";

const apiList = [
  api,
  acceptedInsuranceApi,
  baseAPI,
  baseV2API,
  chartDataApi,
  communicationsApi,
  devicesApi,
  documentManagerApi,
  featureFlagsApi,
  formAssignmentsApi,
  formEditorApi,
  generalSettingsApi,
  inOfficePatientsApi,
  formAssignmentModalDataApi,
  officeTasksApi,
  packagesApi,
  patientApi,
  practiceApi,
  patientV2Api,
  providersApi,
  syncEntitiesApi,
  treatmentsApi,
  usersApi,
  twilioApi,
  pendingFormsApi,
  appointmentRemindersApi,
  importFormApi,
  emailApi,
  recallRemindersApi,
  fillApptsApi,
  fillApptsV2Api,
  emailCampaignsApi,
  emailTemplatesApi,
  formTemplateLibraryApi,
];

export const apis = apiList;

const apiReducers = {
  [api.reducerPath]: api.reducer,
  [acceptedInsuranceApi.reducerPath]: acceptedInsuranceApi.reducer,
  [baseAPI.reducerPath]: baseAPI.reducer,
  [baseV2API.reducerPath]: baseV2API.reducer,
  [chartDataApi.reducerPath]: chartDataApi.reducer,
  [communicationsApi.reducerPath]: communicationsApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [documentManagerApi.reducerPath]: documentManagerApi.reducer,
  [featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
  [formAssignmentsApi.reducerPath]: formAssignmentsApi.reducer,
  [formEditorApi.reducerPath]: formEditorApi.reducer,
  [generalSettingsApi.reducerPath]: generalSettingsApi.reducer,
  [formAssignmentModalDataApi.reducerPath]: formAssignmentModalDataApi.reducer,
  [officeTasksApi.reducerPath]: officeTasksApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [practiceApi.reducerPath]: practiceApi.reducer,
  [patientV2Api.reducerPath]: patientV2Api.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [syncEntitiesApi.reducerPath]: syncEntitiesApi.reducer,
  [treatmentsApi.reducerPath]: treatmentsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [appointmentRemindersApi.reducerPath]: appointmentRemindersApi.reducer,
  [importFormApi.reducerPath]: importFormApi.reducer,
  [emailApi.reducerPath]: emailApi.reducer,
  [recallRemindersApi.reducerPath]: recallRemindersApi.reducer,
  [fillApptsApi.reducerPath]: fillApptsApi.reducer,
  [fillApptsV2Api.reducerPath]: fillApptsV2Api.reducer,
  [emailCampaignsApi.reducerPath]: emailCampaignsApi.reducer,
  [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
  [formTemplateLibraryApi.reducerPath]: formTemplateLibraryApi.reducer,
};

export const getApiReducers = () => {
  return apiReducers;
};

export const getApiMiddlewares = () => {
  return [
    api.middleware,
    acceptedInsuranceApi.middleware,
    baseAPI.middleware,
    baseV2API.middleware,
    chartDataApi.middleware,
    communicationsApi.middleware,
    devicesApi.middleware,
    documentManagerApi.middleware,
    featureFlagsApi.middleware,
    formAssignmentsApi.middleware,
    formEditorApi.middleware,
    generalSettingsApi.middleware,
    inOfficePatientsApi.middleware,
    formAssignmentModalDataApi.middleware,
    officeTasksApi.middleware,
    packagesApi.middleware,
    patientApi.middleware,
    practiceApi.middleware,
    patientV2Api.middleware,
    providersApi.middleware,
    syncEntitiesApi.middleware,
    treatmentsApi.middleware,
    usersApi.middleware,
    twilioApi.middleware,
    pendingFormsApi.middleware,
    appointmentRemindersApi.middleware,
    importFormApi.middleware,
    emailApi.middleware,
    recallRemindersApi.middleware,
    fillApptsApi.middleware,
    fillApptsV2Api.middleware,
    emailCampaignsApi.middleware,
    emailTemplatesApi.middleware,
    formTemplateLibraryApi.middleware,
  ];
};
