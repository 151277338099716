import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { areEqualProps } from "../../helperFunctions/propsChecker/checkIsPropsChanged";

const SCircle = styled("span")({
  width: 8,
  height: 8,
  borderRadius: "50%",
  marginRight: 8,
});

type TCircleWithLabel = {
  circleColor: string;
  label: string;
  fontSize?: string;
};
const CircleWithLabelComponent = ({
  circleColor,
  label,
  fontSize = "1rem",
}: TCircleWithLabel) => {
  return (
    <Box display="flex" alignContent="center" alignItems="center">
      <SCircle data-testid="circle-color" sx={{ background: circleColor }} />
      <Typography
        data-testid="circle-label"
        sx={{ fontSize: `${fontSize} !important` }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const CircleWithLabel = React.memo(
  CircleWithLabelComponent,
  areEqualProps
);
