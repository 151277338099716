import React, { ReactElement } from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface IOptionComponent {
  tooltipText: string;
  children: ReactElement | JSX.Element;
}

const STooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    margin: "0 !important",
  },
});

export const OptionComponent = ({ tooltipText, children }: IOptionComponent) =>
  tooltipText ? (
    <STooltip disableInteractive title={tooltipText}>
      <div>{children}</div>
    </STooltip>
  ) : (
    children
  );
