import React from "react";
import { styled } from "@mui/material/styles";
import loadingImg from "../../../assets/loading.gif";
import { CardMedia, Stack } from "@mui/material";
interface ImageContainerPropTypes {
  img?: string;
  marginTop?: string;
  className?: string;
}

interface CardMediaPropTypes {
  img?: boolean;
  component?: string;
  alt?: string;
  title?: string;
  margintop?: string;
}

const Loading = styled(CardMedia, {
  shouldForwardProp: (prop) => prop !== ("img" || "marginTop"),
})<CardMediaPropTypes>(({ img: img, margintop: marginTop }) =>
  img
    ? {
        marginTop: marginTop,
        maxWidth: 275,
        minWidth: 250,
      }
    : {
        maxWidth: "350px",
      }
);

const ImageContainer: React.FC<ImageContainerPropTypes> = ({
  img = loadingImg,
  marginTop = "30%",
  className = "",
}: ImageContainerPropTypes) => {
  return (
    <Stack
      justifyContent="center"
      data-testid="imageContainer"
      alignItems="center"
      className={className}
    >
      <Loading
        img={img === loadingImg}
        component="img"
        alt="Image container"
        image={img}
        title="Image container"
        margintop={marginTop}
      />
    </Stack>
  );
};

export default ImageContainer;
