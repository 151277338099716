import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../global/store";
import { IGetTreatment } from "../../../../../../../global/types/treatments/IGetTreatment";

interface treatmentSliceTypes {
  treatmentList: IGetTreatment[];
}

const initialState: treatmentSliceTypes = {
  treatmentList: [],
};

export const treatmentsSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {
    updateTreatmentOrder: (state, action: PayloadAction<any>) => {
      state.treatmentList = action.payload;
    },
  },
});

export const selectGetTreatments = (state: RootState): IGetTreatment[] =>
  state.treatmentState.treatmentList;

export const { updateTreatmentOrder } = treatmentsSlice.actions;

export default treatmentsSlice.reducer;
