import { Box, Typography } from "@mui/material";
import successful_check from "../../../../../assets/successful_check.svg";
import React from "react";
import { IOfficeTaskItem } from "./IOfficeTaskItem";

export const OfficeTaskItem = ({ task }: { task: IOfficeTaskItem }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "6px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        width: 11,
      }}
    >
      {task.completed ? (
        <img width={11} alt="successful_check" src={successful_check} />
      ) : null}
    </Box>
    <Typography variant="caption">{task.summary}</Typography>
  </Box>
);
