import { useEffect } from "react";
import { TEN_MINUTES_IN_S } from "../../../../../global/constants";
import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { useGetFormsQuery } from "../../../../../global/services/settings/practiceSettings/formLibrary/formEditor/formEditorApi";
import { EActiveStatus } from "../../../../../global/enums/EActiveStatus";
import { EOrderBy } from "../../../../../global/enums/EOrderBy";
import { updateFormOptions } from "../../../documentManagerSlice";

export const useFormOptionsLoader = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { data: formsResponse, isFetching } = useGetFormsQuery(
    {
      statuses: [EActiveStatus.Active],
      sorting: [{ field: "name", sort: EOrderBy.asc }],
      pageSize: null,
    },
    {
      refetchOnMountOrArgChange: TEN_MINUTES_IN_S,
    }
  );

  useEffect((): void => {
    if (isFetching || !formsResponse?.count) return;
    dispatch(updateFormOptions(formsResponse.value));
  }, [isFetching]);
};
