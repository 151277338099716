import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../global/store";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { EFormStatus } from "../../../../../global/types/documentManager/EFormStatus";
import { IFormData } from "../../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { IFormPackage } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { ISelectedForm } from "../../../../../global/types/documentManager/ISelectedForm.interface";
import { ISelectedPackageForm } from "../../../../../global/types/documentManager/ISelectedPackageForm.interface";
import { EActiveStatus } from "../../../../../global/enums/EActiveStatus";
import { latestOrder } from "../../helpers/latestOrder";

export type TForms = (ISelectedForm | ISelectedPackageForm)[];

export interface IPatientFormState {
  sendTo: EFormAssignmentNotificationType;
  packageName: string;
  isPackageNameVisible: boolean;
  appointmentId: string;
  selectedForms: TForms;
  submittedForms: TForms;
  // next properties are exist for assigned forms
  hasAssignedForms?: boolean;
  formAssignmentId?: string;
}

export interface PendingFormsModalsState {
  isRequestFormModalVisible: boolean;
  isPackageNameVisible: boolean;
  patientFormState: IPatientFormState;
  requiresPatientDob?: boolean;
  resendRequestIsVisible?: boolean;
}

const initialState: PendingFormsModalsState = {
  isRequestFormModalVisible: false,
  isPackageNameVisible: false,
  patientFormState: {
    sendTo: null,
    packageName: null,
    isPackageNameVisible: false,
    appointmentId: null,
    selectedForms: null,
    submittedForms: null,
    hasAssignedForms: false,
    formAssignmentId: null,
  },
  requiresPatientDob: true,
  resendRequestIsVisible: false,
};

const pendingFormsModalsSlice = createSlice({
  name: "pendingFormsModals",
  initialState,
  reducers: {
    updateIsRequestFormModalVisible: (
      state: PendingFormsModalsState,
      action: PayloadAction<boolean>
    ): void => {
      state.isRequestFormModalVisible = action.payload;
    },
    updateIsPackageNameVisible: (
      state: PendingFormsModalsState,
      action: PayloadAction<boolean>
    ): void => {
      state.isPackageNameVisible = action.payload;
    },
    updatePatientFormSendTo: (
      state: PendingFormsModalsState,
      action: PayloadAction<EFormAssignmentNotificationType>
    ): void => {
      state.patientFormState.sendTo = action.payload;
    },
    updatePatientFormPackageName: (
      state: PendingFormsModalsState,
      action: PayloadAction<string>
    ): void => {
      state.patientFormState.packageName = action.payload;
    },
    updatePatientFormAppointmentId: (
      state: PendingFormsModalsState,
      action: PayloadAction<string>
    ): void => {
      state.patientFormState.appointmentId = action.payload;
    },
    updateHasAssignedForms: (
      state: PendingFormsModalsState,
      action: PayloadAction<boolean>
    ): void => {
      state.patientFormState.hasAssignedForms = action.payload;
    },
    updatePatientFormAssignmentId: (
      state: PendingFormsModalsState,
      action: PayloadAction<string>
    ): void => {
      state.patientFormState.formAssignmentId = action.payload;
    },
    updateSelectedPatientForms: (
      state: PendingFormsModalsState,
      action: PayloadAction<{ form: IFormData }>
    ): void => {
      const form: IFormData = action.payload?.form;
      const selectedForms: ISelectedForm[] =
        state.patientFormState.selectedForms;

      const newSelectedForm: ISelectedForm = {
        formId: form.id,
        formName: form.name,
        formStatus: EFormStatus.Todo,
        order: selectedForms ? selectedForms.length + 1 : 1,
      };

      if (state.patientFormState.selectedForms?.length) {
        state.patientFormState.selectedForms = [
          ...state.patientFormState.selectedForms,
          newSelectedForm,
        ];
      } else {
        state.patientFormState.selectedForms = [newSelectedForm];
      }
    },
    setAlreadyAssignedPatientForms: (
      state: PendingFormsModalsState,
      action: PayloadAction<ISelectedForm[]>
    ): void => {
      state.patientFormState.selectedForms = action.payload;
    },
    setAlreadySubmittedPatientForms: (
      state: PendingFormsModalsState,
      action: PayloadAction<TForms>
    ): void => {
      state.patientFormState.submittedForms = action.payload;
    },
    removeSelectedPatientForm: (
      state: PendingFormsModalsState,
      action: PayloadAction<{ formId: number; order: number }>
    ): void => {
      const { formId, order } = action.payload;
      const selectedForms: TForms = state.patientFormState.selectedForms;
      const formIndexThatWillBeDeleted: number = selectedForms?.findIndex(
        (form) => form.formId === formId && form.order === order
      );
      if (formIndexThatWillBeDeleted >= 0) {
        selectedForms.splice(formIndexThatWillBeDeleted, 1);
        state.patientFormState.selectedForms = selectedForms.map(
          (form: ISelectedForm, index: number) => {
            return {
              ...form,
              order: ++index,
            };
          }
        );
      }
    },
    updateSelectedPatientPackages: (
      state: PendingFormsModalsState,
      action: PayloadAction<{ package: IFormPackage }>
    ): void => {
      const formPackage: IFormPackage = action.payload?.package;
      const selectedForms: ISelectedForm[] =
        state.patientFormState.selectedForms;

      let lastOrder: number = selectedForms ? latestOrder(selectedForms) : 0;

      const newForms: ISelectedPackageForm[] = formPackage?.forms
        ?.filter((form): boolean => form.activeStatus === EActiveStatus.Active)
        .map((form): ISelectedPackageForm => {
          const { formId, name }: { formId: number; name: string } = form;
          return {
            formId,
            formName: name,
            order: ++lastOrder,
            formStatus: EFormStatus.Todo,
            packageId: formPackage.id,
          };
        });

      if (selectedForms?.length) {
        state.patientFormState.selectedForms = [...selectedForms, ...newForms];
      } else {
        state.patientFormState.selectedForms = newForms;
      }
    },
    removeSelectedPatientPackage: (
      state: PendingFormsModalsState,
      action: PayloadAction<string>
    ): void => {
      const newFormList: ISelectedForm[] =
        state.patientFormState.selectedForms.filter(
          (form: ISelectedPackageForm) => form.packageId !== action.payload
        );
      state.patientFormState.selectedForms = newFormList.map(
        (form: ISelectedForm, index: number) => {
          return {
            ...form,
            order: ++index,
          };
        }
      );
    },
    setDefaultPatientFormState: (state: PendingFormsModalsState): void => {
      state.patientFormState = {
        sendTo: null,
        packageName: null,
        isPackageNameVisible: false,
        appointmentId: null,
        selectedForms: null,
        submittedForms: null,
        hasAssignedForms: false,
        formAssignmentId: null,
      };
    },
    updateRequiresPatientDob: (
      state: PendingFormsModalsState,
      action: PayloadAction<boolean>
    ): void => {
      state.requiresPatientDob = action.payload;
    },
    updateResendRequestIsVisible: (
      state: PendingFormsModalsState,
      action: PayloadAction<boolean>
    ): void => {
      state.resendRequestIsVisible = action.payload;
    },
  },
});

export const selectIsRequestFormModalVisible = (state: RootState): boolean =>
  state.pendingFormsModalsState.isRequestFormModalVisible;

export const selectIsPackageNameVisible = (state: RootState): boolean =>
  state.pendingFormsModalsState.isPackageNameVisible;

export const selectPatientFormSendTo = (
  state: RootState
): EFormAssignmentNotificationType =>
  state.pendingFormsModalsState.patientFormState.sendTo;

export const selectPatientFormPackageName = (state: RootState): string =>
  state.pendingFormsModalsState.patientFormState.packageName;

export const selectPatientFormAppointmentId = (state: RootState): string =>
  state.pendingFormsModalsState.patientFormState.appointmentId;

export const selectHasAssignedForms = (state: RootState): boolean =>
  state.pendingFormsModalsState.patientFormState.hasAssignedForms;

export const selectPatientFormAssignmentId = (state: RootState): string =>
  state.pendingFormsModalsState.patientFormState.formAssignmentId;

export const selectPatientFormState = (state: RootState): IPatientFormState =>
  state.pendingFormsModalsState.patientFormState;

export const selectSelectedPatientForms = (state: RootState): ISelectedForm[] =>
  state.pendingFormsModalsState.patientFormState.selectedForms;

export const selectSubmittedPatientForms = (state: RootState): TForms =>
  state.pendingFormsModalsState.patientFormState.submittedForms;

export const selectSelectedPackagesIds = (state: RootState): string[] => {
  const selectedForms =
    state.pendingFormsModalsState.patientFormState.selectedForms;
  if (!selectedForms) return [];

  const callback = (form) => form.packageId;
  const packagesIds: string[] = selectedForms.filter(callback).map(callback);
  return Array.from(new Set(packagesIds));
};

export const selectRequiresPatientDob = (state: RootState): boolean =>
  state.pendingFormsModalsState.requiresPatientDob;
export const selectResendRequestForm = (state: RootState): boolean =>
  state.pendingFormsModalsState.resendRequestIsVisible;
export const {
  updateIsRequestFormModalVisible,
  updateResendRequestIsVisible,
  updateIsPackageNameVisible,
  updatePatientFormSendTo,
  updatePatientFormPackageName,
  updatePatientFormAppointmentId,
  updateSelectedPatientForms,
  updateSelectedPatientPackages,
  removeSelectedPatientForm,
  removeSelectedPatientPackage,
  setDefaultPatientFormState,
  setAlreadyAssignedPatientForms,
  updateHasAssignedForms,
  updatePatientFormAssignmentId,
  setAlreadySubmittedPatientForms,
  updateRequiresPatientDob,
} = pendingFormsModalsSlice.actions;

export default pendingFormsModalsSlice.reducer;
