import { useEffect, useState } from "react";

import { useGetInOfficePatientAndAppointmentsQuery } from "../../../../../global/services/patientsApi";
import { useGetInOfficeFamilyMembersQuery } from "../../../../../global/services/document-manager/inOfficePatientsApi";

import { useAppSelector } from "../../../../../global/hooks/useTypedRedux.hook";
import { selectSelectedPatientId } from "../../common/patientSelectorModal/patientSelectorSlice";
import { useTomorrowInSeconds } from "../../hooks/useTomorrowInSeconds.hook";
import { IPatientSummary } from "../../../../../global/domains/patients/types/IPatientSummary.interface";
import { IAppointment } from "../../../../../global/domains/patients/types/IAppointment.interface";

export const useGetInOfficeModalState = (isOpen: boolean) => {
  const selectedPatientId: string = useAppSelector(selectSelectedPatientId);
  const [isFamilyMemberErrorModalVisible, setFamilyMemberErrorModalVisibility] =
    useState(false);

  const tomorrowInSeconds: number = useTomorrowInSeconds();
  const queryParams = { patientId: selectedPatientId, time: tomorrowInSeconds };
  const queryOptions = { skip: !isOpen, refetchOnMountOrArgChange: true };

  const { data, isFetching } = useGetInOfficePatientAndAppointmentsQuery(
    queryParams,
    queryOptions
  );
  const patient: IPatientSummary = data?.patient;
  const patientAppointments: IAppointment[] = data?.appointments;

  const {
    data: familyMembers,
    isFetching: isFetchingFamilyMembers,
    isError: failedFetchingFamilyMembers,
    refetch: refetchFamilyMembers,
  } = useGetInOfficeFamilyMembersQuery(selectedPatientId, {
    skip: !isOpen || !patient,
    refetchOnMountOrArgChange: true,
  });

  useEffect((): void => {
    setFamilyMemberErrorModalVisibility(failedFetchingFamilyMembers);
  }, [failedFetchingFamilyMembers]);

  const isLoading: boolean = isFetching || isFetchingFamilyMembers || !patient;

  return {
    isFamilyMemberErrorModalVisible,
    patient,
    isLoading,
    refetchFamilyMembers,
    setFamilyMemberErrorModalVisibility,
    familyMembers,
    patientAppointments,
  };
};
