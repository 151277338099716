const MATERIAL_BREAKPOINTS = {
  XS: 0,
  SM: 600,
  MD: 900,
  LG: 1200,
  XL: 1536,
};

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
const FIVE_MINUTES_IN_S = 300; // 60 * 5
const TEN_MINUTES_IN_S = 600; // 60 * 10
const ONE_HOUR_IN_S = 3_600; // 60 * 60

const WEBSITE_URL_REGEX =
  /^(https?:\/\/)?(([a-z0-9-]+\.)+[a-z]{2,})(:\d+)?(\/[^\s]*)?$/i;

export {
  WEBSITE_URL_REGEX,
  MATERIAL_BREAKPOINTS,
  EMPTY_GUID,
  TEN_MINUTES_IN_S,
  FIVE_MINUTES_IN_S,
  ONE_HOUR_IN_S,
};
