import { createApi } from "@reduxjs/toolkit/query/react";
import { IGetAppointmentTypes } from "../../../../../types/settings/onlineScheduling/treatments/IGetAppointmentTypes";
import { IGetBlockTypes } from "../../../../../types/settings/onlineScheduling/treatments/IGetBlockTypes";
import { IGetPmsSettingsType } from "../../../../../types/settings/onlineScheduling/treatments/IGetPmsSettingsType";
import { IRecallType } from "../../../../../types/settings/onlineScheduling/treatments/IGetRecallTypes";
import { IProcedureCodeForm } from "../../../../../types/settings/onlineScheduling/treatments/IProcedureCodeForm";
import {
  IGetProcedureCodesResponse,
  ISetTreatment,
  ISetTreatmentResponse,
} from "../../../../../types/settings/onlineScheduling/treatments/ISetTreatment";
import { IGetTreatment } from "../../../../../types/treatments/IGetTreatment";
import { queryStringBuilder } from "../../../../../helperFunctions/api/queryStringBuilder";
import { baseQueryWithReAuth } from "../../../../../helperFunctions/api/baseQueryWithReauth";
import { TEN_MINUTES_IN_S } from "../../../../../constants";

export const treatmentsApi = createApi({
  reducerPath: "treatmentsApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  tagTypes: ["Treatment"],
  endpoints: (builder) => ({
    getProcedureCodes: builder.query<
      IGetProcedureCodesResponse,
      IProcedureCodeForm
    >({
      query: (formData) => {
        const queryParams = queryStringBuilder(formData).toString();
        return `/procedureCodes?${queryParams.replace(/\+/, "%20")}`;
      },
      keepUnusedDataFor: TEN_MINUTES_IN_S,
    }),
    getRecallTypes: builder.query<IRecallType[], null>({
      query: () => {
        return "/RecallTypes";
      },
    }),
    getPmsSettings: builder.query<IGetPmsSettingsType, null>({
      query: () => {
        return "PmsSettings/All";
      },
    }),
    getAppointmentTypes: builder.query<IGetAppointmentTypes[], null>({
      query: () => {
        return "AppointmentTypes";
      },
    }),
    getBlockTypes: builder.query<IGetBlockTypes[], null>({
      query: () => {
        return "ScheduleBlockTypes";
      },
    }),
    setTreatment: builder.mutation<ISetTreatmentResponse, ISetTreatment>({
      query: (treatment) => {
        return {
          url: "Treatments",
          method: "POST",
          body: treatment,
        };
      },
      invalidatesTags: ["Treatment"],
    }),
    updateTreatment: builder.mutation<
      ISetTreatmentResponse,
      { id: string; treatment: ISetTreatment }
    >({
      query: (formData) => {
        return {
          url: `Treatments/${formData.id}`,
          method: "PUT",
          body: formData.treatment,
        };
      },
      invalidatesTags: ["Treatment"],
    }),
    getTreatmentSummaries: builder.query<IGetTreatment, null>({
      query: () => {
        return "TreatmentSummaries";
      },
      providesTags: ["Treatment"],
    }),
    getTreatment: builder.query<ISetTreatmentResponse, string>({
      query: (id) => {
        return `Treatments/${id}`;
      },
    }),
    setTreatmentOrder: builder.mutation<string[], string[]>({
      query: (treatmentOrder) => {
        return {
          url: "TreatmentOrderings",
          method: "PUT",
          body: treatmentOrder,
        };
      },
      invalidatesTags: ["Treatment"],
    }),
    deleteTreatment: builder.mutation<{ id: string }, string>({
      query: (id) => {
        return {
          url: `Treatments/${id}`,
          method: "DELETE",
          body: {
            id: id,
          },
        };
      },
      invalidatesTags: ["Treatment"],
    }),
    enableTreatment: builder.mutation<
      { isEnabled: boolean },
      { id: string; isEnabled: boolean }
    >({
      query: (status) => {
        return {
          url: `TreatmentEnabledStates/${status.id}`,
          method: "PUT",
          body: {
            isEnabled: status.isEnabled,
          },
        };
      },
      invalidatesTags: ["Treatment"],
    }),
  }),
});

export const {
  useGetProcedureCodesQuery,
  useSetTreatmentMutation,
  useGetRecallTypesQuery,
  useGetPmsSettingsQuery,
  useGetAppointmentTypesQuery,
  useGetBlockTypesQuery,
  useUpdateTreatmentMutation,
  useGetTreatmentSummariesQuery,
  useGetTreatmentQuery,
  useSetTreatmentOrderMutation,
  useDeleteTreatmentMutation,
  useEnableTreatmentMutation,
} = treatmentsApi;
