import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";

interface SyncEntitiesProps {
  entities: AlwaysOnSyncEntity[];
  startTimestamp?: number;
  endTimestamp?: number;
}

export enum AlwaysOnSyncEntity {
  Operatories = "Operatories",
  Providers = "Providers",
  ProviderSchedules = "ProviderSchedules",
}

export const syncEntitiesApi = createApi({
  reducerPath: "syncEntitiesApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    setSyncEntities: builder.mutation<number, SyncEntitiesProps>({
      query(syncData) {
        return {
          url: "SyncEntities",
          method: "POST",
          body: syncData,
        };
      },
    }),
  }),
});

export const { useSetSyncEntitiesMutation } = syncEntitiesApi;
