import React, { useEffect } from "react";
import { FormRow } from "../FormRow";

import { TaskStatus } from "./TaskStatus";
import { PatientUploads } from "./PatientUploads";
import { Box } from "@mui/material";
import { getPmsName } from "../../../../../../../../global/helperFunctions/text/getPmsName";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../global/hooks/useTypedRedux.hook";
import {
  useAppHistory,
  TAppHistory,
} from "../../../../../../../../global/hooks/useAppHistory";
import Routes from "../../../../../../../../global/routes/routes";
import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { selectPmsType } from "../../../../../../../authentication/appSlice";
import { useUserActionMutation } from "../../../../../../../../global/services/document-manager/office-tasks/officeTasksApi";
import { updateIsLoading } from "../../../../../../documentManagerSlice";
import { updateError } from "../../../../../../../authentication/globalMessagesSlice";
import { shouldHideOptions } from "./shouldHideOptions";
import { useOptionsByTaskStatus } from "../../../../hooks/useOptionsByTaskStatus";
import { TOfficeToDoRow } from "./types/TOfficeToDoRow";
import { TUserActionMutation } from "./types/TUserActionMutation";

export const OfficeToDoRow = ({
  form,
  formAssignmentId,
  patientId,
  task,
}: TOfficeToDoRow) => {
  const history: TAppHistory = useAppHistory();
  const { status, summary } = task;
  const { id: formId, name: formName, uploadedFiles } = form;
  const [userActionMutation, { isLoading, isError }] = useUserActionMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateIsLoading(isLoading));
  }, [isLoading]);

  useEffect(() => {
    isError && dispatch(updateError(true));
  }, [isError]);
  const pmsName: string = getPmsName(useAppSelector(selectPmsType));
  const options = useOptionsByTaskStatus(
    patientId,
    form,
    pmsName,
    task.id,
    userActionMutation as unknown as TUserActionMutation,
    status
  )[task.status];

  const hideOptions = shouldHideOptions(task.status);

  const openPreviewHandler = (): void => {
    if (form?.name) {
      history.push(
        Routes.DocumentManager.inOfficeFormPreview(form.id, form?.responseId),
        form.name
      );
    }
  };

  const formNameClickHandler = (): typeof openPreviewHandler => {
    const needPreview: boolean = [
      EOfficeTaskStatus.ProviderSignatureRequired,
      EOfficeTaskStatus.FailedToFileToPms,
    ].includes(status);

    return needPreview ? openPreviewHandler : undefined;
  };

  return (
    <FormRow
      responseId={form.responseId}
      key={`todo_${formId}`}
      formAssignmentId={formAssignmentId}
      formId={formId}
      patientId={patientId}
      name={formName}
      customOptions={options}
      hideOptions={hideOptions}
      hideDelete={status === EOfficeTaskStatus.WaitingOnFormPDFCreation}
      formNameClickHandler={formNameClickHandler()}
    >
      <Box pt={0.5} display="flex" flexDirection="column">
        <TaskStatus summary={summary} status={status} />
        <PatientUploads uploadedFiles={uploadedFiles} />
      </Box>
    </FormRow>
  );
};
