import { Breakpoint } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";
import { getBreakpoint } from "../../global/helperFunctions/window/getBreakpoint";

interface ILayoutState {
  currentBreakpoint: Breakpoint;
  mainContentWidth: number;
  height: number;
  isNotificationsDrawerOpen: boolean;
  width: number;
}

const { innerWidth, innerHeight } = window;

const initialState: ILayoutState = {
  currentBreakpoint: getBreakpoint(innerWidth),
  mainContentWidth: 980,
  isNotificationsDrawerOpen: false,
  height: innerHeight,
  width: innerWidth,
};

type TWindowDimensions = {
  height: number;
  width: number;
};

const updateBreakpointIfNew = (state: ILayoutState, width: number) => {
  const newBreakpoint = getBreakpoint(width);
  if (state.currentBreakpoint !== newBreakpoint) {
    state.currentBreakpoint = newBreakpoint;
  }
};

const updateHeightIfNew = (state: ILayoutState, height: number) => {
  if (state.height !== height) {
    state.height = height;
  }
};

const updateWidthAndBreakpointIfNew = (state: ILayoutState, width: number) => {
  if (width !== state.width) {
    state.width = width;
    updateBreakpointIfNew(state, width);
  }
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    updateMainContentWidth: (
      state: ILayoutState,
      { payload }: PayloadAction<number>
    ) => {
      state.mainContentWidth = payload;
    },
    toggleNotificationDrawer: (state: ILayoutState) => {
      state.isNotificationsDrawerOpen = !state.isNotificationsDrawerOpen;
    },
    closeNotificationDrawer: (state: ILayoutState) => {
      state.isNotificationsDrawerOpen = false;
    },
    updateSize: (
      state: ILayoutState,
      { payload }: PayloadAction<TWindowDimensions>
    ) => {
      const { width: newWidth, height: newHeight } = payload;
      updateWidthAndBreakpointIfNew(state, newWidth);
      updateHeightIfNew(state, newHeight);
    },
  },
});

export const {
  toggleNotificationDrawer,
  updateMainContentWidth,
  updateSize,
  closeNotificationDrawer,
} = layoutSlice.actions;

export const selectCurrentBreakpoint = (state: RootState) =>
  state.layoutState.currentBreakpoint;

export const selectMainContentWidth = (state: RootState) =>
  state.layoutState.mainContentWidth;

export const selectIsNotificationsDrawerOpen = (state: RootState) =>
  state.layoutState.isNotificationsDrawerOpen;

export const selectIsMobile = (state: RootState) =>
  state.layoutState.currentBreakpoint === "xs";

export const selectIsTablet = (state: RootState) =>
  state.layoutState.currentBreakpoint === "sm" ||
  state.layoutState.currentBreakpoint === "md";

export const selectIsLarge = (state: RootState) =>
  state.layoutState.currentBreakpoint === "lg" ||
  state.layoutState.currentBreakpoint === "xl";

export const selectHeight = (state: RootState) => state.layoutState.height;
export const selectWidth = (state: RootState) => state.layoutState.width;

export default layoutSlice.reducer;
