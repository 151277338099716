import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export interface PillButtonPropTypes {
  /**
   * Click handle function
   */
  pillButtonClickHandler: (unknown) => void;
  /**
   * Pass any arguments in pillButtonClickHandler
   */
  returnArg?: unknown;
  /**
   * Text inside button
   */
  buttonText: string;
  /**
   * Icon before text
   */
  startIcon?: string | JSX.Element;
  /**
   * Icon after text
   */
  endIcon?: string | JSX.Element;
  /**
   * Boolean disabled true or false
   */
  disabled?: boolean;
  /**
   *  Change the color of the text
   */
  color?: string;
  /**
   *  Change the color of the button's background
   */
  backgroundColor?: string;

  /**
   * Set a variable to track element reference
   */
  refEl?: React.Ref<HTMLButtonElement>;

  /**
   * Height of the button
   */
  height?: number | string;

  width?: number | string;

  className?: string; // To enable styled()
}

type SButtonProps = {
  customColor?: string;
  customBackgroundColor?: string;
};

const StyledPillButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "customColor" && prop !== "customBackgroundColor",
})<SButtonProps>(({ theme, customColor, customBackgroundColor }) => ({
  backgroundColor: customBackgroundColor || theme.palette.background.default,
  borderRadius: "40px",
  border: `1px solid ${theme.palette.text.primary}`,
  color: customColor || theme.palette.text.primary,
  fontWeight: "bold",
  lineHeight: "1",
  "&:hover": {
    background: theme.palette.accent.background,
  },
}));

export const PillButton: React.FC<PillButtonPropTypes> = ({
  pillButtonClickHandler,
  returnArg,
  buttonText,
  startIcon,
  endIcon,
  disabled,
  color,
  backgroundColor,
  refEl,
  height,
  width,
  className,
}: PillButtonPropTypes) => {
  return (
    <StyledPillButton
      ref={refEl || null}
      data-testid={"pillButton"}
      disabled={disabled}
      className={className}
      customColor={color}
      customBackgroundColor={backgroundColor}
      variant="outlined"
      onClick={(e) => pillButtonClickHandler(returnArg ?? e)}
      startIcon={
        typeof startIcon === "string" ? <img src={startIcon} /> : startIcon
      }
      endIcon={typeof endIcon === "string" ? <img src={endIcon} /> : endIcon}
      sx={{
        height: height || "100%",
        width: width || "100%",
        flexGrow: height || width ? 0 : 1,
      }}
    >
      {buttonText}
    </StyledPillButton>
  );
};
