import { styled } from "@mui/material";
import React from "react";

const SDiv = styled("div")({
  background: "#F7B500",
  borderRadius: 4,
  fontWeight: "bold",
  height: "fit-content",
  padding: "0 8px",
  marginLeft: 5,
  textAlign: "center",
});

type TNewPatientTag = {
  fontSize?: string;
  marginLeft?: string;
};

const NewPatientTag = ({
  fontSize = "1rem",
  marginLeft = "5px",
}: TNewPatientTag) => {
  return <SDiv sx={{ fontSize: fontSize, marginLeft }}>NP</SDiv>;
};

export default NewPatientTag;
