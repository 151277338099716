import { Box, styled } from "@mui/material";
import { BaseTabs } from "../../../../../global/components/Tabs/BaseTabs";

export const SAssignFormWrapper = styled(Box)(({ theme }) => ({
  maxHeight: "380px",
  overflowY: "auto",
  paddingRight: "4px",
  marginTop: "16px",
  "&::-webkit-scrollbar": {
    width: "6px",
    marginLeft: 5,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 6px ${theme.palette.divider}}`,
    borderRadius: 4,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 4,
    outline: "1px solid slategrey",
    backgroundClip: "padding-box",
  },
}));

export const SBaseTabs = styled(BaseTabs)({
  marginTop: 0,
});
