import React from "react";
import { DoNotDisturbOn } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { SRowWrapper } from "./styles";
import { IFormRowProps } from "./types/IFormRowProps";

export const FormsTableRow = ({
  boldName = false,
  showRemoveIcon = true,
  name,
  onRemove,
}: IFormRowProps) => {
  const handleRemoveClick = () => {
    onRemove();
  };

  return (
    <SRowWrapper container>
      <Grid display="flex" item xs={8} alignItems="center">
        {showRemoveIcon ? (
          <IconButton
            aria-label="delete-form"
            sx={{ padding: 0 }}
            onClick={handleRemoveClick}
          >
            <DoNotDisturbOn color="error" />
          </IconButton>
        ) : (
          <Box pl={3.5} />
        )}
        <Typography pl={1} fontWeight={boldName ? 700 : 500} variant="body2">
          {name}
        </Typography>
      </Grid>
    </SRowWrapper>
  );
};
