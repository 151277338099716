import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../global/store";
import { IProvider } from "../../../../global/types/providers/IProvider.interface";
import { IOutstandingTask } from "../../../../global/types/documentManager/InOfficeOutstandingTasks";

interface OutstandingTaskState {
  selectedOutstandingTaskFilter: string;
  selectedProviderFilter: IProvider;
  pageIndex: number;
  totalCount: number;
  outstandingTasks: IOutstandingTask[];
}

const initialState: OutstandingTaskState = {
  selectedOutstandingTaskFilter: undefined,
  selectedProviderFilter: null,
  pageIndex: 1,
  totalCount: 0,
  outstandingTasks: [],
};

const outstandingTasksSlice = createSlice({
  name: "outstandingTasks",
  initialState,
  reducers: {
    updateSelectedOutstandingTaskFilter: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedOutstandingTaskFilter = action.payload;
    },
    updateSelectedProviderFilter: (state, action: PayloadAction<IProvider>) => {
      state.selectedProviderFilter = action.payload;
    },
    updatePageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    updateTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    updateOutstandingTasks: (
      state,
      action: PayloadAction<IOutstandingTask[]>
    ) => {
      state.outstandingTasks = action.payload;
    },
  },
});

export const {
  updateSelectedOutstandingTaskFilter,
  updateSelectedProviderFilter,
  updatePageIndex,
  updateTotalCount,
  updateOutstandingTasks,
} = outstandingTasksSlice.actions;

export const selectSelectedProviderFilter = (state: RootState) =>
  state.outstandingTasksState.selectedProviderFilter;
export const selectSelectedOutstandingTaskFilter = (state: RootState) =>
  state.outstandingTasksState.selectedOutstandingTaskFilter;

export const selectOutstandingTasksPageIndex = (state: RootState) =>
  state.outstandingTasksState.pageIndex;

export const selectOutstandingTasksTotalCount = (state: RootState) =>
  state.outstandingTasksState.totalCount;

export const selectOutstandingTasks = (state: RootState) =>
  state.outstandingTasksState.outstandingTasks;

export default outstandingTasksSlice.reducer;
