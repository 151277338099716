import React, { useCallback, useEffect, useMemo } from "react";
import { SelectChangeEvent } from "@mui/material";

import {
  selectAvailableOptionsSendTo,
  updateAvailableOptionsSendTo,
} from "../common/patientSelectorModal/patientSelectorSlice";
import {
  updateInOfficeSendTo,
  updateSelectedDevice,
  selectInOfficePatientSendTo,
  selectIsTabInitialized,
  selectInOfficeSelectedDevice,
} from "../inOfficePatients/FormAssignerModal/inOfficeModalSlice";

import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";
import { TDevice } from "../../../../global/types/settings/DeviceSettings/TDevice.type";
import { SecondaryText } from "../../../../global/components/SecondaryText/SecondaryText";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { selectIsAssignFormModalVisible } from "../inOfficePatients/inOfficePatientsSlice";
import {
  DEVICE_OPTION,
  getOptions,
  PATIENT_TEXT_OPTION,
  PATIENT_EMAIL_OPTION,
} from "./getSendToOptions";
import { SendToView } from "./SendToView";
import { SendToTooltip } from "./SendToTooltip";
import {
  InOfficeOptionsArgs,
  IInOfficePatientsSendTo,
} from "./SendTo.interfaces";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { SCustomMenuWrapper, SCustomMenuItem } from "./styles";
import { SEND_TO } from "./constants";
import { useGetFormAssignmentModalDataQuery } from "../../../../global/services/document-manager/formAssignmentModalDataApi";

const SendTo = ({
  patientId,
  dispatch,
  setValue,
  watch,
  patientTextInformation,
  patientEmailInformation,
  onChange,
}: IInOfficePatientsSendTo) => {
  const sendToValue: EFormAssignmentNotificationType = useAppSelector(
    selectInOfficePatientSendTo(patientId)
  );
  const isFormAssignerModalOpen: boolean = useAppSelector(
    selectIsAssignFormModalVisible
  );
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);
  const isTabInitialized: boolean = useAppSelector(
    selectIsTabInitialized(patientId)
  );

  const { data: patientData } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );

  const selectedDevice = useAppSelector(
    selectInOfficeSelectedDevice(patientId)
  );
  const hasDevices = Boolean(patientData?.enabledDevices?.length);

  useEffect(() => {
    const setAvailableOptions = (
      isTextDisabled: boolean,
      isEmailDisabled: boolean
    ): void => {
      const sendToAvailableOptions: EFormAssignmentNotificationType[] = [];

      if (patientData?.enabledDevices?.length)
        sendToAvailableOptions.push(DEVICE_OPTION.value);
      if (!isTextDisabled)
        sendToAvailableOptions.push(PATIENT_TEXT_OPTION.value);
      if (!isEmailDisabled)
        sendToAvailableOptions.push(PATIENT_EMAIL_OPTION.value);

      if (
        sendToAvailableOptions.length &&
        sendToAvailableOptions.length !== storedAvailableOptions?.length
      ) {
        dispatch(updateAvailableOptionsSendTo(sendToAvailableOptions));
      }
    };

    setAvailableOptions(
      patientTextInformation.disabled,
      patientEmailInformation.disabled
    );
  }, [
    patientTextInformation.disabled,
    patientEmailInformation.disabled,
    storedAvailableOptions,
    patientData?.enabledDevices,
  ]);

  useEffect((): void => {
    if (!isTabInitialized) {
      dispatch(
        updateInOfficeSendTo({ patientId, sendTo: storedAvailableOptions?.[0] })
      );
    }
  }, [storedAvailableOptions]);

  useEffect(() => {
    const selectFirstDeviceIfAnyDevice = (): void => {
      if (hasDevices && !selectedDevice) {
        dispatch(
          updateSelectedDevice({
            selectedDevice: patientData?.previousDevice,
            patientId: patientId,
          })
        );
        setValue(SEND_TO, DEVICE_OPTION.value);
      }

      if (selectedDevice && watch(SEND_TO) !== DEVICE_OPTION.value) {
        setValue(SEND_TO, DEVICE_OPTION.value);
      }
    };

    selectFirstDeviceIfAnyDevice();
  }, [
    hasDevices,
    !selectedDevice,
    isFormAssignerModalOpen,
    patientData?.enabledDevices,
  ]);

  const renderDevicesOrNotFoundMessage = useCallback((): JSX.Element => {
    if (!hasDevices) return <SecondaryText>No devices found.</SecondaryText>;

    const isDeviceOptionSelected: boolean = sendToValue === DEVICE_OPTION.value;

    const handleDeviceClick = (e: SelectChangeEvent): void => {
      const { value: id } = e.target;
      const deviceClicked: TDevice = patientData?.enabledDevices.find(
        (device: TDevice): boolean => device.id === id
      );
      dispatch(
        updateSelectedDevice({
          selectedDevice: deviceClicked,
          patientId: patientId,
        })
      );
    };

    return (
      <SCustomMenuWrapper
        disabled={!isDeviceOptionSelected}
        variant="standard"
        onChange={handleDeviceClick}
        value={selectedDevice?.id || ""}
        MenuProps={{ PaperProps: { style: { maxWidth: 500 } } }}
      >
        {patientData?.enabledDevices.map((device: TDevice) => (
          <SCustomMenuItem
            isSelected={selectedDevice?.name === device.name}
            title={device.name}
            key={device.id}
            value={device.id}
          >
            {device.name}
          </SCustomMenuItem>
        ))}
      </SCustomMenuWrapper>
    );
  }, [selectedDevice, hasDevices, sendToValue]);

  const optionsConditions: InOfficeOptionsArgs = useMemo(() => {
    return {
      deviceCondition: { disabled: !hasDevices },
      textCondition: { disabled: patientTextInformation?.disabled },
      emailCondition: { disabled: patientEmailInformation?.disabled },
      tooltips: {
        patientText: (
          <SendToTooltip missedFields={patientTextInformation?.missedFields} />
        ),
        email: (
          <SendToTooltip missedFields={patientEmailInformation?.missedFields} />
        ),
      },
    };
  }, [hasDevices, patientTextInformation, patientEmailInformation]);

  return (
    <SendToView
      options={getOptions(optionsConditions)}
      setValue={setValue}
      sendTo={sendToValue}
      onChange={onChange}
      renderDevicesOrNotFoundMessage={renderDevicesOrNotFoundMessage}
    />
  );
};

export const InOfficePatientsSendTo = React.memo(SendTo, areEqualProps);
