export enum EFormCategory {
  Unknown,
  Consent,
  Intake,
  MedicalHistory,
  PatientInformation,
  Questionnaires,
}

export enum EFormCategoryLiteral {
  Unknown = "Unknown",
  Consent = "Consent",
  Intake = "Intake",
  MedicalHistory = "Medical History",
  PatientInformation = "Patient Information",
  Questionnaires = "Questionnaires",
}
