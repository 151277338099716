import { TSelectOption } from "../../global/types/select/TSelectOption.type";

enum EAppointmentType {
  Any = "Any",
  OnlineScheduling = "OnlineScheduling",
}

export const APPOINTMENT_TYPE_LIST: TSelectOption<EAppointmentType>[] = [
  {
    label: "Any",
    value: EAppointmentType.Any,
  },
  {
    label: "Online Scheduling",
    value: EAppointmentType.OnlineScheduling,
  },
];

export { EAppointmentType };
