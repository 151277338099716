import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../global/store";

interface ImageState {
  imgSrc: string;
  alt: string;
  responseId: string;
  fileId: string;
  formId: number | null;
  patientId: string;
}
interface ImagesState {
  images: { [fileId: string]: ImageState };
}

const initialState: ImagesState = {
  images: {},
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setImage(state, action: PayloadAction<ImageState>) {
      if (!state.images) {
        state.images = {}; // need to define images.
      }
      state.images[action.payload.fileId] = action.payload;
    },
  },
});

export const { setImage } = imageSlice.actions;
export const selectAllImages = (state: RootState) =>
  Object.values(state.imageState.images);
export const selectImageData = (state: RootState, fileId: string) =>
  state.imageState.images[fileId];

export default imageSlice.reducer;
