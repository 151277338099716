import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationTypeEnum from "../../global/enums/ENotificationType";
import ENotificationStatus from "../../global/enums/ENotificationStatus";
import { RootState } from "../../global/store";
import { IGetNotifications } from "../../global/types/notification/IGetNotifications.interface";
import TNotification from "../../global/types/notification/TNotification.type";
import _ from "lodash";

interface INotificationState {
  notifications: TNotification[];
  page: number;
  pageCount: number;
  shouldRefetch: boolean;
  isLoading: boolean;
  unReadCount: number;
  notificationParams: {
    pageIndex: number;
    pageSize: number;
    type: NotificationTypeEnum;
    status: ENotificationStatus;
    isError: boolean;
  };
}

const defaultNotificationsParams = {
  pageIndex: 0,
  pageSize: 100,
  type: NotificationTypeEnum.All,
  status: ENotificationStatus.New,
  isError: false,
};

const initialState: INotificationState = {
  page: 0,
  pageCount: 1,
  notifications: [],
  notificationParams: defaultNotificationsParams,
  shouldRefetch: false,
  isLoading: false,
  unReadCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<TNotification>) => {
      const newNotifications = [action.payload, ...state.notifications];
      const uniqueList = _.uniqBy(newNotifications, function (notification) {
        return notification.id;
      });
      state.notifications = uniqueList;
    },
    updateNotificationStatus: (
      state,
      action: PayloadAction<ENotificationStatus>
    ) => {
      const params = {
        ...state.notificationParams,
        status: action.payload,
      };
      state.notificationParams = params;
    },
    updateNotification: (state, action: PayloadAction<TNotification>) => {
      const { id } = action.payload;
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification.id === id) return action.payload;
        return notification;
      });
      state.notifications = updatedNotifications;
      state.shouldRefetch = true;
    },
    updateNotifications: (state, action: PayloadAction<IGetNotifications>) => {
      const { payload: result } = action;
      const { data: notifications, page, totalUnreadCount } = result;

      state.notifications = notifications;
      state.pageCount = Math.ceil(page?.totalRows / page?.pageSize);
      state.page = page?.pageIndex;
      state.unReadCount = totalUnreadCount;
    },
    updateNotificationsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      state.notificationParams = {
        ...state.notificationParams,
        pageIndex: action.payload,
      };
    },
    updateNotificationType: (
      state,
      action: PayloadAction<NotificationTypeEnum>
    ) => {
      const params = {
        ...state.notificationParams,
        type: action.payload,
      };
      state.notificationParams = params;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateShouldRefetch: (state, action: PayloadAction<boolean>) => {
      state.shouldRefetch = action.payload;
    },
  },
});

export const selectNotifications = (state: RootState) =>
  state.notificationState.notifications;

export const selectFilteredNotifications = (state: RootState) =>
  state.notificationState.notifications.filter((notification) => {
    const { status } = state.notificationState.notificationParams;
    if (status === ENotificationStatus.All) return true;

    if (status === ENotificationStatus.New) return !notification.isRead;

    return notification.isPinned;
  });

export const selectUnreadNotifications = (state: RootState) =>
  selectNotifications(state).filter((notification) => !notification.isRead);

export const selectNotificationCount = (state: RootState) =>
  state.notificationState.unReadCount;

export const selectNotificationStatus = (state: RootState) =>
  state.notificationState.notificationParams.status;

export const selectNotificationType = (state: RootState) =>
  state.notificationState.notificationParams.type;

export const selectNotificationPage = (state: RootState) =>
  state.notificationState.page;

export const selectNotificationPageCount = (state: RootState) =>
  state.notificationState.pageCount;

export const selectShouldRefetch = (state: RootState) =>
  state.notificationState.shouldRefetch;

export const selectIsLoadingNotifications = (state: RootState) =>
  state.notificationState.isLoading;
export const selectNotificationParams = (state: RootState) =>
  state.notificationState.notificationParams;
export const {
  addNotification,
  updateNotification,
  updateNotifications,
  updateNotificationsPage,
  updateNotificationStatus,
  updateNotificationType,
  updateIsLoading,
  updateShouldRefetch,
} = notificationSlice.actions;

export default notificationSlice.reducer;
