import { useAppSelector } from "../../../../../../../../global/hooks/useTypedRedux.hook";
import { useLazyGetPdfDocumentQuery } from "../../../../../../../../global/services/document-manager/formAssignmentsApi";
import { EFormStatus } from "../../../../../../../../global/types/documentManager/EFormStatus";
import { IFormAssignmentForm } from "../../../../../../../../global/types/documentManager/InOfficePatient";
import { selectInOfficePatient } from "../../../../inOfficePatientsSlice";
import { Patient } from "../../../../../../../../global/types/documentManager/InOfficeOutstandingTasks";
import { selectPatientDataFromPendingForms } from "../../../../../pendingForms/pendingFormsSlice";

export const useDownloadOption = ({
  patientId,
  form,
  patient,
  optionForOutstandingTasks,
}: {
  patientId: string;
  form: IFormAssignmentForm;
  patient?: Patient;
  optionForOutstandingTasks?: boolean;
}) => {
  const { responseId, name: formName, status: formStatus } = form;

  const inOfficePatient = useAppSelector(selectInOfficePatient(patientId));
  const pendingFormsPatient = useAppSelector(
    selectPatientDataFromPendingForms(patientId)
  );
  const patientFirstName =
    inOfficePatient?.patient?.firstName ??
    patient?.firstName ??
    pendingFormsPatient?.patient?.firstName;

  const patientLastName =
    inOfficePatient?.patient?.lastName ??
    patient?.lastName ??
    pendingFormsPatient?.patient?.lastName;

  const [getPdfDocument] = useLazyGetPdfDocumentQuery();
  const downloadHandler = async (): Promise<void> => {
    await getPdfDocument({
      responseId,
      formName,
      patientFirstName,
      patientLastName,
    });
  };
  return {
    label: "Download",
    disabled:
      !optionForOutstandingTasks && EFormStatus.Submitted !== formStatus,
    callBack: downloadHandler,
  };
};
