import {
  EFormCategory,
  EFormCategoryLiteral,
} from "../../../../../../global/enums/EFormCategory";
import { IFormData } from "../../../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { TMenuOption } from "../../../../../../global/components/ContextMenu/ContextMenuWithChildren/TMenuOption";

export const generateFormOptions = (
  forms: IFormData[],
  addForm: (form: IFormData) => void
): TMenuOption[] => {
  const validCategories = [
    EFormCategory.Consent,
    EFormCategory.Intake,
    EFormCategory.MedicalHistory,
    EFormCategory.PatientInformation,
    EFormCategory.Questionnaires,
  ];

  const categoryForms: Partial<Record<EFormCategory, IFormData[]>> = {};
  const categoryOptions: Partial<Record<EFormCategory, TMenuOption[]>> = {};

  validCategories.forEach((category) => {
    categoryForms[category] = forms.filter(
      (form) => form.category === category
    );

    categoryOptions[category] = categoryForms[category].map((form) => ({
      label: form.name,
      callBack: () => addForm(form),
    }));
  });

  return [
    {
      label: `${EFormCategoryLiteral.Consent} forms`,
      children: categoryOptions[EFormCategory.Consent],
    },
    {
      label: EFormCategoryLiteral.Intake,
      children: categoryOptions[EFormCategory.Intake],
    },
    {
      label: EFormCategoryLiteral.MedicalHistory,
      children: categoryOptions[EFormCategory.MedicalHistory],
    },
    {
      label: EFormCategoryLiteral.PatientInformation,
      children: categoryOptions[EFormCategory.PatientInformation],
    },
    {
      label: EFormCategoryLiteral.Questionnaires,
      children: categoryOptions[EFormCategory.Questionnaires],
    },
  ];
};
