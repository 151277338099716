import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import vertical_ellipsis from "../../../assets/vertical_ellipsis.svg";
import { ContextMenu } from "./ContextMenu";
import { TOption } from "./Option.type";

export interface ContextMenuPropTypes {
  /**
   * Array of objects that passes a label and route.
   */
  options: TOption[];
  /**
   * Simple onClick function when MenuItem is selected.
   */
  handleItemClick?: (value: string) => void;
  handleEventClose?: boolean;
  handleMenuOpened?: (e) => void;
}

export const ContextMenuButton: React.FC<ContextMenuPropTypes> = ({
  options = [],
  handleItemClick,
  handleEventClose,
  handleMenuOpened,
}: ContextMenuPropTypes) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleMenuOpened?.(e);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (handleEventClose) handleClose();
  }, [handleEventClose]);

  return (
    <div>
      <IconButton
        role="button"
        name="contextMenu"
        onClick={handleMenuOpen}
        disableFocusRipple
        disableRipple
      >
        <img src={vertical_ellipsis} alt="contextMenu" />
      </IconButton>
      <ContextMenu
        anchorEl={anchorEl}
        options={options}
        handleClose={handleClose}
        handleItemClick={handleItemClick}
      />
    </div>
  );
};
