import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";
import { IFormData } from "../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { IFormPackage } from "../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";

interface DocumentManagerSlice {
  isLoading: boolean;
  formOptions: IFormData[];
  packageOptions: IFormPackage[];
}

const initialState: DocumentManagerSlice = {
  isLoading: false,
  formOptions: [],
  packageOptions: [],
};

export const documentManagerSlice = createSlice({
  name: "documentManager",
  initialState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoading } = action;
      state.isLoading = isLoading;
    },
    updateFormOptions: (
      state: DocumentManagerSlice,
      action: PayloadAction<IFormData[]>
    ): void => {
      state.formOptions = action.payload;
    },
    updatePackageOptions: (
      state: DocumentManagerSlice,
      action: PayloadAction<IFormPackage[]>
    ): void => {
      state.packageOptions = action.payload;
    },
  },
});

export const selectIsDocumentManagerLoading = (state: RootState) =>
  state.documentManagerState.isLoading;

export const selectFormOptions = (state: RootState): IFormData[] =>
  state.documentManagerState.formOptions;

export const selectPackageOptions = (state: RootState): IFormPackage[] =>
  state.documentManagerState.packageOptions;

export const { updateIsLoading, updateFormOptions, updatePackageOptions } =
  documentManagerSlice.actions;

export default documentManagerSlice.reducer;
