import React from "react";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";

import {
  updateIsInOfficePatientAlreadyAddedModalVisible,
  selectIsInOfficePatientAlreadyAddedModalVisible,
} from "../../../inOfficePatients/FormAssignerModal/inOfficeModalSlice";

import BaseModal from "../../../../../../global/components/Modals/BaseModal/BaseModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../../global/store";
import { HEADER_TITLE, SUB_HEADER_TEXT } from "./constants";
import { OK } from "../../../../../../global/constants/modalText";
import { bodyStyles, dialogStyles } from "../constants";

export const PatientAlreadyAddedModal = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const isModalVisible: boolean = useAppSelector(
    selectIsInOfficePatientAlreadyAddedModalVisible
  );

  const btnHandler = (): void => {
    dispatch(updateIsInOfficePatientAlreadyAddedModalVisible(false));
  };

  const changeViewStatus = (isVisible: boolean): void => {
    dispatch(updateIsInOfficePatientAlreadyAddedModalVisible(isVisible));
  };

  return (
    <BaseModal
      sxDiag={dialogStyles}
      sxBody={bodyStyles}
      open={isModalVisible}
      headerText={HEADER_TITLE}
      setVisibility={changeViewStatus}
      subHeaderText={SUB_HEADER_TEXT}
    >
      <CtaButton width={204} clickHandler={btnHandler} ctaText={OK} />
    </BaseModal>
  );
};
