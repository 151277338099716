import React, { useState, useEffect } from "react";
import { Box, FormHelperText, styled } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useGetMatchingPatientsQuery } from "../../../../../../global/services/patientsApi";

import {
  clearPatientResults,
  clearState,
  selectHasSearched,
  selectLastSearchText,
  selectPageIndex,
  selectPatients,
  updateHasSearched,
  updateIsLoading,
  updateLastTextSearch,
  updatePageIndex,
  updatePatients,
  updateTotalPatients,
} from "../patientSelectorSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../global/hooks/useTypedRedux.hook";
import LinkTypography from "../../../../../../global/components/LinkTypography/LinkTypography";
import { SearchBar } from "../../../../../patients/patientSearch/SearchBar";
import { defaultPatientSearchValues } from "./defaultPatientSearchParams";
import { patientSearchValidationSchema } from "../../../../../patients/patientSearch/patientSearchValidationSchema";
import { EPatientStatusCriteria } from "../../../../../../global/domains/patients/enums/EPatientStatusCriteria";
import { EPatientSearchCriteria } from "../../../../../../global/domains/patients/enums/EPatientSearchCriteria";
import { EApiErrorCode } from "../../../../../../global/types/errors/EApiErrorCode.enum";

const SSearchBox = styled(Box)({
  maxWidth: 400,
});

const HELPER_TEXT_STYLES = { paddingTop: "8px", paddingLeft: "10px" };
const TOO_MANY_RESULTS_ERROR_MESSAGE =
  "Too many results. Please try again with a different search term, or use different search criteria";
const { BY_NAME, BY_PHONE, BY_CHART_ID, BY_EMAIL } = EPatientSearchCriteria;
const defaultPatientSearch = () => {
  return {
    ...defaultPatientSearchValues,
    statuses: [
      { value: true, label: EPatientStatusCriteria.Patient },
      {
        value: true,
        label: EPatientStatusCriteria.NewPatient,
      },
    ],
  };
};

const PatientSearch = () => {
  const methods = useForm({
    resolver: yupResolver(patientSearchValidationSchema),
    defaultValues: defaultPatientSearch(),
    mode: "onChange",
  });

  const { setValue, handleSubmit, watch } = methods;

  const currentSearchCriteria = watch("criteria") as EPatientSearchCriteria;
  const currentSearchValue = watch("value");

  const searchCriteriaOptions = [
    { value: BY_NAME, label: "Name" },
    { value: BY_PHONE, label: "Phone" },
    { value: BY_EMAIL, label: "Email" },
    { value: BY_CHART_ID, label: "Chart ID" },
  ];

  const placeholderSearchCriteria = searchCriteriaOptions.find(
    (searchValue) => searchValue.value === currentSearchCriteria
  );

  // rtk
  const lastSearchText = useAppSelector(selectLastSearchText);
  const pageIndex = useAppSelector(selectPageIndex);
  const hasChangedSearchText = lastSearchText !== currentSearchValue;
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useState(defaultPatientSearch());

  useEffect(() => {
    setErrorMessage("");
    dispatch(updateHasSearched(false));
  }, [currentSearchCriteria]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      pageIndex,
    });
  }, [pageIndex]);

  const hasSearched = useAppSelector(selectHasSearched);
  const shouldSkipSearch = () => {
    if (!hasSearched) return true;
    if (errorMessage) return true;
    if (searchParams === defaultPatientSearchValues) return true;
    if (searchParams.value !== currentSearchValue) return true;
    return false;
  };

  const {
    data: patientsResponseData,
    error,
    isError,
    isFetching: isFetchingPatients,
    refetch,
  } = useGetMatchingPatientsQuery(searchParams, {
    refetchOnMountOrArgChange: false,
    skip: shouldSkipSearch(),
  });

  useEffect(() => {
    if (searchParams.pageIndex !== pageIndex) {
      dispatch(updatePageIndex(searchParams.pageIndex));
    }
    refetch();
  }, [searchParams]);

  const displayTooManyResultsError = () => {
    dispatch(updateHasSearched(false));
    setErrorMessage(TOO_MANY_RESULTS_ERROR_MESSAGE);
    dispatch(clearPatientResults());
  };

  useEffect(() => {
    dispatch(updateIsLoading(isFetchingPatients));
    if (isFetchingPatients) return;

    if (isError) {
      const isTooManyResults =
        (error as any)?.data?.errorCode === EApiErrorCode.TOO_MANY_RESULTS;
      if (isTooManyResults) {
        displayTooManyResultsError();
        return;
      }
      dispatch(clearPatientResults());
    }

    const getUpdatedPatients = () => {
      if (hasChangedSearchText) return returnedPatients;
      if (!returnedPatients) return patients;

      return [...patients, ...returnedPatients];
    };

    const updateSliceWithReturnedData = () => {
      dispatch(updateTotalPatients(returnedTotalRows || 0));

      const newPatients = getUpdatedPatients();
      dispatch(updatePatients(newPatients));
      dispatch(updateLastTextSearch(currentSearchValue));
    };

    const hasTooManyEmailResults =
      currentSearchCriteria === BY_EMAIL && returnedTotalRows > 6;

    if (hasTooManyEmailResults) {
      displayTooManyResultsError();
      return;
    }

    updateSliceWithReturnedData();
  }, [isFetchingPatients]);

  const returnedTotalRows = patientsResponseData?.page.totalRows;
  const returnedPatients = patientsResponseData?.data;
  const patients = useAppSelector(selectPatients);
  const dispatch = useAppDispatch();
  const setHasSearched = (value: boolean) => dispatch(updateHasSearched(value));

  const handleTypeChange = (type: string) => {
    setValue("criteria", type);
    setValue("value", "");
  };

  const onSubmit = (data) => {
    const hasSearchedBefore = searchParams.value;
    const shouldIgnoreRepetitiveSearch =
      hasSearchedBefore &&
      data.value === searchParams.value &&
      data.criteria === searchParams.criteria;
    if (shouldIgnoreRepetitiveSearch) return;
    if (!hasSearched) {
      setHasSearched(true);
    }
    setErrorMessage("");
    setSearchParams({ ...data, pageIndex: 0 });
  };

  const onError = (errors) => setErrorMessage(errors.value.message);

  const handleClear = () => {
    setSearchParams(defaultPatientSearch());
    dispatch(clearState());
    setValue("value", "");
    setHasSearched(false);
    setErrorMessage("");
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={2} alignItems="center">
        <SSearchBox>
          <FormProvider {...methods}>
            <SearchBar
              selectValue={currentSearchCriteria}
              selectOptions={searchCriteriaOptions}
              handleSubmit={handleSubmit(onSubmit, onError)}
              handleTypeChange={handleTypeChange}
              placeholder={`patient's ${placeholderSearchCriteria.label.toLocaleLowerCase()}`}
              inputName="value"
              selectName="criteria"
            />
          </FormProvider>
        </SSearchBox>
        {Boolean(currentSearchValue || patients.length) && (
          <LinkTypography text="Clear Search" handleClick={handleClear} />
        )}
      </Box>

      <FormHelperText error={Boolean(errorMessage)} style={HELPER_TEXT_STYLES}>
        {errorMessage}
      </FormHelperText>
    </Box>
  );
};

export default PatientSearch;
