import React from "react";

import { getPmsName } from "../../../../global/helperFunctions/text/getPmsName";
import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";
import { selectPmsType } from "../../../authentication/appSlice";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IMissedFields } from "./SendTo.interfaces";

const Tooltip = ({ missedFields = [] }: IMissedFields) => {
  const pmsName: string = getPmsName(useAppSelector(selectPmsType));
  return (
    <div>
      <p>
        Missing the info needed to continue. Make sure the following patient
        information is in {pmsName}.
      </p>
      <ul>
        {missedFields.map((field) => (
          <li key={field}>{field}</li>
        ))}
      </ul>
    </div>
  );
};

export const SendToTooltip = React.memo(Tooltip, areEqualProps);
