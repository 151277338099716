import React, { useMemo } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { FormatPatientName } from "@yapi/yapi2.utils.text.format-patient-name";
import { IfTextNull } from "@yapi/yapi2.utils.text.if-text-null";

import { TPatient } from "../../../../../../global/domains/patients/types/TPatient.type";
import { formatPhoneString } from "../../../../../../global/helperFunctions/text/formatPhoneString";
import defaultAvatarImg from "../../../../../../assets/PatientProfile/avatar.svg";
import noAvatar from "../../../../../../assets/no_avatar.svg";
import { RootDiv, StyledAvatar, StyledStack } from "./styles";
import { primaryPhone } from "../../../../../../global/helperFunctions/text/primaryPhone";

export type PatientCardPropTypes = {
  patient: TPatient;
  onClick: (patient: TPatient) => void;
  selected?: boolean;
};

export const PatientCard: React.FC<PatientCardPropTypes> = ({
  patient,
  onClick,
  selected = false,
}: PatientCardPropTypes) => {
  const patientName = FormatPatientName(
    patient.firstName,
    patient.middleInitial,
    patient.lastName,
    patient.preferredName
  );

  const onClickHandler = (): void => {
    onClick(patient);
  };

  const description: string = useMemo((): string => {
    return `Age ${IfTextNull(patient.age)} | Chart #: ${IfTextNull(
      patient.chartId
    )}`;
  }, [patient.age, patient.chartId]);

  const phone: string = useMemo(() => {
    if (patient.phones.length === 0) {
      return "-";
    }

    const primaryPhoneNumber = primaryPhone(patient.phones);
    const formattedPhone = formatPhoneString(primaryPhoneNumber.phone);

    return `${primaryPhoneNumber.phoneType}: ${IfTextNull(
      formattedPhone
    ).toString()}`;
  }, [patient.phones]);

  return (
    <RootDiv
      data-testid={`patientCard/${patient.id}`}
      className={selected ? "selected" : ""}
    >
      <Box p={2}>
        <Grid container direction="row">
          <Grid
            item
            container
            onClick={onClickHandler}
            direction="row"
            wrap="nowrap"
            alignItems="center"
            xs={11}
          >
            <StyledAvatar
              src={patient.avatar ? defaultAvatarImg : noAvatar}
              alt="patientCard/avatar"
            />
            <StyledStack>
              <Typography variant="h5" noWrap>
                {patientName}
              </Typography>
              <Typography variant="body2" color="textSecondary" noWrap>
                {description}
              </Typography>
              <Typography variant="body2" noWrap>
                {phone}
              </Typography>
            </StyledStack>
          </Grid>
        </Grid>
      </Box>
    </RootDiv>
  );
};
