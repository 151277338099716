import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppSelector } from "./useTypedRedux.hook";
import { selectPracticeGuid } from "../../features/authentication/appSlice";

export interface ISurveyCredentialsInfo {
  token: string;
  host: string;
  practiceId: string;
  formId: string;
}

export const useHostTokenFormId = (): ISurveyCredentialsInfo => {
  const [accessToken, setAccessToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(`Bearer ${token}`);
    };
    getToken();
  }, [accessToken]);
  const { formId }: { formId: string } = useParams();
  const selectedGUID: string = useAppSelector(selectPracticeGuid);

  const surveyData = {
    token: accessToken,
    host: process.env.REACT_APP_YAPI_DATAFORMS,
    practiceId: selectedGUID,
  };

  return {
    ...surveyData,
    formId,
  };
};
