import { union } from "lodash";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { EFeatureFlags } from "../../enums/EFeatureFlags";

export const featureFlagsApi = createApi({
  reducerPath: "featureFlagsApi",
  tagTypes: ["featureFlags"],
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_HOST.replace("/v1/", "/")
  ),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<EFeatureFlags[], void>({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        const [
          { data: featureFlagsForV1 = [] },
          { data: featureFlagsForV2 = [] },
        ] = await Promise.all([
          fetchWithBQ({
            url: `v1/FeatureManagement/EnabledFeatures`,
            method: "GET",
          }),
          fetchWithBQ({
            url: `v2/FeatureManagement/EnabledFeatures`,
            method: "GET",
          }),
        ]);

        return {
          data: union(
            featureFlagsForV1 as EFeatureFlags[],
            featureFlagsForV2 as EFeatureFlags[]
          ),
        };
      },
    }),
  }),
});

export const { useLazyGetFeatureFlagsQuery, useGetFeatureFlagsQuery } =
  featureFlagsApi;
