import React from "react";
import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import CardContainer from "../../../../appointments/schedule/appointmentCard/CardContainer";
import { updateSelectedCardId } from "../inOfficePatientsSlice";
import { cardWrapperStyles } from "../../constants";

type TInOfficeCardWrapper = {
  formAssignmentId: string;
  patientId: string;
  isSelected: boolean;
  children: JSX.Element[];
};

export const InOfficeCardWrapper = ({
  children,
  formAssignmentId,
  patientId,
  isSelected,
}: TInOfficeCardWrapper) => {
  const dispatch = useAppDispatch();
  const cardId = formAssignmentId || patientId;

  const handleClick = (): void => {
    dispatch(updateSelectedCardId(isSelected ? "" : cardId));
  };

  return (
    <CardContainer
      stopPropagation={false}
      key={formAssignmentId}
      isSelected={isSelected}
      onClick={handleClick}
      sx={cardWrapperStyles}
    >
      {children}
    </CardContainer>
  );
};
