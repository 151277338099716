import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EAppointmentType } from "../AppointmentType.enum";
import { IGetScheduleAppointments } from "../../../global/types/appointments/IGetScheduleAppointments.interface";
import { IProvider } from "../../../global/types/providers/IProvider.interface";
import { IScheduledAppointment } from "../../../global/types/appointments/IScheduledAppointment";
import { EPatientType } from "../../../global/domains/patients/enums/EPatientType";
import { RootState } from "../../../global/store";
import { TSelectOption } from "../../../global/types/select/TSelectOption.type";
import { getProviderLabel } from "../../../global/helperFunctions/text/getProviderLabel";
import { getMidnightDate } from "../../../global/helperFunctions/epoch/getMidnightDate";
import { ScheduleSortFieldEnum } from "./scheduleHeaders/ScheduleSortFields.enum";

export interface ISelectableAppointment extends IScheduledAppointment {
  isSelected?: boolean;
}

const Any: TSelectOption<string> = {
  label: "Any",
  value: "Any",
};

interface ScheduleSlice {
  totalAppointmentsCount: number;
  brokenAppointmentsCount: number;
  completedAppointmentsCount: number;
  estimatedProduction: number;
  completedTotal: number;

  appointmentType: EAppointmentType;
  patientType: EPatientType;
  provider: TSelectOption<string>;
  providerList: IProvider[];

  appointmentList: ISelectableAppointment[];
  selectedAppointments: ISelectableAppointment[];
  selectedDate: Date;

  recipientList: ISelectableAppointment[];
  isLoading: boolean;
  lastSentMassTextGroupId: string;

  sortField: ScheduleSortFieldEnum;
  sortBy: "asc" | "desc";
  page: number;
  pageCount: number;
  pageSize?: number;

  isHidingMetrics: boolean;
}

interface IUpdateAppointment {
  checked: boolean;
  selectedAppointment: ISelectableAppointment;
}
export const initialState: ScheduleSlice = {
  totalAppointmentsCount: 0,
  brokenAppointmentsCount: 0,
  completedAppointmentsCount: 0,
  estimatedProduction: 0,
  completedTotal: 0,

  appointmentType: EAppointmentType.Any,
  patientType: EPatientType.Any,
  provider: Any,
  providerList: [],
  appointmentList: [],
  selectedAppointments: [],
  selectedDate: getMidnightDate(new Date()),
  isLoading: false,
  lastSentMassTextGroupId: "",

  sortField: ScheduleSortFieldEnum.Date,
  sortBy: "asc",
  page: 0,
  pageCount: 1,
  pageSize: 10,

  recipientList: [],

  isHidingMetrics: false,
};

type TUpdateOverride = {
  appointment: ISelectableAppointment;
  isOverride: boolean;
};

const removeSelectedAppointment = (
  state: ScheduleSlice,
  appointment: ISelectableAppointment
) => {
  state.selectedAppointments = state.selectedAppointments.filter(
    (selectedAppointment) => selectedAppointment.id !== appointment.id
  );
};

const addSelectedAppointment = (
  state: ScheduleSlice,
  appointment: ISelectableAppointment
) => {
  state.selectedAppointments.push(appointment);
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    updateSelectedDate: (state, action: PayloadAction<Date>) => {
      const { payload: newDate } = action;
      const midnightDate = getMidnightDate(newDate);
      state.selectedDate = midnightDate;
      state.selectedAppointments = [];
    },
    updateIsAppointmentSelected: (
      state,
      action: PayloadAction<IUpdateAppointment>
    ) => {
      if (action.payload.checked) {
        addSelectedAppointment(state, action.payload.selectedAppointment);
      } else {
        removeSelectedAppointment(state, action.payload.selectedAppointment);
      }
    },
    updateSelectAll: (
      state,
      action: PayloadAction<{
        checked: boolean;
        data?: ISelectableAppointment[];
      }>
    ) => {
      const { checked, data } = action.payload;
      state.selectedAppointments = checked ? data : [];
    },
    updateAppointmentList: (
      state,
      action: PayloadAction<ISelectableAppointment[]>
    ) => {
      state.appointmentList = action.payload;
    },
    updateAppointmentType: (state, action: PayloadAction<EAppointmentType>) => {
      state.appointmentType = action.payload;
    },
    updatePatientType: (state, action: PayloadAction<EPatientType>) => {
      state.patientType = action.payload;
    },
    updateScheduleAppointments: (
      state,
      action: PayloadAction<IGetScheduleAppointments>
    ) => {
      const { payload: response } = action;
      const updateHeaderInfos = () => {
        const {
          brokenCount,
          totalCount,
          completedCount,
          estimatedProduction,
          completedTotal,
          providers,
        } = response;

        state.brokenAppointmentsCount = brokenCount;
        state.totalAppointmentsCount = totalCount;
        state.completedAppointmentsCount = completedCount;
        state.estimatedProduction = estimatedProduction;
        state.completedTotal = completedTotal;
        state.providerList = providers;
      };

      const updateListAndPagination = () => {
        const { data: appointmentList, page } = response;
        const selectableAppointmentList: ISelectableAppointment[] =
          appointmentList.map((appointment) => ({
            ...appointment,
            isSelected: true,
          }));
        state.appointmentList = selectableAppointmentList;
        state.pageCount = Math.ceil(page.totalRows / page.pageSize);
        state.page = page.pageIndex;
      };

      updateHeaderInfos();
      updateListAndPagination();
    },
    DEPRECATED_updateRecipientList: (
      state,
      action: PayloadAction<ISelectableAppointment[]>
    ) => {
      state.recipientList = action.payload;
    },
    DEPRECATED_updateOverrideAppointment: (
      state,
      action: PayloadAction<TUpdateOverride>
    ) => {
      const { appointment: mainAppointment, isOverride } = action.payload;
      if (isOverride) {
        state.recipientList.push(mainAppointment);
      } else {
        state.recipientList = state.recipientList.filter(
          (appointment) => appointment.id !== mainAppointment.id
        );
      }
    },
    updateProvider: (state, action: PayloadAction<TSelectOption<string>>) => {
      state.provider = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<"asc" | "desc">) => {
      state.sortBy = action.payload;
    },
    updateSortField: (state, action: PayloadAction<ScheduleSortFieldEnum>) => {
      state.sortField = action.payload;
    },
    updatePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    resetFilters: (state) => {
      state.selectedDate = new Date();
      state.appointmentType = EAppointmentType.Any;
      state.patientType = EPatientType.Any;
    },
    updateSchedulePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateLastSentMassTextGroupId: (state, action: PayloadAction<string>) => {
      state.lastSentMassTextGroupId = action.payload;
    },
    updateIsHidingMetrics: (state, action: PayloadAction<boolean>) => {
      state.isHidingMetrics = action.payload;
    },
  },
});

export const {
  updateIsAppointmentSelected,
  updateScheduleAppointments,
  updateSelectAll,
  updateSelectedDate,
  updateAppointmentList,
  updateAppointmentType,
  updateIsLoading,
  DEPRECATED_updateOverrideAppointment,
  updateLastSentMassTextGroupId,
  updatePatientType,
  updateProvider,
  DEPRECATED_updateRecipientList,
  updateSchedulePage,
  updateSortField,
  updateSortBy,
  resetFilters,
  updatePageSize,
  updateIsHidingMetrics,
} = scheduleSlice.actions;

export const selectAppointments = (state: RootState) =>
  state.scheduleState.appointmentList;

export const selectHasSelectedAppointment = (state: RootState) =>
  Boolean(state.scheduleState.selectedAppointments?.length);

export const selectRecipientAppointments = (state: RootState) =>
  state.scheduleState.recipientList;

export const DEPRECATED_selectSelectedRecipients = (state: RootState) =>
  state.scheduleState.recipientList.filter(
    (recipient) => recipient?.isSelected
  );

export const selectAppointmentType = (state: RootState) =>
  state.scheduleState?.appointmentType;

export const selectScheduleCurrentPage = (state: RootState) =>
  state.scheduleState.page;

export const selectSchedulePageCount = (state: RootState) =>
  state.scheduleState.pageCount;

export const selectSortBy = (state: RootState) => state.scheduleState.sortBy;

export const selectSortField = (state: RootState) =>
  state.scheduleState.sortField;
export const selectPageSize = (state: RootState) =>
  state.scheduleState.pageSize;
export const selectPatientType = (state: RootState) =>
  state.scheduleState.patientType;

export const selectProvider = (state: RootState): TSelectOption<string> =>
  state.scheduleState.provider;

export const selectProviders = (state: RootState) => {
  const { providerList } = state.scheduleState;
  const defaultProviders = [Any];
  const hasNoAppointments = !providerList?.length;
  if (hasNoAppointments) return defaultProviders;
  const formattedProviders = providerList.map((appointmentProvider) => {
    return {
      label: getProviderLabel(appointmentProvider),
      value: appointmentProvider.id,
    };
  });
  return [...defaultProviders, ...formattedProviders];
};

export const selectSelectedDate = (state: RootState) =>
  new Date(state.scheduleState.selectedDate);

export const selectIsAllSelected = (state: RootState) => {
  return (
    state.scheduleState.totalAppointmentsCount ===
    state.scheduleState.selectedAppointments?.length
  );
};

export const selectTotalAppointmentsCount = (state: RootState) =>
  state.scheduleState.totalAppointmentsCount;

export const selectBrokenAppointmentsCount = (state: RootState) =>
  state.scheduleState.brokenAppointmentsCount;

export const selectCompletedAppointmentsCount = (state: RootState) =>
  state.scheduleState.completedAppointmentsCount;

export const selectEstimatedProduction = (state: RootState) =>
  state.scheduleState.estimatedProduction;

export const selectIsLoading = (state: RootState) =>
  state.scheduleState.isLoading;

export const selectLastSentMassTextGroupId = (state: RootState) =>
  state.scheduleState.lastSentMassTextGroupId;

export const selectedAppointmentsList = (state: RootState) =>
  state.scheduleState.selectedAppointments;

export const selectIsHidingMetrics = (state: RootState) =>
  state.scheduleState.isHidingMetrics;

export default scheduleSlice.reducer;
