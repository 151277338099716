import { MATERIAL_BREAKPOINTS } from "../../constants";

export const getBreakpoint = (width: number) => {
  if (width < MATERIAL_BREAKPOINTS.SM) return "xs";
  if (width < MATERIAL_BREAKPOINTS.MD) return "sm";
  if (width < MATERIAL_BREAKPOINTS.LG) return "md";
  if (width < MATERIAL_BREAKPOINTS.XL) return "lg";

  return "xl";
};
