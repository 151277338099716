import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithReAuth } from "../helperFunctions/api/baseQueryWithReauth";
import { IAssignedProvider } from "../../features/appointments/schedule/fillAppointment/fillAppointment.interface";

interface IAssignedProviderOption {
  value: string;
  label: string;
}

interface IRecallType {
  description: string;
  id: string;
  isActive: boolean;
  name: string;
  hasTreatment?: boolean;
}

interface IRecallTypeOption {
  value: string;
  label: string;
  disabled: boolean;
}

export const baseAPI = createApi({
  reducerPath: "baseAPI",
  tagTypes: [
    "CurrentUser",
    "InOffice",
    "CheckedOut",
    "HomepageMetrics",
    "Notifications",
    "TextMessageTollFreeConfigurations",
    "Operatories",
    "InsuranceVerificationConfig",
  ],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getProviders: builder.query<IAssignedProviderOption[], null>({
      query: () => {
        return "Providers";
      },
      transformResponse: (response: IAssignedProvider[]) => {
        return response.map((provider) => {
          const [firstName, lastName] = provider.name.split(/\s+/);
          return {
            value: provider.id,
            label: `${firstName} ${lastName?.charAt(0)}. (${provider.code})`,
          };
        });
      },
    }),
    getRecallTypes: builder.query<IRecallTypeOption[], boolean>({
      query: (isFillAppointmentsAutomationEnabled) => {
        return isFillAppointmentsAutomationEnabled
          ? "RecallTypeAndTreatmentDetails"
          : "RecallTypes";
      },
      transformResponse: (
        response: IRecallType[],
        _meta,
        isFillAppointmentsAutomationEnabled
      ) => {
        return response.map((type) => ({
          value: type.id,
          label: type.name,
          disabled: isFillAppointmentsAutomationEnabled
            ? !type.hasTreatment
            : false,
        }));
      },
    }),
  }),
});
