import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../global/store";

import { IPatientHealth } from "../../global/domains/patients/types/IPatientHealth.interface";
import { IPatientFamilyMember } from "../../global/domains/patients/types/IPatientFamilyMember.interface";
import { TPatient } from "../../global/domains/patients/types/TPatient.type";
import { TPatientSearchResponse } from "../../global/domains/patients/types/TPatientSearchResponse.type";
import { TPatientAppointmentsResponse } from "../../global/domains/patients/types/TPatientAppointmentResponse";
import { TPaging } from "../../global/types/services/api/TPaging.type";
import { IPatientSummary } from "../../global/domains/patients/types/IPatientSummary.interface";
import { EPNStatus } from "../../global/types/services/pubnub/EPNStatus.enum";
import { IPNRequestStatus } from "../../global/types/services/pubnub/IPNRequestStatus.interface";
import { IPatientInsurance } from "../../global/domains/patients/types/IPatientInsurance.interface";
import { IPatientSchedulePreferences } from "../../global/domains/patients/types/ISchedulingPreferences";
import { IPatientAppointments } from "../../global/domains/patients/types/IPatientAppointments";
import { IPatientPNStatuses } from "../../global/domains/patients/types/IPatientPNStatuses";

interface PatientsStateType {
  searchedPatientsResponse: {
    searchedPatients: TPatient[];
    searchedPatientsPaging: TPaging;
  };
  viewedPatients: TPatient[];
  patientPNStatus: IPatientPNStatuses;
  patientPaging: TPaging;
  currentPatient: currentPatientStateType;
}

interface currentPatientStateType {
  summary: {
    familyMembers: IPatientFamilyMember[];
    general: IPatientSummary;
    health: IPatientHealth;
  };
  insurance: IPatientInsurance[];
  preferences: IPatientSchedulePreferences;
  appointments: IPatientAppointments;
}
const initStatus = {
  status: EPNStatus.None,
  statusDetails: "",
};

const initialState: PatientsStateType = {
  searchedPatientsResponse: {
    searchedPatients: [],
    searchedPatientsPaging: {},
  },
  patientPaging: {},
  viewedPatients: [],
  patientPNStatus: {
    profile: {
      ...initStatus,
      type: "profile",
    },
    finder: { ...initStatus, type: "finder" },
    insurance: {
      ...initStatus,
      type: "insurance",
    },
    general: {
      ...initStatus,
      type: "general",
    },
    family: {
      ...initStatus,
      type: "family",
    },
    health: { ...initStatus, type: "health" },
    appointments: {
      ...initStatus,
      type: "appointments",
    },
    appointmentDetails: {
      ...initStatus,
      type: "appointmentDetails",
    },
    preferences: {
      ...initStatus,
      type: "preferences",
    },
  },

  currentPatient: {
    summary: {
      familyMembers: null,
      general: null,
      health: null,
    },
    insurance: null,
    preferences: null,
    appointments: {
      brokenCount: 0,
      data: [],
      page: {},
    },
  },
};

const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    updateSearchedPatients: (
      state,
      action: PayloadAction<TPatientSearchResponse>
    ) => {
      state.searchedPatientsResponse.searchedPatients = action.payload.data;
      state.searchedPatientsResponse.searchedPatientsPaging =
        action.payload.page;
    },
    updateViewedPatients: (state, action: PayloadAction<TPatient[]>) => {
      state.viewedPatients = action.payload;
    },
    updatePatientSummary: (state, action: PayloadAction<IPatientSummary>) => {
      state.currentPatient.summary.general = action.payload;
    },
    updatePatientFamilyMembers: (
      state,
      action: PayloadAction<IPatientFamilyMember[]>
    ) => {
      state.currentPatient.summary.familyMembers = action.payload;
    },
    updatePatientHealth: (state, action: PayloadAction<IPatientHealth>) => {
      state.currentPatient.summary.health = action.payload;
    },
    updatePatientInsurance: (
      state,
      action: PayloadAction<IPatientInsurance[]>
    ) => {
      state.currentPatient.insurance = action.payload;
    },
    updatePatientSchedulingPreferences: (
      state,
      action: PayloadAction<IPatientSchedulePreferences>
    ) => {
      state.currentPatient.preferences = action.payload;
    },
    updatePatientAppointments: (
      state,
      action: PayloadAction<TPatientAppointmentsResponse>
    ) => {
      state.currentPatient.appointments.brokenCount =
        action.payload.brokenCount;
      state.currentPatient.appointments.data = action.payload.data;
      state.currentPatient.appointments.page = action.payload.page;
    },

    updatePatientPNStatus: (state, action: PayloadAction<IPNRequestStatus>) => {
      state.patientPNStatus[action.payload.type] = action.payload;
    },
  },
});

export const {
  updateSearchedPatients,
  updateViewedPatients,
  updatePatientSummary,
  updatePatientInsurance,
  updatePatientAppointments,
  updatePatientSchedulingPreferences,
  updatePatientPNStatus,
  updatePatientFamilyMembers,
  updatePatientHealth,
} = patientsSlice.actions;

// data selectors
export const selectAllViewedPatients = (state: RootState): TPatient[] =>
  state.patientState.viewedPatients;
export const selectAllSearchedPatients = (state: RootState): TPatient[] =>
  state.patientState.searchedPatientsResponse.searchedPatients;
export const selectSearchedPaging = (state: RootState): TPaging =>
  state.patientState.patientPaging;
export const selectCurrentPatientSummary = (
  state: RootState
): IPatientSummary => state.patientState.currentPatient.summary.general;
export const selectCurrentPatientHealth = (state: RootState): IPatientHealth =>
  state.patientState.currentPatient.summary.health;
export const selectCurrentPatientFamilyMembers = (
  state: RootState
): IPatientFamilyMember[] =>
  state.patientState.currentPatient.summary.familyMembers;
export const selectCurrentPatientInsurance = (
  state: RootState
): IPatientInsurance[] => state.patientState.currentPatient.insurance;
export const selectCurrentPatientAppointments = (
  state: RootState
): IPatientAppointments => state.patientState.currentPatient.appointments;
export const selectCurrentPatientAppointment = (state: RootState, id: string) =>
  state.patientState.currentPatient.appointments.data.find(
    (appt) => appt.id === id
  );
export const selectCurrentPatientSchedulingPreferences = (
  state: RootState
): IPatientSchedulePreferences => state.patientState.currentPatient.preferences;

// status selectors
export const selectPatientPNStatus = (state: RootState): IPatientPNStatuses =>
  state.patientState.patientPNStatus;

export default patientsSlice.reducer;
