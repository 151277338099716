import React, { useCallback, useEffect } from "react";
import { styled, Typography } from "@mui/material";
import { useAppHistory } from "../../hooks/useAppHistory";
import { BackNavHeaderButton } from "../BackNavHeaderButton/BackNavHeaderButton";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedRedux.hook";
import {
  deleteCurrentTabLastRoute,
  selectTabLastPage,
  updateCurrentPageTitle,
} from "../../../features/navigation/navigationSlice";
import { selectHasFormChanges } from "../../../features/authentication/globalMessagesSlice";

type TPageTitle = {
  className?: string; // Allow styled(PageTitle)
  disableMarginTop?: boolean;
  showGoBackButton?: boolean;
  title: string;
  route?: string;
  goBackButtonTitle?: string;
  goBackCallback?: () => Promise<void> | void;
};

const STypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.typography.pxToRem(32),
  marginTop: theme.typography.pxToRem(8),
  [theme.breakpoints.only("xs")]: {
    fontSize: theme.typography.pxToRem(24),
  },
  [theme.breakpoints.only("sm")]: {
    fontSize: theme.typography.pxToRem(22),
    marginTop: theme.typography.pxToRem(4),
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.pxToRem(20),
  },
}));

const SDiv = styled("div")(({ theme }) => ({
  marginTop: theme.typography.pxToRem(8),
  [theme.breakpoints.only("xs")]: {
    marginTop: 0,
  },
}));

const PageTitle = ({
  className = "",
  disableMarginTop = false,
  title,
  showGoBackButton = false,
  route,
  goBackButtonTitle,
  goBackCallback,
}: TPageTitle) => {
  const dispatch = useAppDispatch();

  const lastPage = useAppSelector(selectTabLastPage);
  const history = useAppHistory();
  const hasFormChanges = useAppSelector(selectHasFormChanges);

  useEffect(() => {
    dispatch(updateCurrentPageTitle(title));
  }, []);

  const deleteLastTabRoute = useCallback(() => {
    dispatch(deleteCurrentTabLastRoute());
  }, [dispatch, deleteCurrentTabLastRoute]);

  const goBackWithCallback = useCallback(async () => {
    deleteLastTabRoute();
    if (goBackCallback) await goBackCallback();
  }, [goBackCallback, deleteLastTabRoute]);

  const handleBackClick = () => {
    history.push(route || lastPage.route, undefined, goBackWithCallback);
    if (!hasFormChanges) deleteLastTabRoute();
  };

  return (
    <>
      {!disableMarginTop && <SDiv />}
      {showGoBackButton && (
        <BackNavHeaderButton
          buttonText={`Go back to ${goBackButtonTitle ?? lastPage?.title}`}
          clickHandler={handleBackClick}
        />
      )}
      <STypography
        sx={{
          typography: { xs: "h2", sm: "h6", md: "h5", lg: "h5", xl: "h5" },
        }}
        className={className}
      >
        {title}
      </STypography>
    </>
  );
};

export default PageTitle;
