import { styled } from "@mui/material/styles";
import { Avatar, Grid, Stack } from "@mui/material";

export const RootDiv = styled(Grid)(({ theme }) => ({
  width: "100%",
  minHeight: "92px",
  boxShadow: `0 4px 4px ${theme.palette.text.disabled}`,
  borderRadius: "8px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.text.disabled}`,
  "&:hover": {
    backgroundColor: theme.palette.accent.background,
    border: `1px solid ${theme.palette.accent.main}`,
    cursor: "pointer",
  },
  "&.selected": {
    backgroundColor: theme.palette.accent.background,
    border: `1px solid ${theme.palette.accent.main}`,
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "40px",
    height: "40px",
  },
  [theme.breakpoints.up("md")]: {
    width: "60px",
    height: "60px",
  },
}));

export const StyledStack = styled(Stack)({
  paddingLeft: "1rem",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});
