import React, { useCallback, FC } from "react";
import { Grid, Avatar, Typography, Box, styled } from "@mui/material";
import { SecondaryText } from "../../../../../../../global/components/SecondaryText/SecondaryText";
import { get12HrTimeEpoch } from "../../../../../../../global/helperFunctions/epoch/get12HrTimeEpoch";
import NewPatientTag from "../../../../../../appointments/NewPatientTag";
import { PatientName } from "../../../../../../appointments/schedule/appointmentCard/small/PatientName";

import OptionsMenu from "../../optionsMenu/OptionsMenu";
import { OfficeTaskList } from "../../officeTaskList/OfficeTaskList";
import PatientTaskStatus from "../PatientTaskStatus/PatientTaskStatus";
import uniqBy from "lodash/uniqBy";
import { IFormAssignmentForm } from "../../../../../../../global/types/documentManager/InOfficePatient";
import noAvatar from "../../../../../../../assets/no_avatar.svg";
import { TAppointmentInfo } from "../../types/TAppointmentInfo";

export const SCenteredGrid = styled(Grid)({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  position: "relative",
});

interface IOptionsProps {
  appointmentId: string;
  patientId: string;
  formAssignmentId: string;
  forms: IFormAssignmentForm[];
}

const Options: FC<IOptionsProps> = ({
  appointmentId,
  patientId,
  forms,
  formAssignmentId,
}) => (
  <SCenteredGrid item justifyContent="end" lg={1.2}>
    <OptionsMenu
      appointmentId={appointmentId}
      formAssignmentId={formAssignmentId}
      forms={forms}
      patientId={patientId}
    />
  </SCenteredGrid>
);

const LargeAppointmentInfo = ({
  inOfficePatient: {
    appointment,
    checkInTime,
    patient: {
      age,
      avatar,
      chartId,
      dateOfBirth,
      firstName,
      isNewPatient,
      id: patientId,
      lastName,
    },
    officeTasks,
    formAssignment,
  },
}: TAppointmentInfo) => {
  const Patient = useCallback(
    () => (
      <>
        <SCenteredGrid mr={2} item>
          <Avatar sx={{ width: 50, height: 50 }} src={avatar || noAvatar} />
        </SCenteredGrid>
        <Grid item md={3} display="block">
          <Box display="flex" gap={1} alignItems="center">
            <PatientName
              fontSize="1rem"
              firstName={firstName}
              lastName={lastName}
            />
            {isNewPatient && <NewPatientTag />}
          </Box>
          <SecondaryText>
            DOB: {dateOfBirth} (Age: {age})
          </SecondaryText>
          <SecondaryText>Chart #: {chartId}</SecondaryText>
          <PatientTaskStatus forms={formAssignment?.forms || []} />
        </Grid>
      </>
    ),
    [
      avatar,
      firstName,
      lastName,
      isNewPatient,
      dateOfBirth,
      age,
      chartId,
      appointment?.appointmentStatus,
      formAssignment?.dateCreated,
      formAssignment,
    ]
  );

  const AppointmentTime = useCallback(
    () => (
      <Grid item lg={2}>
        <Typography fontSize={"0.875rem!important"} fontWeight="bold">
          {get12HrTimeEpoch(
            appointment?.startsAt ?? formAssignment?.dateCreated
          )}
        </Typography>
      </Grid>
    ),
    [appointment?.startsAt]
  );

  const TimeCheckedIn = useCallback(
    () => (
      <Grid item lg={2}>
        {checkInTime ? (
          <SecondaryText>{get12HrTimeEpoch(checkInTime)}</SecondaryText>
        ) : (
          "-"
        )}
      </Grid>
    ),
    [checkInTime]
  );

  const OfficeToDo = useCallback(() => {
    const uniqueTasksByStatus = uniqBy(officeTasks, (task) => task.status);

    return (
      <Grid item lg={3}>
        {uniqueTasksByStatus.length ? (
          <OfficeTaskList officeTasks={uniqueTasksByStatus} />
        ) : (
          <Box pl={2}>-</Box>
        )}
      </Grid>
    );
  }, [officeTasks]);

  return (
    <Grid flexWrap="nowrap" container width="100%" py={2} pl={2} pr={1}>
      <Patient />
      <AppointmentTime />
      <TimeCheckedIn />
      <OfficeToDo />
      <Options
        appointmentId={appointment?.id}
        formAssignmentId={formAssignment?.id}
        forms={formAssignment?.forms || []}
        patientId={patientId}
      />
    </Grid>
  );
};

export default LargeAppointmentInfo;
