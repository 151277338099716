import { IFormAssignmentForm } from "../../../../../global/types/documentManager/InOfficePatient";
import { EOfficeTaskStatus } from "../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { TOption } from "../../../../../global/components/ContextMenu/Option.type";
import { AppDispatch } from "../../../../../global/store";
import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import { updateError } from "../../../../authentication/globalMessagesSlice";
import { useDownloadOption } from "../inOfficePatientCard/formsSection/categories/options/useDownloadOption";
import { useAppHistory } from "../../../../../global/hooks/useAppHistory";
import { refileToPmsOption } from "../inOfficePatientCard/formsSection/categories/options/RefileToPms";
import Routes from "../../../../../global/routes/routes";
import { TUserActionMutation } from "../inOfficePatientCard/formsSection/categories/officeTasks/types/TUserActionMutation";

export const useOptionsByTaskStatus = (
  patientId: string,
  form: IFormAssignmentForm,
  pms: string,
  officeTaskId: string,
  userActionMutation: TUserActionMutation,
  taskStatus: EOfficeTaskStatus
): Record<number, TOption[]> => {
  const dispatch: AppDispatch = useAppDispatch();
  const { id: formId, name: formName, responseId } = form;

  const errorCallback = () => {
    dispatch(updateError(true));
  };

  const download = useDownloadOption({
    patientId,
    form,
  });
  const history = useAppHistory();
  const refile = refileToPmsOption(
    pms,
    officeTaskId,
    userActionMutation,
    errorCallback,
    taskStatus
  );

  const signHandler = (): void => {
    history.push(
      Routes.DocumentManager.signaturePreview(formId, responseId),
      formName
    );
  };

  return {
    [EOfficeTaskStatus.FailedToFileToPms]: [refile, download],
    [EOfficeTaskStatus.WaitingOnFormPDFCreation]: [refile],
    [EOfficeTaskStatus.ProviderSignatureRequired]: [
      { label: "Sign", callBack: signHandler },
      download,
    ],
    [EOfficeTaskStatus.FiledToPms]: [download],
  };
};
