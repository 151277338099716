import { TMergeTagList } from "../../global/types/mergeTag/MergeTagList.type";
import mergeTagIcon from "../../assets/merge_tag_icon.svg";
import ScheduleNavIcon from "@yapi/yapi2.icons.schedule-nav-icon/assets/schedule_small.svg";

export const CreateMassTextMergeTagList: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
    "[[ADDRESS]]": "Practice street address",
    "[[EMAIL]]": "Practice email",
  },
  Provider: {
    "[[APPOINTMENTPROVIDER]]": "Provider name",
    "[[APFN]]": "Provider preferred name",
  },
  Appointment: {
    "[[APPOINTMENTDATE]]": "Appointment date",
    "[[APPOINTMENTTIME]]": "Appointment time",
    "[[APPOINTMENTLENGTH]]": "Appointment duration",
    "[[TODAY]]": "Todays date",
    "[[YEAR]]": "Year current",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
    "[[LAST]]": "Patient last name",
    "[[PREFNAME]]": "Patient preferred name",
    "[[BIRTHDATE]]": "Patient Date of Birth",
    "[[PATEMAIL]]": "Patient email",
    "[[PATPHONE]]": "Patient phone",
    "[[PATMOBILE]]": "Patient mobile phone",
  },
};

export const MULTIPLE_RECIPIENTS_MERGE_TAG_LIST: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
    "[[ADDRESS]]": "Practice street address",
  },
};

export const TEXT_MESSAGE_MERGE_TAG_LIST: TMergeTagList = {
  Dates: {
    "[[TODAY]]": "Today's date",
  },
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
    "[[ADDRESS]]": "Practice street address",
    "[[EMAIL]]": "Practice email",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
    "[[PREFNAME]]": "Patient preferred name",
  },
};

const automatedFormsMergeTag =
  "[[AUTOMATED-FORMS | Select the link to complete your paperwork: | Automated forms]]";
const automatedFormsTagIconHTML = `<img src=${mergeTagIcon} style="transform: translateY(3px);" alt="Automated Forms Icon" />`;
const automatedFormsMergeTagText = `Select the link to complete your paperwork: ${automatedFormsTagIconHTML} Automated forms`;

const AUTOMATED_FORMS_MERGE_TAGS: TMergeTagList = {
  Automation: {
    [automatedFormsMergeTag]: automatedFormsMergeTagText,
  },
};

const REMINDERS_TEXT_MERGE_TAGS_BASE: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
  },
  Appointment: {
    "[[APPOINTMENTDATE]]": "Appointment date",
    "[[APPOINTMENTTIME]]": "Appointment time",
  },
};

export const FILL_APPOINTMENT_MERGE_TAG_LIST: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
    "[[ADDRESS]]": "Practice street address",
  },
  Provider: {
    "[[APPOINTMENTPROVIDER]]": "Provider name",
    "[[APFN]]": "Provider preferred name",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
    "[[PREFNAME]]": "Patient preferred name",
  },
  Appointment: {
    "[[FILLAPPOINTMENT]]": "Fill appointment",
  },
};

export const FILL_APPOINTMENT_MANUAL_FLOW_MERGE_TAG_LIST: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
    "[[ADDRESS]]": "Practice street address",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
    "[[PREFNAME]]": "Patient preferred name",
  },
};

const RECALL_REMINDERS_MERGE_TAG_LIST: TMergeTagList = {
  Practice: {
    "[[PHONE]]": "Practice phone",
    "[[PRACTICE]]": "Practice name",
  },
  Recall: {
    "[[RECALLDUEDATE]]": "Recall Due Date",
    "[[RECALLTYPE]]": "Recall Type",
  },
  Patient: {
    "[[FIRST]]": "Patient first name",
  },
};

export const getRemindersMergeTags = (
  hideFamily: boolean,
  isRecallReminder: boolean,
  isReviewsRequest: boolean
): TMergeTagList => {
  if (isRecallReminder) {
    return RECALL_REMINDERS_MERGE_TAG_LIST;
  }

  if (hideFamily) {
    return REMINDERS_TEXT_MERGE_TAGS_BASE;
  }

  if (isReviewsRequest) {
    return REVIEW_REQUEST_MERGE_TAG_LIST;
  }

  return {
    ...REMINDERS_TEXT_MERGE_TAGS_BASE,
    ["Patient"]: {
      ...REMINDERS_TEXT_MERGE_TAGS_BASE["Patient"],
      "[[FAMILYMEMBERS]]": "Family members",
    },
  } as TMergeTagList;
};
const scheduleIconHTML = `<div style="display: inline-block; vertical-align: middle; height: 26px;"><img src=${ScheduleNavIcon} width="16" height="16" style="justify-content: center; align-items: center;"  /></div>`;
const scheduleTag = `Tap this link to schedule online: ${scheduleIconHTML} Recall Schedule Link`;
export const USE_LINK_TO_SCHEDULE_MERGE_TAG =
  "[[RECALL-LINK | Tap this link to schedule online: ]]";
const SCHEDULING_MERGE_TAGS: TMergeTagList = {
  Scheduling: {
    [USE_LINK_TO_SCHEDULE_MERGE_TAG]: scheduleTag,
  },
};

export const REVIEWS_REQUEST_TAG =
  "[[Review-Request-Link | Please leave us a quick review by tapping the link below: ]]";

export const REVIEW_REQUEST_MERGE_TAG_LIST: TMergeTagList = {
  Practice: {
    "[[PRACTICE]]": "Practice name",
    "[[PHONE]]": "Practice phone",
  },
  Patient: {
    "[[FAMILYMEMBERS]]": "Family members",
  },
  Appointment: {
    "[[APPOINTMENTDATE]]": "Appointment date",
    "[[APPOINTMENTTIME]]": "Appointment time",
  },
};
const combinedFlatMergeTags = Object.assign(
  {},
  ...Object.values(CreateMassTextMergeTagList),
  ...Object.values(REMINDERS_TEXT_MERGE_TAGS_BASE),
  ...Object.values(TEXT_MESSAGE_MERGE_TAG_LIST),
  ...Object.values(FILL_APPOINTMENT_MERGE_TAG_LIST),
  ...Object.values(FILL_APPOINTMENT_MANUAL_FLOW_MERGE_TAG_LIST),
  ...Object.values(getRemindersMergeTags(false, false, false)),
  ...Object.values(RECALL_REMINDERS_MERGE_TAG_LIST),
  ...Object.values(AUTOMATED_FORMS_MERGE_TAGS),
  ...Object.values(SCHEDULING_MERGE_TAGS),
  ...Object.values(REVIEW_REQUEST_MERGE_TAG_LIST)
);

export const MergeTagMap = combinedFlatMergeTags;
