import { createApi } from "@reduxjs/toolkit/query/react";
import { IPatientSchedulePreferences } from "../domains/patients/types/ISchedulingPreferences";
import { IPatientEmailHistorySummary } from "../domains/patients/types/IPatientEmailHistorySummary.interface";
import { IEmailHistorySummaryRequest } from "../domains/patients/types/IEmailHistorySummaryRequest.interface";
import { queryStringBuilder } from "../helperFunctions/api/queryStringBuilder";
import { TPatient } from "../domains/patients/types/TPatient.type";
import { TPaging } from "../types/services/api/TPaging.type";
import { IPatientSummary } from "../domains/patients/types/IPatientSummary.interface";
import { IPatientHealth } from "../domains/patients/types/IPatientHealth.interface";
import { IPatientFamilyMember } from "../domains/patients/types/IPatientFamilyMember.interface";
import { IEmailDetailsRequest } from "../domains/patients/types/IEmailDetailsRequest.interface";
import { IPatientEmailDetails } from "../domains/patients/types/IPatientEmailDetails.interface";
import { IPatientAppointments } from "../domains/patients/types/IPatientAppointments";
import { IPatientAppointmentsRequest } from "../domains/patients/types/IPatientAppointmentsRequest";
import { IPatientAppointmentDetails } from "../domains/patients/types/IPatientAppointmentDetails";
import {
  IPatientInsurance,
  IPatientInsuranceV2,
} from "../domains/patients/types/IPatientInsurance.interface";
import { IPatientCommunicationPreferences } from "../domains/patients/types/ICommunicationPreferences";
import { IPatientSearchRequest } from "../domains/patients/types/IPatientSearchRequest.interface";
import { baseQueryWithReAuth } from "../helperFunctions/api/baseQueryWithReauth";
import { updateInOfficeIsFormAssignerModalVisible } from "../../features/documentManager/tabs/inOfficePatients/FormAssignerModal/inOfficeModalSlice";
import { IAppointment } from "../domains/patients/types/IAppointment.interface";
import { IOnlineSchedulingCard } from "../types/onlineScheduling/IOnlineSchedulingCard";
import { IPatientAppointmentCard } from "../types/patientInsurance/PatientAppointmentCard.interfaces";
import { IPatientInsuranceSummary } from "../types/patientInsurance/IPatientInsuranceSummary";
import { IPatientInsuranceEligibilityStatusBody } from "../types/patientInsurance/IPatientInsuranceEligibilityStatusBody";

const MASS_TEXT_MESSAGE_PHONE_NUMBER_TAG_TYPE = "MassTextMessagePhoneNumber";
const COMMUNICATION_PREFERENCES_TAG_TYPE = "MassTextMessagePhoneNumber";
const PATIENT_INSURANCE_ELIGIBILITY_STATUS_TAG_TYPE =
  "PatientInsuranceEligibilityStatus";
export interface testDataResponse {
  _id: string;
  balance: number;
  registered: string;
}

interface MatchingPatients {
  data: TPatient[];
  page: TPaging;
}

interface PatientAppointments {
  id: string;
  formData: IPatientAppointmentsRequest;
}

interface IPatientUpcomingAppointments {
  id: string;
  sinceDateInSeconds?: number;
}

export const patientApi = createApi({
  reducerPath: "patientApi",
  tagTypes: [
    MASS_TEXT_MESSAGE_PHONE_NUMBER_TAG_TYPE,
    COMMUNICATION_PREFERENCES_TAG_TYPE,
    PATIENT_INSURANCE_ELIGIBILITY_STATUS_TAG_TYPE,
  ],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getMatchingPatients: builder.query<MatchingPatients, IPatientSearchRequest>(
      {
        query: (formData) => {
          const { statuses, pageIndex, pageSize, ...cleanFormData } = formData;
          const params = new URLSearchParams({
            ...cleanFormData,
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
          });

          statuses.forEach((status) => {
            if (status.value) {
              params.append("statuses", status.label);
            }
          });

          return `/MatchingPatients?${params.toString()}`;
        },
      }
    ),
    getViewedPatients: builder.query<TPatient[], void>({
      query: () => `/ViewedPatients`,
    }),
    getPatientSummary: builder.query<IPatientSummary, string>({
      query: (id) => `/PatientSummaries/${id}`,
    }),
    getPatientHealthInfo: builder.query<IPatientHealth, string>({
      query: (id) => `/PatientHealthInfos/${id}`,
    }),
    getAppointments: builder.query<IPatientAppointmentDetails, string>({
      query: (id) => `/Appointments/${id}`,
    }),
    getPatientAppointments: builder.query<
      IPatientAppointments,
      PatientAppointments
    >({
      query: ({ formData, id }) => {
        const queryParams = queryStringBuilder(formData).toString();
        return `/PatientAppointments?PatientId=${id}&${queryParams}`;
      },
    }),
    getUpcomingNotBrokenPatientAppointments: builder.query<
      IPatientAppointments,
      IPatientUpcomingAppointments
    >({
      query: ({ id, sinceDateInSeconds }) => {
        let route = `/patientAppointments/?FamilyScope=PatientOnly&PatientId=${id}&FilterCategory=UpcomingNotBroken`;
        if (sinceDateInSeconds) {
          route += `&SinceDate=${sinceDateInSeconds}`;
        }
        return route;
      },
    }),
    getPatientFamilyMembers: builder.query<IPatientFamilyMember[], string>({
      query: (id) => `/PatientFamilyMembers/${id}`,
    }),
    getSchedulingPreferences: builder.query<
      IPatientSchedulePreferences,
      string
    >({
      query: (id) => `/PatientSchedulePreferences/${id}`,
    }),
    updateSchedulingPreferences: builder.mutation<
      IPatientSchedulePreferences,
      { id: string; preferencesData: IPatientSchedulePreferences }
    >({
      query({ id, preferencesData }) {
        const providerPreferencesById =
          preferencesData.providerPreferences.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr.preferred };
          }, {});
        return {
          url: `/PatientSchedulePreferences/${id}`,
          method: "PUT",
          body: {
            ...preferencesData,
            providerPreferences: providerPreferencesById,
          },
        };
      },
    }),
    getCommunicationPreferences: builder.query<
      IPatientCommunicationPreferences,
      string
    >({
      query: (id) => `/PatientCommunicationPreferences/${id}`,
      providesTags: [COMMUNICATION_PREFERENCES_TAG_TYPE],
    }),
    updateCommunicationPreferences: builder.mutation<
      IPatientCommunicationPreferences,
      { id: string; communicationData: any }
    >({
      query({ id, communicationData }) {
        return {
          url: `/PatientCommunicationPreferences/${id}`,
          method: "PUT",
          body: {
            ...communicationData,
          },
        };
      },
      invalidatesTags: [COMMUNICATION_PREFERENCES_TAG_TYPE],
    }),
    sendPatientEmail: builder.mutation<any, any>({
      query(patientEmail) {
        return {
          url: `/PatientEmails`,
          method: "POST",
          body: { ...patientEmail },
        };
      },
    }),
    getEmailHistorySummaries: builder.query<
      IPatientEmailHistorySummary,
      IEmailHistorySummaryRequest
    >({
      query: (requestOptions) => {
        const requestArray = Object.entries(requestOptions);
        const newRequestOptions =
          requestOptions.value === "" || requestOptions.value === null
            ? requestArray.filter(
                ([key]) => key !== "criteria" && key !== "value"
              )
            : requestOptions;
        const queryParams = queryStringBuilder(newRequestOptions).toString();
        return `/PatientEmailSummaries?${queryParams}`;
      },
    }),
    getEmailDetails: builder.query<IPatientEmailDetails, IEmailDetailsRequest>({
      query: (requestOptions) => {
        return `/PatientEmails/${requestOptions.id}`;
      },
    }),
    setSendTextMessageConfiguration: builder.mutation<
      any,
      { phone: string; patientId: string }
    >({
      query(requestOptions) {
        return {
          url: `TextMessageConfigurations/${requestOptions.patientId}`,
          method: "POST",
          body: {
            phone: requestOptions.phone,
          },
        };
      },
    }),
    getPatientInsurances: builder.query<IPatientInsurance[], string>({
      query: (id) => {
        return `/PatientInsurances/${id}`;
      },
    }),
    getPatientStatuses: builder.query<string[], void>({
      query: () => "patientStatuses",
    }),
    getInOfficePatientAndAppointments: builder.query<
      { patient: IPatientSummary; appointments: IAppointment[] },
      { patientId: string; time: number }
    >({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        const { patientId, time } = args;
        const patientResponse = await fetchWithBQ({
          url: `PatientSummaries/${patientId}`,
          method: "GET",
        });
        const patient = patientResponse?.data as IPatientSummary;
        if (!patient) {
          api.dispatch(updateInOfficeIsFormAssignerModalVisible(false));
        }

        let appointmentsQuery = `/patientAppointments/?FamilyScope=PatientOnly&PatientId=${patientId}&FilterCategory=UpcomingNotBroken`;
        if (time) {
          appointmentsQuery += `&SinceDate=${time}`;
        }
        const appointmentsResponse = await fetchWithBQ({
          url: appointmentsQuery,
          method: "GET",
        });
        const appointments = appointmentsResponse?.data as IAppointment[];

        return { data: { patient, appointments } };
      },
    }),
    getOnlineSchedulingAppointmentCard: builder.query<
      IOnlineSchedulingCard,
      string
    >({
      query: (id) => {
        return `/PatientOSAppointmentCards/${id}`;
      },
    }),
    getPatientAppointmentCard: builder.query<IPatientAppointmentCard, string>({
      query: (appointmentId) => `/PatientAppointmentCards/${appointmentId}`,
    }),
    getPatientInsuranceEligibilitySummary: builder.query<
      IPatientInsuranceSummary[],
      string
    >({
      query: (id) => `/PatientInsuranceSummaries/${id}`,
      providesTags: [PATIENT_INSURANCE_ELIGIBILITY_STATUS_TAG_TYPE],
    }),
    setPatientInsuranceEligibilityStatus: builder.mutation<
      unknown,
      IPatientInsuranceEligibilityStatusBody
    >({
      query(body) {
        return {
          url: `/PatientInsuranceEligibilityStatuses`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: [PATIENT_INSURANCE_ELIGIBILITY_STATUS_TAG_TYPE],
    }),
    postCheckInsuranceEligibilityStatus: builder.mutation<
      unknown,
      IPatientInsuranceEligibilityStatusBody
    >({
      query(body) {
        return {
          url: `/CheckInsuranceEligibilityStatus`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [PATIENT_INSURANCE_ELIGIBILITY_STATUS_TAG_TYPE],
    }),
    postCheckInsuranceEligibilityStatusAndBenefits: builder.mutation<
      IPatientInsuranceV2,
      IPatientInsuranceEligibilityStatusBody
    >({
      query(body) {
        return {
          url: `/CheckInsuranceEligibilityStatusAndBenefits`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetSchedulingPreferencesQuery,
  useUpdateSchedulingPreferencesMutation,
  useGetCommunicationPreferencesQuery,
  useUpdateCommunicationPreferencesMutation,
  useSendPatientEmailMutation,
  useGetEmailHistorySummariesQuery,
  useGetEmailDetailsQuery,
  useGetMatchingPatientsQuery,
  useGetViewedPatientsQuery,
  useGetPatientSummaryQuery,
  useGetPatientHealthInfoQuery,
  useGetPatientFamilyMembersQuery,
  useGetPatientAppointmentsQuery,
  useGetAppointmentsQuery,
  useSetSendTextMessageConfigurationMutation,
  useGetPatientInsurancesQuery,
  useGetPatientStatusesQuery,
  useGetInOfficePatientAndAppointmentsQuery,
  useGetUpcomingNotBrokenPatientAppointmentsQuery,
  useLazyGetUpcomingNotBrokenPatientAppointmentsQuery,
  useGetOnlineSchedulingAppointmentCardQuery,
  useGetPatientAppointmentCardQuery,
  useGetPatientInsuranceEligibilitySummaryQuery,
  useSetPatientInsuranceEligibilityStatusMutation,
  usePostCheckInsuranceEligibilityStatusMutation,
  usePostCheckInsuranceEligibilityStatusAndBenefitsMutation,
} = patientApi;
