import { TMenuOption } from "../../../../../../global/components/ContextMenu/ContextMenuWithChildren/TMenuOption";
import { IFormPackage } from "../../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { EActiveStatus } from "../../../../../../global/enums/EActiveStatus";

export const generatePackageOptions = (
  assignedPackagedIds: string[],
  packages: IFormPackage[],
  onSelectPackage: (formPackage: IFormPackage) => void
): TMenuOption[] => {
  const filteredPackages = packages.filter(
    (formPackage) =>
      !formPackage.forms.every(
        (form) => form.activeStatus === EActiveStatus.Inactive
      )
  );

  return filteredPackages.map((formPackage) => ({
    label: formPackage.name,
    callBack: () => {
      onSelectPackage(formPackage);
    },
    disabled:
      !formPackage.forms.length || assignedPackagedIds.includes(formPackage.id),
  }));
};
