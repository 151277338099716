import {
  updateDraftLeaveWithoutSavingCallback,
  displayDraftLeaveWithoutSavingModal,
  hideDraftLeaveWithoutSavingModal,
  updateLeaveWithoutSavingCallback,
  displayLeaveWithoutSavingModal,
  hideLeaveWithoutSavingModal,
  selectLeaveWithoutSaving,
  selectDraftLeaveWithoutSaving,
  selectActiveLeaveModal,
} from "../../features/authentication/globalMessagesSlice";
import { useAppSelector } from "./useTypedRedux.hook";

export const useLeaveWithoutSavingActions = () => {
  const activeLeaveModal = useAppSelector(selectActiveLeaveModal);
  const isLeaveWithoutSavingOpen = useAppSelector(
    selectLeaveWithoutSaving
  )?.isOpen;
  const isDraftLeaveWithoutSavingOpen = useAppSelector(
    selectDraftLeaveWithoutSaving
  )?.isOpen;

  const isDraft = activeLeaveModal === "draftSave";

  return {
    updateLeaveWithoutSavingCallback: isDraft
      ? updateDraftLeaveWithoutSavingCallback
      : updateLeaveWithoutSavingCallback,
    displayLeaveWithoutSavingModal: isDraft
      ? displayDraftLeaveWithoutSavingModal
      : displayLeaveWithoutSavingModal,
    hideLeaveWithoutSavingModal: isDraft
      ? hideLeaveWithoutSavingModal
      : hideDraftLeaveWithoutSavingModal,
    isLeaveWithoutSavingOpen: isDraft
      ? isDraftLeaveWithoutSavingOpen
      : isLeaveWithoutSavingOpen,
  };
};
