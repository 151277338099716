import React, { FC } from "react";

import { updateInOfficeIsFormAssignerModalVisible } from "./inOfficeModalSlice";

import { ConfirmModal } from "../../../../../global/components/Modals/ConfirmModal/ConfirmModal";
import { useAppDispatch } from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IErrorModalProps } from "./types/IErrorModalProps";
import { PRIMARY_TEXT, SECONDARY_TEXT, TITLE } from "./constants";

const ErrorModal: FC<IErrorModalProps> = ({
  isOpen = false,
  refetch,
  patientName,
  setVisibility,
}): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const subtitle = `We've encountered a problem displaying ${patientName}'s family members. Try again or continue assigning forms to ${patientName} only?`;

  const onClose = (): void => {
    dispatch(updateInOfficeIsFormAssignerModalVisible(false));
    setVisibility(false);
  };

  const onSecondaryButtonClick = (): void => {
    refetch();
  };

  const onPrimaryButtonClick = (): void => {
    setVisibility(false);
    dispatch(updateInOfficeIsFormAssignerModalVisible(true));
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={TITLE}
      subtitle={subtitle}
      primaryText={PRIMARY_TEXT}
      secondaryText={SECONDARY_TEXT}
      onClose={onClose}
      onSecondaryButtonClick={onSecondaryButtonClick}
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
};

export const FamilyMembersErrorModal = React.memo(ErrorModal, areEqualProps);
