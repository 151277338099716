import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../global/store";

interface settingsSliceTypes {
  profile: string;
  subscriptions: string;
  smartScheduling: string;
  practiceLogo: string;
  users: any;
  newUserStep: number;
  savedSettings: boolean;
  inviteUnlinkedUser: {
    id: string;
    label: string;
  };
}

const initialState: settingsSliceTypes = {
  profile: "",
  subscriptions: "",
  smartScheduling: "",
  practiceLogo: "",
  users: null,
  newUserStep: 0,
  savedSettings: false,
  inviteUnlinkedUser: {
    id: "",
    label: "",
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updatePmsId: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    updateNewUserStep: (state, action: PayloadAction<number>) => {
      state.newUserStep = action.payload;
    },
    updateSavedSettings: (state, action: PayloadAction<boolean>) => {
      state.savedSettings = action.payload;
    },
    updateInviteUnlinkedUser: (
      state,
      action: PayloadAction<{ id: string; label: string }>
    ) => {
      state.inviteUnlinkedUser = action.payload;
    },
  },
});

export const selectNewUserStep = (state: RootState): number =>
  state.settingsState.newUserStep;

export const selectInviteUnlinkedUser = (
  state: RootState
): { id: string; label: string } => state.settingsState.inviteUnlinkedUser;

export const {
  updatePmsId,
  updateNewUserStep,
  updateSavedSettings,
  updateInviteUnlinkedUser,
} = settingsSlice.actions;

export default settingsSlice.reducer;
