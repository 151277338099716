import React from "react";
import { Menu, MenuItem, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TOption } from "./Option.type";
import { OptionComponent } from "./OptionComponent";

export interface ContextMenuPropTypes {
  /**
   * function that sets the element to null.
   */
  handleClose: () => void;
  /**
   * HTML element.
   */
  anchorEl: HTMLElement;
  /**
   * Array of objects that passes a label and route.
   */
  options: TOption[];
  /**
   * Simple onClick function when MenuItem is selected.
   */
  handleItemClick?: (value: string) => void;
  /**
   * Controls visibility
   */
  open?: boolean;
}

const SMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.accent.background,
  },
}));

const ITEM_HEIGHT = 48;

export const ContextMenu: React.FC<ContextMenuPropTypes> = ({
  handleClose,
  anchorEl = null,
  options = [],
  handleItemClick,
  open = Boolean(anchorEl),
}: ContextMenuPropTypes) => {
  return (
    <Menu
      anchorEl={anchorEl}
      data-testid="contextMenu/menu"
      keepMounted
      open={open}
      onClose={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        handleClose();
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * (options.length ? options.length : 4.5),
          width: "fit-content",
          backgroundColor: "#F7F7F7",
          boxShadow: "0px 4px 4px rgba(229, 229, 229, 0.25)",
          border: "0.5px solid #E5E5E5",
          borderRadius: "6px",
        },
      }}
      MenuListProps={{ style: { paddingBottom: "0px", paddingTop: "0px" } }}
    >
      {options.map((option, index) => (
        <div key={option.label}>
          <OptionComponent tooltipText={option.tooltipText}>
            <SMenuItem
              onClick={async function (e) {
                e.stopPropagation();
                if ("route" in option && handleItemClick) {
                  handleItemClick(option.route);
                } else if ("callBack" in option) {
                  await option.callBack(e);
                }
                handleClose();
              }}
              data-testid={`contextMenu/item/${option.label}`}
              disabled={option.disabled || false}
            >
              <Typography variant="caption">{option.label}</Typography>
            </SMenuItem>
          </OptionComponent>

          {index < options.length - 1 && <Divider style={{ margin: "0px" }} />}
        </div>
      ))}
    </Menu>
  );
};
