import { Typography } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../../../../global/hooks/useTypedRedux.hook";
import { useDeleteFormMutation } from "../../../../../../global/services/document-manager/formAssignmentsApi";
import { ConfirmModal } from "../../../../../../global/components/Modals/ConfirmModal/ConfirmModal";
import { removeDeletedForm } from "../../inOfficePatientsSlice";
import { TDeleteFormModal } from "./TDeleteFormModal";

export const DeleteFormModal = ({
  formAssignmentId,
  formId,
  name,
  isOpen,
  patientId,
  setIsOpen,
  responseId,
  refetch,
}: TDeleteFormModal) => {
  const dispatch = useAppDispatch();
  const [deleteForm, { isLoading }] = useDeleteFormMutation();

  const handleClick = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
    try {
      await deleteForm({
        formAssignmentId,
        formId,
        patientId,
        responseId,
      }).unwrap();
      dispatch(removeDeletedForm({ formAssignmentId, formId }));
      if (refetch) {
        refetch();
      }
      setIsOpen(false);
    } catch {
      return;
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onPrimaryButtonClick={handleClick}
      title="Delete Form?"
      subtitle="Are you sure you want to delete this form?"
      secondaryText="No, Go Back"
      primaryText="Yes, Delete"
      disablePrimary={isLoading}
      disableSecondary={isLoading}
    >
      <Typography fontWeight="bold" pt={3} pl={5}>
        {name}
      </Typography>
    </ConfirmModal>
  );
};
