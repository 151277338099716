import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import {
  TSummaryRecipients,
  TCreateCampaign,
  TEmailCampaign,
  TSentTestEmail,
  TSendNow,
  TPatientsForPreview,
  TSentScheduled,
  TEmailCampaignsList,
  TEmailCampaignsListParams,
  TUpdateCampaign,
} from "../../domains/emailCampaigns/types/TEmailCampaignTemplate";
import { queryStringBuilder } from "../../helperFunctions/api/queryStringBuilder";

export const emailCampaignsApi = createApi({
  reducerPath: "emailCampaignsApi",

  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_YAPI_EMAILCMPGNS),
  tagTypes: ["SummaryRecipients", "EmailCampaign", "GetCampaigns"],

  endpoints: (builder) => ({
    getAllEmailCampaigns: builder.query<
      TEmailCampaignsList,
      TEmailCampaignsListParams
    >({
      query(body) {
        return {
          url: `/campaigns/list?${queryStringBuilder({ ...body }).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["GetCampaigns"],
    }),
    updateCampaign: builder.mutation<string, TUpdateCampaign>({
      query(body) {
        return {
          url: `/campaigns/${body.campaignId}`,
          method: "PATCH",
          body,
        };
      },
    }),
    getEmailCampaign: builder.query<TEmailCampaign, string>({
      query(id) {
        return {
          url: `/campaigns/${id}`,
          method: "GET",
        };
      },
      providesTags: ["EmailCampaign"],
    }),
    isEmailCampaignNameAlreadyExist: builder.query<boolean, string>({
      query(name) {
        return {
          url: `/campaigns/isnameunique?${queryStringBuilder({
            name,
          }).toString()}`,
          method: "GET",
        };
      },
    }),
    getSummaryRecipients: builder.query<TSummaryRecipients, string>({
      query(id) {
        return {
          url: `/campaigns/${id}/summary/`,
          method: "GET",
        };
      },
      providesTags: ["SummaryRecipients"],
    }),
    createNewCampaign: builder.mutation<string, TCreateCampaign>({
      query(campaignBody) {
        return {
          url: "/campaigns/",
          method: "POST",
          body: { ...campaignBody },
        };
      },
    }),
    deleteCampaign: builder.mutation<string, string>({
      query(id) {
        return {
          url: `/campaigns/${id}`,
          method: "DELETE",
        };
      },
    }),
    sendTest: builder.mutation<undefined, TSentTestEmail>({
      query({ campaignId, ...body }) {
        return {
          url: `/campaigns/${campaignId}/campaign-emails/send-test-email`,
          method: "POST",
          body,
        };
      },
    }),
    sendNow: builder.mutation<undefined, TSendNow>({
      query({ campaignId, ...body }) {
        return {
          url: `/campaigns/${campaignId}/campaign-emails/send-now`,
          method: "POST",
          body,
        };
      },
    }),
    sentScheduled: builder.mutation<undefined, TSentScheduled>({
      query({ campaignId, ...body }) {
        return {
          url: `/campaigns/${campaignId}/campaign-emails/send-scheduled`,
          method: "POST",
          body,
        };
      },
    }),
    getPatientsForPreview: builder.query<TPatientsForPreview[], string>({
      query(campaignId) {
        return {
          url: `/campaigns/campaign-emails/patients-for-preview?${queryStringBuilder(
            {
              campaignId,
            }
          ).toString()}`,
          method: "GET",
        };
      },
    }),
    copyCampaign: builder.mutation<string, string>({
      query(id) {
        return {
          url: `/campaigns/${id}/copy`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetPatientsForPreviewQuery,
  useGetEmailCampaignQuery,
  useCreateNewCampaignMutation,
  useLazyIsEmailCampaignNameAlreadyExistQuery,
  useSendTestMutation,
  useSendNowMutation,
  useGetSummaryRecipientsQuery,
  useDeleteCampaignMutation,
  useCopyCampaignMutation,
  useSentScheduledMutation,
  useGetAllEmailCampaignsQuery,
  useUpdateCampaignMutation,
} = emailCampaignsApi;
