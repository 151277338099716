import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import appReducer from "../../features/authentication/appSlice";
import inOfficePatientReducer from "../../features/documentManager/tabs/inOfficePatients/inOfficePatientsSlice";
import inOfficeModalSliceReducer from "../../features/documentManager/tabs/inOfficePatients/FormAssignerModal/inOfficeModalSlice";
import layoutReducer from "../../features/layout/layoutSlice";
import navigationReducer from "../../features/navigation/navigationSlice";
import notificationReducer from "../../features/notification/notificationSlice";
import paymentsReducer from "../../features/payments/paymentsSlice";
import dashboardReducer from "../../features/dashboard/dashboardSlice";
import documentManagerReducer from "../../features/documentManager/documentManagerSlice";
import patientsReducer from "../../features/patients/patientsSlice";
import imageReducer from "../../features/patients/patientProfile/imageSlice";
import settingsReducer from "../../features/settings/settingsSlice";
import scheduleReducer from "../../features/appointments/schedule/scheduleSlice";
import homepageReducer from "../../features/homepage/homepageSlice";
import communicationReducer from "../../features/patientCommunication/communicationSlice";
import globalMessagesReducer from "../../features/authentication/globalMessagesSlice";
import treatmentsReducer from "../../features/settings/practiceSettings/onlineScheduling/onlineSchedulingTabs/appointments/treatment/treatmentsSlice";
import patientSelectorReducer from "../../features/documentManager/tabs/common/patientSelectorModal/patientSelectorSlice";
import formLibraryReducer from "../../features/settings/practiceSettings/formsLibrary/formLibrarySlice";
import { persistReducer } from "redux-persist";
import { setupListeners } from "@reduxjs/toolkit/query";
import CURRENT_STORE_VERSION from "./version";
import providersReducer from "../../features/settings/practiceSettings/onlineScheduling/onlineSchedulingTabs/providers/providersSlice";
import newPackageModalReducer from "../../features/settings/practiceSettings/formsLibrary/NewPackageModal/newPackageModalSlice";
import addToPackageModalReducer from "../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Forms/modals/AddToPackageModal/addToPackageModalSlice";
import featureFlagsReducer from "../services/feature-flags/featureFlagsSlice";
import outstandingTasksReducer from "../../features/documentManager/tabs/outstandingTasks/outstandingTasksSlice";
import { configureStore } from "@reduxjs/toolkit";
import { getApiMiddlewares, getApiReducers } from "./apiHelper";
import pendingFormsReducer from "../../features/documentManager/tabs/pendingForms/pendingFormsSlice";
import pendingFormsModalsReducer from "../../features/documentManager/tabs/pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import textTemplateReducer from "../../features/textTemplate/textTemplateSlice";
import fillAppointmentReducer from "../../features/appointments/schedule/fillAppointment/fillAppointmentSlice";
import templateLibraryReducer from "../../features/settings/practiceSettings/templateLibrary/templateLibrarySlice";

const apiReducers = getApiReducers();
const reducers = combineReducers({
  ...apiReducers,
  appState: appReducer,
  dashboardState: dashboardReducer,
  documentManagerState: documentManagerReducer,
  inOfficePatientState: inOfficePatientReducer,
  inOfficeModalState: inOfficeModalSliceReducer,
  pendingFormsState: pendingFormsReducer,
  pendingFormsModalsState: pendingFormsModalsReducer,
  layoutState: layoutReducer,
  navigationState: navigationReducer,
  notificationState: notificationReducer,
  patientState: patientsReducer,
  imageState: imageReducer,
  communicationState: communicationReducer,
  paymentsState: paymentsReducer,
  settingsState: settingsReducer,
  scheduleState: scheduleReducer,
  homepageState: homepageReducer,
  globalMessages: globalMessagesReducer,
  treatmentState: treatmentsReducer,
  providerState: providersReducer,
  patientSelectorState: patientSelectorReducer,
  formLibraryState: formLibraryReducer,
  newPackageModalState: newPackageModalReducer,
  addToPackageModalState: addToPackageModalReducer,
  featureFlagsState: featureFlagsReducer,
  outstandingTasksState: outstandingTasksReducer,
  textTemplateState: textTemplateReducer,
  fillAppointmentState: fillAppointmentReducer,
  templateLibraryState: templateLibraryReducer,
});
const apiReducerNames = Object.keys(apiReducers);

const blacklistStateNames = [
  "communicationState",
  "fillAppointmentState",
  "formLibraryState",
];

const persistConfig = {
  key: "root",
  storage,
  version: CURRENT_STORE_VERSION,
  migrate: (state) => Promise.resolve(state),
  blacklist: [...blacklistStateNames, ...apiReducerNames],
};

type TReducers = ReturnType<typeof reducers>;

// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action): TReducers => {
  if (action.type === "persist/PURGE") {
    void storage.removeItem("persist:root");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer<TReducers>(persistConfig, rootReducer);

const apiMiddlewares = getApiMiddlewares();
export const store = configureStore({
  reducer: persistedReducer,
  // Ignoring TS types as it's not possible to define middlewares response type based on all apis
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiMiddlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
