import { IIndexable } from "../../types/IIndexable.interface";

export const saveState = (key: string, state: IIndexable | string): void => {
  try {
    const serialState = JSON.stringify(state);
    sessionStorage.setItem(`appState.${key}`, serialState);
  } catch (err) {
    throw new Error();
  }
};
