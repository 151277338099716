import dayjs from "dayjs";
import { ESchedulingAppointmentStatus } from "../../global/enums/EAppointmentStatus";

const pastCards = [
  ESchedulingAppointmentStatus.Unconfirmed,
  ESchedulingAppointmentStatus.Broken,
  ESchedulingAppointmentStatus.Completed,
];
export const todayCards = [
  ESchedulingAppointmentStatus.Unconfirmed,
  ESchedulingAppointmentStatus.Broken,
  ESchedulingAppointmentStatus.Confirmed,
];
const futureCards = [
  ESchedulingAppointmentStatus.Unconfirmed,
  ESchedulingAppointmentStatus.Confirmed,
];

export const getScheduledVisibleCards = (
  selectedDate: Date
): ESchedulingAppointmentStatus[] => {
  const today = new Date();
  if (selectedDate > today) {
    return futureCards;
  }

  if (dayjs(selectedDate).isSame(today, "day")) {
    return todayCards;
  }

  return pastCards;
};
