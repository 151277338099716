import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../global/hooks/useTypedRedux.hook";
import { ContextMenuButton } from "../../../../../../global/components/ContextMenu/ContextMenuButton";
import { removeInOfficePatient } from "../../inOfficePatientsSlice";
import {
  updateInOfficeIsFormAssignerModalVisible,
  updateWhereFormAssignerModalOpened,
} from "../../FormAssignerModal/inOfficeModalSlice";
import { updateSelectedPatientId } from "../../../common/patientSelectorModal/patientSelectorSlice";
import { ConfirmModal } from "../../../../../../global/components/Modals/ConfirmModal/ConfirmModal";
import { useCheckoutMutation } from "../../../../../../global/services/document-manager/inOfficePatientsApi";
import { Typography } from "@mui/material";
import {
  INCOMPLETE_FORMS_DESCRIPTION,
  NO_INCOMPLETE_FORMS_DESCRIPTION,
} from "./CheckOutMessages";
import {
  updateError,
  updateSnackbar,
} from "../../../../../authentication/globalMessagesSlice";
import { isIncompleteForm } from "../../../../isIncompleteForm";
import { EFormAssignButtons } from "../../FormAssignerModal/types/EFormAssignButtons";
import { EFormStatus } from "../../../../../../global/types/documentManager/EFormStatus";
import { SWrapperContextMenuButton } from "../../../styles";
import { TOptionsMenu } from "./types/TOptionsMenu";
import { EOptionsMenu } from "./types/EOptionsMenu";

const CUSTOM_STYLES = { whiteSpace: "pre-wrap" };

const OptionsMenu = ({
  appointmentId,
  formAssignmentId,
  patientId,
  forms,
}: TOptionsMenu) => {
  // [WARNING] This is a really low level component that's being used in each item of document managers list
  // We should not do ANY GET request on it
  const dispatch = useAppDispatch();
  const [checkOutDescription, setCheckOutDescription] = useState(
    NO_INCOMPLETE_FORMS_DESCRIPTION
  );
  const [isCheckOutModalOpen, setCheckOutModalVisibility] = useState(false);
  const [checkoutPatient] = useCheckoutMutation();

  useEffect(() => {
    const hasIncompleteForms = forms.some((form) => isIncompleteForm(form));

    setCheckOutDescription(
      hasIncompleteForms
        ? INCOMPLETE_FORMS_DESCRIPTION
        : NO_INCOMPLETE_FORMS_DESCRIPTION
    );
  }, [forms]);

  const showAssignFormDevicesModal = (optionType: number) => {
    dispatch(updateSelectedPatientId(patientId));
    dispatch(updateInOfficeIsFormAssignerModalVisible(true));
    dispatch(updateWhereFormAssignerModalOpened(optionType));
  };

  const showCheckoutModal = () => {
    setCheckOutModalVisibility(true);
  };

  const performCheckout = async () => {
    setCheckOutModalVisibility(false);
    try {
      await checkoutPatient({
        appointmentId,
        patientId,
        formAssignmentId,
      }).unwrap();
      dispatch(removeInOfficePatient(patientId));
      dispatch(
        updateSnackbar({
          status: true,
          message: "Patient checked-out and forms deleted",
        })
      );
    } catch {
      dispatch(updateError(true));
    }
  };

  const assignOption = {
    label: EOptionsMenu.AssignIncompleteForm,
    disabled: false,
    callBack: () =>
      showAssignFormDevicesModal(
        EFormAssignButtons.AssignIncompleteFormsToDevice
      ),
  };

  const addFormOption = {
    label: EOptionsMenu.AddFormPackage,
    disabled: false,
    callBack: () =>
      showAssignFormDevicesModal(EFormAssignButtons.AddFormOrPackage),
  };

  const checkOutOption = {
    label: EOptionsMenu.CheckOut,
    disabled: false,
    callBack: showCheckoutModal,
  };

  const menuOptions = () => {
    const allOptions = [addFormOption, checkOutOption];
    const isNotSubmittedForm: boolean = forms.some(
      (form) =>
        form.status === EFormStatus.Todo ||
        form.status === EFormStatus.InProgress
    );

    if (isNotSubmittedForm) {
      allOptions.unshift(assignOption);
    }

    return allOptions;
  };

  const onClose = (): void => {
    setCheckOutModalVisibility(false);
  };

  return (
    <>
      <ConfirmModal
        title="Check-Out"
        subtitle={""}
        isOpen={isCheckOutModalOpen}
        onPrimaryButtonClick={performCheckout}
        onClose={onClose}
      >
        <Typography
          variant="h5"
          fontWeight="500"
          sx={CUSTOM_STYLES}
          aria-label="Check-Out description"
        >
          {checkOutDescription}
        </Typography>
      </ConfirmModal>
      <SWrapperContextMenuButton>
        <ContextMenuButton
          options={menuOptions()}
          handleItemClick={undefined}
        />
      </SWrapperContextMenuButton>
    </>
  );
};

export default OptionsMenu;
