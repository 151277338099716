import { Box, Grid, styled } from "@mui/material";

export const SBoxWithScroll = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  minHeight: "125px",
  width: "100%",
  overflowY: "auto",
  scrollMarginLeft: 4,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #E5E5E5",
    webkitBoxShadow: "inset 0 0 6px #E5E5E5",
    borderRadius: 4,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#595959",
    borderRadius: 4,
    outline: "1px solid slategrey",
    backgroundClip: "content-box",
  },
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
}));

export const SRowWrapper = styled(Grid)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  ":last-child": {
    borderBottom: 0,
  },
}));
