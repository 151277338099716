// this endpoint may expire and the data may be purged.
// if so, refer to (chartTest / testDataFetch / data.json) and use
// that to repopulate mocki and update queryId
// https://mocki.io/v1/ff404041-b605-40b9-8522-3e5e0175d39e

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface testDataResponse {
  _id: string;
  balance: number;
  registered: string;
}
export const chartDataApi = createApi({
  reducerPath: "chartDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://mocki.io/v1",
  }),
  endpoints: (builder) => ({
    getChartData: builder.query<testDataResponse[], number>({
      // will eventually take arguments of (startDate, endDate, interval("weekly" | "monthly" | "quarterly"))
      query: () => `ff404041-b605-40b9-8522-3e5e0175d39e`,
    }),
  }),
});

export const { useGetChartDataQuery } = chartDataApi;
