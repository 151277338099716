import { IPatientPhone } from "../../domains/patients/types/IPatientPhone.interface";

export enum EPhoneType {
  Mobile = "Mobile",
  Home = "Home",
  Work = "Work",
  Fax = "Fax",
  Other = "Other",
  Default = "M",
}

//TODO: Simplify this function

export const primaryPhone = (
  phones: IPatientPhone[],
  phoneType = EPhoneType.Default
) => {
  const mobile = phones.find((phone) => phone.phoneType === EPhoneType.Mobile);
  const home = phones.find((phone) => phone.phoneType === EPhoneType.Home);
  const work = phones.find((phone) => phone.phoneType === EPhoneType.Work);
  const fax = phones.find((phone) => phone.phoneType === EPhoneType.Fax);
  const other = phones.find((phone) => phone.phoneType === EPhoneType.Other);
  if (mobile) {
    return mobile;
  } else if (work) {
    return work;
  } else if (home) {
    return home;
  } else if (other) {
    return other;
  } else if (fax) {
    return fax;
  } else {
    return {
      phoneType,
      phone: "-",
    };
  }
};
