import React from "react";
import {
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useFormContext } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { EPatientSearchCriteria } from "../../../global/domains/patients/enums/EPatientSearchCriteria";
import { patientSearchInputValidator } from "../../../global/helperFunctions/patients/patientSearchInputValidator";
import { ControlledPhoneField } from "../../../global/components/SearchBar/ControlledPhoneField";

export type SearchBarPropTypes = {
  /**
   * selected value in dropdown
   */
  selectValue: EPatientSearchCriteria;
  /**
   * list of options in the dropdown
   */
  selectOptions: { value: string; label: string }[];
  /**
   * function to handle submit
   */
  handleSubmit: () => void;
  /**
   * input value to change for react-hook-form
   */
  inputName: string;
  /**
   * select value to change for react-hook-form
   */
  selectName: string;
  /**
   * placeholder set within the search bar
   */
  placeholder?: string;
  /**
   * function to handle type change
   */
  handleTypeChange?: (string) => void;
};

const RootDiv = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: "56px",
  boxShadow: `0px 4px 4px ${theme.palette.text.disabled}`,
}));

const SelectOption = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.accent.background,
  },
  color: theme.palette.text.secondary,
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: "51px",
  padding: "0px 10px 0px 18px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "transparent",
  },
  ".MuiSelect-select": {
    color: theme.palette.text.secondary,
  },
  ".MuiSelect-select > p": {
    fontWeight: 500,
  },
  "&:focus": {
    backgroundColor: "transparent",
  },
}));

const StyledInput = styled(InputBase)(() => ({
  paddingLeft: "10px",
}));

export const SearchBar: React.FC<SearchBarPropTypes> = ({
  selectValue,
  selectOptions,
  handleSubmit,
  handleTypeChange,
  inputName,
  selectName,
  placeholder,
}: SearchBarPropTypes) => {
  const { control, setValue } = useFormContext();

  const options = selectOptions.map((option) => (
    <SelectOption
      value={option.value}
      key={option.value}
      selected={option.value === selectValue}
    >
      <Typography
        variant="body1"
        align="center"
        fontWeight={option.value === selectValue ? 600 : 500}
      >
        {option.label}
      </Typography>
    </SelectOption>
  ));

  return (
    <RootDiv direction="row" alignItems="center" sx={{ width: "100%" }}>
      <div style={{ borderRight: "1px solid black" }}>
        <Controller
          control={control}
          name={selectName}
          render={({ field: { value } }) => (
            <StyledSelect
              value={value}
              onChange={(e) => handleTypeChange(e.target.value)}
              inputProps={{
                "aria-label": "searchbar/select",
              }}
              data-testid="searchbar/select"
              IconComponent={(props) => (
                <KeyboardArrowDownRoundedIcon {...props} />
              )}
            >
              {options}
            </StyledSelect>
          )}
        />
      </div>
      {selectValue === EPatientSearchCriteria.BY_PHONE ? (
        <ControlledPhoneField
          control={control}
          inputName={inputName}
          placeholder={placeholder}
          handleSubmit={handleSubmit}
        />
      ) : (
        <Controller
          control={control}
          name={inputName}
          render={({ field: { onChange, value } }) => (
            <StyledInput
              fullWidth
              placeholder={`Type ${placeholder}`}
              data-testid="searchbar/input"
              inputProps={{
                "aria-label": "searchbar/input",
                ...((selectValue === EPatientSearchCriteria.BY_NAME ||
                  selectValue === EPatientSearchCriteria.BY_NAME_USER) && {
                  maxLength: 50,
                }),
              }}
              onChange={(e) => {
                onChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
                if (e.key === "Backspace" && value.length === 1) {
                  setValue("value", "");
                }
                patientSearchInputValidator(e, selectValue);
              }}
              value={value}
            />
          )}
        />
      )}

      <IconButton
        color="secondary"
        data-testid="searchbar/button"
        aria-label="searchbar/button"
        onClick={handleSubmit}
        size="large"
        disableRipple
      >
        <SearchIcon />
      </IconButton>
    </RootDiv>
  );
};
