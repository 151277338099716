import { IUserModel } from "../../types/users/IUserModel";
import { baseAPI } from "../baseApi";

export const currentUserApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<IUserModel, any>({
      query: () => `Users/Current`,
      providesTags: ["CurrentUser"],
    }),
    setCurrentUser: builder.mutation<any, any>({
      query(userData) {
        return {
          url: "Users/Current",
          method: "PUT",
          body: userData,
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
    getCurrentUserPermissions: builder.query<any, any>({
      query: () => "UserPermissions/Current",
    }),
    getCurrentUserPhoto: builder.query<any, any>({
      query: (id) => `UserPhotos/${id}`,
    }),
    deleteCurrentUserPhoto: builder.mutation<any, any>({
      query() {
        return {
          url: `UserPhotos/Current`,
          method: "DELETE",
        };
      },
    }),
    setCurrentUserPhoto: builder.mutation<any, any>({
      query(photo) {
        return {
          url: "UserPhotos/Current",
          method: "PUT",
          body: photo,
        };
      },
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useSetCurrentUserMutation,
  useGetCurrentUserPhotoQuery,
  useSetCurrentUserPhotoMutation,
  useDeleteCurrentUserPhotoMutation,
  useGetCurrentUserPermissionsQuery,
} = currentUserApi;
