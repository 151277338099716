import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { TEmailCampaignTemplate } from "../../domains/emailCampaigns/types/TEmailCampaignTemplate";

export const emailTemplatesApi = createApi({
  reducerPath: "emailTemplatesApi",

  tagTypes: ["EmailCampaignTemplates"],

  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_YAPI_EMAILTMPLS),

  endpoints: (builder) => ({
    getEmailCampaignTemplates: builder.query<TEmailCampaignTemplate[], void>({
      query: () => `/Templates`,
      providesTags: ["EmailCampaignTemplates"],
    }),
  }),
});

export const { useGetEmailCampaignTemplatesQuery } = emailTemplatesApi;
