import {
  IMassTextMessageParams,
  IPatientTextMessageParams,
} from "../../../global/types/communications/IMassTextMessageParams.interface";
import { ETextMessagesCriteria } from "../../../global/types/communications/ETextMessagesFilter.enum";

export const defaultPatientTextParams: IPatientTextMessageParams = {
  criteria: ETextMessagesCriteria.NAME,
  value: "",
  filter: 1,
  pageIndex: 0,
  pageSize: 20,
};

export const defaultMassTextParams: IMassTextMessageParams = {
  search: "",
  filter: 1,
  pageIndex: 0,
  pageSize: 20,
  textMessageType: 2,
};
