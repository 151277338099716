import { TSelectOption } from "../../../types/select/TSelectOption.type";

export enum EPatientType {
  Any = "Any",
  New = "New",
  Existing = "Existing",
}

export const PATIENT_TYPE_LIST: TSelectOption<EPatientType>[] = [
  {
    label: "Any",
    value: EPatientType.Any,
  },
  {
    label: "New Patient",
    value: EPatientType.New,
  },
  {
    label: "Existing Patient",
    value: EPatientType.Existing,
  },
];
