import { createApi } from "@reduxjs/toolkit/query/react";
import { queryStringBuilder } from "../../../../../helperFunctions/api/queryStringBuilder";
import { IOnlineSchedulingProviderResponse } from "../../../../../types/onlineScheduling/IOnlineSchedulingProviderResponse.interface";
import { IOnlineSchedulingProviderRequest } from "../../../../../types/onlineScheduling/IOnlineSchedulingProviderRequest.interface";
import { IOnlineSchedulingProviderBioResponse } from "../../../../../types/onlineScheduling/IOnlineSchedulingProviderBioResponse.interface";
import { IOnlineSchedulingProviderSettingsResponse } from "../../../../../types/onlineScheduling/IOnlineSchedulingProviderSettingsResponse.interface";
import { IProviderSchedulingRulesSummaryRequest } from "../../../../../types/settings/onlineScheduling/schedulingRules/IProviderSchedulingRulesRequest.interface";
import { IProviderSchedulingRulesSummary } from "../../../../../types/settings/onlineScheduling/schedulingRules/IProviderSchedulingRulesSummary.interface";
import { IAvailableSlotsQuery } from "../../../../../types/settings/onlineScheduling/schedulingRules/IAvailableSlotsQuery.interface";
import { IAvailableSlots } from "../../../../../types/settings/onlineScheduling/schedulingRules/IAvailableSlots.interface";
import { IIdParams } from "../../../../../types/settings/onlineScheduling/schedulingRules/IIdParams.interface";
import { baseQueryWithReAuth } from "../../../../../helperFunctions/api/baseQueryWithReauth";

interface IAvailabilityDay {
  isAvailable?: string;
  startTime: string;
  endTime: string;
}

export interface IAvailability {
  Monday?: IAvailabilityDay;
  Tuesday?: IAvailabilityDay;
  Wednesday?: IAvailabilityDay;
  Thursday?: IAvailabilityDay;
  Friday?: IAvailabilityDay;
  Saturday?: IAvailabilityDay;
  Sunday?: IAvailabilityDay;
}

interface IOperatory {
  id: string;
  name?: string;
  abbreviation?: string;
}

interface ISchedulingRuleResponse {
  id: string;
  operatories: IOperatory[];
  isAlwaysAvailable: boolean;
  availabilities: IAvailability;
}

interface ISetSchedulingRuleResponse {
  treatmentId: string;
  providerId: string;
  operatories: IOperatory[];
  isAlwaysAvailable: boolean;
  availabilities: IAvailability;
}

interface ISchedulingRuleRequest {
  operatories: IOperatory[];
  isAlwaysAvailable: boolean;
  availabilities: IAvailability;
}

export interface IGetProvidersResponse {
  id: string;
  name: string;
}

export const providersApi = createApi({
  reducerPath: "providersApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  tagTypes: ["CurrentRule", "CurrentRuleSummary"],
  endpoints: (builder) => ({
    getProviders: builder.query<IGetProvidersResponse[], void>({
      query: () => `/providers`,
    }),
    getSchedulingProviders: builder.query<
      IOnlineSchedulingProviderResponse,
      IOnlineSchedulingProviderRequest
    >({
      query: (params) => {
        const updatedProviderParams = { ...params };
        if (updatedProviderParams.statusFilter === "All") {
          delete updatedProviderParams.statusFilter;
        }
        const queryParams = queryStringBuilder(
          updatedProviderParams
        ).toString();
        return `/schedulingProviders?${queryParams.replace(/\+/, "%20")}`;
      },
    }),
    setSchedulingProviderEnabledState: builder.mutation<
      { id: string; isEnabled: boolean },
      { id: string; formData: { isEnabled: boolean } }
    >({
      query: (providerStatus) => {
        return {
          url: `SchedulingProviderEnabledStates/${providerStatus.id}`,
          method: "PUT",
          body: providerStatus.formData,
        };
      },
    }),
    getProviderBio: builder.query<IOnlineSchedulingProviderBioResponse, string>(
      {
        query: (id) => {
          return `/SchedulingProviderBios/${id}`;
        },
      }
    ),
    setProviderBio: builder.mutation<
      { id: string; onlineSchedulingName: string; bioParagraph: string },
      {
        id: string;
        formData: { onlineSchedulingName: string; bioParagraph: string };
      }
    >({
      query(providerBioData) {
        return {
          url: `SchedulingProviderBios/${providerBioData.id}`,
          method: "PUT",
          body: providerBioData.formData,
        };
      },
    }),
    getProviderSettings: builder.query<
      IOnlineSchedulingProviderSettingsResponse,
      string
    >({
      query: (id) => {
        return `/SchedulingProviderSettings/${id}`;
      },
    }),
    setProviderSettings: builder.mutation<
      {
        id: string;
        bookingCapacitySelection: string;
        minimumBookingAge: number | null;
        maximumBookingAge: number | null;
      },
      {
        id: string;
        formData: {
          bookingCapacitySelection: string;
          minimumBookingAge: number | null;
          maximumBookingAge: number | null;
        };
      }
    >({
      query(providerSettingsData) {
        return {
          url: `SchedulingProviderSettings/${providerSettingsData.id}`,
          method: "PUT",
          body: providerSettingsData.formData,
        };
      },
    }),
    getSchedulingRulesSummaries: builder.query<
      IProviderSchedulingRulesSummary[],
      IProviderSchedulingRulesSummaryRequest
    >({
      query: (rulesParams) => {
        const rulesQuery = queryStringBuilder(rulesParams).toString();
        return `/SchedulingProviderRuleSummaries?${rulesQuery}`;
      },
      providesTags: ["CurrentRuleSummary"],
    }),
    getSchedulingRule: builder.query<ISchedulingRuleResponse, IIdParams>({
      query: (idParams) => {
        return `/SchedulingProviderRules/${idParams.treatmentId}:${idParams.providerId}`;
      },
      providesTags: ["CurrentRule"],
    }),
    setSchedulingRule: builder.mutation<
      ISetSchedulingRuleResponse,
      {
        id: IIdParams;
        formData: ISchedulingRuleRequest;
      }
    >({
      query(ruleData) {
        return {
          url: `SchedulingProviderRules/${ruleData.id.treatmentId}:${ruleData.id.providerId}`,
          method: "PUT",
          body: ruleData.formData,
        };
      },
      invalidatesTags: ["CurrentRule", "CurrentRuleSummary"],
    }),
    setSchedulingRuleEnabledState: builder.mutation<
      void,
      {
        id: string;
        formData: { isEnabled: boolean };
      }
    >({
      query(ruleData) {
        return {
          url: `SchedulingProviderRuleEnabledStates/${ruleData.id}`,
          method: "PUT",
          body: ruleData.formData,
        };
      },
      invalidatesTags: ["CurrentRuleSummary"],
    }),
    getAvailableSlots: builder.query<IAvailableSlots, IAvailableSlotsQuery>({
      query: (params) => {
        const queryParams = queryStringBuilder(params).toString();
        return `availableSlots?${queryParams}`;
      },
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useGetSchedulingProvidersQuery,
  useSetSchedulingProviderEnabledStateMutation,
  useGetProviderBioQuery,
  useSetProviderBioMutation,
  useGetProviderSettingsQuery,
  useSetProviderSettingsMutation,
  useGetSchedulingRulesSummariesQuery,
  useGetSchedulingRuleQuery,
  useSetSchedulingRuleMutation,
  useSetSchedulingRuleEnabledStateMutation,
  useGetAvailableSlotsQuery,
} = providersApi;
