import React, { FC, useMemo, useState } from "react";
import { Theme, useTheme } from "@mui/material";

import {
  useAssignFormsMutation,
  useDeviceNotificationMutation,
  useNotificationMutation,
  useSaveFormAssignmentMutation,
} from "../../../../../global/services/document-manager/formAssignmentsApi";

import {
  selectInOfficePatientSelectedForms,
  selectInOfficePatientSendTo,
  selectInOfficePackageName,
  selectInOfficePatientAppointmentId,
  selectFamilyMembersWithForms,
  selectFamilyMembersWithoutForms,
  selectSubmitFormsData,
  selectWhereFormAssignerModalOpened,
  selectInOfficePatientSubmittedForms,
  selectIsFamilyMembersPackageNameValid,
  updateIsPatientSelectorModalVisible,
  updateInOfficeIsFormAssignerModalVisible,
  IRequestAssignForms,
} from "./inOfficeModalSlice";
import { updatePageIndex } from "../inOfficePatientsSlice";
import { updateSnackbar } from "../../../../authentication/globalMessagesSlice";

import { TForms } from "../../pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import { AppDispatch } from "../../../../../global/store";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { ModalBodyView } from "../../common/ModalBodyView";
import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { InOfficePatientSection } from "./InOfficePatientSection";
import { FamilyMemberTabs } from "./FamilyMemberTabs";
import { ConfirmFamilyMembersWithoutFormsModal } from "./ConfirmFamilyMembersWithoutFormsModal";
import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";
import { TDeviceNotification } from "../../../../../global/types/notification/TDeviceFormAssignmentNotificationsRequest.type";
import { EFormAssignButtons } from "./types/EFormAssignButtons";
import { SecondaryText } from "../../../../../global/components/SecondaryText/SecondaryText";
import { getShowCustomPackageName } from "../../common/getShowCustomPackageName";
import { selectAvailableOptionsSendTo } from "../../common/patientSelectorModal/patientSelectorSlice";
import { IModalBodyProps } from "./types/IModalBodyProps";
import { useGetFeatureFlag } from "../../../../../global/hooks/useGetFeatureFlag";
import { EFeatureFlags } from "../../../../../global/enums/EFeatureFlags";

const snackbarMessagesMapper = {
  [EFormAssignButtons.AddPatient]: "Form assigned",
  [EFormAssignButtons.AssignIncompleteFormsToDevice]: "Device assigned",
  [EFormAssignButtons.AddFormOrPackage]: "Forms added",
};

const ModalBody: FC<IModalBodyProps> = ({
  patientTabList,
  patient,
  setVisibility,
  patientsListRefetch,
}): JSX.Element => {
  const theme: Theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isFormAssigning, setIsFormAssigning] = useState<boolean>(false);

  const [assignForms] = useAssignFormsMutation();
  const [sendNotification] = useNotificationMutation();
  const [sendDeviceNotification] = useDeviceNotificationMutation();
  const [saveAssignedForms] = useSaveFormAssignmentMutation();

  const { isEnabled: isFamilyMembersEnabled } = useGetFeatureFlag(
    EFeatureFlags.FamilyMembersEnabled
  );

  const selectedForms: TForms = useAppSelector(
    selectInOfficePatientSelectedForms(patient?.id)
  );
  const submittedForms: TForms = useAppSelector(
    selectInOfficePatientSubmittedForms(patient?.id)
  );
  const packageName: string = useAppSelector(
    selectInOfficePackageName(patient?.id)
  );
  const sendTo: EFormAssignmentNotificationType = useAppSelector(
    selectInOfficePatientSendTo(patient?.id)
  );
  const appointmentId: string = useAppSelector(
    selectInOfficePatientAppointmentId(patient?.id)
  );
  const familyMembersWithForms: IPatientFamilyMember[] = useAppSelector(
    selectFamilyMembersWithForms({ patientTabList })
  );
  const familyMembersWithoutForms: IPatientFamilyMember[] = useAppSelector(
    selectFamilyMembersWithoutForms({ patientTabList })
  );
  const submitData: IRequestAssignForms[] = useAppSelector(
    selectSubmitFormsData(patientTabList.map((tab) => tab.id))
  );
  const whereFormAssignerModalOpened: EFormAssignButtons = useAppSelector(
    selectWhereFormAssignerModalOpened
  );
  const isValidFamilyMembersPackageName: boolean = useAppSelector(
    selectIsFamilyMembersPackageNameValid({
      patientTabList,
      mainPatientId: patient?.id,
    })
  );
  const availableSendToOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);

  const showCustomPackageName: boolean = useMemo(() => {
    return getShowCustomPackageName(selectedForms, submittedForms);
  }, [selectedForms, submittedForms]);

  const handleCancel = (): void => {
    setVisibility(false);
  };

  const onCloseConfirmModal = (): void => {
    setConfirmModalOpen(false);
  };

  const showSnackbar = (message: string): void => {
    dispatch(updateSnackbar({ status: true, message }));
  };

  const closeModals = (): void => {
    dispatch(updateIsPatientSelectorModalVisible(false));
    dispatch(updateInOfficeIsFormAssignerModalVisible(false));
    setIsFormAssigning(false);
  };

  const sendDeviceNotifications = async (requestData: {
    [key: string]: TDeviceNotification[];
  }): Promise<void> => {
    const deviceNotificationsRequests: [string, TDeviceNotification[]][] =
      Object.entries(requestData);

    if (deviceNotificationsRequests.length) {
      try {
        for (const notificationPayload of deviceNotificationsRequests) {
          await sendDeviceNotification({
            deviceId: notificationPayload[0],
            patientId: submitData[0]?.patientId,
            payload: notificationPayload[1],
          });
        }
      } catch (e) {
        closeModals();
      }
    }
  };

  const assignNewForms = async (data): Promise<{ id: string }> => {
    return await assignForms(data).unwrap();
  };

  const updateAssignedForms = async (data): Promise<{ id: string }> => {
    return await saveAssignedForms(data).unwrap();
  };

  const submitForms = async (): Promise<void> => {
    const deviceRequestPayloads = {};
    setIsFormAssigning(true);
    for (const familyMemberData of submitData) {
      const commonPayload = {
        packageName: familyMemberData.packageName || "Custom Package",
        patientId: familyMemberData.patientId,
        forms: familyMemberData.forms,
        appointmentId: familyMemberData.appointmentId,
      };
      const requestInfo =
        familyMemberData.haveAssignedForms || familyMemberData.formAssignmentId
          ? {
              // edit mode
              callback: updateAssignedForms,
              payload: {
                ...commonPayload,
                formAssignmentId: familyMemberData.formAssignmentId,
              },
            }
          : {
              // create mode
              callback: assignNewForms,
              payload: {
                ...commonPayload,
                pendingPatientCheckIn: familyMemberData.pendingPatientCheckIn,
              },
            };

      try {
        const response = await requestInfo.callback(requestInfo.payload);
        showSnackbar(snackbarMessagesMapper[whereFormAssignerModalOpened]);

        if (
          familyMemberData.sendTo !== EFormAssignmentNotificationType.Device
        ) {
          await sendNotification({
            patientId: familyMemberData.patientId,
            formAssignmentId: response.id,
            type: familyMemberData.sendTo,
          }).unwrap();
        } else {
          const payload: TDeviceNotification = {
            patientId: familyMemberData.patientId,
            formAssignmentId: response.id,
          };
          const payloadByDeviceId =
            deviceRequestPayloads[familyMemberData.deviceId];

          deviceRequestPayloads[familyMemberData.deviceId] = payloadByDeviceId
            ? [...payloadByDeviceId, payload]
            : [payload];
        }
      } catch (e) {
        closeModals();
      }
    }

    await sendDeviceNotifications(deviceRequestPayloads);
    dispatch(updatePageIndex(1));
    patientsListRefetch();
    closeModals();
  };

  const handleSend = async (): Promise<void> => {
    const isFamilyMembersWithoutFormsExist: boolean =
      familyMembersWithoutForms.length > 0 && isFamilyMembersEnabled;

    if (isFamilyMembersWithoutFormsExist) {
      setConfirmModalOpen(true);
      return;
    }

    await submitForms();
  };

  const isSendDisabled: boolean = useMemo((): boolean => {
    if (!availableSendToOptions?.length) return true;
    if (!isValidFamilyMembersPackageName) return true;
    return !selectedForms?.length;
  }, [
    selectedForms,
    packageName,
    showCustomPackageName,
    isValidFamilyMembersPackageName,
    availableSendToOptions,
  ]);

  if (isFormAssigning) return <ImageContainer marginTop="90px" />;

  return (
    <ModalBodyView
      patient={patient}
      onClick={handleSend}
      onCancel={handleCancel}
      btnColor={theme.palette.text.secondary}
      disabled={isSendDisabled}
    >
      <SecondaryText pb={2}>
        Attach and send forms to your checked-in patients and family members
      </SecondaryText>
      {patientTabList?.length > 1 && isFamilyMembersEnabled ? (
        <FamilyMemberTabs patients={patientTabList} dispatch={dispatch} />
      ) : (
        <InOfficePatientSection
          showCustomPackageName={showCustomPackageName}
          storedPackageName={packageName}
          sendTo={sendTo}
          appointmentId={appointmentId}
          patientId={patient?.id}
        />
      )}
      <ConfirmFamilyMembersWithoutFormsModal
        isOpen={isConfirmModalOpen}
        onClose={onCloseConfirmModal}
        familyMembersWithForms={familyMembersWithForms}
        familyMembersWithoutForms={familyMembersWithoutForms}
        onPrimaryButtonClick={submitForms}
      />
    </ModalBodyView>
  );
};

export const InOfficeModalBody = React.memo(ModalBody, areEqualProps);
