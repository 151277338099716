import { PathsMap, paths } from "./routesPaths";

export enum TabTitle {
  Campaigns = "Campaigns",
  CreateMassText = "Create Mass Text",
  Empty = "",
  EmailCampaigns = "Email Campaigns",
  HelpResources = "Help Resources",
  Home = "Home",
  Forms = "Forms",
  PatientFinder = "Patient Finder",
  PracticeSettings = "Practice Settings",
  Profile = "Profile",
  TextMessages = "Text Messages",
  TextTemplates = "Text Templates",
  Users = "Users",
}

export const pathTitleMap: PathsMap = {
  [paths.acceptedInsurance]: TabTitle.PracticeSettings,
  [paths.addTreatment]: TabTitle.PracticeSettings,
  [paths.composePatientEmail]: TabTitle.PatientFinder,
  [paths.dashboard]: TabTitle.Home,
  [paths.documentManager]: TabTitle.Forms,
  [paths.editAdminUser]: TabTitle.Users,
  [paths.editProvider]: TabTitle.PracticeSettings,
  [paths.editTreatment]: TabTitle.PracticeSettings,
  [paths.editUnlinkedPmsUser]: TabTitle.Users,
  [paths.emailDetails]: TabTitle.PatientFinder,
  [paths.fillApptManualFlow]: TabTitle.Home,
  [paths.fillAppointment]: TabTitle.Home,
  [paths.formEditor]: TabTitle.PracticeSettings,
  [paths.formPreview]: TabTitle.PracticeSettings,
  [paths.formsLibrary]: TabTitle.PracticeSettings,
  [paths.helpResources]: TabTitle.HelpResources,
  [paths.inOfficeDeviceSettings]: TabTitle.PracticeSettings,
  [paths.inOfficeFormPreview]: TabTitle.Empty,
  [paths.inviteNewUser]: TabTitle.Users,
  [paths.loadingScreen]: TabTitle.Empty,
  [paths.massTextMessagesCreate]: TabTitle.CreateMassText,
  [paths.multiLocation]: TabTitle.Empty,
  [paths.onlineScheduling]: TabTitle.PracticeSettings,
  [paths.operatories]: TabTitle.PracticeSettings,
  [paths.outstandingTaskFormPreview]: TabTitle.Empty,
  [paths.pageNotFound]: TabTitle.Empty,
  [paths.patientFinder]: TabTitle.PatientFinder,
  [paths.patientProfile]: TabTitle.PatientFinder,
  [paths.patientRemindersTextTemplatesComposeEdit]: TabTitle.TextTemplates,
  [paths.pendingFormPreview]: TabTitle.Empty,
  [paths.viewImagePreview]: TabTitle.Empty,
  [paths.practiceInformation]: TabTitle.PracticeSettings,
  [paths.preferences]: TabTitle.PatientFinder,
  [paths.profile]: TabTitle.Profile,
  [paths.providerSignature]: TabTitle.Empty,
  [paths.root]: TabTitle.Empty,
  [paths.schedulingWidget]: TabTitle.PracticeSettings,
  [paths.switchLocation]: TabTitle.Empty,
  [paths.templateCreator]: TabTitle.Campaigns,
  [paths.textMessagesPatientComm]: TabTitle.TextMessages,
  [paths.textTemplatesComposeEdit]: TabTitle.TextTemplates,
  [paths.textTemplatesCompose]: TabTitle.TextTemplates,
  [paths.textTemplates]: TabTitle.TextTemplates,
  [paths.treatmentDetails]: TabTitle.PracticeSettings,
  [paths.users]: TabTitle.Users,
  [paths.practiceSettings]: TabTitle.PracticeSettings,
  [paths.appointmentDetails]: TabTitle.PatientFinder,
  [paths.editPmsUser]: TabTitle.Users,
  [paths.practicePatientCommunicationSetting]: TabTitle.PracticeSettings,
  [paths.practicePatientCommunication]: TabTitle.PracticeSettings,
  [paths.patientReminders]: TabTitle.PracticeSettings,
  [paths.emailCampaigns]: TabTitle.EmailCampaigns,
  [paths.unauthorized]: TabTitle.Empty,
};
