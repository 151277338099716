import { Box, styled, Typography } from "@mui/material";

export const SSubtitle = styled(Typography)({
  fontSize: "1rem",
});
export const SRowWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: 48,
  paddingRight: 28,
  paddingTop: 16,
  paddingBottom: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  ":last-child": {
    borderBottom: "none",
  },
}));
