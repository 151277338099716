import { IAssignedForm } from "../../../../../global/services/settings/practiceSettings/formLibrary/formEditor/AssignedForm.interface";
import { isIncompleteForm } from "../../../isIncompleteForm";

export const shouldShowPackageName = (
  forms: IAssignedForm[],
  onlyIncompleteForms = false
) => {
  if (!forms?.length) return false;
  const uniquePackagesIds = new Set();
  let singleFormsCount = 0;
  const comparableForms = onlyIncompleteForms
    ? forms.filter((form) => isIncompleteForm(form))
    : forms;
  comparableForms.forEach((form) => {
    const { packageId } = form;
    const isPackage = Boolean(packageId);
    if (isPackage) {
      uniquePackagesIds.add(packageId);
    } else {
      singleFormsCount++;
    }
  });

  const { size: packagesCount } = uniquePackagesIds;
  return packagesCount + singleFormsCount > 1;
};
