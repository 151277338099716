import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../global/store";

import { IChartOptions } from "../../global/types/charts/IChartOptions.interface";
import { IPaymentRequest } from "../../global/types/payments/IPaymentRequest.interface";
import * as types from "../../global/mockData/chartData";

interface paymentSliceTypes {
  chartType: IChartOptions;
  paymentRequestFormData?: IPaymentRequest;
}
const initialState: paymentSliceTypes = {
  chartType: types.monthly,
  paymentRequestFormData: null,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    updateChartOptions: (state, action: PayloadAction<string>) => {
      state.chartType = types[action.payload];
    },
    updatePaymentRequestForm: (
      state,
      action: PayloadAction<IPaymentRequest>
    ) => {
      state.paymentRequestFormData = action.payload;
    },
  },
});

export const { updateChartOptions, updatePaymentRequestForm } =
  paymentsSlice.actions;

export const selectAllChartOptions = (state: RootState): IChartOptions =>
  state.paymentsState.chartType;

export default paymentsSlice.reducer;
