import { TabTitle, pathTitleMap } from "./pathTitleMap";

export const getTitleByPath = (url: string): string => {
  try {
    const fullUrl = url.startsWith("http") ? url : window.location.origin + url;
    const urlPath = new URL(fullUrl).pathname;
    const title = pathTitleMap[urlPath];
    if (title) return title;

    const pathTitleMapKeys = Object.keys(pathTitleMap);
    const urlPieces = urlPath.split("/").filter(Boolean);
    for (const path of pathTitleMapKeys) {
      const pathPieces = path.split("/").filter(Boolean);
      if (pathPieces.length !== urlPieces.length) {
        continue;
      }

      const isMatch = pathPieces.every((pathPiece, index) => {
        const isParameterSection = pathPiece.startsWith(":");
        const arePiecesEqual = pathPiece === urlPieces[index];
        return arePiecesEqual || isParameterSection;
      });

      if (isMatch) return pathTitleMap[path]; // return title
    }
  } catch (err) {
    return TabTitle.Empty;
  }
  return TabTitle.Empty;
};
