import { ISelectedPackageForm } from "../../types/documentManager/ISelectedPackageForm.interface";

export const checkIsAllFormsFromOnePackage = (
  selectedForms: ISelectedPackageForm[]
): boolean => {
  const form = selectedForms?.find(
    (form: ISelectedPackageForm) => form?.packageId
  );
  const packageId: string = form?.packageId;

  return selectedForms?.every(
    (form: ISelectedPackageForm) =>
      form.packageId && form.packageId === packageId
  );
};
