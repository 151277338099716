import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronDown from "../../../assets/sidenav/chevron_down.svg";

interface BackNavHeaderButtonPropTypes {
  buttonText: string;
  clickHandler: any;
}

const RotatedImg = styled("img")(() => ({
  transform: "rotate(90deg)",
}));

const BackNavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  "&:hover": { backgroundColor: "transparent" },
  padding: 0,
}));

export const BackNavHeaderButton: React.FC<BackNavHeaderButtonPropTypes> = ({
  buttonText,
  clickHandler,
}: BackNavHeaderButtonPropTypes) => {
  return (
    <Grid item xs={12} p={0}>
      <BackNavButton
        variant="text"
        startIcon={<RotatedImg src={ChevronDown} />}
        onClick={clickHandler}
        data-testid="BackNavHeaderButton"
        disableRipple
      >
        <Typography data-testid="BackNavHeaderButtonTitle" variant="body2">
          {buttonText}
        </Typography>
      </BackNavButton>
    </Grid>
  );
};
