import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";

export const getPatientTabList = (
  patient,
  patientFamilyMembersData: IPatientFamilyMember[]
): IPatientFamilyMember[] => {
  const selectedPatient: IPatientFamilyMember = {
    id: patient.id,
    firstName: patient.firstName,
    headOfHousehold: patient.headOfHousehold,
    lastInitial: patient.lastName?.[0].toUpperCase(),
  };

  if (patientFamilyMembersData) {
    return [selectedPatient, ...patientFamilyMembersData];
  }
  return [];
};
