import { IOfficeTask } from "../../../../../../../global/types/documentManager/InOfficePatient";

export const getTasksByFormId = (officeTasks: IOfficeTask[]) => {
  const officeTasksByFormId: Record<string, IOfficeTask[]> = {};
  officeTasks.forEach((task) => {
    const { formId } = task;
    if (officeTasksByFormId[formId]) officeTasksByFormId[formId].push(task);
    else officeTasksByFormId[formId] = [task];
  });
  return officeTasksByFormId;
};
