import { IGetPracticeSettingsRequest } from "./practice-settings/GetPracticeSettingsRequest.interface";
import { IDocumentManagerPracticeSettings } from "./practice-settings/DocumentManagerPracticeSettings.interface";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";

const GET_CHANNEL_SETTINGS_TAG = "CHANNEL_SETTINGS";

export const documentManagerApi = createApi({
  reducerPath: "documentManagerApi",
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/`
  ),
  tagTypes: [GET_CHANNEL_SETTINGS_TAG],
  endpoints: (builder) => ({
    getChannelSettings: builder.query<
      IDocumentManagerPracticeSettings,
      IGetPracticeSettingsRequest
    >({
      query: ({ practiceId }) => `practices/${practiceId}/settings`,
      providesTags: [GET_CHANNEL_SETTINGS_TAG],
      keepUnusedDataFor: 3600,
    }),
    changeAutomaticFilingBeforeAppointment: builder.mutation<
      IDocumentManagerPracticeSettings,
      any
    >({
      query({ practiceId, automaticFilingBeforeAppointment }) {
        return {
          url: `practices/${practiceId}/settings`,
          method: "PUT",
          body: {
            automaticFilingBeforeAppointment,
          },
        };
      },
      invalidatesTags: [GET_CHANNEL_SETTINGS_TAG],
    }),
  }),
});

export const {
  useGetChannelSettingsQuery,
  useChangeAutomaticFilingBeforeAppointmentMutation,
} = documentManagerApi;
