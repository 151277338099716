import React, { FC, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";

import {
  selectInOfficePatientSelectedForms,
  selectInOfficeIsPackageNameVisible,
  selectInOfficePatientSubmittedForms,
  updateIsPackageNameVisible,
  updateInOfficePackageName,
  removeInOfficeSelectedForm,
  removeInOfficeSelectedPackage,
} from "./inOfficeModalSlice";

import { SecondaryText } from "../../../../../global/components/SecondaryText/SecondaryText";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { getFormsAndPackages } from "../../common/formsTable/getFormsAndPackages";
import { SBoxWithScroll } from "../../common/formsTable/styles";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IFormPackage } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { ISelectedForm } from "../../../../../global/types/documentManager/ISelectedForm.interface";
import { selectPackageOptions } from "../../../documentManagerSlice";
import { shouldShowPackageName } from "../../common/formsTable/shouldShowPackageName";
import { TPackagesById } from "./types/TPackagesById";
import { IFormsBoxProps } from "./types/IFormsBoxProps";

const FormsBox: FC<IFormsBoxProps> = ({ patientId }): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const [packagesById, setPackagesById] = useState<TPackagesById>({});

  const packagesOptions: IFormPackage[] = useAppSelector(selectPackageOptions);
  const forms: ISelectedForm[] = useAppSelector(
    selectInOfficePatientSelectedForms(patientId)
  );
  const isPackageNameVisible: boolean = useAppSelector(
    selectInOfficeIsPackageNameVisible(patientId)
  );
  const submittedForms: ISelectedForm[] = useAppSelector(
    selectInOfficePatientSubmittedForms(patientId)
  );

  useEffect((): void => {
    const isVisible: boolean = shouldShowPackageName(forms);

    if (isVisible !== isPackageNameVisible) {
      dispatch(
        updateIsPackageNameVisible({
          isPackageNameVisible: isVisible,
          patientId,
        })
      );
    }
  }, [forms]);

  useEffect((): void => {
    const newPackagesByIdHash: TPackagesById = {};
    packagesOptions.forEach(
      (formPackage: IFormPackage) =>
        (newPackagesByIdHash[formPackage.id] = formPackage)
    );

    setPackagesById(newPackagesByIdHash);
  }, [packagesOptions]);

  const handleFormRemoval = (form: ISelectedForm): void => {
    const isRemovingSecondForm: boolean = forms.length === 2;
    if (isRemovingSecondForm && !submittedForms?.length) {
      dispatch(updateInOfficePackageName({ packageName: "", patientId }));
    }
    dispatch(
      removeInOfficeSelectedForm({
        patientId,
        formId: form.formId,
        order: form.order,
      })
    );
  };

  const handlePackageRemoval = (packageId: string): void => {
    dispatch(removeInOfficeSelectedPackage({ patientId, packageId }));
  };

  const formsAndPackages: JSX.Element[] = useMemo(
    () =>
      getFormsAndPackages({
        forms: forms || [],
        packagesById,
        onRemoveForm: handleFormRemoval,
        onRemovePackage: handlePackageRemoval,
      }),
    [packagesById, forms]
  );

  const formsTitle = `${forms?.length ? "Incomplete" : ""} Forms`;

  return (
    <>
      <Grid container pt={2} pb={1}>
        <Grid item xs={8}>
          <SecondaryText>{formsTitle}</SecondaryText>
        </Grid>
        <Grid item xs={4}>
          <SecondaryText>Task</SecondaryText>
        </Grid>
      </Grid>
      <SBoxWithScroll>{formsAndPackages}</SBoxWithScroll>
    </>
  );
};

export const InOfficeFormsBox = React.memo(FormsBox, areEqualProps);
