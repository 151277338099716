import { Control, Controller } from "react-hook-form";
import React from "react";
import NumberFormat from "react-number-format";
import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";

const PhoneText = styled(InputBase)(() => ({
  padding: "0px 10px 0px 18px",
  width: "100%",
}));

type ControlledPhoneFieldProps = {
  control: Control;
  inputName: string;
  placeholder: string;
  handleSubmit: () => void;
  fontSize?: string;
};

export const ControlledPhoneField = ({
  control,
  inputName,
  placeholder,
  handleSubmit,
  fontSize,
}: ControlledPhoneFieldProps) => {
  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={""}
      render={({ field: { onChange, value } }) => (
        <NumberFormat
          style={fontSize ? { fontSize: fontSize } : {}}
          format="(###) ###-####"
          value={value}
          customInput={PhoneText}
          onValueChange={(phoneText) => {
            const { value } = phoneText;
            onChange(value);
          }}
          placeholder={`Type ${placeholder}`}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        />
      )}
    />
  );
};
