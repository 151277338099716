import { INTERNET_INTERRUPTION } from "./constants";

export const parseResponse = (
  response: PromiseSettledResult<any>[],
  files: File[]
) => {
  return response.map((chunk, index) => {
    if (chunk?.status === "fulfilled") {
      if (chunk.value?.data) {
        return {
          formId: chunk.value.data.id,
          name: chunk.value.data.name,
          fileName: files[index]?.name,
        };
      } else if (chunk.value?.error) {
        return {
          isError: true,
          status: chunk.value.error.status,
          message:
            chunk.value.error.status === INTERNET_INTERRUPTION
              ? chunk.value.error?.error
              : chunk.value.error.data?.Message,
          fileName: files[index]?.name,
        };
      }
    } else {
      return {
        isError: true,
        fileName: files[index]?.name,
      };
    }
  });
};
