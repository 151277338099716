import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";

export const TASK_STATUS_WITHOUT_OPTIONS = [
  EOfficeTaskStatus.FiledToPms,
  EOfficeTaskStatus.FilingToPms,
];

export const shouldHideOptions = (task: EOfficeTaskStatus) => {
  return TASK_STATUS_WITHOUT_OPTIONS.includes(task);
};
