import React from "react";
import { Box } from "@mui/material";
import { SSubtitle } from "./styles";
import { TCategoryHeader } from "./types/TCategoryHeader";

export const CategoryHeader = ({ background, title }: TCategoryHeader) => {
  return (
    <Box p={1} pl={3} sx={{ background }}>
      <SSubtitle>{title}</SSubtitle>
    </Box>
  );
};
