import { Typography } from "@mui/material";
import React from "react";

type TPatient = {
  firstName: string;
  lastName: string;
  fontSize?: string;
};

export const PatientName = ({
  firstName,
  lastName,
  fontSize = "1.5rem",
}: TPatient) => (
  <Typography
    fontWeight="bold"
    sx={{ fontSize: `${fontSize} !important`, wordBreak: "break-word" }}
  >
    {firstName} {lastName}
  </Typography>
);
