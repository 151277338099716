import { IFormAssignment } from "../../../../../../global/types/documentManager/InOfficePatient";

export const getFormSectionTitle = (formAssignment: IFormAssignment) => {
  const forms = formAssignment?.forms || [];
  const customPackageName = formAssignment?.packageName;
  if (customPackageName) return customPackageName;

  if (forms.length === 1) return forms[0].name;

  return forms[0]?.packageName ?? "Summary";
};
