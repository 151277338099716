import { createApi } from "@reduxjs/toolkit/query/react";
import { IGetPracticeInsurances } from "../../../../../types/settings/onlineScheduling/insurances/IGetPracticeInsurances";
import { IGlobalInsurance } from "../../../../../types/settings/onlineScheduling/insurances/IGlobalInsurance";
import { IPracticeInsuranceParams } from "../../../../../types/settings/onlineScheduling/insurances/IPracticeInsuracesParams";
import { IPracticeInsurancesList } from "../../../../../types/settings/onlineScheduling/insurances/IPracticeInsurancesList";
import { queryStringBuilder } from "../../../../../helperFunctions/api/queryStringBuilder";
import { baseQueryWithReAuth } from "../../../../../helperFunctions/api/baseQueryWithReauth";

export const acceptedInsuranceApi = createApi({
  reducerPath: "acceptedInsuranceApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  tagTypes: ["Insurances"],
  endpoints: (builder) => ({
    getPracticeInsurances: builder.query<
      IPracticeInsurancesList,
      IPracticeInsuranceParams
    >({
      query: (formData) => {
        const params = queryStringBuilder(formData);
        return `/PracticeInsurances?${params.toString()}`;
      },
      providesTags: ["Insurances"],
    }),
    setInsurancePopularity: builder.mutation<
      any,
      { id: string; isPopular: boolean }
    >({
      query: (status) => {
        return {
          url: `PracticeInsurancePopularities/${status.id}`,
          method: "PUT",
          body: {
            isPopular: status.isPopular,
          },
        };
      },
      invalidatesTags: ["Insurances"],
    }),
    deleteInsurance: builder.mutation<unknown, string>({
      query: (id) => {
        return {
          url: `/PracticeInsurances/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Insurances"],
    }),
    enabledInsurance: builder.mutation<
      { isEnabled: boolean },
      { id: string; isEnabled: boolean }
    >({
      query: (status) => {
        return {
          url: `PracticeInsuranceEnablements/${status.id}`,
          method: "PUT",
          body: {
            isEnabled: status.isEnabled,
          },
        };
      },
      invalidatesTags: ["Insurances"],
    }),
    setPracticeInsurances: builder.mutation<
      IGetPracticeInsurances,
      IGetPracticeInsurances
    >({
      query: (formData) => {
        return {
          url: "/PracticeInsurances",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Insurances"],
    }),
    updatePracticeInsurances: builder.mutation<
      IGetPracticeInsurances,
      { id: string; formData: IGetPracticeInsurances }
    >({
      query: (data) => {
        return {
          url: `/PracticeInsurances/${data.id}`,
          method: "PUT",
          body: data.formData,
        };
      },
      invalidatesTags: ["Insurances"],
    }),
    getGlobalInsurances: builder.query<IGlobalInsurance[], null>({
      query: () => {
        return `/GlobalInsurances`;
      },
    }),
    setImportedList: builder.mutation<
      { id: string; import: boolean }[],
      { id: string; import: boolean }[]
    >({
      query: (data) => {
        return {
          url: `/InsuranceImports/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Insurances"],
    }),
  }),
});

export const {
  useGetPracticeInsurancesQuery,
  useSetInsurancePopularityMutation,
  useDeleteInsuranceMutation,
  useEnabledInsuranceMutation,
  useSetPracticeInsurancesMutation,
  useUpdatePracticeInsurancesMutation,
  useGetGlobalInsurancesQuery,
  useSetImportedListMutation,
} = acceptedInsuranceApi;
