import React, { useEffect, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { debounce, throttle } from "lodash";
import { VirtualizedTable as DefaultVirtualizedTable } from "./VirtualizedTable";
import { TInfiniteScroll } from "./types/TInfiniteScroll";
import { SCustomScroll } from "./styles";

export const InfiniteScroll = ({
  renderItem,
  totalCount,
  loadMore,
  loadedItems,
  estimatedItemSize = 50,
  overscan = 5,
  disableCustomScroll = false,
  hideEndOfResultsText = false,
  VirtualizedTable = DefaultVirtualizedTable,
}: TInfiniteScroll) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    setIsAtBottom(false);
  }, [loadedItems]);

  const parentRef = React.useRef();
  const rowVirtualizer = useVirtualizer({
    overscan,
    count: loadedItems,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimatedItemSize,
  });

  const handleScroll = (e) => {
    debounce(() => {
      const {
        target: { scrollTop, scrollHeight, offsetHeight },
      } = e;

      const hasReachedBottom = scrollHeight - offsetHeight - scrollTop < 150;
      if (!hasReachedBottom) return;
      if (isAtBottom) return;

      setIsAtBottom(true);
      const loadMoreOnceIfNeeded = throttle(() => {
        const hasMoreElements = totalCount > loadedItems;
        hasMoreElements && loadMore?.();
      }, 500);
      loadMoreOnceIfNeeded();
    }, 100)();
  };

  return (
    <SCustomScroll
      id="infinite-scroll"
      ref={parentRef}
      onScroll={handleScroll}
      hidden={disableCustomScroll}
    >
      <VirtualizedTable
        renderItem={renderItem}
        rowVirtualizer={rowVirtualizer}
        totalCount={totalCount}
        hideEndOfResultsText={hideEndOfResultsText}
      />
    </SCustomScroll>
  );
};
