const rule = /&(amp|lt|gt|quot|apos);/g;
const mapper = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": `"`,
  "&apos;": `'`,
};

export const replaceSpecialHTMLSymbols = (
  word: string,
  regex: RegExp = rule
): string => {
  if (!word || typeof word !== "string") {
    return word;
  }

  return word.replace(regex, (symbol: string): string => {
    return mapper[symbol];
  });
};
