import React, { ReactNode } from "react";
import { Grid, IconButton, Typography, Dialog, Paper } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/material/styles";

import closeButton from "../../../../assets/close_button.svg";

interface BaseModalPropTypes {
  children?: ReactNode;
  open: boolean;
  setVisibility?: (isVisible: boolean) => void;
  clickHandler?: () => void;
  headerText?: string;
  subHeaderText?: string | ReactNode;
  toggleCloseButton?: boolean;
  fullWidth?: boolean;
  titleTooltip?: string;
  sxDiag?: SxProps<Theme>;
  sxBody?: SxProps<Theme>;
}

interface SBodyContainerProps {
  isdisabled?: boolean;
}
const StyledPaper = styled(Paper)(() => ({
  borderRadius: "8px",
  padding: "16px",
}));

const SBodyContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isdisabled",
})<SBodyContainerProps>(({ isdisabled, theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: isdisabled ? "0px" : "8px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: isdisabled ? "0px" : "16px",
  },
}));

interface SCloseButtonRowProps {
  hasHeaderText: boolean;
}

const SCloseButtonRow = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "hasHeaderText",
})<SCloseButtonRowProps>(({ hasHeaderText }) => ({
  justifyContent: "flex-end",
  alignItems: "center",
  position: hasHeaderText ? "unset" : "absolute",
  right: hasHeaderText ? 0 : 20,
}));

const BaseModal: React.FC<BaseModalPropTypes> = ({
  children,
  open,
  setVisibility,
  headerText,
  subHeaderText,
  toggleCloseButton = true,
  clickHandler,
  fullWidth = false,
  sxDiag = { maxWidth: fullWidth ? "100%" : "600px", px: 2 },
  sxBody = null,
  titleTooltip,
}: BaseModalPropTypes) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    clickHandler ? clickHandler() : setVisibility(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperComponent={StyledPaper}
      PaperProps={{ sx: sxDiag }}
      data-testid="baseModal"
    >
      {toggleCloseButton && (
        <SCloseButtonRow container item hasHeaderText={Boolean(headerText)}>
          <IconButton
            onClick={clickHandler ? clickHandler : () => setVisibility(false)}
            disableRipple
          >
            <img src={closeButton} />
          </IconButton>
        </SCloseButtonRow>
      )}
      {headerText && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          data-testid="baseModal/header"
        >
          <Typography title={titleTooltip} variant="h3" align="center">
            {headerText}
          </Typography>
        </Grid>
      )}
      {subHeaderText && (
        <SBodyContainer isdisabled={false}>
          <Typography variant="body1" align="left">
            {subHeaderText}
          </Typography>
        </SBodyContainer>
      )}
      <SBodyContainer id="body" sx={sxBody} isdisabled={!toggleCloseButton}>
        {children}
      </SBodyContainer>
    </Dialog>
  );
};

export default BaseModal;
