import React, { ReactNode } from "react";
import { Grid, styled, SxProps, Theme } from "@mui/material";

type TCardContainerStyle = {
  isSelected: boolean;
};

const SGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<TCardContainerStyle>(({ theme, isSelected }) => ({
  border: `1px solid ${
    isSelected ? theme.palette.accent.main : theme.palette.text.disabled
  }`,
  borderRadius: 16,
  cursor: "pointer",
  background: isSelected
    ? theme.palette.accent.background
    : theme.palette.background.default,
}));

type TCardContainer = {
  children: ReactNode;
  isSelected: boolean;
  onClick: (event?) => void;
  sx?: SxProps<Theme>;
  stopPropagation?: boolean;
};

const CardContainer = ({
  children,
  isSelected,
  onClick,
  sx = {},
  stopPropagation = true,
}: TCardContainer) => {
  const handleClick = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <SGrid
      data-testid="appointment-card-container"
      container
      sx={sx}
      onClick={handleClick}
      isSelected={isSelected}
    >
      {children}
    </SGrid>
  );
};

export default CardContainer;
