import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../global/store";

import { IFormTemplateQueryParams } from "../../../../global/types/settings/templates/IFormTemplateQueryParams";
import { EOrderBy } from "../../../../global/enums/EOrderBy";

interface ITemplateLibrarySlice {
  isShowFormTemplatesBanner: boolean;
  isShowExportErrorModal: boolean;
  formsQueryParams: IFormTemplateQueryParams;
}

const initialState: ITemplateLibrarySlice = {
  isShowFormTemplatesBanner: false,
  isShowExportErrorModal: false,
  formsQueryParams: {
    page: 1,
    pageSize: 10,
    search: "",
    sorting: [{ field: "name", sort: EOrderBy.asc }],
    // filters
    languages: [],
    categories: [],
  },
};

// do not add this all slice to persist blacklist. Banner shouldn't disappear after refresh
export const templateLibrarySlice = createSlice({
  name: "templateLibrary",
  initialState,
  reducers: {
    updateIsShowFormTemplatesBanner: (
      state: ITemplateLibrarySlice,
      action: PayloadAction<boolean>
    ): void => {
      state.isShowFormTemplatesBanner = action.payload;
    },
    updateIsShowExportErrorModal: (
      state: ITemplateLibrarySlice,
      action: PayloadAction<boolean>
    ): void => {
      state.isShowExportErrorModal = action.payload;
    },
    updateFormsQueryParams: (
      state: ITemplateLibrarySlice,
      action: PayloadAction<Partial<IFormTemplateQueryParams>>
    ): void => {
      state.formsQueryParams = { ...state.formsQueryParams, ...action.payload };
    },
    setDefaultFormsQueryParams: (state: ITemplateLibrarySlice): void => {
      state.formsQueryParams = {
        page: 1,
        pageSize: 10,
        search: "",
        sorting: [{ field: "name", sort: EOrderBy.asc }],
        languages: [],
        categories: [],
      };
    },
  },
});

export const selectIsShowFormTemplatesBanner = (state: RootState): boolean => {
  return state.templateLibraryState.isShowFormTemplatesBanner;
};

export const selectIsShowExportErrorModal = (state: RootState): boolean => {
  return state.templateLibraryState.isShowExportErrorModal;
};

export const selectFormsQueryParams = (
  state: RootState
): IFormTemplateQueryParams => {
  return state.templateLibraryState.formsQueryParams;
};

export const {
  updateIsShowFormTemplatesBanner,
  updateIsShowExportErrorModal,
  updateFormsQueryParams,
  setDefaultFormsQueryParams,
} = templateLibrarySlice.actions;

export default templateLibrarySlice.reducer;
