import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SSlider = styled(Slider)(({ theme }) => ({
  ".MuiSlider-rail": {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  ".MuiSlider-track": {
    color: "transparent",
  },
  "& .MuiSlider-thumb": {
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
  },
}));
