import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { useEffect } from "react";
import { Action } from "redux";
import { RootState } from "../../../../../global/store";

export const useRefetchOnPubnubNotification = <T = string>(
  inOfficePatientsRefetch: () => void,
  selector: (state: RootState) => T,
  actionForDispatch: (payload: null) => Action
) => {
  const dispatch = useAppDispatch();
  const selectedId = useAppSelector(selector);

  useEffect(() => {
    inOfficePatientsRefetch();
    return () => {
      dispatch(actionForDispatch(null));
    };
  }, [selectedId]);
};
