import React from "react";
import { Box, Typography } from "@mui/material";

export const NoResultsMessage = () => (
  <Box display="flex" flexDirection="column" gap={1} pb={1}>
    <Typography color="red">No results.</Typography>
    <Typography color="red">
      Please try again with a different search item.
    </Typography>
  </Box>
);
