import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { BaseTabs } from "../../../global/components/Tabs/BaseTabs";

export const SWrapperContextMenuButton = styled(Box)(() => ({
  "& button[name=contextMenu]": {
    height: "4rem",
    width: "100%",
    justifyContent: "end",
  },
}));

export const SBaseTabs = styled(BaseTabs)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});
