import { EOfficeTaskUserAction } from "../../../../../../../../global/enums/EOfficeTaskUserAction";
import { TOption } from "../../../../../../../../global/components/ContextMenu/Option.type";
import { EOfficeTaskStatus } from "../../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { TUserActionMutation } from "../officeTasks/types/TUserActionMutation";

export const refileToPmsOption = (
  pmsName: string,
  officeTaskId: string,
  userActionMutation: TUserActionMutation,
  errorCallback: () => void,
  taskStatus: EOfficeTaskStatus
): TOption => {
  const isWaitingForPDFStatus =
    taskStatus === EOfficeTaskStatus.WaitingOnFormPDFCreation;

  const label = `${isWaitingForPDFStatus ? "Refile" : "File"} to ${pmsName}`;

  const userAction = isWaitingForPDFStatus
    ? EOfficeTaskUserAction.RefilePdfRequested
    : EOfficeTaskUserAction.RefileToPmsRequested;

  return {
    label,
    callBack: async () => {
      try {
        await userActionMutation({
          id: officeTaskId,
          userAction,
        }).unwrap();
      } catch (e) {
        errorCallback();
      }
    },
  };
};
