import { Grid, styled } from "@mui/material";
import { OfficeTaskList } from "./inOfficePatientCard/officeTaskList/OfficeTaskList";

export const SCenteredGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    marginRight: "0.4rem",
  },
}));

export const SOfficeTaskList = styled(OfficeTaskList)({
  marginLeft: 32,
});
