import React, { useMemo } from "react";
import { EFormStatus } from "../../../../../../../global/types/documentManager/EFormStatus";
import { IOfficeTask } from "../../../../../../../global/types/documentManager/InOfficePatient";
import { OfficeTasks } from "../categories/officeTasks/OfficeTasks";
import { PatientIncompleteForms } from "../categories/patientIncompleteForms/PatientIncompleteForms";
import SubmittedForms from "../categories/submittedForms/SubmittedForms";
import { EOfficeTaskStatus } from "../../../../../../../global/types/documentManager/EOfficeTaskStatus.enum";
import { getTasksByFormId } from "./getTasksByFormId";
import { TFormsByCategory } from "./TFormsByCategory";

export const FormsByCategory = ({
  formAssignmentId,
  formGroups,
  officeTasks,
  patientId,
}: TFormsByCategory) => {
  const { byCategory: formsByCategory, byResponseId: formsByResponseId } =
    formGroups;
  const tasksByFormId = useMemo(
    () => getTasksByFormId(officeTasks),
    [officeTasks]
  );

  const todoForms = formsByCategory[EFormStatus.Todo];
  const inProgressForms = formsByCategory[EFormStatus.InProgress];
  const incompleteForms = useMemo(
    () => [...todoForms, ...inProgressForms],
    [todoForms, inProgressForms]
  );
  const submittedForms = formsByCategory[EFormStatus.Submitted];
  const successfullyImportedForms = useMemo(
    () =>
      submittedForms.filter((form) => {
        const formTasks: IOfficeTask[] = tasksByFormId[form.id];
        if (!formTasks) return false;

        const task: IOfficeTask = formTasks.find(
          (task: IOfficeTask) =>
            task.responseId === form.responseId && task.formId === form.id
        );

        return task?.status === EOfficeTaskStatus.FiledToPms;
      }),
    [tasksByFormId, submittedForms]
  );

  return (
    <>
      <PatientIncompleteForms
        formAssignmentId={formAssignmentId}
        patientId={patientId}
        incompleteForms={incompleteForms}
      />
      <OfficeTasks
        formAssignmentId={formAssignmentId}
        officeTasks={officeTasks}
        patientId={patientId}
        formsByResponseId={formsByResponseId}
      />
      <SubmittedForms
        submittedForms={successfullyImportedForms}
        formAssignmentId={formAssignmentId}
        patientId={patientId}
      />
    </>
  );
};
