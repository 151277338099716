import { ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    accent: {
      background: string;
      main: string;
    };
    gray?: {
      background: string;
    };
  }
  interface PaletteOptions {
    accent: {
      background: string;
      main: string;
    };
    gray?: {
      background: string;
    };
  }
  interface MuiStyledOptions {
    shouldForwardProp: (prop: string) => boolean;
  }
}

export const LIGHT_THEME: ThemeOptions = {
  palette: {
    primary: {
      main: "#F7B500",
      dark: "#EBAC00",
      light: "#F8BC19",
      contrastText: "#000000",
    },
    secondary: {
      main: "#006FE6",
      light: "#338BEB",
      dark: "#3C4949",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    text: {
      secondary: "#595959",
      disabled: "#E5E5E5",
      primary: "#000000",
    },
    info: {
      main: "#AD36F6",
      light: "#F7EBFE",
      dark: "#7925AC",
      contrastText: "#000000",
    },
    error: {
      main: "#EB0000",
      dark: "#A40000",
      light: "#FDE6E6",
      contrastText: "#000000",
    },
    success: {
      main: "#2E8406",
      light: "#EAF3E6",
      dark: "#205C04",
      contrastText: "#000000",
    },
    divider: "#E5E5E5",
    warning: {
      main: "#EB0000",
      dark: "#A40000",
      light: "#EF3333",
      contrastText: "#FFFFFF",
    },
    accent: {
      background: "#FFF3E7",
      main: "#F7B500",
    },
    gray: {
      background: "#ECECEC",
    },
  },
  typography: {
    fontFamily: "Roboto Condensed",
    htmlFontSize: 16,
    h1: {
      fontSize: "3.3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.1rem",
      fontWeight: 700,
    },
    fontSize: 16,
    h3: {
      fontSize: "1.75rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.3rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    caption: {
      fontSize: "0.9rem",
    },
    // subtitle2: {
    //   fontSize: "1rem",
    //   fontWeight: 700,
    // },
    // subtitle1: {
    //   fontSize: "1.1rem",
    //   fontWeight: 700,
    // },
    body1: {
      fontSize: "1.125rem",
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    button: {
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "none",
    },
    overline: {
      fontSize: "1rem",
      textTransform: "none",
    },
  },
};
