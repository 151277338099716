import { IAssignedForm } from "../../global/services/settings/practiceSettings/formLibrary/formEditor/AssignedForm.interface";
import { EFormStatus } from "../../global/types/documentManager/EFormStatus";
import { IFormAssignmentForm } from "../../global/types/documentManager/InOfficePatient";

export const isIncompleteForm = (form: IFormAssignmentForm | IAssignedForm) => {
  let status: EFormStatus;

  if ("formStatus" in form) {
    status = form.formStatus;
  } else if ("status" in form) {
    status = form.status;
  }

  return status === EFormStatus.InProgress || status === EFormStatus.Todo;
};
