export function numberOfElementsInArrays(arrays: Array<unknown[]>): number {
  if (!Array.isArray(arrays)) {
    return arrays;
  }

  let count = 0;
  arrays.forEach((array) => {
    const arrayLength = array?.length ?? 0;
    count += arrayLength;
  });

  return count;
}
