import React from "react";
import { styled } from "@mui/material/styles";

type TRootDiv = {
  className?: string; // Doing this to allow styled(RootDiv)
  children: JSX.Element | JSX.Element[];
};

const BaseRootDiv = styled("div")<TRootDiv>({
  flexGrow: 1,
  padding: "16px",
});

export const RootDiv = ({ children, className, ...other }: TRootDiv) => {
  return (
    <BaseRootDiv className={className} {...other}>
      <>{children}</>
    </BaseRootDiv>
  );
};
