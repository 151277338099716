import * as H from "history";

export const getFormattedSearch = (search: string): string => {
  if (!search) return "";
  if (search.startsWith("?")) return search;
  return `?${search}`;
};

export const getFormattedPathname = (pathname: string): string => {
  if (pathname.startsWith("./") || pathname.startsWith("/")) return pathname;
  return `./${pathname}`;
};

export const getFormattedHash = (hash: string): string => {
  if (!hash) return "";
  if (hash.startsWith("#")) return hash;
  return `#${hash}`;
};

export const getFullPath = (
  location: H.LocationDescriptorObject<H.LocationState>
) => {
  const formattedSearch = getFormattedSearch(location.search);
  const formattedPathname = getFormattedPathname(location.pathname);
  const formattedHash = getFormattedHash(location.hash);
  return `${formattedPathname}${formattedSearch}${formattedHash}`;
};

export const getMergedRoutes = (route: string, subRoute: string): string => {
  const routes = route.split("/");
  if (routes.length > 2) routes.pop();
  return subRoute.replace("./", routes.join("/") + "/");
};
