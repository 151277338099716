export const TEMPLATE_BANNER_DESCRIPTION =
  "Template duplicated, go back to the Form Library to use the duplicated form";
export const TEMPLATE_BANNER_DUPLICATED_LINK = "View Duplicated Template";
export const NO_FORMS_MESSAGE = "No forms match your search";
export const FORM_TEMPLATES_TAG = "formTemplatesTag";
export const FORMS_TEMPLATE_PAGE_ERROR_TEXT =
  "Form Templates aren't available on smaller screens. Try increasing the width of your browser or using a device with a larger screen.";
export const FORM_TEMPLATE_WRAPPER_TEST_ID = "formsTemplateTable";
export const FORM_TEMPLATE_DATA_GRID_TEST_ID = "formsTemplateDataGrid";
export const FORM_LIBRARY_WRAPPER_TEST_ID = "formsLibraryTable";
export const FORM_LIBRARY_DATA_GRID_TEST_ID = "formsLibraryDataGrid";
export const NO_TEMPLATES = "You currently have no templates available";
export const TEMPLATES_ERROR = "An error has occurred";
export const FORMS_LIBRARY_SMALL_SCREEN_WARNING =
  "Form Library and Editor aren't available on smaller screens. Try increasing the width of your browser or using a device with a larger screen.";
export const FORM_PREVIEW = "Form Preview";
export const FORM_EXPORT_ERROR_HEADER_TEXT = "Export Failed";
export const FORM_EXPORT_ERROR_MODAL_TEXT = "Please try again.";
