import React from "react";
import { Box } from "@mui/material";
import { FormTextInput } from "../../../../../global/components/Form/FormTextInput";

export const CustomPackageName = () => (
  <Box width="60%" mt={2}>
    <FormTextInput
      noFormLabel
      parentLabel="Custom Package Name"
      placeholder="Enter Custom Package Name"
      dataTestid="customPackageName"
      name="packageName"
    />
  </Box>
);
