import React from "react";
import { useAppSelector } from "../../../../../global/hooks/useTypedRedux.hook";
import { IInOfficePatient } from "../../../../../global/types/documentManager/InOfficePatient";
import { selectIsLarge } from "../../../../layout/layoutSlice";
import {
  selectSelectedCardId,
  selectTotalCount,
} from "../inOfficePatientsSlice";
import "./in-office-patient-list.scss";
import { InfiniteScroll } from "./InfiniteScroll/InfiniteScroll";
import { InOfficePatientCard } from "../inOfficePatientCard/inOfficePatientCard/InOfficePatientCard";
import { SVirtualizedTable } from "./styles";
import { ListHeader } from "./ListHeader";

export const InOfficePatientList = ({
  inOfficePatientList,
}: {
  inOfficePatientList: IInOfficePatient[];
}) => {
  const isLarge: boolean = useAppSelector(selectIsLarge);
  const totalCount = useAppSelector(selectTotalCount);
  const selectedCardId = useAppSelector(selectSelectedCardId);

  const renderItem = (index: number) => {
    const card = inOfficePatientList[index];
    const isSelected = card.formAssignment
      ? card.formAssignment.id === selectedCardId
      : card.patient.id === selectedCardId;
    return (
      <InOfficePatientCard key={index} patient={card} isSelected={isSelected} />
    );
  };

  return (
    <>
      {isLarge ? <ListHeader /> : null}
      <InfiniteScroll
        overscan={20}
        estimatedItemSize={234}
        renderItem={renderItem}
        totalCount={totalCount}
        loadedItems={inOfficePatientList.length}
        VirtualizedTable={SVirtualizedTable}
      />
    </>
  );
};
