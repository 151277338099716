import React from "react";

import { Divider } from "@mui/material";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";

import { SMenuItem, SEllipsisText } from "./styles";

type TMenuOption = {
  label: string;
  disabled?: boolean;
  children?: TMenuOption[];
  callBack?: () => void;
};

export type TMenuOptionProps = {
  option: TMenuOption;
  index: number;
  isLast: boolean;
  isFirstLevelOptions: boolean;
  onOptionClick: (option: TMenuOption, index: number) => void;
};

const MenuOption = ({
  option,
  index,
  isLast,
  isFirstLevelOptions,
  onOptionClick,
}: TMenuOptionProps) => {
  const { children, disabled, label } = option;
  const hasChildren = !!children?.length;
  const isBeforeOption = !isFirstLevelOptions && index === 0 && hasChildren;

  const handleClick = (): void => {
    onOptionClick(option, index);
  };

  return (
    <div>
      <SMenuItem
        disabled={disabled}
        onClick={handleClick}
        isBeforeOption={isBeforeOption}
      >
        {isBeforeOption && <NavigateBefore />}
        <SEllipsisText variant="caption">{label}</SEllipsisText>
        {hasChildren && !isBeforeOption && <NavigateNext />}
      </SMenuItem>
      {!isLast && <Divider style={{ margin: "0 16px" }} />}
    </div>
  );
};

export default MenuOption;
