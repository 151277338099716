import { styled } from "@mui/material/styles";
import { MenuItem, Typography } from "@mui/material";

interface IMenuItem {
  isBeforeOption?: boolean;
}

export const SEllipsisText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const SMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "isBeforeOption",
})<IMenuItem>(({ theme, isBeforeOption }) => ({
  display: "flex",
  justifyContent: isBeforeOption ? "left" : "space-between",
  paddingLeft: isBeforeOption ? 8 : 16,
  "> span": {
    fontWeight: isBeforeOption ? "bold" : "normal",
  },
  "&:hover": {
    backgroundColor: theme.palette.accent.background,
  },
}));
