import React, { useState, useEffect } from "react";

import get from "lodash/get";
import initial from "lodash/initial";
import flatMap from "lodash/flatMap";

import { TStylableComponent } from "../../../types/ui/TStylableComponent";
import { CustomContextMenu } from "../CustomContextMenu";
import MenuOption from "./MenuOption";

export type TMenuOption = {
  label: string;
  disabled?: boolean;
  children?: TMenuOption[];
  callBack?: () => void;
};

interface IContextMenuOptionsPropTypes {
  options: TMenuOption[];
  openFromElement: HTMLElement;
  hideParent: () => void;
  parentRef?: HTMLElement;
  shouldCloseOnSelect?: boolean;
}

const CHILDREN_KEY = "children";

const ContextMenuOptions: React.FC<
  TStylableComponent<IContextMenuOptionsPropTypes>
> = ({
  options,
  openFromElement,
  hideParent,
  parentRef,
  shouldCloseOnSelect = false,
}: IContextMenuOptionsPropTypes) => {
  const [levelOptions, setLevelOptions] = useState<TMenuOption[]>(options);
  const [pathKeys, setPathKeys] = useState<number[]>([]);

  const isFirstLevelOptions = pathKeys.length === 0;

  useEffect(() => {
    if (options) {
      updateLevelOptions(pathKeys);
    }
  }, [options, pathKeys]);

  const handleOptionClick = (option: TMenuOption, index: number): void => {
    if (!isFirstLevelOptions && index === 0 && option.children?.length) {
      handleBeforeOptionClick();
    } else if (option.children?.length) {
      handleChildOptionClick(option, index);
    } else {
      option.callBack && option.callBack();
      shouldCloseOnSelect && closeContextMenu();
    }
  };

  const handleBeforeOptionClick = (): void => {
    const previousPathKeys = initial(pathKeys);
    setPathKeys(previousPathKeys);
  };

  const handleChildOptionClick = (option: TMenuOption, index: number): void => {
    const optionIndex = isFirstLevelOptions ? index : index - 1;
    setPathKeys((prev) => [...prev, optionIndex]);
  };

  const updateLevelOptions = (path: number[]): void => {
    const pathWithChildren = flatMap(path, (element) => [
      element,
      CHILDREN_KEY,
    ]);
    if (pathWithChildren.length) {
      const option = get(options, initial(pathWithChildren));
      setLevelOptions([option, ...option.children]);
    } else {
      setLevelOptions(options);
    }
  };

  const closeContextMenu = (): void => {
    hideParent();
    setLevelOptions(options);
    setPathKeys([]);
  };

  const handleClose = (e): void => {
    e.stopPropagation();
    closeContextMenu();
  };

  return (
    <CustomContextMenu
      parentRef={parentRef}
      anchorEl={openFromElement}
      onClose={handleClose}
    >
      {levelOptions.map((option, index) => (
        <MenuOption
          key={`${option.label}_${index}`}
          option={option}
          index={index}
          isLast={index === levelOptions.length - 1}
          isFirstLevelOptions={isFirstLevelOptions}
          onOptionClick={handleOptionClick}
        />
      ))}
    </CustomContextMenu>
  );
};

export default ContextMenuOptions;
