export const formatPatientName = (
  first: string,
  middle: string,
  last: string,
  preferred: string
) => {
  const patientPreferredName = preferred
    ? `(${preferred.substring(0, 10)})`
    : "";
  return `${first} ${
    middle ? middle.substring(0, 1) : ""
  } ${last} ${patientPreferredName}`;
};
