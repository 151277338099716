import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../../../global/helperFunctions/api/baseQueryWithReauth";
import { TEN_MINUTES_IN_S } from "../../../../global/constants";
import { sliceArrayIntoChunks } from "../../../../global/helperFunctions/arrays/sliceArrayIntoChunks";
import {
  setFormUploadFailedModalOpen,
  setIsRefetchFormTable,
} from "./formLibrarySlice";
import { parseResponse } from "./parseResponse";
import { CONTENT_TYPE } from "./constants";

export const importFormApi = createApi({
  reducerPath: "importFormApi",
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_YAPI_DATAFORMS,
    CONTENT_TYPE
  ),
  endpoints: (build) => ({
    importForms: build.mutation<unknown, { files: File[] }>({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const LIMIT_OF_CONCURRENT_REQUESTS = 5;
        const { files } = args;
        const chunks = sliceArrayIntoChunks(
          files,
          LIMIT_OF_CONCURRENT_REQUESTS
        );
        let result = [];
        for (const item of chunks) {
          const formsRequest = item.map((file) => {
            const formData = new FormData();
            formData.append("file", file);

            return fetchWithBQ({
              url: "/form/import",
              method: "POST",
              body: formData,
            });
          });
          const chunkResponse = await Promise.allSettled(formsRequest);
          const parsedResponse = parseResponse(chunkResponse, item);

          result = [...result, ...parsedResponse];
        }

        const brokenForms = result
          .filter((form) => form.isError)
          .map((form) => form.fileName);

        if (brokenForms.length) {
          api.dispatch(
            setFormUploadFailedModalOpen({
              isOpen: true,
              failedForms: brokenForms,
            })
          );
        }

        if (result.length !== brokenForms.length) {
          api.dispatch(setIsRefetchFormTable(true));
        }

        return { data: result };
      },
    }),
  }),
  keepUnusedDataFor: TEN_MINUTES_IN_S,
});

export const { useImportFormsMutation } = importFormApi;
