import React from "react";

import { FormsTableRow } from "./FormRow";
import { TSingleForm } from "./types/TSingleForm";

export const SingleForm = ({
  form,
  onRemoveForm,
}: TSingleForm): JSX.Element => {
  const { formId, formName, packageId } = form;
  return (
    <FormsTableRow
      key={`${packageId}${formId}`}
      showRemoveIcon={!packageId}
      name={formName}
      onRemove={() => onRemoveForm(form)}
    />
  );
};
