import { Typography, styled } from "@mui/material";

type TSecondaryText = {
  fontSize?: string;
};

export const SecondaryText = styled(Typography)<TSecondaryText>(
  ({ theme, fontSize = "0.875rem!important" }) => ({
    color: theme.palette.text.secondary,
    fontSize,
  })
);
