import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";

type TLeaveWithoutSaving = {
  isOpen: boolean;
  leaveWithoutSavingCallback?: () => Promise<void> | void;
};

type TDraftLeaveWithoutSaving = {
  isOpen: boolean;
  draftLeaveWithoutSavingCallback?: () => Promise<void> | void;
};

interface ISnackbarState {
  status: boolean;
  message: string;
}

interface GlobalMessagesType {
  error: boolean;
  hasFormChanges: boolean;
  leaveWithoutSaving: TLeaveWithoutSaving;
  draftLeaveWithoutSaving: TDraftLeaveWithoutSaving;
  activeLeaveModal: string;
  snackbar: ISnackbarState;
  errorSnackbar: ISnackbarState;
}

type TDisplayLeaveWithoutSavingModal = {
  onLeave: () => void;
};

const initialState: GlobalMessagesType = {
  error: false,
  hasFormChanges: false,
  leaveWithoutSaving: {
    isOpen: false,
  },
  draftLeaveWithoutSaving: {
    isOpen: false,
  },
  activeLeaveModal: null,
  snackbar: {
    status: false,
    message: "",
  },
  errorSnackbar: {
    status: false,
    message: "",
  },
};

const globalMessagesSlice = createSlice({
  name: "globalMessages",
  initialState,
  reducers: {
    updateSnackbar: (state, action: PayloadAction<ISnackbarState>) => {
      state.snackbar = action.payload;
    },
    updateErrorSnackbar: (state, action: PayloadAction<ISnackbarState>) => {
      state.errorSnackbar = action.payload;
    },
    updateHasFormChanges: (state, action: PayloadAction<boolean>) => {
      state.hasFormChanges = action.payload;
    },
    updateError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    updateLeaveWithoutSavingCallback: (
      state,
      action: PayloadAction<() => void>
    ) => {
      state.leaveWithoutSaving.leaveWithoutSavingCallback = action.payload;
    },
    displayLeaveWithoutSavingModal: (
      state,
      action: PayloadAction<TDisplayLeaveWithoutSavingModal>
    ) => {
      state.leaveWithoutSaving.isOpen = true;
      state.leaveWithoutSaving.leaveWithoutSavingCallback =
        action.payload.onLeave;
    },
    hideLeaveWithoutSavingModal: (state) => {
      state.leaveWithoutSaving.isOpen = false;
      state.leaveWithoutSaving.leaveWithoutSavingCallback = undefined;
    },
    updateDraftLeaveWithoutSavingCallback: (
      state,
      action: PayloadAction<() => void>
    ) => {
      state.draftLeaveWithoutSaving.draftLeaveWithoutSavingCallback =
        action.payload;
    },
    displayDraftLeaveWithoutSavingModal: (
      state,
      action: PayloadAction<TDisplayLeaveWithoutSavingModal>
    ) => {
      state.draftLeaveWithoutSaving.isOpen = true;
      state.draftLeaveWithoutSaving.draftLeaveWithoutSavingCallback =
        action.payload.onLeave;
    },
    hideDraftLeaveWithoutSavingModal: (state) => {
      state.draftLeaveWithoutSaving.isOpen = false;
      state.draftLeaveWithoutSaving.draftLeaveWithoutSavingCallback = undefined;
    },
    setActiveLeaveModal: (state, action: PayloadAction<string>) => {
      state.activeLeaveModal = action.payload;
    },
  },
});

export const selectHasFormChanges = (state: RootState) =>
  state.globalMessages.hasFormChanges;
export const selectSnackbar = (state: RootState) =>
  state.globalMessages.snackbar;
export const selectErrorSnackbar = (state: RootState): ISnackbarState =>
  state.globalMessages.errorSnackbar;
export const selectErrorState = (state: RootState) =>
  state.globalMessages.error;
export const selectLeaveWithoutSaving = (state: RootState) =>
  state.globalMessages.leaveWithoutSaving;
export const selectDraftLeaveWithoutSaving = (state: RootState) =>
  state.globalMessages.draftLeaveWithoutSaving;
export const selectActiveLeaveModal = (state: RootState) =>
  state.globalMessages.activeLeaveModal;

export const {
  updateLeaveWithoutSavingCallback,
  updateHasFormChanges,
  updateSnackbar,
  updateErrorSnackbar,
  updateError,
  displayLeaveWithoutSavingModal,
  hideLeaveWithoutSavingModal,
  updateDraftLeaveWithoutSavingCallback,
  displayDraftLeaveWithoutSavingModal,
  hideDraftLeaveWithoutSavingModal,
  setActiveLeaveModal,
} = globalMessagesSlice.actions;

export default globalMessagesSlice.reducer;
