import { styled } from "@mui/material";

export const SCustomScroll = styled("main", {
  shouldForwardProp: (prop) => prop !== "hidden",
})<{
  hidden: boolean;
}>(({ theme, hidden }) => ({
  "&::-webkit-scrollbar": {
    width: 6,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 6px ${theme.palette.text.disabled}`,
    borderRadius: 4,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 4,
    outline: "1px solid slategrey",
    backgroundClip: "content-box",
  },
  flex: "1",
  height: `100%`,
  overflow: hidden ? null : "auto",
  position: "relative",
}));

export const SInfiniteScrollRow = styled("div")<{
  start: number;
}>(({ start }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  transform: `translateY(${start}px)`,
}));
