import { TLanguages } from "../../types/settings/formBuilder/TLanguages";

export const getUrlParamsFromArray = (
  fieldName: string,
  values: TLanguages | number[] | string[]
): string => {
  const paramName = `&$${fieldName}=`;

  return values?.length ? `${paramName}${values.join(paramName)}` : "";
};
