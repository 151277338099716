import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { difference } from "lodash";

import { RootState } from "../../../../global/store";
import { ESummaryDetailsType } from "../../../../global/enums/EAppointmentStatus";
import { EPatientsListType } from "./choosePatients/summaryDetails/EPatientsListType";
import { ISummaryDetails } from "./fillAppointment.interface";

export interface IAsapForm {
  appointmentTypeId: string;
  providerId: string;
  excludeContactedToday: boolean;
  appointmentId?: string;
}

export interface IRecallForm {
  recallTypeIds?: string[];
  lastReminded: string;
  excludeContactedToday: boolean;
}

export interface IRemovedAppointmentIds {
  asap: string[];
  recall: string[];
}

export interface IPatientActionPayload {
  id: string;
  type: EPatientsListType;
}

export interface IFillAppointmentData {
  step: number;
  patientsListType: EPatientsListType;
  editorText: string;
  asapForm: IAsapForm;
  recallForm: IRecallForm;
  summaryDetails: ISummaryDetails;
  removedAppointmentIds: IRemovedAppointmentIds;
}

export const initialState: IFillAppointmentData = {
  step: 0,
  patientsListType: null,
  editorText: "",
  asapForm: {
    appointmentTypeId: null,
    providerId: null,
    excludeContactedToday: true,
  },
  recallForm: {
    recallTypeIds: [],
    lastReminded: "NeverReminded",
    excludeContactedToday: true,
  },
  summaryDetails: {
    ids: [],
    smsOptOutIds: [],
    noMobilePhoneIds: [],
  },
  removedAppointmentIds: {
    asap: [],
    recall: [],
  },
};

const fillAppointmentSlice = createSlice({
  name: "fillAppointment",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    updatePatientsListType: (
      state,
      action: PayloadAction<EPatientsListType>
    ) => {
      state.patientsListType = action.payload;
    },
    updateEditorText: (state, action: PayloadAction<string>) => {
      state.editorText = action.payload;
    },
    updateAsapForm: (state, action: PayloadAction<IAsapForm>) => {
      state.asapForm = action.payload;
    },
    updateRecallForm: (state, action: PayloadAction<IRecallForm>) => {
      state.recallForm = action.payload;
    },
    setSummaryDetails: (state, action: PayloadAction<ISummaryDetails>) => {
      state.summaryDetails = action.payload;
    },
    addAppointment: (
      state,
      { payload }: PayloadAction<IPatientActionPayload>
    ) => {
      state.removedAppointmentIds[payload.type] = state.removedAppointmentIds[
        payload.type
      ].filter((item) => item !== payload.id);
    },
    removeAppointment: (
      state,
      { payload }: PayloadAction<IPatientActionPayload>
    ) => {
      state.removedAppointmentIds[payload.type] = [
        ...state.removedAppointmentIds[payload.type],
        payload.id,
      ];
    },
    resetFillAppointmentState: (state) => {
      state.patientsListType = initialState.patientsListType;
      state.editorText = initialState.editorText;
      state.asapForm = initialState.asapForm;
      state.recallForm = initialState.recallForm;
      state.summaryDetails = initialState.summaryDetails;
      state.removedAppointmentIds = initialState.removedAppointmentIds;
      state.step = initialState.step;
    },
  },
});

export const selectStep = (state: RootState): number =>
  state.fillAppointmentState.step;

export const selectPatientsListType = (state: RootState): EPatientsListType =>
  state.fillAppointmentState.patientsListType;

export const selectEditorText = (state: RootState): string =>
  state.fillAppointmentState.editorText;

export const selectAsapForm = (state: RootState): IAsapForm =>
  state.fillAppointmentState.asapForm;

export const selectRecallForm = (state: RootState): IRecallForm =>
  state.fillAppointmentState.recallForm;

export const selectRemovedAppointmentIds =
  (type: EPatientsListType) =>
  (state: RootState): string[] =>
    state.fillAppointmentState.removedAppointmentIds[type];

export const selectAddedAppointmentIds =
  (type: ESummaryDetailsType, patientsListType: EPatientsListType) =>
  (state: RootState): string[] =>
    difference(
      state.fillAppointmentState.summaryDetails[type],
      state.fillAppointmentState.removedAppointmentIds[patientsListType]
    );

export const selectTotalMassTextAppointmentIds =
  (type: EPatientsListType) =>
  (state: RootState): string[] => {
    const { ids, smsOptOutIds, noMobilePhoneIds } =
      state.fillAppointmentState.summaryDetails;
    const { removedAppointmentIds } = state.fillAppointmentState;

    return difference(
      ids,
      smsOptOutIds,
      noMobilePhoneIds,
      removedAppointmentIds[type]
    );
  };

export const {
  setStep,
  updatePatientsListType,
  updateEditorText,
  updateAsapForm,
  updateRecallForm,
  setSummaryDetails,
  addAppointment,
  removeAppointment,
  resetFillAppointmentState,
} = fillAppointmentSlice.actions;

export default fillAppointmentSlice.reducer;
