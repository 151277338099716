import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../global/store";

interface IProviderTotals {
  all: number;
  active: number;
  inactive: number;
}

interface providerSliceTypes {
  providerTotals: IProviderTotals;
  currentProviderName: string;
}

const initialState: providerSliceTypes = {
  providerTotals: { all: 0, active: 0, inactive: 0 },
  currentProviderName: "",
};

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    updateProviderTotals: (state, action: PayloadAction<any>) => {
      state.providerTotals = action.payload;
    },
    updateCurrentProviderName: (state, action: PayloadAction<any>) => {
      state.currentProviderName = action.payload;
    },
  },
});

export const selectGetProviderTotals = (state: RootState): IProviderTotals =>
  state.providerState.providerTotals;

export const selectGetCurrentProviderName = (state: RootState): string =>
  state.providerState.currentProviderName;

export const { updateProviderTotals, updateCurrentProviderName } =
  providersSlice.actions;

export default providersSlice.reducer;
