import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../global/store";

import { IWidget } from "../../global/types/dashboard/IWidget.interface";

interface DashboardStateType {
  activeWidgets: IWidget[];
  inactiveWidgets: IWidget[];
}
const initialState: DashboardStateType = {
  activeWidgets: [
    {
      id: 0,
      name: "campaign",
      icon: "PatientEngagementNavIcon",
      title: "Campaign Statistics",
      widgetText: "Open Rate",
      date: "12/26/2021",
      amount: 52,
      currentSize: "small",
      buttonText: "View Campaign Statistics",
    },
  ],
  inactiveWidgets: [
    {
      id: 1,
      name: "schedule",
      icon: "ScheduleNavIcon",
      title: "Schedule",
      widgetText: "Upcoming Appointments",
      date: "12/26/2021",
      amount: 101,
      currentSize: "small",
      buttonText: "View Upcoming Appointments",
    },
    {
      id: 2,
      name: "payments",
      icon: "PaymentsNavIcon",
      title: "Payments",
      widgetText: "Payments Scheduled",
      date: "11/12/2021",
      amount: 15,
      currentSize: "small",
      buttonText: "View Late Payment Requests",
    },
    {
      id: 3,
      name: "document",
      icon: "DocumentsNavIcon",
      title: "Documents",
      widgetText: "Signatures Needed",
      date: "09/22/2021",
      amount: 4,
      currentSize: "small",
      buttonText: "View & Sign Documents",
    },
    {
      id: 4,
      name: "review",
      icon: "ShareAndReviewNavIcon",
      title: "InstaReview",
      widgetText: "New Reviews",
      date: "09/22/2021",
      amount: 11,
      currentSize: "small",
      buttonText: "View InstaReview Reports",
    },
    {
      id: 5,
      name: "careCalls",
      icon: "MyCareNavIcon",
      title: "Care Calls",
      widgetText: "Care Calls Scheduled",
      date: "06/31/2022",
      amount: 11,
      currentSize: "small",
      buttonText: "View Care Call List",
    },
  ],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateActiveWidgets: (state, action: PayloadAction<IWidget[]>) => {
      state.activeWidgets = action.payload;
    },
    updateInactiveWidgets: (state, action: PayloadAction<IWidget[]>) => {
      state.inactiveWidgets = action.payload;
    },
    removeActiveWidget: (state, action: PayloadAction<string>) => {
      state.activeWidgets.forEach(function (elem, index) {
        if (elem.name === action.payload) {
          state.activeWidgets.splice(index, 1);
          state.inactiveWidgets.push(elem);
        }
      });
    },
    addAvailableWidget: (state, action: PayloadAction<string>) => {
      state.inactiveWidgets.forEach(function (elem, index) {
        if (elem.name === action.payload) {
          state.inactiveWidgets.splice(index, 1);
          state.activeWidgets.push(elem);
        }
      });
    },
    defaultWidgetOrder: (state) => {
      state.activeWidgets = initialState.activeWidgets;
      state.inactiveWidgets = initialState.inactiveWidgets;
    },
  },
});

export const {
  updateActiveWidgets,
  updateInactiveWidgets,
  removeActiveWidget,
  addAvailableWidget,
  defaultWidgetOrder,
} = dashboardSlice.actions;

export const selectActiveWidgets = (state: RootState) =>
  state.dashboardState.activeWidgets;
export const selectInactiveWidgets = (state: RootState) =>
  state.dashboardState.inactiveWidgets;
export default dashboardSlice.reducer;
