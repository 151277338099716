import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "../../../../../global/helperFunctions/api/baseQueryWithReauth";
import {
  IAppointmentReminderExclusionsResponse,
  IAppointmentRemindersApiContract,
} from "./IAppointmentRemindersApiContract";
import { EAppointmentReminderExclusionType } from "./enums/EAppointmentReminderExclusionType";

const TAG_NAME_EXCLUSIONS = "TAG_NAME_EXCLUSIONS";
export const appointmentRemindersApi = createApi({
  reducerPath: "appointmentRemindersApi",
  tagTypes: [TAG_NAME_EXCLUSIONS],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getAppointmentRemindersConfig: builder.query<
      IAppointmentRemindersApiContract,
      void
    >({
      query: () => "AppointmentReminderConfigurationDetails/current",
    }),

    setAppointmentRemindersConfig: builder.mutation<
      {
        type: string;
        title: string;
        status: number;
        date: string;
        instance: string;
      },
      IAppointmentRemindersApiContract
    >({
      query: (body) => ({
        url: "AppointmentReminderConfigurationDetails/current",
        method: "PUT",
        body,
      }),
    }),
    getAppointmentRemindersExclusions: builder.query<
      IAppointmentReminderExclusionsResponse,
      void
    >({
      query: () => "AppointmentReminderExclusions",
      providesTags: [TAG_NAME_EXCLUSIONS],
    }),
    setAppointmentReminderExclusion: builder.mutation<
      {
        type: EAppointmentReminderExclusionType;
        id: string;
      },
      { type: EAppointmentReminderExclusionType; id: string }
    >({
      query: ({ type, id }) => ({
        url: `AppointmentReminderExclusions/${type}:${id}`,
        method: "PUT",
        responseHandler: "text",
      }),
      invalidatesTags: [TAG_NAME_EXCLUSIONS],
    }),
    deleteAppointmentReminderExclusion: builder.mutation<
      {
        type: EAppointmentReminderExclusionType;
        id: string;
      },
      { type: EAppointmentReminderExclusionType; id: string }
    >({
      query: ({ type, id }) => ({
        url: `AppointmentReminderExclusions/${type}:${id}`,
        method: "DELETE",
        responseHandler: "text",
      }),
      invalidatesTags: [TAG_NAME_EXCLUSIONS],
    }),
  }),
});

export const {
  useGetAppointmentRemindersConfigQuery,
  useSetAppointmentRemindersConfigMutation,
  useGetAppointmentRemindersExclusionsQuery,
  useSetAppointmentReminderExclusionMutation,
  useDeleteAppointmentReminderExclusionMutation,
} = appointmentRemindersApi;
