import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../../helperFunctions/api/baseQueryWithReauth";

export const emailApi = createApi({
  reducerPath: "emailApi",
  tagTypes: [],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getEmailTemplate: builder.query<any, string>({
      query: (category) => {
        return `MailchimpTemplates/${category}`;
      },
    }),
  }),
});

export const { useGetEmailTemplateQuery } = emailApi;
