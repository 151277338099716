import React from "react";
import { EFormStatus } from "../../../../../../../global/types/documentManager/EFormStatus";
import { IFormAssignmentForm } from "../../../../../../../global/types/documentManager/InOfficePatient";
import { CircleWithLabel } from "../../../../../../../global/components/CircleWithLabel/CircleWithLabel";
import { useTheme } from "@mui/material";

const PatientTaskStatus = ({ forms }: { forms: IFormAssignmentForm[] }) => {
  const theme = useTheme();

  if (!forms.length) return null;

  const isComplete = forms.every(
    (form) => form.status === EFormStatus.Submitted
  );

  if (isComplete)
    return (
      <CircleWithLabel
        circleColor={theme.palette.success.main}
        label="Completed"
      />
    );

  return (
    <CircleWithLabel
      circleColor={theme.palette.primary.main}
      label="Incomplete"
    />
  );
};

export default PatientTaskStatus;
