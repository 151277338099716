import { Select, styled } from "@mui/material";

export const SSelectDropdown = styled(Select, {
  shouldForwardProp: (prop) => prop !== "placeholder",
})(({ placeholder }) => ({
  backgroundColor: "transparent",
  "&:after": {
    borderBottomColor: "black",
    backgroundColor: "transparent",
  },
  ".MuiSelect-select": {
    paddingLeft: "15px",
    paddingTop: "20px",
    paddingBottom: "5px",
  },
  ".MuiSelect-iconOpen": {
    transform: "rotate(-180deg)",
  },
  ".MuiSelect-iconClose": {
    transform: "rotate(0)",
  },
  ".MuiList-padding": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  ".MuiSelect-select:focus": {
    background: "transparent",
  },
  "& .MuiSelect-select .notranslate::after": placeholder
    ? {
        content: `"${placeholder}"`,
        opacity: 0.42,
      }
    : {},
}));
