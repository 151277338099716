import { EFormStatus } from "../../../../../../global/types/documentManager/EFormStatus";
import { IFormAssignmentForm } from "../../../../../../global/types/documentManager/InOfficePatient";
import { TFormGroups } from "./types/TFormGroups";
import { TBySelector } from "./types/TBySelector";

export const getFormGroups = (forms: IFormAssignmentForm[]): TFormGroups => {
  const formsByCategory: Record<number, IFormAssignmentForm[]> = {
    [EFormStatus.InProgress]: [],
    [EFormStatus.Submitted]: [],
    [EFormStatus.Todo]: [],
  };

  const formsById: TBySelector = {};
  const formsByResponseId: TBySelector = {};

  forms.forEach((form) => {
    formsByCategory[form.status].push(form);
    formsById[form.id] = form;
    formsByResponseId[form.responseId] = form;
  });
  return {
    byCategory: formsByCategory,
    byId: formsById,
    byResponseId: formsByResponseId,
  };
};
